import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { blueGrey, deepOrange, grey, purple } from "@material-ui/core/colors";
import { Add, People } from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";
import ApiRequest from "../../../api/ApiRequest";
import { BNK_API_GET_CUSTOMER_STATISTICS } from "../../../api/apiThreads";
import { SelectBranchMenu } from "../Transactions/TransactionsTable";
import AccountsTable from "./AccountsTable";
import OpenCustomerAccountButton from "./OpenCustomerAccountButton";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "#F5F5FD",
    // borderRadius: "10px",
    // padding: "10px",
  },
  paper: {
    minHeight: "200px",
    boxShadow: theme.boxShadows[1],
    borderRadius: "10px",
    padding: "20px",
  },
  verticalDivider: {
    height: "40px",
    marginTop: "4px",
    width: "1px",
    backgroundColor: theme.palette.primary.main,
  },
  statTitle: {
    color: blueGrey[700],
    fontWeight: 500,
    marginBottom: "10px",
  },
  smallTitleText: {
    fontSize: "0.9rem",
    color: grey[700],
    marginTop: "10px",
  },
  circle: {
    width: "100px",
    height: "100px",
    border: "solid 10px " + deepOrange[700],
    borderRadius: "100%",
  },
}));

export default function Accounts(props) {
  const classes = useStyles();
  const [apiQueryState, setApiQueryState] = useState({
    branch: "ALL",
  });
  return (
    <Grid container spacing={3} justify="center" className={clsx(classes.root)}>
      <Grid item xs={12}>
        <Helmet>
          <title>Customer Wallets</title>
        </Helmet>
        <div className="d-flex justify-content-between align-items-center">
          <Typography variant="h6">Customers Wallets</Typography>
          <OpenCustomerAccountButton />
        </div>
        <Divider className="mt-2" />
      </Grid>
      <Grid item xs={12} md={8} xl={9}>
        <AccountsTable title="Customer Accounts" />
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <CustomerStatistics
          apiQueryState={apiQueryState}
          setApiQueryState={setApiQueryState}
          showSelectBranch={true}
        />
      </Grid>
    </Grid>
  );
}

export function CustomerStatistics(props) {
  const classes = useStyles();
  const rootUrl = useRouteMatch().url;
  const { apiQueryState, setApiQueryState } = props;

  return (
    <Paper className={classes.paper}>
      <ApiRequest
        autoload
        thread={BNK_API_GET_CUSTOMER_STATISTICS}
        query={apiQueryState}
        addOnThread={JSON.stringify(apiQueryState)}
      >
        {({ loading, RefreshButton, error, res, ErrorView }) => {
          if (loading == true) {
            return (
              <div className="w-100 text-center p-4" align="center">
                <CircularProgress />
              </div>
            );
          }

          if (error == true) {
            return <ErrorView />;
          }

          let statistics = null;
          if (typeof res == "object") {
            if (typeof res.data == "object") {
              if (typeof res.data.statistics == "object") {
                statistics = res.data.statistics;
              }
            }
          }

          if (statistics == null) {
            return (
              <div className="p-5 w-100" align="center">
                <Typography>Failed to get statistics</Typography>
              </div>
            );
          }

          return (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid
                  container
                  className="align-items-center justify-content-between"
                >
                  <Grid item>
                    <small>Customer</small>
                    <Typography className={classes.statTitle}>
                      Accounts Summary
                    </Typography>
                  </Grid>
                  <Grid item>
                    {props.showSelectBranch === true ? (
                      <SelectBranchMenu
                        apiQueryState={apiQueryState}
                        setApiQueryState={setApiQueryState}
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <div className="d-flex  align-items-center">
                  <div className="me-2">
                    <People style={{ fontSize: "100px", color: purple[500] }} />
                  </div>
                  <div>
                    <Typography variant="h2">{statistics.customers}</Typography>
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: "0.9rem",
                        color: grey[500],
                      }}
                    >
                      Opened Accounts
                    </Typography>
                  </div>
                </div>

                {/* <Typography className={classes.statTitle}>
              Customers accounts summary
            </Typography> */}
                {/* <div className="d-flex justify-content-between">
              <div>
                <Typography variant="h3">2001</Typography>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "0.9rem",
                    color: grey[500],
                  }}
                >
                  Opened Accounts
                </Typography>
              </div>
              <div>
                <Typography variant="h3">2001</Typography>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "0.9rem",
                    color: grey[500],
                  }}
                >
                  Inactive Accounts
                </Typography>
              </div>
              <div>
                <Typography variant="h3">2001</Typography>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "0.9rem",
                    color: grey[500],
                  }}
                >
                  Closed Accounts
                </Typography>
              </div>
            </div> */}
                {/* <Divider className="my-3" />
            <Typography className={classes.statTitle}>
              Customers by gender summary
            </Typography>
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <div className="pr-3 pr-md-4 d-flex flex-wrap align-items-end">
                  <Typography variant="h4">2001</Typography>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "0.9rem",
                      color: grey[500],
                    }}
                    className="pl-2"
                  >
                    Males
                  </Typography>
                </div>
                <div className="pr-3 pr-md-4 d-flex flex-wrap align-items-end">
                  <Typography variant="h4">2001</Typography>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "0.9rem",
                      color: grey[500],
                    }}
                    className="pl-2"
                  >
                    Females
                  </Typography>
                </div>
              </div>
              <Divider
                className={clsx(classes.verticalDivider, "mx-3")}
                orientation="vertical"
              />
              <div className="d-flex">
                <div className="pr-3 pr-md-4 d-flex flex-wrap align-items-end">
                  <Typography variant="h4">2001</Typography>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "0.9rem",
                      color: grey[500],
                    }}
                    className="pl-2"
                  >
                    Adults
                  </Typography>
                </div>
                <div className="pr-3 pr-md-4 d-flex flex-wrap align-items-end">
                  <Typography variant="h4">2001</Typography>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "0.9rem",
                      color: grey[500],
                    }}
                    className="pl-2"
                  >
                    Children
                  </Typography>
                </div>
              </div>
            </div> */}
              </Grid>
            </Grid>
          );
        }}
      </ApiRequest>
    </Paper>
  );
}
