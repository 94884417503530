import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Radio,
  Typography,
} from "@material-ui/core";
import { ArrowForward, Close, Edit } from "@material-ui/icons";
import React, { useState } from "react";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_GET_ALL_LOANS,
  BNK_API_GET_APPROVED_LOAN_FOR_EDITING,
  BNK_API_UPDATE_APPROVED_LOAN,
} from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";
import {
  loanApplicationStyles,
  StepThreeChooseLoanType,
} from "./LoanApplicationButton";

export default function EditApprovedLoan(props) {
  const classes = loanApplicationStyles();
  const [dialogState, setDialogState] = useState(false);
  const [addOnThread, setAddOnThread] = useState(props.application_id);
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
    console.log(props);
  };
  return (
    <React.Fragment>
      <IconButton onClick={onDialogStateChange}>
        <Edit />
      </IconButton>
      <Dialog open={dialogState} maxWidth="sm" fullWidth>
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <Typography>Edit Approved Loan</Typography>
            <IconButton onClick={onDialogStateChange}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <ApiRequest
            autoload
            addOnThread={addOnThread}
            thread={BNK_API_GET_APPROVED_LOAN_FOR_EDITING}
            payload={{
              application_id: props.application_id,
              loan_application_id: props.application_id,
              branch_id: props.branch_id,
            }}
          >
            {({ loading, res, error, ErrorView }) => {
              if (loading) {
                return <CircularProgress />;
              }

              if (error) {
                return <ErrorView />;
              }

              let loan = null;
              if (typeof res == "object") {
                if (typeof res.data == "object") {
                  if (res.data.loan) {
                    loan = res.data.loan;
                  }
                }
              }

              if (error) {
                return <ErrorView />;
              }

              if (loan == null) {
                return <Typography>Failed to find loan.</Typography>;
              }

              return (
                <ApiRequest
                  thread={BNK_API_UPDATE_APPROVED_LOAN}
                  initialPayload={{
                    ...loan,
                  }}
                  reloadOnSuccess={[
                    BNK_API_GET_ALL_LOANS,
                    BNK_API_GET_APPROVED_LOAN_FOR_EDITING +
                      props.application_id,
                  ]}
                  callbacks={{
                    success: (res, xthis) => {
                      if (typeof res === "object") {
                        if (typeof res.data === "object") {
                          if (res.data.updated === true) {
                            onDialogStateChange();
                          }
                        }
                      }
                    },
                  }}
                >
                  {(reqBody) => {
                    const { input, payload, setPayload, SubmitButton } =
                      reqBody;
                    return (
                      <div>
                        <div>
                          <StepThreeChooseLoanType fullWidth {...reqBody} />
                        </div>
                        {/* <Typography variant="h6" className="my-3">
                            Guarantor Details
                        </Typography> */}
                        <div>
                          {/* <StepThreeSelectGuarantorAccount
                            fullWidth
                            {...reqBody}
                          /> */}
                        </div>
                        <Paper className="my-2 p-3">
                          <Typography variant="h5">Customer Request</Typography>
                          <Divider className="my-2" />
                          <KijjeInput
                            square
                            placeholder="Enter the amount your applying for"
                            variant="filled"
                            label="Loan amount applied:"
                            {...input({ name: "loan_amount" })}
                            endIcon={<span className="pe-2">Ush</span>}
                          />
                          <Typography className={classes.label}>
                            Purpose of loan
                          </Typography>
                          <FormGroup size="small" row>
                            <FormControlLabel
                              size="small"
                              onClick={() =>
                                setPayload({ loan_purpose: "business" })
                              }
                              control={
                                <Radio
                                  checked={payload.loan_purpose == "business"}
                                  size="small"
                                />
                              }
                              label="Business"
                            />
                            <FormControlLabel
                              onClick={() =>
                                setPayload({ loan_purpose: "medical" })
                              }
                              size="small"
                              control={
                                <Radio
                                  size="small"
                                  checked={payload.loan_purpose === "medical"}
                                />
                              }
                              label="Medical"
                            />
                            <FormControlLabel
                              size="small"
                              onClick={() =>
                                setPayload({ loan_purpose: "school_fees" })
                              }
                              control={
                                <Radio
                                  size="small"
                                  checked={
                                    payload.loan_purpose === "school_fees"
                                  }
                                />
                              }
                              label="School Fees"
                            />
                            <FormControlLabel
                              size="small"
                              onClick={() =>
                                setPayload({ loan_purpose: "other" })
                              }
                              control={
                                <Radio
                                  size="small"
                                  checked={payload.loan_purpose === "other"}
                                />
                              }
                              label="Other"
                            />
                          </FormGroup>
                          <KijjeInput
                            square
                            placeholder="Your first installment date:"
                            variant="filled"
                            label="First installment"
                            type="date"
                            {...input({ name: "first_installment_date" })}
                          />
                          <div>
                            <label>Installment type</label>
                            <select
                              className={classes.select}
                              {...input({ name: "installment_type" })}
                            >
                              <option value="DAILY">Daily</option>
                              <option value="WEEKLY">Weekly</option>
                              <option value="MONTHLY">Monthly</option>
                              <option value="YEARLY">Yearly</option>
                            </select>
                          </div>
                          <KijjeInput
                            square
                            placeholder="Amount in Ugx:"
                            variant="filled"
                            label="Amount per installment"
                            type="text"
                            {...input({ name: "amount_per_installment" })}
                          />
                        </Paper>
                        <Divider className="my-3" />
                        <div className="w-100 d-flex justify-content-end mb-4">
                          <SubmitButton
                            variant="contained"
                            endIcon={<ArrowForward />}
                            size="small"
                          >
                            Update Loan
                          </SubmitButton>
                        </div>
                      </div>
                    );
                  }}
                </ApiRequest>
              );
            }}
          </ApiRequest>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
