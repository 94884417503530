import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React from "react";

export default function DeleteTransactionButton(props) {
  return (
    <IconButton>
      <Delete />
    </IconButton>
  );
}
