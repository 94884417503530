import axios from "axios";
import store, { openConfirmationPinDialog } from "./store";

axios.interceptors.request.use(
  (req) => {
    // Add configurations here
    // store.dispatch(openConfirmationPinDialog());
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);
