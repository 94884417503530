import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React from "react";
import { NavLink, Route, Switch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tab: {
    color: "#5C607F",
    borderRadius: "5px!important",
    // fontWeight: 300,
    letterSpacing: 1,
  },
  activeTab: {
    backgroundColor: grey[300],
  },
  contentRoot: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    minHeight: "300px",
  }
}));

export default function Settings(props) {
  const classes = useStyles();
  const { subModules } = props;
  return (
    <Grid container style={{ marginTop: "-20px" }}>
      <Grid item xs={12}>
        <Divider className="my-3" />
        <Typography variant="h6" className="mt-4">
          Account Settings
        </Typography>
        <div className="mt-3">
          {subModules.map(({ path, name }, index) => {
            return (
              <Button
                exact
                size="small"
                className={classes.tab}
                key={index}
                activeClassName={classes.activeTab}
                component={NavLink}
                to={path}
              >
                {name}
              </Button>
            );
          })}
        </div>
      </Grid>
      <Grid container className="mt-3">
        <Grid item xs={12} lg={9}>
          <Switch>
            {subModules.map(({ path, Component }, index) => {
              return (
                <Route
                  exact
                  path={path}
                  render={(props) => <Component {...props} />}
                  key={index}
                />
              );
            })}
          </Switch>
        </Grid>
      </Grid>
    </Grid>
  );
}
