import { makeStyles } from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";

const settingsStyles = makeStyles((theme) => ({
  userCardRoot: {
    boxShadow: "none",
    position: "relative",
    cursor: "pointer",
    boxShadow: theme.boxShadows[3],
    transition: "0.3s ease",
    ["&:hover"]: {
      transition: "0.3s ease",
      borderBottom: "solid 2px #3eb3d0",
      transform: "scale(0.9)",
      zIndex: theme.zIndex.drawer,
    },
  },
  cardHeader: {
    position: "absolute",
    right: "10px",
  },
  profilePictureWrapper: {
    position: "relative",
    width: "80px",
    background: "#EEEFF8",
    height: "80px",
    borderRadius: "100%",
    backgroundSize: "cover",
    backgroundPosition: "top",
    padding: "5px",
    boxShadow: theme.boxShadows[3],
  },
  profilePicture: {
    borderRadius: "100%",
    transform: "scale(1.1)",
    zIndex: 10,
  },
  profilePictureBorder: {
    border: "solid 2px #fff",
    width: "100%",
    height: "100%",
    borderRadius: "100%",
  },
  cardFooter: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "#F7F7FB",
  },
  name: {
    fontSize: "0.9rem",
    color: theme.palette.primary.main, //"#2B2B54",
    fontWeight: 500,
  },
  email: {
    fontSize: "0.8rem",
    color: theme.palette.primary.light,
  },
  role: {
    fontWeight: 500,
    color: "#7B789A",
  },
  accessLevel: {
    width: "40px",
    height: "5px",
    background: "#E7E7F1",
    borderRadius: "5px",
    margin: "5px",
  },
  smallText: {
    fontSize: "0.7rem",
  },
  addUserCard: {
    height: "100%",
    border: "dotted 2px " + grey[500],
    background: "transparent",
    boxShadow: "none",
    cursor: "pointer",
    transition: "0.3s ease",
    ["& .bi"]: {
      fontSize: "2rem",
    },
    ["&:hover"]: {
      transform: "scale(0.8)",
      transition: "0.3s ease",
      backgroundColor: grey[200],
    },
  },
  addUserText: {},
  tabsWrapper: {
    background: "#fff",
    marginBottom: "20px",
    borderRadius: "10px",
  },
  addUserForm: {
    position: "fixed",
    right: "0%",
    left: "16.666667%",
    top: "0%",
    bottom: "0px",
    backgroundColor: "#fff",
    zIndex: theme.zIndex.modal,
    padding: "20px",
  },
  profilePictureIcon: {
    position: "absolute",
    width: "35px",
    right: "-17px",
    top: "35%",
    background: "#0099FF",
    border: "#fff 1px solid",
    boxShadow: theme.boxShadows[3],
    height: "35px",
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "5px",
    fontSize: "19px",
    color: "#fff",
    cursor: "pointer",
  },
  formCard: {
    background: grey[100],
  },
  hasPermissions: {
    width: "100%",
    height: "100%",
    background: green[500],
    borderRadius: "5px",
  },
}));

export default settingsStyles;
