import {
  Dialog,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import { blueGrey, green } from "@material-ui/core/colors";
import { ArrowForward, Close } from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_GET_ALL_BRANCHES,
  BNK_API_OPEN_BRACH,
} from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: blueGrey[50],
    borderRadius: "20px",
  },
  paper: {
    borderRadius: "0px",
    height: "100vh",
    boxShadow: "none",
    overflowY: "auto",
    padding: "15px",
  },
}));

export default function OpenBranch(props) {
  const classes = useStyles();
  const [dialogState, setDialogState] = useState(false);
  const [completed, setCompleted] = useState(false);
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
    setCompleted(false);
  };
  return (
    <React.Fragment>
      <Button onClick={onDialogStateChange} {...props}>
        Open Branch
      </Button>
      <Dialog open={dialogState} fullScreen fullWidth>
        <Grid container justify="flex-end">
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={classes.paper}>
              <div className="d-flex justify-content-between align-items-center">
                <Typography variant="h6">Open Branch</Typography>
                <IconButton onClick={onDialogStateChange}>
                  <Close />
                </IconButton>
              </div>

              <Divider />

              <ApiRequest
                isForm
                thread={BNK_API_OPEN_BRACH}
                reloadOnSuccess={[BNK_API_GET_ALL_BRANCHES]}
                callbacks={{
                  success: (res, xthis) => {
                    if (typeof res == "object") {
                      if (typeof res.data == "object") {
                        if (res.data.opened == true) {
                          setCompleted(true);
                        }
                      }
                    }
                  },
                }}
              >
                {({ SubmitButton, input, res }) => {
                  if (typeof res == "object") {
                    if (typeof res.data == "object") {
                      if (res.data.opened == true && completed == true) {
                        return (
                          <div align="center" className="mt-5 p-3">
                            <Typography>Branch has been created.</Typography>
                            <Button endIcon={<Close />} onClick={onDialogStateChange} size="small">
                              Close
                            </Button>
                          </div>
                        );
                      }
                    }
                  }
                  return (
                    <div>
                      <Typography variant="body2" className="my-3">
                        <small>
                          Fill in the details of the branch you are creating and
                          ensure that they are correct
                        </small>
                      </Typography>
                      <Divider className="my-3" />
                      {/* <FormControlLabel
                  {...input({ name: "is_main_branch" })}
                  control={<Switch />}
                  label="Branch"
                /> */}
                      <KijjeInput
                        variant="filled"
                        square
                        label="Branch Name"
                        {...input({ name: "name" })}
                      />
                      <div className="d-flex w-100">
                        <div className="w-50 pe-1">
                          <KijjeInput
                            variant="filled"
                            square
                            label="Primary Email"
                            {...input({ name: "email" })}
                          />
                        </div>
                        <div className="w-50 ps-3">
                          <KijjeInput
                            variant="filled"
                            square
                            label="Phone Number"
                            {...input({ name: "phone_number" })}
                          />
                        </div>
                      </div>
                      <div className="d-flex w-100">
                        <div
                          className="pe-3"
                          style={{ width: "calc(100% / 3)" }}
                        >
                          <KijjeInput
                            variant="filled"
                            square
                            label="Village"
                            {...input({ name: "village" })}
                          />
                        </div>
                        <div
                          className="pe-3"
                          style={{ width: "calc(100% / 3)" }}
                        >
                          <KijjeInput
                            variant="filled"
                            square
                            label="City"
                            {...input({ name: "city" })}
                          />
                        </div>
                        <div style={{ width: "calc(100% / 3)" }}>
                          <KijjeInput
                            variant="filled"
                            square
                            label="Region"
                            {...input({ name: "region" })}
                          />
                        </div>
                      </div>

                      <KijjeInput
                        variant="filled"
                        square
                        multiline
                        label="Description"
                        {...input({ name: "description" })}
                      />

                      <Grid
                        container
                        className="p-2 py-2 my-2"
                        spacing={2}
                        style={{
                          backgroundColor: green[50],
                          borderRadius: "10px",
                        }}
                      >
                        <Grid item xs={12} md={6}>
                          <KijjeInput
                            placeholder="Enter name"
                            label="Branch Admin Name"
                            variant="filled"
                            {...input({
                              name: "adminName",
                            })}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <KijjeInput
                            placeholder="Branch admin username"
                            label="Enter username"
                            variant="filled"
                            {...input({
                              name: "adminUsername",
                            })}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <KijjeInput
                            placeholder="Branch Admin User Email"
                            label="Enter email address"
                            variant="filled"
                            {...input({
                              name: "adminEmail",
                            })}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <KijjeInput
                            placeholder="Enter password"
                            label="Branch Admin Password"
                            variant="filled"
                            {...input({
                              type: "password",
                              name: "adminPassword",
                            })}
                          />
                        </Grid>
                      </Grid>

                      <div align="right">
                        <SubmitButton
                          endIcon={<ArrowForward />}
                          variant="contained"
                          color="secondary"
                        >
                          Open Branch
                        </SubmitButton>
                      </div>
                    </div>
                  );
                }}
              </ApiRequest>
            </Paper>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
