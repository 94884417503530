import Branches from ".";

const branchesModule = {
  name: "Branches",
  path: "/branches",
  icon: <i class="bi bi-bar-chart-steps"></i>,
  Component: Branches,
  subModules: [],
};

export default branchesModule;