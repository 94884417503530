import { CircularProgress, IconButton } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import ApiRequest from "../../api/ApiRequest";
import { BNK_API_SYNCHRONIZE_DATA_TO_ONLINE_SERVER } from "../../api/apiThreads";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    height: "40px",
    width: "40px",
    marginRight: "15px",
    backgroundColor: green[500],
    color: "#fff",
    transition: "0.3s ease",
    ["&:hover"]: {
      border: "solid 1px " + green[500],
      transition: "0.3s ease",
      color: green[500],
    },
  },
}));

export function SyncButton(props) {
  const classes = useStyles();
  return (
    <ApiRequest
      thread={BNK_API_SYNCHRONIZE_DATA_TO_ONLINE_SERVER}
      callbacks={{
        success: (res, xthis) => {
          if (typeof res == "object") {
            if (typeof res.data == "object") {
              // check if the update has happened...
            }
          }
        },
      }}
    >
      {({ loading, error, res, submit }) => {
        return (
          <IconButton
            disabled={loading}
            onClick={submit}
            className={classes.iconButton}
          >
            {loading == true ? (
              <CircularProgress />
            ) : (
              <div
                style={{
                  fontSize: "1.2rem",
                }}
              >
                <i className="bi bi-arrow-repeat"></i>
              </div>
            )}
          </IconButton>
        );
      }}
    </ApiRequest>
  );
}
