import React, { useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";

let pathname
function ScrollToTop_({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      if(history.pathname != pathname){
        pathname = history.location.pathname;
      } else {
        window.scrollTo(0, 0);
        pathname = history.location.pathname
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return <Fragment>{children}</Fragment>;
}

const ScrollToTop = withRouter(ScrollToTop_);

export default ScrollToTop;
