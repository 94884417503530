/**
 * All api End points have been brought here
 */

import {
  ADD_DOMAIN_TO_DOMAIN_LIST,
  BLG_API_ADD_AUTHOR,
  BLG_API_CREATE_BLOG,
  BLG_API_CREATE_POST,
  BLG_API_DELETE_POST,
  BLG_API_GET_ALL_BLOGS,
  BLG_API_GET_AUTHORS,
  BLG_API_GET_BLOG,
  BLG_API_GET_POSTS,
  BLG_API_PUBLISH_POST,
  BLG_API_SHOW_AUTHOR,
  BLG_API_SHOW_POST,
  BLG_API_UPDATE_POST,
  BNK_API_ADD_BRANCH_MANAGER,
  BNK_API_ADD_INCOME_CATEGORY,
  BNK_API_ADD_LOAN_TYPE,
  BNK_API_APPLY_FOR_A_LOAN,
  BNK_API_BRANCH_GET_EMPLOYEES,
  BNK_API_BRANCH_OPEN_ACCOUNT,
  BNK_API_BRANCH_REGISTER_EMPLOYEE,
  BNK_API_CHANGE_LOAN_APPLICATION_STATUS,
  BNK_API_CHECKING_ACCOUNT_GET_TRANSACTIONS,
  BNK_API_CHECKING_ACCOUNT_RECORD_BNK_CHARGES,
  BNK_API_CHECKING_ACCOUNT_RECORD_DEPOSIT,
  BNK_API_CHECKING_ACCOUNT_RECORD_WITHDRAW,
  BNK_API_CORRECT_LOAN_ISSUES,
  BNK_API_CREATE_BANK,
  BNK_API_CREATE_EXPENSE_CATEGORY,
  BNK_API_CREATE_TRANSACTION,
  BNK_API_DELETE_CUSTOMER_ACCOUNT,
  BNK_API_DELETE_LOAN,
  BNK_API_DELETE_LOAN_TYPE,
  BNK_API_DELETE_WEB_CUSTOMER_USER,
  BNK_API_DEPOSIT_TO_LOAN_CHECKING_ACCOUNT,
  BNK_API_FIND_CUSTOMER_ACCOUNT,
  BNK_API_GET_ALL_BRANCHES,
  BNK_API_GET_ALL_CUSTOMERS,
  BNK_API_GET_ALL_INCOMES,
  BNK_API_GET_ALL_LOANS,
  BNK_API_GET_ALL_LOAN_TYPES,
  BNK_API_GET_ALL_WEB_CUSTOMER_USERS,
  BNK_API_GET_APPROVED_LOAN_FOR_EDITING,
  BNK_API_GET_BRANCHES_NOT_ACCESSED_BY_USER,
  BNK_API_GET_BRANCHES_STATISTICS,
  BNK_API_GET_BRANCH_CUSTOMERS,
  BNK_API_GET_BRANCH_SETTINGS,
  BNK_API_GET_CHECKING_ACCOUNT_REPORT,
  BNK_API_GET_CUSTOMER_ACCOUNT_DETAILS,
  BNK_API_GET_CUSTOMER_ACCOUNT_SUMMARY,
  BNK_API_GET_CUSTOMER_RUNNING_LOANS,
  BNK_API_GET_CUSTOMER_SAVINGS_REPORT,
  BNK_API_GET_CUSTOMER_STATISTICS,
  BNK_API_GET_CUSTOMER_TRANSACTIONS,
  BNK_API_GET_DATE_INSTALLMENTS,
  BNK_API_GET_DEPOSIT_HISTORY,
  BNK_API_GET_EXPENSES,
  BNK_API_GET_EXPENSES_REPORT,
  BNK_API_GET_EXPENSE_CATEGORIES,
  BNK_API_GET_INCOMES_AND_EXPENSES_TOTAL,
  BNK_API_GET_INCOME_CATEGORIES,
  BNK_API_GET_INCOME_REPORTS,
  BNK_API_GET_LOANS_FOR_CORRECTIONS,
  BNK_API_GET_LOANS_REPORT,
  BNK_API_GET_LOANS_SUMMARY,
  BNK_API_GET_LOANS_TO_BE_PAID_TODAY,
  BNK_API_GET_LOAN_BALANCE,
  BNK_API_GET_LOAN_CHECKING_ACCOUNT_SUMMARY,
  BNK_API_GET_LOAN_CHECKING_ACCOUNT_TRANSACTIONS,
  BNK_API_GET_MONTHLY_LOAN_INSTALLMENTS,
  BNK_API_GET_MY_BANK,
  BNK_API_GET_RUNNING_BALANCE_REPORT,
  BNK_API_GET_RUNNING_CLOSING_OPENING_BALANCE,
  BNK_API_GET_SMS_MESSAGES_CONFIG,
  BNK_API_GET_TOTAL_CATEGORY_EXPENSE,
  BNK_API_GET_TOTAL_CATEGORY_INCOME,
  BNK_API_GET_TRANSACTION_HISTORY,
  BNK_API_GET_TRANSACTION_RECEIPT,
  BNK_API_GET_TRANSACTION_STATISTICS,
  BNK_API_GET_WITHDRAW_HISTORY,
  BNK_API_GRANT_BRANCH_ACCESS_TO_USER,
  BNK_API_LOAN_APPLICATION_FORM_VALIDATION,
  BNK_API_MAKE_DEPOSIT,
  BNK_API_MAKE_LOAN_PAYMENT,
  BNK_API_MAKE_WITHDRAW,
  BNK_API_MANAGE_BRANCH,
  BNK_API_OPEN_ACCOUNT_CHOOSE_BRANCH_VALIDATION,
  BNK_API_OPEN_BRACH,
  BNK_API_RECORD_EXPENSE,
  BNK_API_RECORD_INCOME,
  BNK_API_RESET_WEB_CUSTOMER_USER_PASSWORD,
  BNK_API_SEARCH_ACCOUNT,
  BNK_API_SEND_TEST_MESSAGE,
  BNK_API_SHOW_LOAN,
  BNK_API_SHOW_LOAN_TYPE,
  BNK_API_SYNCHRONIZE_DATA_TO_ONLINE_SERVER,
  BNK_API_UPDATE_APPROVED_LOAN,
  BNK_API_UPDATE_BRANCH_USER_ACCESS,
  BNK_API_UPDATE_CUSTOMER_ACCOUNT_DETAILS,
  BNK_API_UPDATE_CUSTOMER_ACCOUNT_DETAILS_VALIDATOR,
  BNK_API_UPDATE_CUSTOMER_DOCUMENTS,
  BNK_API_UPDATE_LOAN_APPLICATION,
  BNK_API_UPDATE_LOAN_APPLICATION_VALIDATOR,
  BNK_API_UPDATE_LOAN_TYPE,
  BNK_API_UPDATE_SMS_MESSAGES_CONFIG,
  BNK_API_UPLOAD_CUSTOMER_DOCUMENTS,
  BNK_API_USER_HAS_PERMISSIONS,
  BNK_API_VALIDATE_LOAN_APPLICATION_FORM,
  DELETE_IMAGE,
  GET_ADMIN_USERS,
  GET_ADMIN_USER_DETAILS,
  GET_ALL_IMAGES,
  GET_ALL_MARKET_PLACE_ORDERS,
  GET_ALL_MARKET_PLACE_PRODUCT,
  GET_ALL_MARKET_PLACE_PRODUCT_CATEGORIES,
  GET_ALL_USERS,
  GET_DOMAIN_ACCESS_LIST,
  GET_LOGGED_IN_USER_PROFILE_THREAD,
  GET_SETTINGS,
  IS_LOGGED_IN_THREAD,
  LOGIN_ADMIN,
  LOGOUT_ADMIN,
  MARKET_PLACE_DELETE_PRODUCT,
  MP_ADD_BRAND,
  MP_ADD_CATEGORY_GROUP,
  MP_ADD_CATEGORY_TO_CATEGORY_GROUP_ITEMS,
  MP_ADD_PRODUCT,
  MP_ADD_PRODUCT_CATEGORY,
  MP_ADD_PRODUCT_CATEGORY_GROUP,
  MP_DELETE_BRAND,
  MP_DELETE_CATEGORY_GROUP,
  MP_DELETE_LANDING_PAGE_SLIDE,
  MP_DELETE_PRODUCT_CATEGORY,
  MP_GET_ALL_BRANDS,
  MP_GET_ALL_PRODUCTS,
  MP_GET_ALL_PRODUCT_CATEGORY_GROUPS,
  MP_GET_BRAND,
  MP_GET_CATEGORIES_NOT_ADDED_TO_CATEGORY_GROUP,
  MP_GET_CATEGORY_GROUP,
  MP_GET_CATEGORY_GROUP_ITEMS,
  MP_GET_LANDING_PAGE_CONTENT,
  MP_GET_LANDING_PAGE_SLIDE,
  MP_GET_PRODUCT,
  MP_GET_PRODUCT_CATEGORY,
  MP_MP_ADD_PRODUCT,
  MP_REMOVE_ITEM_FROM_CATEGORY_GROUP_LIST,
  MP_UPDATE_BRAND,
  MP_UPDATE_CATEGORY_GROUP,
  MP_UPDATE_LANDING_PAGE_SLIDE,
  MP_UPDATE_PRODUCT,
  MP_UPDATE_PRODUCT_CATEGORY,
  MP_UPLOAD_LANDING_PAGE_SLIDE,
  REFRESH_AUTH_TOKEN,
  REGISTER_ADMIN_USER_THREAD,
  REQUEST_LOGIN_VERIFICATION_CODE,
  SEND_DOCTOR_PASSWORD_RESET_LINK,
  SETTINGS_GET_PAYPAL_CONFIG,
  SETTINGS_GET_SYSTEM_NOTIFICATIONS_MAIL,
  SETTINGS_NOTIFICATIONS_MAIL_SEND_TEST_MAIL,
  SETTINGS_UPDATE_PAYPAL_CONFIG,
  SETTINGS_UPDATE_SYSTEM_NOTIFICATIONS_MAIL,
  UPDATE_ADMIN_USER_DETAILS,
  UPDATE_SETTINGS,
} from "./apiThreads";

// const API_BASE_URL = "http://api.hapibnk.com:8080";
// const API_BASE_URL = "http://192.168.0.102:3333";
// const API_BASE_URL = "https://v1.api.mjbc.georgebarna.com";
// const API_BASE_URL = "https://api.nexenug.com";
// const API_BASE_URL = "https://v2.api.abm.banking.hapipie.com";
// const API_BASE_URL = "https://api.abmbanking.com";
// const API_BASE_URL = "https://v2.api.georgebarna.com";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const base_url = (url, user = "/admin") => API_BASE_URL + user + url;

const makeUrl = (endPoint, params = {}) => {
  for (var param in params) {
    endPoint = endPoint.replace(param, params[param]);
  }
  // support without [:]
  var oldEndPoint;
  do {
    oldEndPoint = endPoint;
    endPoint = endPoint.replace(":", "");
  } while (oldEndPoint !== endPoint);

  return base_url(endPoint, "");
};

export const makeEndPoint = makeUrl;

export const apiEndPoints = {
  [IS_LOGGED_IN_THREAD]: {
    endPoint: () => makeUrl("/admin/auth/valid-token"),
    method: "get",
  },
  [LOGIN_ADMIN]: {
    endPoint: () => makeUrl("/admin/auth/login"),
    method: "post",
  },
  [REQUEST_LOGIN_VERIFICATION_CODE]: {
    endPoint: () => makeUrl("/admin/auth/request-code/login"),
    method: "post",
  },
  [GET_LOGGED_IN_USER_PROFILE_THREAD]: {
    endPoint: () => makeUrl("/admin/account/profile"),
    method: "GET",
  },
  [LOGOUT_ADMIN]: {
    endPoint: () => makeUrl("/admin/auth/logout"),
    method: "patch",
  },
  [REFRESH_AUTH_TOKEN]: {
    endPoint: () => makeUrl("/admin/auth/refresh"),
  },
  // admin user thread
  [GET_ADMIN_USERS]: {
    endPoint: () => makeUrl("/admin/system-users/all"),
    method: "GET",
  },
  [REGISTER_ADMIN_USER_THREAD]: {
    endPoint: () => makeUrl("/admin/system-users/register-user"),
    method: "POST",
  },
  [UPDATE_ADMIN_USER_DETAILS]: {
    endPoint: () => makeUrl("/admin/system-users/update"),
    method: "Patch",
  },
  [GET_ADMIN_USER_DETAILS]: {
    endPoint: () => makeUrl("/admin/system-users/get-details"),
    method: "GET",
  },
  [SEND_DOCTOR_PASSWORD_RESET_LINK]: {
    endPoint: () => makeUrl("/admin/doctors/send-password-reset-link"),
    method: "POST",
  },
  /**
   * Handle users module
   */
  [GET_ALL_USERS]: {
    endPoint: () => makeUrl("/admin/clients/all"),
    method: "get",
  },

  /**
   * Resources, List all resource
   */
  [GET_ALL_IMAGES]: {
    endPoint: () => makeUrl("/admin/resources/image/all"),
    method: "get",
  },

  /**
   * dELETE FILES FROM THE FRONT END
   */
  [DELETE_IMAGE]: {
    endPoint: (params) => makeUrl("/admin/resources/image/delete/:id", params),
    method: "delete",
    dataThread: GET_ALL_IMAGES,
  },

  /**
   * sETTINGS
   */
  [GET_SETTINGS]: {
    endPoint: () => makeUrl("/admin/settings"),
    method: "GET",
  },
  [UPDATE_SETTINGS]: {
    endPoint: () => makeUrl("/admin/settings/update"),
    method: "patch",
  },
  [SETTINGS_GET_SYSTEM_NOTIFICATIONS_MAIL]: {
    endPoint: () => makeUrl("/admin/settings/system/notifications-mail"),
    method: "get",
  },
  [SETTINGS_UPDATE_SYSTEM_NOTIFICATIONS_MAIL]: {
    endPoint: () => makeUrl("/admin/settings/system/notifications-mail/update"),
    method: "POST",
  },
  [SETTINGS_NOTIFICATIONS_MAIL_SEND_TEST_MAIL]: {
    endPoint: () =>
      makeUrl("/admin/settings/system/notifications-mail/send-test-mail"),
    method: "POST",
  },

  /**
   * Branch access and athorizations
   */
  [BNK_API_GET_BRANCHES_NOT_ACCESSED_BY_USER]: {
    endPoint: () => makeUrl("/admin/users/modules/get/not-accessed-by-user"),
    method: "GET",
  },
  [BNK_API_GRANT_BRANCH_ACCESS_TO_USER]: {
    endPoint: () => makeUrl("/admin/users/modules/assign-user"),
    method: "POST",
  },
  [BNK_API_UPDATE_BRANCH_USER_ACCESS]: {
    method: "PATCH",
    endPoint: () => makeUrl("/admin/users/modules/update-module-access"),
  },

  /**
   * Paypal Config
   */
  [SETTINGS_GET_PAYPAL_CONFIG]: {
    endPoint: makeUrl("/admin/settings/system/paypal-config"),
    method: "GET",
  },
  [SETTINGS_UPDATE_PAYPAL_CONFIG]: {
    endPoint: makeUrl("/admin/settings/system/paypal-config/update"),
    method: "PATCH",
  },

  /**
   * sETTINGS DOMAIN ACCESS LIST
   */
  [GET_DOMAIN_ACCESS_LIST]: {
    endPoint: makeUrl("/admin/settings/domain-access-list"),
    method: "GET",
  },

  [ADD_DOMAIN_TO_DOMAIN_LIST]: {
    endPoint: makeUrl("/admin/settings/domain-access-list"),
    method: "POST",
    uiUpdateThread: GET_DOMAIN_ACCESS_LIST,
    uiUpdateType: "push",
    uiUpdateKey: "domains",
    responseKey: "domain",
  },

  /**
   * bANKING API
   *
   */
  [BNK_API_GET_MY_BANK]: {
    endPoint: makeUrl("/admin/my-bank"),
    method: "get",
  },
  [BNK_API_CREATE_BANK]: {
    endPoint: makeUrl("/admin/create"),
    method: "POST",
    uiUpdateKey: "bank",
    uiUpdateThread: BNK_API_GET_MY_BANK,
    uiUpdateType: "replace",
    responseKey: "bank",
  },
  [BNK_API_GET_ALL_BRANCHES]: {
    endPoint: makeUrl("/admin/branches/all"),
    method: "GET",
  },
  [BNK_API_OPEN_BRACH]: {
    endPoint: makeUrl("/admin/branches/open"),
    method: "POST",
    uiUpdateKey: "branches",
    uiUpdateThread: BNK_API_GET_MY_BANK,
    uiUpdateType: "push",
    responseKey: "branch",
  },
  [BNK_API_MANAGE_BRANCH]: {
    endPoint: makeUrl("/admin/branches/manage"),
    method: "GET",
  },
  [BNK_API_ADD_BRANCH_MANAGER]: {
    endPoint: makeUrl("/admin/branches/manager/add"),
    method: "POST",
    uiUpdateThread: BNK_API_MANAGE_BRANCH,
    uiUpdateKey: "manager",
    uiUpdateType: "replace",
    responseKey: "manager",
  },

  [BNK_API_GET_BRANCH_SETTINGS]: {
    endPoint: makeUrl("/admin/branches/manage/settings"),
    method: "GET",
  },
  [BNK_API_BRANCH_OPEN_ACCOUNT]: {
    endPoint: makeUrl("/admin/branches/manage/customers/open-account"),
    method: "POST",
  },
  [BNK_API_OPEN_ACCOUNT_CHOOSE_BRANCH_VALIDATION]: {
    endPoint: (params) =>
      makeUrl(
        "/admin/branches/manage/customers/open-account/validate-info/:step",
        params
      ),
    method: "POST",
  },
  [BNK_API_FIND_CUSTOMER_ACCOUNT]: {
    endPoint: makeUrl("/admin/branches/manage/customers/account/find"),
    method: "GET",
  },
  [BNK_API_SEARCH_ACCOUNT]: {
    endPoint: makeUrl("/admin/banking/customers/accounts/search"),
    method: "GET",
  },
  [BNK_API_GET_BRANCH_CUSTOMERS]: {
    endPoint: makeUrl("/admin/branches/manage/customers/all"),
    method: "GET",
    dataTable: true,
  },
  [BNK_API_GET_ALL_CUSTOMERS]: {
    endPoint: makeUrl("/admin/customers/all"),
    method: "GET",
  },
  [BNK_API_MAKE_WITHDRAW]: {
    endPoint: makeUrl("/admin/withdraws/make-withdraw"),
    method: "POST",
  },
  [BNK_API_MAKE_DEPOSIT]: {
    endPoint: makeUrl("/admin/deposits/make-deposit"),
    method: "POST",
  },
  [BNK_API_GET_DEPOSIT_HISTORY]: {
    endPoint: makeUrl("/admin/deposits/history"),
    method: "GET",
  },
  [BNK_API_GET_WITHDRAW_HISTORY]: {
    endPoint: makeUrl("/admin/withdraws/history"),
    method: "GET",
  },

  [BNK_API_GET_TRANSACTION_HISTORY]: {
    endPoint: makeUrl("/admin/transactions"),
    method: "GET",
  },
  // Transaction history
  [BNK_API_GET_TRANSACTION_STATISTICS]: {
    endPoint: makeUrl("/admin/transactions/statistics"),
    method: "GET",
  },
  [BNK_API_BRANCH_REGISTER_EMPLOYEE]: {
    endPoint: makeUrl("/admin/employees/register"),
    method: "POST",
  },
  [BNK_API_BRANCH_GET_EMPLOYEES]: {
    endPoint: makeUrl("/admin/employees/all"),
    method: "GET",
  },
  [BNK_API_GET_CUSTOMER_STATISTICS]: {
    endPoint: makeUrl("/admin/customers/statistics"),
    method: "GET",
  },
  [BNK_API_GET_BRANCHES_STATISTICS]: {
    endPoint: makeUrl("/admin/branches/statistics"),
    method: "GET",
  },

  /**
   * LOAN TYPES
   */
  [BNK_API_GET_ALL_LOAN_TYPES]: {
    endPoint: makeUrl("/admin/loan-types/all"),
    method: "GET",
  },
  [BNK_API_ADD_LOAN_TYPE]: {
    endPoint: makeUrl("/admin/loan-types/add"),
    method: "POST",
  },
  [BNK_API_UPDATE_LOAN_TYPE]: {
    endPoint: (params) =>
      makeUrl("/admin/loan-types/update/:loan_type", params),
    method: "POST",
  },
  [BNK_API_DELETE_LOAN_TYPE]: {
    endPoint: (params) =>
      makeUrl("/admin/loan-types/delete/:loan_type", params),
    method: "DELETE",
  },
  [BNK_API_SHOW_LOAN_TYPE]: {
    endPoint: (params) =>
      makeUrl("/admin/loan-types/show/:loan_type_id", params),
    method: "GET",
  },
  [BNK_API_GET_LOAN_BALANCE]: {
    endPoint: (params) => makeUrl("/admin/get/loan/balance"),
    method: "GET",
  },

  /**
   * Synchronization of data
   */
  [BNK_API_SYNCHRONIZE_DATA_TO_ONLINE_SERVER]: {
    endPoint: makeUrl("/admin/settings/synchronize-data"),
    method: "PATCH",
  },

  /**
   * GET WEB CUSTOMER USERS
   */
  [BNK_API_GET_ALL_WEB_CUSTOMER_USERS]: {
    endPoint: makeUrl("/admin/banking/bnk-web-customer-users/all"),
    method: "GET",
  },
  [BNK_API_DELETE_WEB_CUSTOMER_USER]: {
    endPoint: makeUrl("/admin/banking/bnk-web-customer-users/delete/:user_id"),
    method: "DELETE",
  },
  [BNK_API_RESET_WEB_CUSTOMER_USER_PASSWORD]: {
    endPoint: makeUrl("/admin/banking/bnk-web-customer-users/reset-password"),
    method: "PATCH",
  },

  /**
   * Loan application..
   */
  [BNK_API_LOAN_APPLICATION_FORM_VALIDATION]: {
    endPoint: makeUrl("/admin/banking/loans/application/apply/validate"),
    method: "POST",
  },
  [BNK_API_UPDATE_LOAN_APPLICATION_VALIDATOR]: {
    endPoint: makeUrl("/admin/banking/loans/applications/update/validate"),
    method: "POST",
  },
  [BNK_API_UPDATE_LOAN_APPLICATION]: {
    endPoint: makeUrl("/admin/banking/loans/applications/update"),
    method: "POST",
  },

  // GET ALL LOASN
  [BNK_API_GET_ALL_LOANS]: {
    endPoint: makeUrl("/admin/loans/all"),
    method: "GET",
  },

  /**
   * Loan application
   */
  [BNK_API_VALIDATE_LOAN_APPLICATION_FORM]: {
    endPoint: (params) =>
      makeUrl("/admin/banking/loan-application/validate/:step", params),
    method: "POST",
  },

  [BNK_API_APPLY_FOR_A_LOAN]: {
    endPoint: (params) => makeUrl("/admin/banking/loans/apply", params),
    method: "POST",
  },

  /**
   * Account details
   */
  [BNK_API_GET_CUSTOMER_ACCOUNT_DETAILS]: {
    endPoint: (params) => makeUrl("/admin/banking/accounts/account-details"),
    method: "GET",
  },
  [BNK_API_UPDATE_CUSTOMER_ACCOUNT_DETAILS]: {
    method: "POST",
    endPoint: (params) =>
      makeUrl("/admin/banking/accounts/update/account-details", params),
  },
  [BNK_API_UPDATE_CUSTOMER_ACCOUNT_DETAILS_VALIDATOR]: {
    method: "POST",
    endPoint: (params) =>
      makeUrl("/admin/banking/accounts/validate/account-details/:step", params),
  },
  [BNK_API_GET_CUSTOMER_TRANSACTIONS]: {
    endPoint: (params) =>
      makeUrl("/admin/banking/customer/transaction", params),
    method: "GET",
  },

  [BNK_API_GET_CUSTOMER_RUNNING_LOANS]: {
    endPoint: (params) => makeUrl("/admin/banking/customer/loans/all", params),
    method: "GET",
  },

  [BNK_API_CHANGE_LOAN_APPLICATION_STATUS]: {
    endPoint: makeUrl("/admin/banking/loans/application/change-status"),
    method: "POST",
  },

  [BNK_API_GET_LOANS_SUMMARY]: {
    method: "GET",
    endPoint: makeUrl("/admin/banking/loans/summary"),
  },
  [BNK_API_MAKE_LOAN_PAYMENT]: {
    method: "POST",
    endPoint: makeUrl("/admin/banking/loans/loan-payment"),
  },

  [BNK_API_SHOW_LOAN]: {
    method: "GET",
    endPoint: (params) => makeUrl("/admin/banking/loans/show/:loan_id", params),
  },
  [BNK_API_GET_CUSTOMER_ACCOUNT_SUMMARY]: {
    method: "GET",
    endPoint: (params) =>
      makeUrl("/admin/banking/accounts/account-summary", params),
  },

  [BNK_API_GET_LOAN_CHECKING_ACCOUNT_TRANSACTIONS]: {
    method: "GET",
    endPoint: (params) =>
      makeUrl("/admin/banking/loans/checking-account/transactions"),
  },

  [BNK_API_DEPOSIT_TO_LOAN_CHECKING_ACCOUNT]: {
    endPoint: (params) =>
      makeUrl("/admin/banking/loans/checking-account/deposit"),
    method: "POST",
  },

  [BNK_API_GET_LOAN_CHECKING_ACCOUNT_SUMMARY]: {
    endPoint: (params) =>
      makeUrl("/admin/banking/loans/checking-account/summary"),
    method: "GET",
  },

  [BNK_API_GET_LOANS_TO_BE_PAID_TODAY]: {
    endPoint: (params) =>
      makeUrl("/admin/banking/loans/loans-to-be-paid-today", params),
    method: "GET",
  },

  [BNK_API_GET_MONTHLY_LOAN_INSTALLMENTS]: {
    endPoint: (params) => makeUrl("/admin/loans/payments-in-month"),
    method: "GET",
  },
  [BNK_API_GET_DATE_INSTALLMENTS]: {
    endPoint: (params) => makeUrl("/admin/loans/payments-in-date"),
    method: "GET",
  },

  /**
   * Uplaod customer documents
   */
  [BNK_API_UPLOAD_CUSTOMER_DOCUMENTS]: {
    method: "POST",
    endPoint: (params) =>
      makeUrl("/admin/customer-documents/add-document", params),
  },
  [BNK_API_UPDATE_CUSTOMER_DOCUMENTS]: {
    method: "POST",
    endPoint: (params) =>
      makeUrl("/admin/customer-documents/update-document", params),
  },

  /**
   * Get Transaction Receipt
   */
  [BNK_API_GET_TRANSACTION_RECEIPT]: {
    method: "GET",
    endPoint: (params) => makeUrl("/admin/transactions/print-receipt", params),
  },

  /**
   * DELETE CUSTOMER ACCOUNTS
   */
  [BNK_API_DELETE_CUSTOMER_ACCOUNT]: {
    method: "DELETE",
    endPoint: (params) =>
      makeUrl("/admin/banking/customer-accounts/delete", params),
  },

  [BNK_API_GET_RUNNING_CLOSING_OPENING_BALANCE]: {
    method: "GET",
    endPoint: (params) =>
      makeUrl("/admin/banking/dashboard/closing-opening-balance", params),
  },

  [BNK_API_CREATE_EXPENSE_CATEGORY]: {
    method: "POST",
    endPoint: (params) => makeUrl("/admin/banking/expenses/categories/create"),
  },

  [BNK_API_GET_EXPENSE_CATEGORIES]: {
    method: "get",
    endPoint: (params) => makeUrl("/admin/banking/expenses/categories/all"),
  },
  [BNK_API_RECORD_EXPENSE]: {
    method: "POST",
    endPoint: (params) => makeUrl("/admin/banking/expenses/record"),
  },
  [BNK_API_GET_EXPENSES]: {
    method: "GET",
    endPoint: (params) => makeUrl("/admin/banking/expenses/all"),
  },

  /**
   * SMS MESSAGES
   */
  [BNK_API_GET_SMS_MESSAGES_CONFIG]: {
    method: "GET",
    endPoint: makeUrl("/admin/banking/configs/sms-messages"),
  },
  [BNK_API_UPDATE_SMS_MESSAGES_CONFIG]: {
    method: "PATCH",
    endPoint: (params) => makeUrl("/admin/banking/configs/sms-messages/update"),
  },
  [BNK_API_SEND_TEST_MESSAGE]: {
    method: "POST",
    endPoint: (params) =>
      makeUrl("/admin/banking/configs/sms-messages/send-test-message"),
  },

  // BANK API GET APPROVED LOANS
  [BNK_API_GET_APPROVED_LOAN_FOR_EDITING]: {
    method: "GET",
    endPoint: (params) =>
      makeUrl("/admin/banking/loans/approved/get-loan-for-editing"),
  },

  [BNK_API_UPDATE_APPROVED_LOAN]: {
    method: "PATCH",
    endPoint: (params) =>
      makeUrl("/admin/banking/loans/approved/update-approved-loan"),
  },
  [BNK_API_DELETE_LOAN]: {
    method: "DELETE",
    endPoint: (params) => makeUrl("/admin/banking/loans/delete"),
  },
  [BNK_API_CORRECT_LOAN_ISSUES]: {
    method: "PATCH",
    endPoint: (params) => makeUrl("/admin/banking/correct-loan-issues"),
  },
  [BNK_API_GET_LOANS_FOR_CORRECTIONS]: {
    method: "GET",
    endPoint: (params) => makeUrl("/admin/banking/get-loans-for-corrections"),
  },
  [BNK_API_USER_HAS_PERMISSIONS]: {
    method: "GET",
    endPoint: (params) => makeUrl("/admin/permissions/has"),
  },

  // incomes
  [BNK_API_ADD_INCOME_CATEGORY]: {
    method: "POST",
    endPoint: (params) => makeUrl("/admin/banking/incomes/categories/create"),
  },
  [BNK_API_GET_INCOME_CATEGORIES]: {
    method: "GET",
    endPoint: (params) => makeUrl("/admin/banking/incomes/categories"),
  },
  [BNK_API_GET_TOTAL_CATEGORY_INCOME]: {
    method: "GET",
    endPoint: makeUrl("/admin/banking/incomes/total_category_income"),
  },
  [BNK_API_GET_TOTAL_CATEGORY_EXPENSE]: {
    method: "GET",
    endPoint: makeUrl("/admin/banking/expenses/total_category_expense"),
  },
  [BNK_API_RECORD_INCOME]: {
    method: "POST",
    endPoint: (params) => makeUrl("/admin/banking/incomes/record"),
  },
  [BNK_API_GET_ALL_INCOMES]: {
    method: "GET",
    endPoint: (params) => makeUrl("/admin/banking/incomes/all"),
  },
  [BNK_API_GET_INCOMES_AND_EXPENSES_TOTAL]: {
    method: "GET",
    endPoint: (params) => makeUrl("/admin/banking/incomes-and-expenses-total"),
  },
  // checking account
  [BNK_API_CHECKING_ACCOUNT_GET_TRANSACTIONS]: {
    method: "GET",
    endPoint: (params) =>
      makeUrl("/admin/banking/checking-account/transactions"),
  },
  [BNK_API_CHECKING_ACCOUNT_RECORD_DEPOSIT]: {
    method: "POST",
    endPoint: (params) =>
      makeUrl("/admin/banking/checking-account/record/deposit"),
  },
  [BNK_API_CHECKING_ACCOUNT_RECORD_WITHDRAW]: {
    method: "POST",
    endPoint: (params) =>
      makeUrl("/admin/banking/checking-account/record/withdraw"),
  },
  [BNK_API_CHECKING_ACCOUNT_RECORD_BNK_CHARGES]: {
    method: "POST",
    endPoint: (params) =>
      makeUrl("/admin/banking/checking-account/record/bnk-charges"),
  },
  // Dashboard reports
  [BNK_API_GET_CUSTOMER_SAVINGS_REPORT]: {
    method: "GET",
    endPoint: (params) => makeUrl("/admin/banking/reports/savings-report"),
  },
  [BNK_API_GET_INCOME_REPORTS]: {
    method: "GET",
    endPoint: (params) => makeUrl("/admin/banking/reports/incomes-report"),
  },
  [BNK_API_GET_EXPENSES_REPORT]: {
    method: "GET",
    endPoint: (params) => makeUrl("/admin/banking/reports/expenses-report"),
  },
  [BNK_API_GET_CHECKING_ACCOUNT_REPORT]: {
    method: "GET",
    endPoint: (params) =>
      makeUrl("/admin/banking/reports/checking-account-report"),
  },
  [BNK_API_GET_LOANS_REPORT]: {
    method: "GET",
    endPoint: (params) => makeUrl("/admin/banking/reports/loans-report"),
  },
  [BNK_API_GET_RUNNING_BALANCE_REPORT]: {
    method: "GET",
    endPoint: (params) => makeUrl("/admin/banking/reports/running-balance"),
  },
  [BNK_API_CREATE_TRANSACTION]: {
    method: "POST",
    endPoint: (params) => makeUrl("/admin/banking/transactions/create"),
  },

  /**
   * blog api
   */
  [BLG_API_CREATE_BLOG]: {
    endPoint: makeUrl("/admin/blog/create-blog"),
    method: "POST",
  },
  [BLG_API_GET_ALL_BLOGS]: {
    endPoint: makeUrl("/admin/blog/all"),
    method: "get",
  },
  [BLG_API_GET_BLOG]: {
    endPoint: makeUrl("/admin/blog/"),
    method: "get",
  },
  [BLG_API_CREATE_POST]: {
    endPoint: makeUrl("/admin/blog/posts/create"),
    method: "POST",
  },
  [BLG_API_GET_POSTS]: {
    endPoint: makeUrl("/admin/blog/posts/all"),
    method: "GET",
  },

  [BLG_API_SHOW_POST]: {
    endPoint: (params) => makeUrl("/admin/blog/posts/show/:post_id", params),
    method: "GET",
  },
  [BLG_API_PUBLISH_POST]: {
    endPoint: (params) => makeUrl("/admin/blog/posts/publish/:post_id", params),
    method: "PATCH",
  },
  [BLG_API_DELETE_POST]: {
    endPoint: (params) => makeUrl("/admin/blog/posts/delete/:post_id", params),
    method: "DELETE",
  },
  [BLG_API_UPDATE_POST]: {
    endPoint: (params) => makeUrl("/admin/blog/posts/update/:post_id", params),
    method: "post",
  },
  // blog authors
  [BLG_API_ADD_AUTHOR]: {
    endPoint: makeUrl("/admin/blog/authors/add"),
    method: "POST",
  },
  [BLG_API_GET_AUTHORS]: {
    endPoint: makeUrl("/admin/blog/authors/all"),
    method: "GET",
  },
  [BLG_API_SHOW_AUTHOR]: {
    endPoint: (params) =>
      makeUrl("/admin/blog/authors/show/:author_id", params),
    method: "GET",
  },

  /**
   * Market Place
   */
  [GET_ALL_MARKET_PLACE_PRODUCT_CATEGORIES]: {
    endPoint: makeUrl("/admin/market-place/products/categories/all"),
    method: "GET",
  },
  [MP_ADD_PRODUCT_CATEGORY]: {
    endPoint: makeUrl("/admin/market-place/products/categories/add"),
    method: "POST",
  },
  [MP_MP_ADD_PRODUCT]: {
    endPoint: makeUrl("/admin/market-place/products/add"),
    method: "POST",
  },
  [GET_ALL_MARKET_PLACE_PRODUCT]: {
    endPoint: (params) => makeUrl("/admin/market-place/products/all", params),
    method: "GET",
  },
  [MARKET_PLACE_DELETE_PRODUCT]: {
    endPoint: (params) =>
      makeUrl("/admin/market-place/products/delete/:productId", params),
    method: "DELETE",
  },
  [GET_ALL_MARKET_PLACE_ORDERS]: {
    endPoint: (params) => makeUrl("/admin/market-place/orders/all", params),
    method: "GET",
  },
  [MP_ADD_CATEGORY_GROUP]: {
    endPoint: (params) =>
      makeUrl("/admin/market-place/products/categories/groups/create", params),
    method: "POST",
  },
  // [MP_GET_ALL_PRODUCT_CATEGORY_GROUPS]: {
  //   endPoint: (params) =>
  //     makeUrl("/admin/market-place/products/categories/groups/all", params),
  //   method: "GET",
  // },
  [MP_ADD_PRODUCT_CATEGORY_GROUP]: {
    endPoint: (params) => makeUrl("/admin/market-place/categories/groups/add"),
    method: "POST",
  },
  [MP_GET_ALL_PRODUCT_CATEGORY_GROUPS]: {
    endPoint: (params) => makeUrl("/admin/market-place/categories/groups/all"),
    method: "GET",
  },
  [MP_GET_CATEGORY_GROUP]: {
    endPoint: (params) =>
      makeUrl("/admin/market-place/categories/groups/show/:groupId", params),
    method: "GET",
  },
  [MP_UPDATE_CATEGORY_GROUP]: {
    endPoint: (params) =>
      makeUrl("/admin/market-place/categories/groups/update/:groupId", params),
    method: "POST",
  },
  [MP_DELETE_CATEGORY_GROUP]: {
    endPoint: (params) =>
      makeUrl("/admin/market-place/categories/groups/delete/:groupId", params),
    method: "DELETE",
  },
  [MP_DELETE_PRODUCT_CATEGORY]: {
    endPoint: (params) =>
      makeUrl(
        "/admin/market-place/products/categories/delete/:categoryId",
        params
      ),
    method: "DELETE",
  },
  [MP_UPDATE_PRODUCT_CATEGORY]: {
    endPoint: (params) =>
      makeUrl(
        "/admin/market-place/products/categories/update/:categoryId",
        params
      ),
    method: "PATCH",
  },
  [MP_GET_PRODUCT_CATEGORY]: {
    endPoint: (params) =>
      makeUrl(
        "/admin/market-place/products/categories/show/:categoryId",
        params
      ),
    method: "GET",
  },
  [MP_UPDATE_PRODUCT]: {
    endPoint: (params) =>
      makeUrl("/admin/market-place/products/update/:productId", params),
    method: "PATCH",
  },
  [MP_GET_PRODUCT]: {
    endPoint: (params) =>
      makeUrl("/admin/market-place/products/show/:slug", params),
    method: "GET",
  },
  [MP_GET_BRAND]: {
    endPoint: (params) => makeUrl("/admin/market-place/brands"),
  },
  [MP_GET_LANDING_PAGE_CONTENT]: {
    endPoint: (params) => makeUrl("/admin/market-place/landing-page"),
  },
  [MP_UPLOAD_LANDING_PAGE_SLIDE]: {
    endPoint: makeUrl("/admin/market-place/landing-page/slides/upload"),
    method: "POST",
  },
  [MP_DELETE_LANDING_PAGE_SLIDE]: {
    endPoint: (params) =>
      makeUrl(
        "/admin/market-place/landing-page/slides/delete/:slideId",
        params
      ),
    method: "DELETE",
  },
  [MP_UPDATE_LANDING_PAGE_SLIDE]: {
    endPoint: makeUrl("/admin/market-place/landing-page/slides/update"),
    method: "POST",
  },
  [MP_GET_LANDING_PAGE_SLIDE]: {
    endPoint: (params) =>
      makeUrl("/admin/market-place/landing-page/slides/show/:slideId", params),
    method: "get",
  },
  [MP_GET_ALL_BRANDS]: {
    endPoint: (params) => makeUrl("/admin/market-place/brands/all", params),
    method: "GET",
  },
  [MP_GET_BRAND]: {
    endPoint: (params) =>
      makeUrl("/admin/market-place/brands/show/:brandId", params),
    method: "GET",
  },
  [MP_UPDATE_BRAND]: {
    endPoint: (params) => makeUrl("/admin/market-place/brands/update", params),
    method: "PATCH",
  },
  [MP_DELETE_BRAND]: {
    endPoint: (params) =>
      makeUrl("/admin/market-place/brands/delete/:brandId", params),
    method: "DELETE",
  },
  [MP_ADD_BRAND]: {
    endPoint: (params) => makeUrl("/admin/market-place/brands/add"),
    method: "POST",
  },
  [MP_GET_CATEGORY_GROUP_ITEMS]: {
    endPoint: (params) =>
      makeUrl("/admin/market-place/categories/groups/items/all"),
    method: "GET",
  },
  [MP_GET_CATEGORIES_NOT_ADDED_TO_CATEGORY_GROUP]: {
    endPoint: (params) =>
      makeUrl("/admin/market-place/categories/groups/items/not-in-list"),
    method: "GET",
  },
  [MP_ADD_CATEGORY_TO_CATEGORY_GROUP_ITEMS]: {
    endPoint: (params) =>
      makeUrl("/admin/market-place/categories/groups/items/add"),
    method: "POST",
  },
  [MP_REMOVE_ITEM_FROM_CATEGORY_GROUP_LIST]: {
    endPoint: (params) =>
      makeUrl("/admin/market-place/categories/groups/items/remove"),
    method: "DELETE",
  },
  [MP_ADD_PRODUCT]: {
    endPoint: makeUrl("/admin/market-place/products/add"),
    method: "POST",
  },
  [MP_GET_ALL_PRODUCTS]: {
    endPoint: (params) => makeUrl("/admin/market-place/products/all", params),
    method: "GET",
  },
};
