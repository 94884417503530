import {
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { formatNumber } from "accounting-js";
import ApiRequest from "../../../api/ApiRequest";
import { BNK_API_GET_CHECKING_ACCOUNT_REPORT } from "../../../api/apiThreads";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    boxShadow: theme.boxShadows[2],
    borderRadius: "10px",
    padding: "20px",
  },
}));

/**
 * Checking Account Transaction Balances (CAT)
 * Total Debits,
 * Total Credits,
 * Total Charges,
 * Account Balance
 * @param {*} props
 */
export default function CATBalances(props) {
  return (
    <ApiRequest
      autoload
      query={props.apiQueryState}
      addOnThread={JSON.stringify(props.apiQueryState)}
      thread={BNK_API_GET_CHECKING_ACCOUNT_REPORT}
    >
      {({ res, loading, RefreshButton }) => {
        if (loading) {
          return <CircularProgress size="50px" />;
        }

        let total_deposits,
          checking_balance,
          total_withdraws,
          total_bnk_charges = 0;
        if (typeof res == "object") {
          if (typeof res.data == "object") {
            if (typeof res.data.total_credit != "undefined") {
              total_deposits = res.data.total_credit;
              total_bnk_charges = res.data.total_bnk_charges;
              total_withdraws = res.data.total_withdraw;
              checking_balance = res.data.checking_balance;
            }
          }
        }

        return (
          <Grid container spacing={2}>
            <Card
              title="Available Balance"
              amount={formatNumber(checking_balance)}
            />
            <Card
              title="Total Deposits"
              amount={formatNumber(total_deposits)}
            />
            <Card
              title="Total Withdraws"
              amount={formatNumber(total_withdraws)}
            />
            <Card
              title="Total Bank Charges"
              amount={formatNumber(total_bnk_charges)}
            />
          </Grid>
        );
      }}
    </ApiRequest>
  );
}

function Card(props) {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} md={3} lg={2}>
      <Paper className={classes.paper}>
        <Typography>
          <small>{props.title}</small>
        </Typography>
        <Typography>UGX: {props.amount}</Typography>
      </Paper>
    </Grid>
  );
}
