import {
  Grid,
  Typography,
  Paper,
  Divider,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import KijjeInput from "../../../global/components/inputs/Inputs";
import clsx from "clsx";
import { KeyboardArrowRight, Save } from "@material-ui/icons";
import ApiRequest from "../../../api/ApiRequest";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.boxShadows[3],
    borderRadius: "10px",
  },
  title: {
      fontSize: "0.8rem",
      fontWeight: 700,
  }
}));
export default function FrontEndSettings(props) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={10}>
        <Paper className={clsx(classes.root, "p-3")}>
          <ApiRequest
            initialPayload={{
              admin_url: "",
              hospital_url: "",
              doctor_url: "",
              patient_url: "",
            }}
          >
            {(reqBody) => {
              let { loading, error, res, ErrorView } = reqBody;

              if (loading == true) {
                return <CircularProgress />;
              }

              if (error == true) {
                return <ErrorView />;
              }

              let config = {};
              if(typeof res == 'object'){
                  if(typeof res.data == 'object'){
                      if(typeof res.data.admin_url == 'string'){
                          config = res.data.front_end_config
                      }
                  }
              }

              return (
                <ApiRequest payload={config}>
                  {(reqBody2) => {
                    return (
                      <React.Fragment>
                        <Grid container spacing={3} alignItems="flex-end">
                          <Grid item xs={12} md={6}>
                            <Typography className={classes.title}>Admin Url</Typography>
                            <small>
                              Url or domain where the admin dashboard is hosted.
                            </small>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <KijjeInput
                              placeholder="admin_url"
                              variant="filled"
                              square
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <Typography  className={classes.title}>Hospital Url</Typography>
                            <small>
                              Url or domain where the hospital application is
                              hosted.
                            </small>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <KijjeInput
                              placeholder="hospital_url"
                              variant="filled"
                              square
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <Typography  className={classes.title}>Doctor Url</Typography>
                            <small>
                              Url or domain where the admin doctor application
                              is hosted.
                            </small>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <KijjeInput
                              placeholder="hospital_url"
                              variant="filled"
                              square
                            />
                          </Grid>
                        </Grid>
                        <Divider className="my-3" />

                        <Button
                          align="right"
                          size="small"
                          variant="outlined"
                          endIcon={<Save />}
                        >
                          Update
                        </Button>
                      </React.Fragment>
                    );
                  }}
                </ApiRequest>
              );
            }}
          </ApiRequest>
        </Paper>
      </Grid>
    </Grid>
  );
}
