import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { deepOrange, green, grey } from "@material-ui/core/colors";
import {
  CalendarToday as Calender,
  LocationCity,
  RefreshOutlined,
} from "@material-ui/icons";
import { blueGrey } from "@mui/material/colors";
import { formatNumber } from "accounting-js";
import clsx from "clsx";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "react-vis/dist/style.css";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_GET_ALL_BRANCHES,
  BNK_API_GET_CHECKING_ACCOUNT_REPORT,
  BNK_API_GET_CUSTOMER_SAVINGS_REPORT,
  BNK_API_GET_EXPENSES_REPORT,
  BNK_API_GET_INCOME_REPORTS,
  BNK_API_GET_LOANS_REPORT,
  BNK_API_GET_RUNNING_BALANCE_REPORT,
} from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";
import { CustomerStatistics } from "../Accounts";
import { SelectBranchMenu } from "../Transactions/TransactionsTable";
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "#F3EFEF",
    backgroundColor: grey[50],
    borderRadius: "15px",
  },
  userStatRoot: {
    boxShadow: theme.boxShadows[2],
    backgroundColor: "#fff",
    borderRadius: "10px",
  },
  iconBox: {
    width: "40px",
    height: "40px",
    borderRadius: "5px",
    backgroundColor: deepOrange[400],
    color: "#fff",
  },
  number: {
    fontSize: "1.2rem",
    lineHeight: "1.2rem",
    fontWeight: 500,
  },
  title: {
    fontSize: "0.8rem",
    lineHeight: "1rem",
    fontWeight: 400,
    color: grey[600],
  },
  divider: {
    background: "#000",
  },

  /**
   * Use by device
   */
  useByDeviceRoot: {
    boxShadow: theme.boxShadows[2],
    backgroundColor: "#fff",
    borderRadius: "10px",
    marginTop: "-10px",
  },
  startCardRoot: {
    width: "calc(100% - 20px)",
    borderRadius: "10px",
    height: "100%",
    minHeight: "200px",
    boxShadow: theme.boxShadows[3],
  },
  calenderIcon: {
    fontSize: "1.3rem",
  },
  iconButtonSmall: {
    width: "25px",
    height: "25px",
    "& *": {
      fontSize: "0.8rem!important",
    },
  },
  filterCalenderRoot: {
    width: "calc(100% + 20px)",
    boxShadow: theme.boxShadows[4],

    backgroundColor: grey[50],
  },
  smallFontSize: {
    "& *": {
      fontSize: "0.8rem",
    },
  },
  iconBig: {
    backgroundColor: "#90caf9",
    borderRadius: "10px",
    padding: "10px",
    marginRight: "10px",
    "& *": {
      fontSize: "2rem",
    },
  },
  paper: {
    borderRadius: "10px",
    boxShadow: theme.boxShadows[3],
    padding: "10px",
    minHeight: "200px",
  },
  paper2: {
    boxShadow: "none",
    borderColor: "transparent",
  },
  skeleton: {
    width: "100%",
    height: "100%",
  },
  mediumText: {
    fontSize: "0.9rem",
    color: blueGrey[600],
  },
}));

export default function AppDashboard(props) {
  const classes = useStyles();
  const [apiQueryState, setApiQueryState] = useState({
    branch: "ALL",
    start_date: "",
    end_date: "",
    refresh: "",
  });

  const onRefresh = () => {
    setApiQueryState({ ...apiQueryState, refresh: Date.now() });
  };

  return (
    <div className="w-100 p-1">
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div
        className="d-flex mb-3 justify-content-between align-items-end"
        style={{ position: "relative" }}
      >
        <div className="d-flex align-items-center">
          <div style={{ fontSize: "1.5rem" }}>
            <i className=" bi-grid"></i>
          </div>
          <Typography className="mt-1 ms-1" variant="h6">
            Dashboard
          </Typography>
        </div>
        <div className="d-flex align-items-center ">
          <div className="pt-3">
            <IconButton onClick={onRefresh} className={classes.refreshBtn}>
              <RefreshOutlined />
            </IconButton>
          </div>
          <div className="px-1">
            <KijjeInput
              value={apiQueryState.start_date}
              onChange={(e) => {
                setApiQueryState({
                  ...apiQueryState,
                  start_date: e.target.value,
                });
              }}
              type="date"
              label="Start date"
              square
            />
          </div>
          <div className="px-1">
            <KijjeInput
              value={apiQueryState.end_date}
              onChange={(e) => {
                setApiQueryState({
                  ...apiQueryState,
                  end_date: e.target.value,
                });
              }}
              type="date"
              label="End date"
              square
            />
          </div>
          <SelectBranchMenu
            hasAll={true}
            isSelect
            input={() => ({
              onChange: (e) => {
                setApiQueryState({
                  ...apiQueryState,
                  branch: e.target.value,
                });
              },
              value: apiQueryState.branch,
            })}
            apiQueryState={apiQueryState}
            setApiQueryState={setApiQueryState}
          />
        </div>
      </div>
      <Divider className={clsx(classes.divider, "mb-2")} />
      <RunningBalanceReport apiQueryState={apiQueryState} />
      <Divider className={clsx(classes.divider, "mb-2")} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12} sm={4} md={4}>
              <SavingReport apiQueryState={apiQueryState} />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <IncomesReport apiQueryState={apiQueryState} />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <ExpensesReport apiQueryState={apiQueryState} />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <CheckingAccountReport apiQueryState={apiQueryState} />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <LoansReport apiQueryState={apiQueryState} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <BranchesReport
                apiQueryState={apiQueryState}
                setApiQueryState={setApiQueryState}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomerStatistics
                apiQueryState={apiQueryState}
                setApiQueryState={setApiQueryState}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

/**
 * Get savings report
 */
export function SavingReport(props) {
  const classes = useStyles();
  return (
    <ApiRequest
      autoload
      query={props.apiQueryState}
      addOnThread={JSON.stringify(props.apiQueryState)}
      thread={BNK_API_GET_CUSTOMER_SAVINGS_REPORT}
    >
      {({ res, loading, RefreshButton }) => {
        if (loading) {
          return <CircularProgress size="50px" />;
        }

        let total_deposits,
          total_withdraws,
          savings_balance = 0;
        if (typeof res == "object") {
          if (typeof res.data == "object") {
            if (typeof res.data.total_deposits != "undefined") {
              total_deposits = res.data.total_deposits;
              total_withdraws = res.data.total_withdraws;
              savings_balance = res.data.savings_balance;
            }
          }
        }

        return (
          <React.Fragment>
            <Paper className={clsx(classes.startCardRoot)}>
              <div className="p-3 w-100 mb-4">
                <Typography variant="h6">Customer Savings</Typography>
                <Divider className="my-1" />
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <Typography className={clsx(classes.mediumText, "my-1")}>
                      Deposits:
                    </Typography>
                  </div>
                  <div>
                    <small>Ugx: {formatNumber(total_deposits)}</small>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <Typography className={classes.mediumText}>
                      Withdraws:
                    </Typography>
                  </div>
                  <div>
                    <small>Ugx: {formatNumber(total_withdraws)}</small>
                  </div>
                </div>
              </div>
              <Paper
                className={clsx(
                  classes.filterCalenderRoot,
                  "d-flex justify-content-between align-items-center px-3 py-2"
                )}
              >
                <div className={classes.smallFontSize}>
                  <small>Savings Balance</small>
                </div>
                <div className="d-flex align-items-center">
                  <span className={clsx(classes.smallFontSize, "me-1")}>
                    <small>UGX </small>
                  </span>
                  <Typography
                    className="ps-1"
                    style={{ fontSize: "1rem!important" }}
                    variant="h5"
                  >
                    {formatNumber(savings_balance)}
                  </Typography>
                </div>
              </Paper>
            </Paper>
          </React.Fragment>
        );
      }}
    </ApiRequest>
  );
}

export function IncomesReport(props) {
  const classes = useStyles();
  return (
    <ApiRequest
      autoload
      query={props.apiQueryState}
      addOnThread={JSON.stringify(props.apiQueryState)}
      thread={BNK_API_GET_INCOME_REPORTS}
    >
      {({ res, loading, RefreshButton }) => {
        if (loading) {
          return <CircularProgress size="50px" />;
        }

        let total_loan_profit,
          other_incomes,
          total_incomes = 0;
        if (typeof res == "object") {
          if (typeof res.data == "object") {
            if (typeof res.data.total_incomes != "undefined") {
              total_loan_profit = res.data.total_loan_profit;
              other_incomes = res.data.other_incomes;
              total_incomes = res.data.total_incomes;
            }
          }
        }

        return (
          <React.Fragment>
            <Paper className={clsx(classes.startCardRoot)}>
              <div className="p-3 w-100 mb-4">
                <Typography variant="h6">Incomes</Typography>

                <Divider className="my-2" />
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <Typography className={clsx(classes.mediumText)}>
                      Loan Interest:
                    </Typography>
                  </div>
                  <div>
                    <small>Ugx: {formatNumber(total_loan_profit)}</small>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <Typography className={clsx(classes.mediumText)}>
                      Others:
                    </Typography>
                  </div>
                  <div>
                    <small>Ugx: {formatNumber(other_incomes)}</small>
                  </div>
                </div>
              </div>
              <Paper
                className={clsx(
                  classes.filterCalenderRoot,
                  "d-flex justify-content-between align-items-center px-3 py-2"
                )}
              >
                <div className={classes.smallFontSize}>
                  <small>Total Incomes</small>
                </div>
                <div className="d-flex align-items-center">
                  <span className={clsx(classes.smallFontSize, "me-1")}>
                    <small>UGX </small>
                  </span>
                  <Typography
                    className="ps-1"
                    style={{ fontSize: "1rem!important" }}
                    variant="h5"
                  >
                    {formatNumber(total_incomes)}
                  </Typography>
                </div>
              </Paper>
            </Paper>
          </React.Fragment>
        );
      }}
    </ApiRequest>
  );
}

export function ExpensesReport(props) {
  const classes = useStyles();
  return (
    <ApiRequest
      autoload
      query={props.apiQueryState}
      addOnThread={JSON.stringify(props.apiQueryState)}
      thread={BNK_API_GET_EXPENSES_REPORT}
    >
      {({ res, loading, RefreshButton }) => {
        if (loading) {
          return <CircularProgress size="50px" />;
        }

        let other_expenses,
          total_expenses = 0;
        if (typeof res == "object") {
          if (typeof res.data == "object") {
            if (typeof res.data.total_expenses != "undefined") {
              other_expenses = res.data.other_expenses;
              total_expenses = res.data.total_expenses;
            }
          }
        }

        return (
          <React.Fragment>
            <Paper className={clsx(classes.startCardRoot)}>
              <div className="p-3 w-100 mb-4">
                <Typography variant="h6">Expenses </Typography>

                <Divider className="my-2" />
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <Typography className={clsx(classes.mediumText)}>
                      Expenses
                    </Typography>
                  </div>
                  <div>
                    <small>Ugx: {formatNumber(total_expenses)}</small>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <Typography className={clsx(classes.mediumText)}>
                      Others:
                    </Typography>
                  </div>
                  <div>
                    <small>Ugx: {formatNumber(other_expenses)}</small>
                  </div>
                </div>
              </div>
              <Paper
                className={clsx(
                  classes.filterCalenderRoot,
                  "d-flex justify-content-between align-items-center px-3 py-2"
                )}
              >
                <div className={classes.smallFontSize}>
                  <small>Total Expenses</small>
                </div>
                <div className="d-flex align-items-center">
                  <span className={clsx(classes.smallFontSize, "me-1")}>
                    <small>UGX </small>
                  </span>
                  <Typography
                    className="ps-1"
                    style={{ fontSize: "1rem!important" }}
                    variant="h5"
                  >
                    {formatNumber(total_expenses)}
                  </Typography>
                </div>
              </Paper>
            </Paper>
          </React.Fragment>
        );
      }}
    </ApiRequest>
  );
}

export function CheckingAccountReport(props) {
  const classes = useStyles();
  return (
    <ApiRequest
      autoload
      query={props.apiQueryState}
      addOnThread={JSON.stringify(props.apiQueryState)}
      thread={BNK_API_GET_CHECKING_ACCOUNT_REPORT}
    >
      {({ res, loading, RefreshButton }) => {
        if (loading) {
          return <CircularProgress size="50px" />;
        }

        let total_credits,
          checking_balance,
          total_debits = 0;
        if (typeof res == "object") {
          if (typeof res.data == "object") {
            if (typeof res.data.total_debit != "undefined") {
              total_credits = res.data.total_credit;
              total_debits = res.data.total_debit;
              checking_balance = res.data.checking_balance;
            }
          }
        }

        return (
          <React.Fragment>
            <Paper className={clsx(classes.startCardRoot)}>
              <div className="p-3 w-100 mb-4">
                <Typography variant="h6">Checking Account </Typography>

                <Divider className="my-2" />
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <Typography className={clsx(classes.mediumText)}>
                      Credits
                    </Typography>
                  </div>
                  <div>
                    <small>Ugx: {formatNumber(total_credits)}</small>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <Typography className={clsx(classes.mediumText)}>
                      Debits
                    </Typography>
                  </div>
                  <div>
                    <small>Ugx: {formatNumber(total_debits)}</small>
                  </div>
                </div>
              </div>
              <Paper
                className={clsx(
                  classes.filterCalenderRoot,
                  "d-flex justify-content-between align-items-center px-3 py-2"
                )}
              >
                <div className={classes.smallFontSize}>
                  <small>Checking Balance</small>
                </div>
                <div className="d-flex align-items-center">
                  <span className={clsx(classes.smallFontSize, "me-1")}>
                    <small>UGX </small>
                  </span>
                  <Typography
                    className="ps-1"
                    style={{ fontSize: "1rem!important" }}
                    variant="h5"
                  >
                    {formatNumber(checking_balance)}
                  </Typography>
                </div>
              </Paper>
            </Paper>
          </React.Fragment>
        );
      }}
    </ApiRequest>
  );
}

export function LoansReport(props) {
  const classes = useStyles();
  return (
    <ApiRequest
      autoload
      query={props.apiQueryState}
      addOnThread={JSON.stringify(props.apiQueryState)}
      thread={BNK_API_GET_LOANS_REPORT}
    >
      {({ res, loading, RefreshButton }) => {
        if (loading) {
          return <CircularProgress size="50px" />;
        }

        let cash_out,
          cash_received,
          cash_unpaid = 0;
        if (typeof res == "object") {
          if (typeof res.data == "object") {
            cash_out = res.data.total_amount_issued;
            cash_received = res.data.total_amount_collected;
            cash_unpaid = res.data.cash_unpaid;
          }
        }

        return (
          <React.Fragment>
            <Paper className={clsx(classes.startCardRoot)}>
              <div className="p-3 w-100 mb-4">
                <Typography variant="h6">Loans Report</Typography>

                <Divider className="my-2" />
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <Typography className={clsx(classes.mediumText)}>
                      Cash Out
                    </Typography>
                  </div>
                  <div>
                    <small>Ugx: {formatNumber(cash_out)}</small>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <Typography className={clsx(classes.mediumText)}>
                      Cash Received
                    </Typography>
                  </div>
                  <div>
                    <small>Ugx: {formatNumber(cash_received)}</small>
                  </div>
                </div>
              </div>
              <Paper
                className={clsx(
                  classes.filterCalenderRoot,
                  "d-flex justify-content-between align-items-center px-3 py-2"
                )}
              >
                <div className={classes.smallFontSize}>
                  <small>Uncleared Amount</small>
                </div>
                <div className="d-flex align-items-center">
                  <span className={clsx(classes.smallFontSize, "me-1")}>
                    <small>UGX </small>
                  </span>
                  <Typography
                    className="ps-1"
                    style={{ fontSize: "1rem!important" }}
                    variant="h5"
                  >
                    {formatNumber(cash_unpaid)}
                  </Typography>
                </div>
              </Paper>
            </Paper>
          </React.Fragment>
        );
      }}
    </ApiRequest>
  );
}

export function RunningBalanceReport(props) {
  const classes = useStyles();
  return (
    <ApiRequest
      autoload
      query={props.apiQueryState}
      addOnThread={JSON.stringify(props.apiQueryState)}
      thread={BNK_API_GET_RUNNING_BALANCE_REPORT}
    >
      {({ res, loading, RefreshButton }) => {
        if (loading) {
          return <CircularProgress size="50px" />;
        }

        let cash_at_hand,
          cash_in_bank,
          cash_in_loans,
          loan_interest_on_uncleared_loans;
        if (typeof res == "object") {
          if (typeof res.data == "object") {
            cash_at_hand = res.data.cash_at_hand;
            cash_in_bank = res.data.cash_in_bank;
            cash_in_loans = res.data.cash_in_loans;
            loan_interest_on_uncleared_loans =
              res.data.loan_interest_on_uncleared_loans;
          }
        }

        return (
          <Grid container spacing={1} className="my-3">
            <Grid item xs={12} sm={4} md={3}>
              <Paper variant="outlined" className={clsx(classes.paper2)}>
                <Typography>
                  <b>
                    <small>Cash at hand</small>
                  </b>
                </Typography>
                <Typography variant="h4">
                  UGX: {formatNumber(cash_at_hand)}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              <Paper variant="outlined" className={clsx(classes.paper2)}>
                <Typography>
                  <b>
                    <small>Cash in bank</small>
                  </b>
                </Typography>
                <Typography variant="h4">
                  UGX: {formatNumber(cash_in_bank)}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              <Paper variant="outlined" className={clsx(classes.paper2)}>
                <Typography>
                  <b>
                    <small>Cash in loans</small>
                  </b>
                </Typography>
                <Typography variant="h4">
                  UGX: {formatNumber(cash_in_loans)}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Paper variant="outlined" className={clsx(classes.paper2)}>
                <Typography>
                  <b>
                    <small>Interest on uncleared loans</small>
                  </b>
                </Typography>
                <Typography variant="h4">
                  UGX: {formatNumber(loan_interest_on_uncleared_loans)}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        );
      }}
    </ApiRequest>
  );
}

export function BranchesReport(props) {
  const classes = useStyles();
  const { apiQueryState, setApiQueryState } = props;
  return (
    <Paper className={classes.paper}>
      <ApiRequest
        autoload
        thread={BNK_API_GET_ALL_BRANCHES}
        query={apiQueryState}
        addOnThread={JSON.stringify(apiQueryState)}
      >
        {({ loading, RefreshButton, error, res, ErrorView }) => {
          if (loading == true) {
            return (
              <div className="w-100 text-center p-4" align="center">
                <CircularProgress />
              </div>
            );
          }

          if (error == true) {
            return <ErrorView />;
          }

          let branches = 0;
          if (typeof res == "object") {
            if (typeof res.data == "object") {
              branches = res.data.branches.length;
            }
          }

          return (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid
                  container
                  className="align-items-center justify-content-between"
                >
                  <Grid item>
                    <small>Branches</small>
                    <Typography className={classes.statTitle}>
                      Branches opened
                    </Typography>
                  </Grid>
                </Grid>
                <div className="d-flex  align-items-center">
                  <div className="me-2">
                    <LocationCity
                      style={{ fontSize: "100px", color: green[500] }}
                    />
                  </div>
                  <div>
                    <Typography variant="h2">{branches}</Typography>
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: "0.9rem",
                        color: grey[500],
                      }}
                    >
                      Opened Branches
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          );
        }}
      </ApiRequest>
    </Paper>
  );
}
