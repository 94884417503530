import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { ArrowBack, PersonAdd, Print, ArrowForward } from "@material-ui/icons";
import React, { useState } from "react";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_BRANCH_OPEN_ACCOUNT,
  BNK_API_GET_ALL_BRANCHES,
  BNK_API_GET_ALL_CUSTOMERS,
  BNK_API_GET_CUSTOMER_STATISTICS,
  BNK_API_OPEN_ACCOUNT_CHOOSE_BRANCH_VALIDATION,
  BNK_API_UPLOAD_CUSTOMER_DOCUMENTS,
} from "../../../api/apiThreads";
import PassportPhotoAndIdCardPhotoCropper, {
  dataURItoBlob,
} from "./PassportPhotoAndIdCardPhotoCropper";

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "100%",
    minHeight: "100vh",
    paddingBottom: "100px",
  },
  passportPhoto: {
    height: "150px",
    backgroundSize: "contain",
    position: "relative",
    backgroundRepeat: "no-repeat",
    borderRadius: "10px",
    backgroundPosition: "center",
  },
  passportPhotoCover: {
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundColor: "rgba(0,0,0,0.3)",
    borderRadius: "10px",
    color: "#fff",
    fontSize: "3rem",
    ["& *"]: {
      fontSize: "3rem",
    },
  },
  select: {
    width: "100%",
    height: "40px",
    padding: "5px 10px",
  },
  listItemLeft: {
    width: "200px",
    color: theme.palette.primary.main,
  },
  listItemTextRight: {
    width: "200px",
    textAlign: "left",
    ["&*"]: {
      textAlign: "left",
    },
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  listCard: {
    borderRadius: "10px",
    border: "solid 1px " + theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  listItemSecondaryText: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  ac_opened_card: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  passportPhotoImage: {
    width: "150px",
    height: "150px",
    background: "#fefefe",
    borderRadius: "5px",
    border: "dashed 1px #000",
  },
  profileId: {
    width: "150px",
    height: "150px",
    borderRadius: "5px",
    border: "solid 2px black",
  },
  idPhoto: {
    width: "200px",
    height: "150px",
    borderRadius: "5px",
    border: "solid 2px black",
  },
}));

const printAccountInfo = () => {
  window.print();
};

/**
 * We shall register members from here
 *
 */
export default function OpenCustomerAccountButton(props) {
  const classes = useStyles();
  const [dialogState, setDialogState] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [documents, setDocuments] = React.useState({
    passport_photo: "",
    identity_card_back: "",
    identity_card_front: "",
  });
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
    setSelectedBranch(null);
    setDocuments({
      passport_photo: "",
      identity_card_back: "",
      identity_card_front: "",
    });
  };
  const onSelectBranch = (branch) => {
    setSelectedBranch(branch);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        size="small"
        startIcon={<PersonAdd />}
        {...props}
        onClick={onDialogStateChange}
      >
        Open Account
      </Button>
      <Dialog open={dialogState} fullScreen fullWidth scroll="body">
        <Paper className={classes.root}>
          <Container>
            <Grid container>
              <Grid item xs={12} className="mt-4">
                <Button onClick={onDialogStateChange} startIcon={<ArrowBack />}>
                  Open Account
                </Button>
              </Grid>

              <ApiRequest thread={BNK_API_GET_ALL_BRANCHES} autoload>
                {({ res, loading, error, ErrorView, RefreshButton }) => {
                  if (loading) {
                    return (
                      <div
                        className="w-100 d-flex justify-content-center align-items-center p-5"
                        align="center"
                      >
                        <CircularProgress size="50px" />
                      </div>
                    );
                  }

                  if (error) {
                    return <ErrorView />;
                  }

                  let branches = [];
                  if (typeof res == "object") {
                    if (typeof res.data == "object") {
                      if (Array.isArray(res.data.branches)) {
                        branches = res.data.branches;
                      }
                    }
                  }

                  if (branches.length == null) {
                    return (
                      <div className="p-4 my-3" align="center">
                        <Typography>No Branches opened yet.</Typography>
                        <RefreshButton variant="icon" />
                      </div>
                    );
                  }

                  return (
                    <ApiRequest
                      thread={BNK_API_BRANCH_OPEN_ACCOUNT}
                      reloadOnSuccess={[
                        BNK_API_GET_ALL_CUSTOMERS,
                        BNK_API_GET_CUSTOMER_STATISTICS,
                      ]}
                      isForm
                      initialPayload={{
                        account_type: "",
                        passport_photo: "",
                        auto_account_no: true,
                        branchId: selectedBranch,
                        surname: "",
                        given_name: "",
                        middle_name: "",
                        other_name: "",
                      }}
                    >
                      {(apiBody) => {
                        let account = {};
                        let { completed, res } = apiBody;

                        if (typeof res == "object") {
                          if (typeof res.data == "object") {
                            if (typeof res.data.account === "object") {
                              if (completed === true && res.success === true) {
                                account = res.data.account;
                                return (
                                  <ApiRequest
                                    thread={BNK_API_UPLOAD_CUSTOMER_DOCUMENTS}
                                    addOnThread={Date.now()}
                                    autoload={true}
                                    withFileUpload={true}
                                    payload={{
                                      passport_photo: dataURItoBlob(
                                        documents.passport_photo
                                      ),
                                      identity_card_back: dataURItoBlob(
                                        documents.identity_card_back
                                      ),
                                      identity_card_front: dataURItoBlob(
                                        documents.identity_card_front
                                      ),
                                      account_number: account.account_number,
                                      branch_id: account.branch_id,
                                    }}
                                  >
                                    {({
                                      loading,
                                      res,
                                      error,
                                      ErrorView,
                                      SubmitButton,
                                    }) => {
                                      if (typeof res == "object") {
                                        if (typeof res.data == "object") {
                                          if (res.data.uploaded === true) {
                                            return (
                                              <AccountCreatedCard
                                                documents={documents}
                                                account={account}
                                              />
                                            );
                                          }
                                        }
                                      }

                                      if (loading) {
                                        return (
                                          <div>
                                            <CircularProgress />
                                            <Typography>
                                              Account Created. Uploading
                                              documents.
                                            </Typography>
                                          </div>
                                        );
                                      }

                                      if (error) {
                                        return <ErrorView />;
                                      }

                                      return "";
                                    }}
                                  </ApiRequest>
                                );
                              }
                            }
                          }
                        }

                        return (
                          <React.Fragment>
                            <Grid item xs={12} md={7} className="mt-3 pl-3">
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <VerticalLinearStepper
                                    {...apiBody}
                                    branches={branches}
                                    onSelectBranch={onSelectBranch}
                                    selectedBranch={selectedBranch}
                                    documents={documents}
                                    setDocuments={setDocuments}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        );
                      }}
                    </ApiRequest>
                  );
                }}
              </ApiRequest>
            </Grid>
          </Container>
        </Paper>
      </Dialog>
    </React.Fragment>
  );
}

const useStepperStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return [
    "Select Branch",
    "Personal Information",
    "Address Information",
    "Contact Information",
    "Job Information",
    "Next of Kin Information",
    "Account Information",
    "Show Account Info",
  ];
}

function getStepContent(props) {
  switch (props.step) {
    case 0:
      return <StepOneChooseBranch {...props} />;
    case 1:
      return <PersonalInformation {...props} />;
    case 2:
      return <AddressInformation {...props} />;
    case 3:
      return <ContactInformation {...props} />;
    case 4:
      return <JobInformation {...props} />;
    case 5:
      return <NextOfKinInformation {...props} />;
    case 6:
      return <AccountInformation {...props} />;
    // case 7:
    // return <ShowAccountDetails {...props} />;
    default:
      return "Unknown step";
  }
}

function VerticalLinearStepper(props) {
  const classes = useStepperStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const { documents, setDocuments } = props;

  const handleNext = () => {
    if (props.selectedBranch == null) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  let OpenAccountButton = props.SubmitButton;

  return (
    <ApiRequest
      thread={BNK_API_OPEN_ACCOUNT_CHOOSE_BRANCH_VALIDATION}
      params={{
        step: getSteps()[activeStep].replaceAll(" ", "_").toUpperCase(),
      }}
      payload={props.payload}
      callbacks={{
        success: (res, xthis) => {
          if (typeof res == "object") {
            if (typeof res.data == "object") {
              if (res.data.validated == true) {
                if (activeStep == 6) {
                } else {
                  handleNext();
                }
              }
            }
          }
        },
      }}
    >
      {({ SubmitButton }) => {
        return (
          <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    <Grid container>
                      <Grid item xs={12}>
                        {getStepContent({
                          ...props,
                          setDocuments,
                          documents,
                          step: index,
                        })}
                      </Grid>
                    </Grid>
                    <div className={classes.actionsContainer}>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                          size="small"
                          color="secondary"
                          startIcon={<ArrowBack />}
                        >
                          Back
                        </Button>

                        {activeStep === 6 ? (
                          <OpenAccountButton
                            endIcon={<ArrowForward />}
                            variant="contained"
                            color="primary"
                          >
                            Open Account
                          </OpenAccountButton>
                        ) : (
                          <SubmitButton
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                          >
                            Next
                          </SubmitButton>
                        )}
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} className={classes.resetContainer}>
                <Typography>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset} className={classes.button}>
                  Reset
                </Button>
              </Paper>
            )}
          </div>
        );
      }}
    </ApiRequest>
  );
}

function StepOneChooseBranch(props) {
  return (
    <div>
      <Typography variant="h4">Choose Branch</Typography>
      <Typography className="my-2">
        <small>
          Ensure that you have chosen the correct branch, once you have
          registered a member on a different branch it will cause some issues.
        </small>
      </Typography>
      <div style={{ width: 300 }}>
        <select
          value={props.selectedBranch}
          onChange={(e) => {
            props.setPayload({ branch_id: e.target.value });
            props.onSelectBranch(e.target.value);
          }}
          style={{ width: "100%", padding: "5px 5px", borderRadius: "5px" }}
        >
          <option value="null">Select Branch</option>
          {props.branches.map((branch, index) => {
            return (
              <option value={branch.branch_id} key={index}>
                {branch.name}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}

function PersonalInformation(props) {
  const classes = useStyles();
  const { input, FileInput, setPayload, payload } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" color="primary">
        Personal Information
      </Typography>
      <Typography variant="body2" className="mt-2">
        The Client who is opening up the account should provide all the
        necessary information. Fields marked with * are required and a client
        has have provided them on the hard copy
      </Typography>
      <Divider className="my-2" />
      <Grid container alignItems="center">
        {/* <Grid item xs={12} md={5}>
          <small>
            Select the customer passport photo and national identity card.
            Please choose both front and back
          </small>
        </Grid> */}
        <Grid item>
          <Typography>
            <b>Passport Photo</b>
          </Typography>
          <div style={{ width: "150px", height: "150px" }}>
            <PassportPhotoAndIdCardPhotoCropper
              id="passport-photo"
              aspectRatio={1 / 1}
              onChange={(file) =>
                props.setDocuments({ ...props.documents, passport_photo: file })
              }
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item>
          <Typography>
            <b>Id Card Front</b>
          </Typography>
          <div style={{ width: "249px", height: "157px" }}>
            <PassportPhotoAndIdCardPhotoCropper
              id="identity-card_font"
              aspectRatio={249 / 157}
              onChange={(file) => {
                props.setDocuments({
                  ...props.documents,
                  identity_card_front: file,
                });
              }}
            />
          </div>
        </Grid>
        <Grid item>
          <Typography>
            <b>Id Card Back</b>
          </Typography>
          <div style={{ width: "249px", height: "157px" }}>
            <PassportPhotoAndIdCardPhotoCropper
              aspectRatio={249 / 157}
              onChange={(file) =>
                props.setDocuments({
                  ...props.documents,
                  identity_card_back: file,
                })
              }
              id="identity-card_back"
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-3">
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            square
            placeholder="Enter Surname"
            label="Surname"
            required
            {...input({ name: "surname" })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            square
            placeholder="Enter Given Name"
            label="Given Name"
            required
            {...input({ name: "given_name" })}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            size="small"
            className="mt-0"
            variant="outlined"
            square
            placeholder="Enter Date of Birth"
            label="Date of Birth"
            type="date"
            required
            {...input({ name: "date_of_birth" })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            square
            placeholder="Enter nationality"
            label="Nationality"
            required
            {...input({ name: "nationality" })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            square
            placeholder="Entre Tribe"
            label="Tribe"
            required
            {...input({ name: "tribe" })}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label>Select your gender</label>
          <select className={classes.select} {...input({ name: "gender" })}>
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label>Select your Religion</label>
          <select className={classes.select} {...input({ name: "religion" })}>
            <option value="">choose your religion</option>
            <option value="moslem">Moslem</option>
            <option value="protestant">Protestant</option>
            <option value="catholic">Catholic</option>
            <option value="anglican">Anglican</option>
            <option value="adventist">Adventist</option>
            <option value="born_again">Born Again</option>
            <option value="none">None</option>
            <option value="">Other</option>
          </select>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label>Marital Status</label>
          <select
            className={classes.select}
            {...input({ name: "marital_status" })}
          >
            <option value="">choose marital status</option>
            <option value="single">Single</option>
            <option value="married">Married</option>
            <option value="divorced">Divorced</option>
          </select>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <label>Id Card type</label>
          <select
            className={classes.select}
            {...input({ name: "id_card_type" })}
          >
            <option selected value="">
              choose the type of your card
            </option>
            <option value="national_id">National Identity Card</option>
            <option value="passport">Passport</option>
            <option value="school">School Id</option>
            <option value="village">Village Id</option>
            <option value="work">Work Id</option>
            <option value="other">Other</option>
          </select>
        </Grid>
        <Grid item xs={12} md={6} lg={4} style={{ paddingTop: "40px" }}>
          <TextField
            {...input({ name: "id_card_number" })}
            fullWidth
            size="small"
            variant="outlined"
            square
            placeholder="Id Card Number"
            label="Id Card Number"
            required
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function ContactInformation(props) {
  const { input } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Divider className="my-3 mb-4" />
        <Typography variant="h6" color="primary">
          Contact Details
        </Typography>
        <Typography variant="body2" className="mt-2">
          Enter your contact details that will be used to contact you.
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Primary Phone number"
          label="Primary Phone number"
          required
          {...input({ name: "primary_phone_number" })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Other Phone Number"
          label="Other Phone Number"
          required
          {...input({ name: "secondary_phone_number" })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          square
          type="email"
          placeholder="Email Address"
          label="Email Address"
          required
          {...input({ name: "email" })}
        />
      </Grid>
    </Grid>
  );
}

function AddressInformation(props) {
  const { input } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Divider className="my-3 mb-4" />
        <Typography variant="h6" color="primary">
          Address Details
        </Typography>
        <Typography variant="body2" className="mt-2">
          Enter the address details, of your current locations.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Enter Country of Residence"
          label="Country of residence"
          required
          {...input({ name: "country_of_residence" })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Enter City"
          label="City"
          required
          {...input({ name: "city" })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Enter Village"
          label="Village"
          required
          {...input({ name: "village" })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Physical Address"
          label="Physical Address"
          required
          {...input({ name: "physical_address" })}
        />
      </Grid>
    </Grid>
  );
}

function NextOfKinInformation(props) {
  const { input } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Divider className="my-3 mb-4" />
        <Typography variant="h6" color="primary">
          Next of Kin Details
        </Typography>
        <Typography variant="body2" className="mt-2">
          Next of kin will be able of to clain your money incase you are dead.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Enter Surname"
          label="Surname"
          required
          {...input({ name: "nxt_of_kin_surname" })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Enter other names"
          label="Other names"
          required
          {...input({ name: "nxt_of_kin_other_names" })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Primary Phone number"
          label="Primary Phone number"
          required
          {...input({ name: "nxt_of_kin_primary_phone_number" })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Enter Secondary Phone number"
          label="Secondary Phone number"
          required
          {...input({ name: "nxt_of_kin_secondary_phone_number" })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Email Address"
          label="Email Address"
          required
          {...input({ name: "nxt_of_kin_email_address" })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Relationship"
          label="Relationship"
          {...input({ name: "nxt_of_kin_relationship" })}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Address"
          label="Address"
          required
          {...input({ name: "nxt_of_kin_address" })}
        />
      </Grid>
    </Grid>
  );
}

function JobInformation(props) {
  const { input } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Divider className="my-3" />
        <Typography variant="h6" color="primary">
          Job Information
        </Typography>
        <Typography variant="body2"></Typography>
      </Grid>
      <Grid item xs={12} md={6} className="mt-2">
        <label>Employment Status</label>
        <select
          className={classes.select}
          {...input({ name: "employment_status" })}
        >
          <option value="">Select Employment status</option>

          <option value="employed">Employed</option>
          <option value="self_employed">Self Employed</option>
          <option value="student">Student</option>
          <option value="unemployed">Unemployed</option>
        </select>
      </Grid>
      <Grid item xs={12} md={6} className="pt-5">
        <TextField
          {...input({ name: "occupation" })}
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Enter Occupation"
          label="Occupation"
          required
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          {...input({ name: "business_name" })}
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Business / Work / Company Name"
          label="Business / Work / Company Name "
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          {...input({ name: "job_title" })}
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Job Title"
          label="Job Title"
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          {...input({ name: "work_address" })}
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Address"
          label="Address"
          required
        />
      </Grid>
    </Grid>
  );
}

/**
 * Account Information
 */
function AccountInformation(props) {
  const classes = useStyles();
  const { input, setPayload } = props;
  const [autoAccountNo, setAutoAccountNo] = useState(true);
  const [earlyEntryDate, setEarlyEntryDate] = useState(false);
  const onChange = (what) => () => {
    if (what == "EARLY_ENTRY_DATE") {
      setEarlyEntryDate(!earlyEntryDate);
      setPayload({
        account_opened_on: null,
        early_entry_date: !earlyEntryDate,
      });
    }

    if (what == "AUTO_ACCOUNT") {
      setAutoAccountNo(!autoAccountNo);
      setPayload({ account_number: null, auto_account_no: !autoAccountNo });
    }
  };
  return (
    <Grid container spacing={2} className="mb-3">
      <Grid item xs={12}>
        <Divider className="my-3" />
        <Typography variant="h6" color="primary">
          Account Information
        </Typography>
        <Typography variant="body2">
          The details of the type of account that is going to be opened.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} className="mt-2">
        <label>Account Type</label>

        <select
          className={classes.select}
          {...input({ name: "account_type" })}
          style={{ marginTop: "5px" }}
        >
          <option value="">Choose account type</option>
          <option value="current">Current</option>
          <option value="fixed">Fixed</option>
          <option value="saving">Savings</option>
          <option value="student">Student</option>
        </select>
      </Grid>
      <Grid item xs={12} md={6} className="pt-5">
        <TextField
          {...input({ name: "estimated_monthly_savings" })}
          fullWidth
          size="small"
          variant="outlined"
          square
          placeholder="Estimated Savings"
          label="Estimated Savings"
          required
          type="text"
        />
      </Grid>
      <Grid item xs={12} md={12} className="pt-1">
        <Typography>
          <b>Account Number</b>
        </Typography>
        <div className="mb-2">
          <small>
            Would you like us to create an auto generated account number
          </small>
          <Switch
            onChange={onChange("AUTO_ACCOUNT")}
            checked={autoAccountNo}
            size="small"
          />
        </div>
        {autoAccountNo == true ? (
          ""
        ) : (
          <TextField
            {...input({ name: "account_number" })}
            fullWidth
            size="small"
            variant="outlined"
            square
            placeholder="Account Number"
            label="Account Number"
            required
            type="text"
          />
        )}
      </Grid>
      <Grid item xs={12} md={12} className="pt-1">
        <Typography>
          <b>Account Opening Date</b>
        </Typography>
        <div className="mb-2">
          <small>Record as early entry date</small>
          <Switch
            onChange={onChange("EARLY_ENTRY_DATE")}
            checked={earlyEntryDate}
            size="small"
          />
        </div>
        {earlyEntryDate == false ? (
          ""
        ) : (
          <TextField
            {...input({ name: "account_opened_on", type: "date" })}
            fullWidth
            size="small"
            variant="outlined"
            type="date"
            square
            placeholder="Account opened On"
            label="Account opened on"
            required
          />
        )}
      </Grid>
    </Grid>
  );
}

/**
 * Show Customer Account
 */
function AccountCreatedCard(props) {
  const classes = useStyles();
  let customer = props.account;
  return (
    <Grid container className={classes.accountCreatedRoot}>
      <Grid item xs={12} md={7}>
        <Paper className="p-3">
          <div className="d-flex justify-content-between my-1">
            <Typography variant="h6">Account Created</Typography>
            <div>
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                endIcon={<Print />}
                onClick={printAccountInfo}
              >
                Print
              </Button>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item>
              <div>
                <Avatar
                  variant="square"
                  src={props.documents.passport_photo}
                  className={classes.profileId}
                />
              </div>
            </Grid>
            <Grid item>
              <div>
                <Avatar
                  variant="square"
                  src={props.documents.identity_card_front}
                  className={classes.idPhoto}
                />
              </div>
            </Grid>
            <Grid item>
              <div>
                <Avatar
                  variant="square"
                  src={props.documents.identity_card_back}
                  className={classes.idPhoto}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Account Type:</b>
                </Typography>
                <Typography>{customer.account_type}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Account Number:</b>
                </Typography>
                <Typography>{customer.account_number}</Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Account Opened On:</b>
                </Typography>
                <Typography>{customer.account_opened_on}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Estimated Monthly Saving:</b>
                </Typography>
                <Typography>{customer.estimated_monthly_savings}</Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className="my-2">
                Personal Information
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Surname:</b>
                </Typography>
                <Typography>{customer.surname}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Given Name:</b>
                </Typography>
                <Typography>{customer.given_name}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Date of birth:</b>
                </Typography>
                <Typography>{customer.date_of_birth}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Nationality:</b>
                </Typography>
                <Typography>{customer.nationality}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Tribe:</b>
                </Typography>
                <Typography>{customer.tribe}</Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Gender:</b>
                </Typography>
                <Typography>{customer.gender}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Religion:</b>
                </Typography>
                <Typography>{customer.religion}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Marital Status:</b>
                </Typography>
                <Typography>{customer.marital_status}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Id Card Type:</b>
                </Typography>
                <Typography>{customer.id_card_type}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Id Card Number:</b>
                </Typography>
                <Typography>{customer.id_card_number}</Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className="my-2">
                Address Details
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Country of Residence:</b>
                </Typography>
                <Typography>{customer.country_of_residence}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>City:</b>
                </Typography>
                <Typography>{customer.city}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Village:</b>
                </Typography>
                <Typography>{customer.village}</Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className="my-2">
                Contact details
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Phone Number:</b>
                </Typography>
                <Typography>{customer.primary_phone_number}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Other Phone Number:</b>
                </Typography>
                <Typography>{customer.secondary_phone_number}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Email:</b>
                </Typography>
                <Typography>{customer.email}</Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className="my-2">
                Employment details / Job Information
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Employment Status:</b>
                </Typography>
                <Typography>{customer.employment_status}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Business or Employer Company:</b>
                </Typography>
                <Typography>{customer.business_name}</Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Job Title:</b>
                </Typography>
                <Typography>{customer.job_title}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Work Address:</b>
                </Typography>
                <Typography>{customer.work_address}</Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className="my-2">
                Next of Kin Details
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Next of Kin Surname:</b>
                </Typography>
                <Typography>{customer.nxt_of_kin_surname}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Other names:</b>
                </Typography>
                <Typography>{customer.nxt_of_kin_other_names}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Phone Number:</b>
                </Typography>
                <Typography>
                  {customer.nxt_of_kin_primary_phone_number}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Other Phone Number</b>
                </Typography>
                <Typography>
                  {customer.nxt_of_kin_secondary_phone_number}
                </Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Relationship</b>
                </Typography>
                <Typography>{customer.nxt_of_kin_relationship}</Typography>
              </div>
              <div className="d-flex  align-items-center">
                <Typography className="me-3">
                  <b>Address</b>
                </Typography>
                <Typography>{customer.nxt_of_kin_address}</Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
