import { base_url } from "../../api/apiEndPoints";
import { DateTime, Settings } from "luxon";

Settings.defaultZone = "Africa/Kampala";

export const makeTitle = (title) => {
  title = title.split("-");
  var word;
  var _final = [];
  for (var key in title) {
    word = title[key].split("");
    word[0] = word[0].toUpperCase();
    _final.push(word.join(""));
  }

  return _final.join(" ");
};

export const makeUrl = (url) => {
  url = url.toLowerCase();
  url = url.split(" ");
  return url.join("-");
};

export const lazyImageUrl = (url) => ({
  // small: CONFIG.IMG_DIR + "/small/" + url,
  // large: CONFIG.IMG_DIR + "/large/" + url,
});

export function showDate(date) {
  if (date == null || date == "null") {
    return "N/a";
  }

  let n_date = new Date(date);
  let today = new Date(Date.now());
  let year = n_date.getFullYear();
  let month = n_date.getMonth();
  let monthDay = n_date.getDate();

  // let n_date = new DateTime(n_date);
  // let year = n_date.year;
  // let month = n_date.month;
  // let monthDay = n_date.day;

  // if (
  //   today.getDate() == monthDay &&
  //   today.getFullYear() === year &&
  //   month === today.getMonth()
  // ) {
  //   return <span style={{ color: deepOrange[500] }}>Today</span>;
  // }
  // let yesterday = Number(monthDay) + 2;
  // if (
  //   today.getDate() === yesterday &&
  //   today.getFullYear() === year &&
  //   month === today.getMonth()
  // ) {
  //   return <span style={{ color: green[700] }}>Yesterday</span>;
  // }
  return (
    monthDay +
    (monthDay == "1"
      ? "st"
      : monthDay == "2"
      ? "nd"
      : monthDay == "3"
      ? "rd"
      : monthDay == "22"
      ? "nd"
      : monthDay == "23"
      ? "rd"
      : monthDay == "31"
      ? "st"
      : "th") +
    " /" +
    [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ][Number(month)] +
    " /" +
    year
  );
}

export function renderDocumentLoanUrl(path) {
  return base_url(path, "");
}

export const getProductImageUrl = (productId, size = "large") => {
  return base_url(
    "/market-place/products/images/show/" + productId + "?size=" + size,
    "/patient"
  );
};

export const getDeviceImageUrl = (productId, size = "large") => {
  return base_url(
    "/market-place/devices/images/show/" + productId + "?size=" + size,
    "/patient"
  );
};
