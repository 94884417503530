import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Add, Close, MoreHoriz } from "@material-ui/icons";
import React, { useState } from "react";
import { Divider } from "rsuite";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_CREATE_EXPENSE_CATEGORY,
  BNK_API_GET_EXPENSE_CATEGORIES,
  BNK_API_GET_TOTAL_CATEGORY_EXPENSE,
} from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";
import { SelectBranchMenu } from "../Transactions/TransactionsTable";
import { formatNumber } from "accounting-js";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "10px",
    minHeight: "200px",
    boxShadow: theme.boxShadows[2],
    padding: "10px",
  },
}));

export default function ExpenseCategories(props) {
  const classes = useStyles();
  const { apiQueryState } = props;

  return (
    <Paper className={classes.root}>
      <ApiRequest
        query={apiQueryState}
        addOnThread={JSON.stringify(apiQueryState)}
        thread={BNK_API_GET_EXPENSE_CATEGORIES}
        autoload
      >
        {({ loading, res, error, ErrorView, RefreshButton }) => {
          if (loading) {
            return <CircularProgress />;
          }

          if (error) {
            return <ErrorView />;
          }

          let categories = [];
          if (typeof res == "object") {
            if (typeof res.data == "object") {
              if (Array.isArray(res.data.categories)) {
                categories = res.data.categories;
              }
            }
          }

          if (categories.length === 0) {
            return (
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <Typography variant="h6">Expense Categories</Typography>
                  <AddExpenseCategory />
                </div>
                <div align="center">
                  <Typography>No expense categories added</Typography>
                  <RefreshButton variant="icon" />
                </div>
              </div>
            );
          }

          return (
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <Typography variant="h6">Expense Categories</Typography>
                <div>
                  <RefreshButton variant="icon" />
                  <AddExpenseCategory />
                </div>
              </div>
              <List dense>
                {categories.slice(0, 5).map((cat, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText secondary={cat.description}>
                        {cat.name}
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <Chip
                          label={
                            <ApiRequest
                              autoload
                              addOnThread={
                                JSON.stringify(apiQueryState) + cat.id
                              }
                              thread={BNK_API_GET_TOTAL_CATEGORY_EXPENSE}
                              apiQueryState={apiQueryState}
                              query={{
                                ...apiQueryState,
                                category_id: cat.id,
                              }}
                            >
                              {({ res, loading, error, RefreshButton }) => {
                                if (loading) {
                                  return <CircularProgress size="30px" />;
                                }
                                let total_expenses = 0;
                                if (typeof res == "object") {
                                  if (typeof res.data === "object") {
                                    total_expenses = res.data.total_expenses;
                                  }
                                }
                                return (
                                  <span>
                                    Ugx: {formatNumber(total_expenses)}
                                  </span>
                                );
                              }}
                            </ApiRequest>
                          }
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
                {categories.length > 4 ? (
                  <ShowAllExpenseCategories
                    {...props}
                    categories={categories}
                  />
                ) : (
                  ""
                )}
              </List>
            </div>
          );
        }}
      </ApiRequest>
    </Paper>
  );
}

function AddExpenseCategory(props) {
  const classes = useStyles();
  const [dialogState, setDialogState] = useState(false);
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
  };

  return (
    <React.Fragment>
      <IconButton onClick={onDialogStateChange}>
        <Add />
      </IconButton>
      <Dialog open={dialogState} fullWidth maxWidth="sm">
        <DialogTitle>
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Typography variant="h6">Expense category</Typography>
            <IconButton onClick={onDialogStateChange}>
              <Close />
            </IconButton>
          </div>
          <Divider className="my-0" />
        </DialogTitle>
        <DialogContent>
          <ApiRequest
            reloadOnSuccess={[BNK_API_GET_EXPENSE_CATEGORIES]}
            thread={BNK_API_CREATE_EXPENSE_CATEGORY}
            callbacks={{
              success: (res) => {
                if (typeof res === "object") {
                  if (typeof res.data === "object") {
                    if (res.data.created === true) {
                      onDialogStateChange();
                    }
                  }
                }
              },
            }}
          >
            {({ SubmitButton, setPayload, input }) => {
              return (
                <div>
                  <KijjeInput
                    {...input({ name: "name" })}
                    variant="outlined"
                    square
                    label="Category Name"
                  />
                  <SelectBranchMenu input={input} isSelect={true} />
                  <KijjeInput
                    {...input({ name: "description" })}
                    variant="outlined"
                    square
                    label="Category Description"
                    multiline
                  />
                  <SubmitButton
                    className="mt-2"
                    variant="contained"
                    size="small"
                    color="primary"
                  >
                    Add Category
                  </SubmitButton>
                </div>
              );
            }}
          </ApiRequest>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

function ShowAllExpenseCategories(props) {
  const [dialogState, setDialogState] = useState(false);
  const { apiQueryState, setApiQueryState } = props;
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
  };

  return (
    <React.Fragment>
      <Button fullWidth onClick={onDialogStateChange} endIcon={<MoreHoriz />}>
        Show All
      </Button>
      <Dialog open={dialogState} fullWidth maxWidth="sm">
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <Typography variant="outlined">All Categories</Typography>
            <IconButton onClick={onDialogStateChange}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <List dense>
          {props.categories.map((cat, index) => {
            return (
              <ListItem key={index}>
                <ListItemText secondary={cat.description}>
                  {cat.name}
                </ListItemText>
                <ListItemSecondaryAction>
                  <Chip
                    label={
                      <ApiRequest
                        autoload
                        addOnThread={JSON.stringify(apiQueryState) + cat.id}
                        thread={BNK_API_GET_TOTAL_CATEGORY_EXPENSE}
                        apiQueryState={apiQueryState}
                        query={{
                          ...apiQueryState,
                          category_id: cat.id,
                        }}
                      >
                        {({ res, loading, error, RefreshButton }) => {
                          if (loading) {
                            return <CircularProgress size="30px" />;
                          }
                          let total_expenses = 0;
                          if (typeof res == "object") {
                            if (typeof res.data === "object") {
                              total_expenses = res.data.total_expenses;
                            }
                          }
                          return (
                            <span>Ugx: {formatNumber(total_expenses)}</span>
                          );
                        }}
                      </ApiRequest>
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Dialog>
    </React.Fragment>
  );
}
