import { Chip, makeStyles } from "@material-ui/core";
import { blueGrey, grey } from "@material-ui/core/colors";
import { Check } from "@material-ui/icons";
import { blue } from "@mui/material/colors";
import { formatNumber } from "accounting-js";
import React from "react";
import { showDate } from "../../../global/hooks";
import { tableStyles } from "../Transactions/TransactionsTable";

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: blueGrey[500],
  },
  paperRoot: {
    background: grey[100],
  },
  paper: {
    borderRadius: "5px",
    padding: "10px",
    margin: "2px 0px",
    boxShadow: "none",
  },
  listItem: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    margin: "5px 0px",
    boxShadow: theme.boxShadows[3],
  },
  idCardPhoto: {
    width: "300px",
    height: "190px",
    borderRadius: "10px",
    border: "dotted 1px " + blue[200],
    outline: "none",
    objectFit: "contain",
  },
  listItemContainer: {
    border: "solid 1px " + blue[300],
    borderRadius: "5px",
  },
  collateralCollapse: {
    backgroundColor: grey[100],
    margin: "-10px",
  },
  avatar: {
    "& .rs-avatar-image": {
      width: "100%",
      height: "100%",
      borderRadius: "5px",
    },
  },
}));

export default function LoanInstallmentRender(props) {
  const tableClasses = tableStyles();
  return (
    <div className="mb-4">
      <table className={tableClasses.table}>
        <thead className={tableClasses.tableHeading}>
          <tr>
            <th style={{ width: "30px" }}>No.</th>
            <th>Date</th>
            <th>Installment</th>
            <th>Total</th>
            <th>Balance</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody className={tableClasses.tbody}>
          {props.installments.map((installment, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{showDate(installment.payment_on)}</td>
                <td>
                  {formatNumber(installment.amount_per_installment)}
                  <small>Ugx</small>
                </td>
                <td>
                  {formatNumber(installment.total_amount_paid)}
                  <small>Ugx</small>
                </td>
                <td>
                  {formatNumber(installment.balance)}
                  <small>Ugx</small>
                </td>
                <td>
                  <Chip
                    {...statusStyles(installment)}
                    label={
                      installment.installment_paid == true
                        ? "CLEARED"
                        : installment.amount_paid > 0
                        ? "Paid-" + formatNumber(installment.amount_paid)
                        : "PENDING"
                    }
                    size="small"
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function statusStyles(installment) {
  if (installment.installment_paid == true) {
    return {
      color: "primary",
      deleteIcon: <Check />,
      onDelete: () => {},
      // style: {
      //   backgroundColor: green[500] + "!important"
      // }
    };
  }

  if (installment.amount_paid > 0) {
    return {
      color: "secondary",
    };
  }
}
