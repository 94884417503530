import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close, Delete } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import ApiRequest from "../../../api/ApiRequest";
import { BNK_API_DELETE_LOAN } from "../../../api/apiThreads";

export function DeleteLoanButton(props) {
  const [dialogState, setDialogState] = useState(false);
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
  };
  return (
    <React.Fragment>
      <IconButton
        onClick={onDialogStateChange}
        fullWidth
        className="justify-content-between"
      >
        <Delete />
      </IconButton>
      <Dialog open={dialogState} fullWidth maxWidth="xs">
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <Typography>Delete Loan Application</Typography>
            <IconButton onClick={onDialogStateChange}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="w-100" align="center">
            <Alert severity="warning">
              <Typography>
                <small>
                  Deleting the loan will mean deleting all its associated
                  transactions, amortization and documents. This process will
                  change the documents and history of your transactions.
                </small>
              </Typography>
            </Alert>
            <ApiRequest
              thread={BNK_API_DELETE_LOAN}
              reloadOnSuccess={[props.reloadThread]}
              payload={{
                loan_id: props.id,
              }}
              callbacks={{
                success: (res, xthis) => {
                  if (typeof res === "object") {
                    if (typeof res.data === "object") {
                      if (res.data.deleted) {
                        onDialogStateChange();
                      }
                    }
                  }
                },
              }}
            >
              {({ SubmitButton }) => {
                return (
                  <SubmitButton
                    className="mt-3"
                    variant="contained"
                    size="small"
                    color="secondary"
                    endIcon={<Delete />}
                  >
                    Delete
                  </SubmitButton>
                );
              }}
            </ApiRequest>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
