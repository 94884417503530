import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  deepOrange,
  green,
  grey,
  orange,
  pink,
  purple,
  teal,
} from "@material-ui/core/colors";
import { Add, ArrowForward, Refresh } from "@material-ui/icons";
import accounting from "accounting-js";
import clsx from "clsx";
import React from "react";
import ApiRequest from "../../../api/ApiRequest";
import { BNK_API_GET_TRANSACTION_HISTORY } from "../../../api/apiThreads";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "300px",
  },
  iconButton: {
    width: "30px",
    height: "30px",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: "1rem",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  page: {
    fontSize: "0.9rem",
    fontWeight: 400,
  },
  title: {
    fontWeight: 500,
  },
  listItemDate: {
    fontWeight: 500,
    width: "150px",
    textAlign: "left",
    fontSize: "0.8rem",
  },
  listItemTransNo: {
    textAlign: "left",
    width: "100px",
    fontSize: "0.8rem",
  },
  listItemEmp: {
    width: "150px",
    textAlign: "left",
    fontSize: "0.8rem",
  },
  listItemType: {
    width: "150px",
    textAlign: "left",
    fontSize: "0.8rem",
  },
  listItemAmount: {
    width: "150px",
    textAlign: "right",
    fontSize: "0.8rem",
    paddingRight: "10px",
  },
  icon: {
    fontSize: "0.8rem",
  },
  amount: {
    fontWeight: 500,
  },
  /**
   * Transaction type icon
   */
  dotWrapper: {
    width: "20px",
    height: "20px",
    background: purple[50],
  },
  dot: {
    background: purple[400],
    borderRadius: "100%",
    width: "10px",
    height: "10px",
  },
}));
export default function TransactionHistory(props) {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <ApiRequest
          autoload
          thread={BNK_API_GET_TRANSACTION_HISTORY}
          query={{
            show: "date|trans_no|type|amount|emp|deposit|withdraw",
            limit: 50,
          }}
        >
          {({ loading, error, ErrorView, res, refresh, refreshing }) => {
            // error
            if (error === true) {
              return (
                <div className="w-100 my-4" align="center">
                  <ErrorView />
                </div>
              );
            }

            if (loading == true) {
              return (
                <div className="d-flex justify-content-center align-items-center my-5 py-5">
                  <CircularProgress size="50px" />
                </div>
              );
            }
            let transactions = [];
            if (typeof res === "object") {
              if (typeof res.data == "object") {
                if (Array.isArray(res.data.transactions)) {
                  transactions = res.data.transactions;
                }
              }
            }

            return (
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <Typography className={classes.title}>
                    Transactions History
                  </Typography>
                  <div className="d-flex">
                    {/* <Typography className={clsx(classes.page, "me-2")}>
              Today
            </Typography> */}
                    <IconButton
                      onClick={refresh}
                      className={clsx(classes.iconButton, "me-2")}
                      {...(refreshing === true ? { disabled: true } : {})}
                    >
                      {refreshing === true ? (
                        <div>
                          <CircularProgress size="18px" color="inherit" />
                        </div>
                      ) : (
                        <Refresh />
                      )}
                    </IconButton>
                    <IconButton className={classes.iconButton}>
                      <ArrowForward />
                    </IconButton>
                  </div>
                </div>
                <List dense disablePadding className="p-0, mt-3">
                  <ListItem className="p-0 pl-0">
                    <ListItemText>
                      <Typography className={classes.listItemDate}>
                        Date
                      </Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography
                        className={clsx(classes.listItemTransNo, classes.title)}
                      >
                        Trans No.
                      </Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography
                        className={clsx(classes.listItemEmp, classes.title)}
                      >
                        Employee
                      </Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography
                        className={clsx(classes.listItemType, classes.title)}
                      >
                        Type
                      </Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography
                        className={clsx(classes.listItemAmount, classes.title)}
                      >
                        Amount
                      </Typography>
                    </ListItemText>
                    {/* <ListItemSecondaryAction>
              <IconButton>
                <KeyboardArrowDown />
              </IconButton>
            </ListItemSecondaryAction> */}
                  </ListItem>
                  <Divider />

                  {transactions.map((transaction, index) => {
                    return (
                      <React.Fragment>
                        <ListItem className="p-0 pl-0">
                          <ListItemText>
                            <Typography className={classes.listItemDate}>
                              {showTransactionDate(transaction.created_at)}
                            </Typography>
                          </ListItemText>
                          <ListItemText>
                            <Typography className={classes.listItemTransNo}>
                              TS-{transaction.id}
                            </Typography>
                          </ListItemText>
                          <ListItemText>
                            <Typography className={classes.listItemEmp}>
                              Employee
                            </Typography>
                          </ListItemText>
                          <ListItemText>
                            <Typography className={classes.listItemType}>
                              <ReadTransactionType type={transaction.type} />
                            </Typography>
                          </ListItemText>
                          <ListItemText>
                            <div
                              align="right"
                              className={classes.listItemAmount}
                            >
                              <ShowTransactionAmount {...transaction} />
                            </div>
                          </ListItemText>
                        </ListItem>

                        <Divider className="my-1" />
                      </React.Fragment>
                    );
                  })}
                  <div className="w-100 my-2" align="center">
                    <Button color="primary" endIcon={<ArrowForward />}>
                      Load More
                    </Button>
                  </div>
                </List>
              </div>
            );
          }}
        </ApiRequest>
      </Grid>
    </Grid>
  );
}

export function showTransactionDate(date) {
  let n_date = new Date(date);
  let today = new Date(Date.now());
  let year = n_date.getFullYear();
  let month = n_date.getMonth();
  let monthDay = n_date.getDate();

  if (
    today.getDate() == monthDay &&
    today.getFullYear() === year &&
    month === today.getMonth()
  ) {
    return <span style={{ color: deepOrange[500] }}>Today</span>;
  }
  let yesterday = Number(monthDay) + 1;
  if (
    today.getDate() === yesterday &&
    today.getFullYear() === year &&
    month === today.getMonth()
  ) {
    return <span style={{ color: green[700] }}>Yesterday</span>;
  }

  return (
    monthDay +
    " /" +
    [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ][month] +
    " /" +
    year
  );
}

export function ReadTransactionType({ type }) {
  const classes = useStyles();
  switch (type) {
    case "withdraw":
      return (
        <div className="d-flex align-items-center">
          <div
            className={clsx(
              classes.dotWrapper,
              "d-flex justify-content-center align-items-center"
            )}
          >
            <div className={classes.dot}></div>
          </div>
          <div className="ms-2" style={{ color: purple[400] }}>
            {type}
          </div>
        </div>
      );

    case "deposit":
      return (
        <div className="d-flex align-items-center">
          <div
            className={clsx(
              classes.dotWrapper,
              "d-flex justify-content-center align-items-center"
            )}
            style={{ backgroundColor: teal[50] }}
          >
            <div
              className={classes.dot}
              style={{ backgroundColor: teal[400] }}
            ></div>
          </div>
          <div className="ms-2" style={{ color: teal[400] }}>
            {type}
          </div>
        </div>
      );
    default:
      break;
  }
  return (
    <div className="d-flex align-items-center">
      <div
        className={clsx(
          classes.dotWrapper,
          "d-flex justify-content-center align-items-center"
        )}
      >
        <div className={classes.dot}></div>
      </div>
      <div className="ms-2">{type}</div>
    </div>
  );
}

export function ShowTransactionAmount({ debit, credit, type }) {
  const classes = useStyles();
  switch (type) {
    case "WITHDRAW":
      return (
        <div className={classes.amount} style={{ color: purple[700] }}>
          <span className={classes.icon}>-</span>
          <span>{accounting.formatNumber(debit, 2)}</span>
          <span
            style={{ color: grey[500], fontSize: "0.6rem" }}
            className="ms-1"
          >
            UGX
          </span>
        </div>
      );

    case "DEPOSIT":
      return (
        <div className={classes.amount} style={{ color: teal[700] }}>
          <span className={classes.icon}>
            <Add className={classes.icon} />
          </span>
          <span>{accounting.formatNumber(credit, 2)}</span>
          <span
            style={{ color: grey[500], fontSize: "0.6rem" }}
            className="ms-1"
          >
            UGX
          </span>
        </div>
      );
    case "DIRECT_LOAN_PAYMENT_DEPOSIT":
      return (
        <div className={classes.amount} style={{ color: orange[700] }}>
          <span className={classes.icon}>
            <Add className={classes.icon} />
          </span>
          <span>{accounting.formatNumber(credit, 2)}</span>
          <span
            style={{ color: grey[500], fontSize: "0.6rem" }}
            className="ms-1"
          >
            UGX
          </span>
        </div>
      );

    case "DIRECT_LOAN_PAYMENT":
      return (
        <div className={classes.amount} style={{ color: pink[700] }}>
          <span className={classes.icon}>-</span>
          <span>{accounting.formatNumber(debit, 2)}</span>
          <span
            style={{ color: grey[500], fontSize: "0.6rem" }}
            className="ms-1"
          >
            UGX
          </span>
        </div>
      );
    case "LOAN_PAYMENT":
      return (
        <div className={classes.amount} style={{ color: pink[700] }}>
          <span className={classes.icon}>-</span>
          <span>{accounting.formatNumber(debit, 2)}</span>
          <span
            style={{ color: grey[500], fontSize: "0.6rem" }}
            className="ms-1"
          >
            UGX
          </span>
        </div>
      );
    default:
      return (
        <div className={classes.amount}>
          <span className={classes.icon}>-</span>
          <span>{accounting.formatNumber(debit, 2)}</span>
          <span
            style={{ color: grey[500], fontSize: "0.6rem" }}
            className="ms-1"
          >
            UGX
          </span>
        </div>
      );
  }
}
