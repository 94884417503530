import {
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { ArrowForward } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ApiRequest from "../../../api/ApiRequest";
import { GET_SETTINGS, UPDATE_SETTINGS } from "../../../api/apiThreads";
import { KijjeInput } from "../../../global/components/inputs/Inputs";

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    border: "solid 1px " + grey[300],
    borderRadius: "5px",
    width: "100%",
    height: "100px",
    color: "#65A8A6",
    "& .bi-image": {
      fontSize: "1.5rem",
      //   color: grey[700],
    },
    "& .small": {
      fontSize: "0.65rem",
      lineHeight: "0.9rem",
    },
  },
  logo: {
    width: "auto",
    maxWidth: "100%",
    height: "auto",
    maxHeight: "70px",
  },
  paper: {
    padding: theme.spacing(3),
    boxShadow: theme.boxShadows[3],
    borderRadius: "5px",
  },
}));
export default function ProfileAndBranding(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <React.Fragment>
        <ApiRequest thread={GET_SETTINGS} autoload={true}>
          {({ loading, error, res, RefreshButton }) => {
            if (loading) {
              return <CircularProgress size="50px" />;
            }

            let settings = null;
            if (typeof res == "object") {
              if (typeof res.data == "object") {
                if (typeof res.data.settings == "object") {
                  settings = res.data.settings;
                }
              }
            }

            if (settings == null) {
              return (
                <div className="w-100 p-5" align="center">
                  <Typography>Failed to get application settings </Typography>
                  <RefreshButton variant="icon" />
                </div>
              );
            }

            return (
              <ApiRequest
                thread={UPDATE_SETTINGS}
                initialPayload={{ ...settings }}
                reloadOnSuccess={[GET_SETTINGS]}
              >
                {({
                  res,
                  input,
                  SubmitButton,
                  payload,
                  FileInput,
                  setPayload,
                }) => {
                  return (
                    <React.Fragment>
                      <Grid container spacing={3} alignItems="flex-start">
                        <Grid item xs={12}>
                          {error === true ? (
                            <Alert severity="error">An error ocurred!!</Alert>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography>
                            <b>Company Name</b>
                          </Typography>
                          <Typography>
                            <small>
                              Enter the full name of the business or company,
                              this name will appear in the Mobile application
                              and with also be sent out to in all the messages
                              and notifications from the application
                            </small>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <KijjeInput
                            variant="filled"
                            placeholder="Add Company name"
                            {...input({
                              name: "companyName",
                            })}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} alignItems="flex-start">
                        <Grid item xs={12}>
                          {error === true ? (
                            <Alert severity="error">An error ocurred!!</Alert>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography>
                            <b>Machine Id</b>
                          </Typography>
                          <Typography>
                            <small>
                              Machine id should be a unique number for the
                              machine which has the online or offline
                              installation of the system. This will be used in
                              the syncronization of the system
                            </small>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <KijjeInput
                            variant="filled"
                            placeholder="Machine Id"
                            {...input({
                              name: "machine_id",
                            })}
                          />
                        </Grid>
                      </Grid>
                      <Divider className="my-3" />
                      <Grid container spacing={3} alignItems="flex-start">
                        <Grid item xs={12} md={6}>
                          <Typography>
                            <b>Company Logo</b>
                          </Typography>
                          <Typography>
                            <small>
                              Company logo is so important, please upload a png
                              file or and svg file that will be used in the
                              application.{" "}
                              <b>
                                <i>The Logo Should not be more than 1MB</i>
                              </b>
                            </small>
                          </Typography>
                        </Grid>
                        <FileInput
                          accept="image/*"
                          name="imageFile"
                          inputClassName="d-none"
                          maxFileSize={4}
                          onContentLoaded={({ url }) => {
                            setPayload({ companyLogo: url });
                          }}
                        >
                          {({ Label, url }) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                md={6}
                                className="d-flex w-100"
                              >
                                <div
                                  className={clsx(
                                    "w-50 p-3",
                                    classes.logoWrapper
                                  )}
                                >
                                  <LazyLoadImage
                                    src={payload.companyLogo}
                                    className={classes.logo}
                                  />
                                </div>
                                <div className="w-50 p-3">
                                  <Label>
                                    <div
                                      className={clsx(
                                        classes.inputWrapper,
                                        "d-flex p-3 align-items-center justify-content-center"
                                      )}
                                    >
                                      <div align="center">
                                        <i className=" bi-image"></i>
                                        <Typography className="small">
                                          Click to change the <b>Logo</b>
                                          <br />
                                          Select a <b>PNG | SVG | JPG</b> file
                                          less than 1MB
                                        </Typography>
                                      </div>
                                    </div>
                                  </Label>
                                </div>
                              </Grid>
                            );
                          }}
                        </FileInput>
                      </Grid>
                      <Divider className="my-3" />

                      <Grid
                        container
                        spacing={3}
                        justify="flex-end"
                        align="right"
                      >
                        <Grid item>
                          <SubmitButton
                            variant="contained"
                            color="secondary"
                            size="small"
                            endIcon={<ArrowForward />}
                          >
                            Update
                          </SubmitButton>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
                }}
              </ApiRequest>
            );
          }}
        </ApiRequest>
      </React.Fragment>
    </Paper>
  );
}
