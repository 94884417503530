// check
/**
 * auth routes
 */
export const LOGIN_ADMIN_THREAD = "LOGIN_ADMIN_THREAD";
export const REQUEST_LOGIN_VERIFICATION_CODE =
  "REQUEST_LOGIN_VERIFICATION_CODE";
export const LOGOUT_ADMIN_THREAD = "LOGOUT_ADMIN_THREAD";
export const SIGN_UP_THREAD = "SIGN_UP_THREAD";
export const CREATE_PROFILE_THREAD = "CREATE_PROFILE_THREAD";
export const GET_ADMIN_USERS = "GET_ADMIN_USERS";
export const GET_ADMIN_USER_DETAILS = "GET_ADMIN_USER_DETAILS";
export const UPDATE_ADMIN_USER_DETAILS = "UPDATE_ADMIN_USER_DETAILS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_SETTINGS = "GET_SETTINGS";
export const IS_LOGGED_IN_THREAD = "IS_LOGGED_IN_THREAD";
export const LOGIN_ADMIN = "LOGIN_ADMIN";
export const LOGOUT_ADMIN = "LOGOUT_ADMIN";
export const REFRESH_AUTH_TOKEN = "REFRESH_AUTH_TOKEN";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";

// USER ACCESS AND PERMISSION
export const BNK_API_GET_BRANCHES_NOT_ACCESSED_BY_USER =
  "BNK_API_GET_BRANCHES_NOT_ACCESSED_BY_USER";
export const BNK_API_GRANT_BRANCH_ACCESS_TO_USER =
  "BNK_API_GRANT_BRANCH_ACCESS_TO_USER";
export const BNK_API_UPDATE_BRANCH_USER_ACCESS =
  "BNK_API_UPDATE_BRANCH_USER_ACCESS";

/**
 * Resources
 */
export const DELETE_IMAGE = "DELETE_IMAGE";
export const GET_ALL_IMAGES = "GET_ALL_IMAGES";

// loan application
export const BNK_API_APPLY_FOR_A_LOAN = "BNK_API_APPLY_FOR_A_LOAN";
export const BNK_API_MAKE_LOAN_PAYMENT = "BNK_API_MAKE_LOAN_PAYMENT";
export const BNK_API_GET_LOAN_BALANCE = "BNK_API_GET_LOAN_BALANCE";

/**
 * sETTINGS -
 */
export const ADD_DOMAIN_TO_DOMAIN_LIST = "ADD_DOMAIN_TO_DOMAIN_LIST";
export const GET_DOMAIN_ACCESS_LIST = "GET_DOMAIN_ACCESS_LIST";

/**
 * REGISTER ADMIN
 */
export const REGISTER_ADMIN_USER = "REGISTER_ADMIN_USER";

/**
 * Settings
 */
export const SETTINGS_GET_SYSTEM_NOTIFICATIONS_MAIL =
  "SETTINGS_GET_SYSTEM_NOTIFICATIONS_MAIL";
export const SETTINGS_UPDATE_SYSTEM_NOTIFICATIONS_MAIL =
  "SETTINGS_UPDATE_SYSTEM_NOTIFICATIONS_MAIL";
export const SETTINGS_NOTIFICATIONS_MAIL_SEND_TEST_MAIL =
  "SETTINGS_NOTIFICATIONS_SETTINGS_SEND_TEST_MAIL";

/**
 * Manage hospitals
 */
export const GET_ALL_HOSPITALS = "GET_ALL_HOSPITALS";
export const ASSIGN_HOSPITAL_TO_PATIENT = "ASSIGN_HOSPITAL_TO_PATIENT";
export const GET_HOSPITAL_STATISTICS = "GET_HOSPITAL_STATISTICS";
export const GET_HOSPITALS_NOT_ASSIGNED_TO_PATIENT =
  "GET_HOSPITALS_NOT_ASSIGNED_TO_PATIENT";
export const GET_HOSPITALS_ASSIGNED_TO_PATIENT =
  "GET_HOSPITALS_ASSIGNED_TO_PATIENT";
export const UN_ASSIGN_HOSPITAL_FROM_PATIENT = "UNASSIGN_HOSPITAL_FROM_PATIENT";

// DOCTOR HOSPITAL ASSIGNMENT
export const GET_HOSPITALS_NOT_ASSIGNED_TO_DOCTOR =
  "GET_HOSPITALS_NOT_ASSIGNED_TO_DOCTOR";
export const GET_HOSPITALS_ASSIGNED_TO_DOCTOR =
  "GET_HOSPITALS_ASSIGNED_TO_DOCTOR";
export const ASSIGN_HOSPITAL_TO_DOCTOR = "ASSIGN_HOSPITALS_TO_DOCTOR";
export const UN_ASSIGN_HOSPITAL_FROM_DOCTOR = "UN_ASSIGN_HOSPITAL_FROM_DOCTOR";

/**
 * Manage Doctors
 */
export const GET_ALL_DOCTORS = "GET_ALL_DOCTORS";
export const SEND_DOCTOR_PASSWORD_RESET_LINK =
  "SEND_DOCTOR_PASSWORD_RESET_LINK";

// SYSTEM USERS
export const REGISTER_ADMIN_USER_THREAD = "REGISTER_ADMIN_USER_THREAD";

export const GET_LOGGED_IN_USER_PROFILE_THREAD =
  "GET_LOGGED_IN_USER_PROFILE_THREAD";

/**
 * Paypal Configurations
 */
export const SETTINGS_GET_PAYPAL_CONFIG = "GET_PAYPAL_CONFIG";
export const SETTINGS_UPDATE_PAYPAL_CONFIG = "UPDATE_PAYPAL_CONFIG";
export const MAKE_TEST_PAYPAL_TRANSACTION = "MAKE_TEST_PAYPAL_TRANSACTION";

/**
 * Market Place
 */
export const GET_ALL_MARKET_PLACE_PRODUCT_CATEGORIES =
  "GET_ALL_MARKET_PLACE_PRODUCT_CATEGORIES";
export const MP_ADD_PRODUCT_CATEGORY = "MP_ADD_PRODUCT_CATEGORY";

/**
 * Banking THREADS
 */
export const BNK_API_GET_MY_BANK = "BNK_API_GET_MY_BANK";
export const BNK_API_CREATE_BANK = "BNK_API_CREATE_BANK";
export const BNK_API_UPDATE_BANK_DETAILS = "BNK_API_UPDATE_BANK_DETAILS";
export const BNK_API_OPEN_BRACH = "BNK_API_OPEN_BRANCH";
export const BNK_API_GET_ALL_BRANCHES = "BNK_API_GET_ALL_BRANCHES";
export const BNK_API_ADD_BRANCH_MANAGER = "BNK_API_ADD_BRANCH_MANAGER";
export const BNK_API_MANAGE_BRANCH = "BNK_API_MANAGE_BRANCH";
export const BNK_API_GET_BRANCH_SETTINGS = "BNK_API_GET_BRANCH_SETTINGS";
export const BNK_API_BRANCH_REGISTER_EMPLOYEE =
  "BNK_API_BRANCH_REGISTER_EMPLOYEE";
export const BNK_API_BRANCH_UPDATE_EMPLOYEE_DETAILS =
  "BNK_API_UPDATE_EMPLOYEE_DETAILS";
export const BNK_API_BRANCH_GET_EMPLOYEES = "BNK_API_BRANCH_GET_EMPLOYEES";
export const BNK_API_BRANCH_GET_EMPLOYEE_DETAILS =
  "BNK_API_BRANCH_GET_EMPLOYEE_DETAILS";
export const BNK_API_UPDATE_BRANCH_DETAILS = "BNK_API_UPDATE_BRANCH-DETAILS";
export const BNK_API_BRANCH_OPEN_ACCOUNT = "BNK_API_BRACH_OPEN_ACCOUNT";
export const BNK_API_FIND_CUSTOMER_ACCOUNT = "BNK_API_FIND_CUSTOMER_ACCOUNT";
export const BNK_API_GET_BRANCH_CUSTOMERS = "BNK_API_GET_BRANCH_CUSTOMERS";
export const BNK_API_MAKE_DEPOSIT = "BNK_API_MAKE_DEPOSIT";
export const BNK_API_MAKE_WITHDRAW = "BNK_API_MAKE_WITHDRAW";
export const BNK_API_GET_TRANSACTION_STATISTICS =
  "BNK_API_GET_TRANSACTION_STATISTICS";

export const BNK_API_GET_DEPOSIT_HISTORY = "BNK_API_GET_DEPOSIT_HISTORY";
export const BNK_API_GET_WITHDRAW_HISTORY = "BNK_API_GET_WITHDRAW_HISTORY";
export const BNK_API_GET_TRANSACTION_HISTORY =
  "BNK_API_GET_TRANSACTION_HISTORY";
export const BNK_API_GET_ALL_CUSTOMERS = "BNK_API_GET_ALL_CUSTOMERS";

// 0PEN ACCOUNT VALIDATIONS
export const BNK_API_OPEN_ACCOUNT_CHOOSE_BRANCH_VALIDATION =
  "BNK_API_OPEN_ACCOUNT_CHOOSE_BRANCH_VALIDATION";
export const BNK_API_GET_CUSTOMER_STATISTICS =
  "BNK_API_GET_CUSTOMER_STATISTICS";

export const BNK_API_SYNCHRONIZE_DATA_TO_ONLINE_SERVER =
  "BNK_API_SYNCHRONIZE_DATA_TO_ONLINE_SERVER";

// loan types
export const BNK_API_GET_ALL_LOAN_TYPES = "BNK_API_GET_ALL_LOAN_TYPES";
export const BNK_API_ADD_LOAN_TYPE = "BNK_API_ADD_LOAN_TYPE";
export const BNK_API_DELETE_LOAN_TYPE = "BNK_API_DELETE_LOAN_TYPE";
export const BNK_API_SHOW_LOAN_TYPE = "BNK_API_SHOW_LOAN_TYPE";
export const BNK_API_UPDATE_LOAN_TYPE = "BNK_API_UPDATE_LOAN_TYPE";

// BANK WEB CUSTOMER USERS
export const BNK_API_GET_ALL_WEB_CUSTOMER_USERS =
  "BNK_API_GET-ALL_WEB_CUSTOMER_USERS";
export const BNK_API_DELETE_WEB_CUSTOMER_USER =
  "BNK_API_DELETE_WEB_CUSTOMER_USER";
export const BNK_API_RESET_WEB_CUSTOMER_USER_PASSWORD =
  "BNK_API_RESET_WEB_CUSTOMER_USER_PASSWORD";

// Loan Application
export const BNK_API_LOAN_APPLICATION_FORM_VALIDATION =
  "BNK_API_LOAN_APPLICATION_FORM_VALIDATION";

// SEARCH customer account.
export const BNK_API_SEARCH_ACCOUNT = "BNK_API_SEARCH_ACCOUNT";
export const BNK_API_GET_ALL_LOANS = "BNK_API_GET_ALL_LOANS";
export const BNK_API_GET_DASHBOARD_STATISTICS =
  "BNK_API_GET_DASHBOARD_STATISTICS";
export const BNK_API_GET_BRANCHES_STATISTICS =
  "BNK_API_GET_BRANCHES_STATISTICS";
export const BNK_API_VALIDATE_LOAN_APPLICATION_FORM =
  "BNK_API_VALIDATE_LOAN_APPLICATION_FORM";

/**
 * GET TEH BNK CUISTOMER ACCOUNT AND ITS DETAILWS
 */
export const BNK_API_GET_CUSTOMER_ACCOUNT_DETAILS =
  "BNK_API_GET_CUSTOMER_ACCOUNT_DETAILS";
export const BNK_API_GET_CUSTOMER_ACCOUNT_SUMMARY =
  "BNK_API_GET_CUSTOMER_ACCOUNT_SUMMARY";
export const BNK_API_GET_CUSTOMER_TRANSACTIONS =
  "BNK_API_GET_CUSTOMER_TRANSACTIONS";
export const BNK_API_GET_CUSTOMER_LOAN_SUMMARY =
  "BNK_API_GET_CUSTOMER_LOAN_SUMMARY";
export const BNK_API_GET_CUSTOMER_LOAN_DETAILS =
  "BNK_API_GET_CUSTOMER_LOAN_DETAILS";
export const BNK_API_UPDATE_CUSTOMER_LOAN_DETAILS =
  "BNK_API_UPDATE_CUSTOMER_LOAN_DETAILS";
export const BNK_API_UPDATE_CUSTOMER_ACCOUNT_DETAILS =
  "BNK_API_UPDATE_CUSTOMER_ACCOUNT_DETAILS";
export const BNK_API_UPDATE_CUSTOMER_ACCOUNT_DETAILS_VALIDATOR =
  "BNK_API_UPDATE_CUSTOMER_ACCOUNT_DETAILS_VALIDATOR";

export const BNK_API_CHANGE_LOAN_APPLICATION_STATUS =
  "BNK_API_CHANGE_LOAN_APPLICATION_STATUS";
export const BNK_API_GET_LOANS_SUMMARY = "BNK_API_GET_LOANS_SUMMARY";
export const BNK_API_SHOW_LOAN = "BNK_API_SHOW_LOAN";
export const BNK_API_GET_LOANS_TO_BE_PAID_TODAY =
  "BNK_API_GET_LOANS_TO_BE_PAID_TODAY";
export const BNK_API_UPDATE_LOAN_APPLICATION_VALIDATOR =
  "BNK_API_UPDATE_LOAN_APPLICATION_VALIDATOR";
export const BNK_API_UPDATE_LOAN_APPLICATION =
  "BNK_API_UPDATE_LOAN_APPLICATION";
export const BNK_API_GET_CUSTOMER_RUNNING_LOANS =
  "BNK_API_GET_CUSTOMER_RUNNING_LOANS";
export const BNK_API_GET_MONTHLY_LOAN_INSTALLMENTS =
  "BNK_API_GET_MONTHLY_LOAN_INSTALLMENTS";
export const BNK_API_GET_DATE_INSTALLMENTS = "BNK_API_GET_DATE_INSTALLMENTS";

/**
 * MANAGE LOAN CHECKING ACCOUNT
 */
export const BNK_API_DEPOSIT_TO_LOAN_CHECKING_ACCOUNT =
  "BNK_API_DEPOSIT_TO_LOAN_CHECKING_ACCOUNT";
export const BNK_API_GET_LOAN_CHECKING_ACCOUNT_TRANSACTIONS =
  "BNK_API_GET_LOAN_CHECKING_ACCOUNT_TRANSACTIONS";
export const BNK_API_GET_LOAN_CHECKING_ACCOUNT_SUMMARY =
  "BNK_API_GET_LOAN_CHECKING_ACCOUNT_SUMMARY";
export const BNK_API_UPLOAD_CUSTOMER_DOCUMENTS =
  "BNK_API_UPLOAD_CUSTOMER_DOCUMENTS";
export const BNK_API_UPDATE_CUSTOMER_DOCUMENTS =
  "BNK_API_UPDATE_CUSTOMER_DOCUMENTS";

// pRINTING RQCIEPTS
export const BNK_API_GET_TRANSACTION_RECEIPT =
  "BNK_API_GET_TRANSACTION_RECEIPT";

// detele the customer account and all thier transactions.
export const BNK_API_DELETE_CUSTOMER_ACCOUNT =
  "BNK_API_DELETE_CUSTOMER_ACCOUNT";
export const BNK_API_GET_RUNNING_CLOSING_OPENING_BALANCE =
  "BNK_API_GET_RUNNING_CLOSING_OPENING_BALANCE";
export const BNK_API_CREATE_EXPENSE_CATEGORY =
  "BNK_API_CREATE_EXPENSE_CATEGORY";
export const BNK_API_GET_EXPENSE_CATEGORIES = "BNK_API_GET_EXPENSE_CATEGORIES";
export const BNK_API_RECORD_EXPENSE = "BNK_API_RECORD_EXPENSE";
export const BNK_API_GET_EXPENSES = "BNK_API_GET_EXPENSES";

// SMS CONFIG
export const BNK_API_GET_SMS_MESSAGES_CONFIG =
  "BNK_API_GET_SMS_MESSAGES_CONFIGS";
export const BNK_API_UPDATE_SMS_MESSAGES_CONFIG =
  "BNK_API_UPDATE_SMS_MESSAGES_CONFIG";
export const BNK_API_SEND_TEST_MESSAGE = "BNK_API_SEND_TEST_MESSAGE";

export const BNK_API_GET_APPROVED_LOAN_FOR_EDITING =
  "BNK_API_GET_APPROVED_LOAN_FOR_EDITING";
export const BNK_API_UPDATE_APPROVED_LOAN = "BNK_API_UPDATE_APPROVED_LOAN";
export const BNK_API_DELETE_LOAN = "BNK_API_DELETE_LOAN";
export const BNK_API_CORRECT_LOAN_ISSUES = "BNK_API_CORRECT_LOAN_ISSUES";
export const BNK_API_GET_LOANS_FOR_CORRECTIONS =
  "BNK_API_GET_LOANS_FOR_CORRECTIONS";
export const BNK_API_USER_HAS_PERMISSIONS = "BNK_API_USER_HAS_PERMISSIONS";
export const BNK_API_ADD_INCOME_CATEGORY = "BNK_API_ADD_INCOME_CATEGORY";
export const BNK_API_GET_INCOME_CATEGORIES = "BNK_API_GET_INCOME_CATEGORIES";
export const BNK_API_GET_TOTAL_CATEGORY_INCOME =
  "BNK_API_GET_TOTAL_CATEGORY_INCOME";
export const BNK_API_GET_TOTAL_CATEGORY_EXPENSE =
  "BNK_API_GET_TOTAL_CATEGORY_EXPENSE";
export const BNK_API_RECORD_INCOME = "BNK_API_RECORD_INCOME";
export const BNK_API_GET_ALL_INCOMES = "BNK_API_GET_ALL_INCOMES";

/**
 * cHECKING ACCOUNT
 */
export const BNK_API_CHECKING_ACCOUNT_RECORD_DEPOSIT =
  "BNK_API_CHECKING_ACCOUNT_RECORD_DEPOSIT";
export const BNK_API_CHECKING_ACCOUNT_RECORD_WITHDRAW =
  "BNK_API_CHECKING_ACCOUNT_RECORD_WITHDRAW";
export const BNK_API_CHECKING_ACCOUNT_RECORD_BNK_CHARGES =
  "BNK_API_CHECKING_ACCOUNT_RECORD_BNK_CHARGES";
export const BNK_API_CHECKING_ACCOUNT_GET_TRANSACTIONS =
  "BNK_API_CHECKING_ACCOUNT_GET_TRANSACTIONS";
// dashboard report
export const BNK_API_GET_CUSTOMER_SAVINGS_REPORT =
  "BNK_API_GET_CUSTOMER_SAVINGS_REPORT";
export const BNK_API_GET_INCOME_REPORTS = "BNK_API_GET_INCOME_REPORTS";
export const BNK_API_GET_EXPENSES_REPORT = "BNK_API_GET_EXPENSES_REPORT";
export const BNK_API_GET_CHECKING_ACCOUNT_REPORT =
  "BNK_API_GET_CHECKING_ACCOUNT_REPORT";
export const BNK_API_GET_RUNNING_BALANCE_REPORT =
  "BNK_API_GET_RUNNING_BALANCE_REPORT";
export const BNK_API_GET_LOANS_REPORT = "BNK_API_GET_LOANS_REPORT";
export const BNK_API_CREATE_TRANSACTION = "BNK_API_CREATE_TRANSACTION";

/**
 * COMPANY ACCOUNTS INCOMES AND EXPENSES
 */
export const BNK_API_GET_INCOMES_AND_EXPENSES_TOTAL =
  "BNK_API_GET_INCOMES_AND_EXPENSES_TOTAL";
export const BNK_API_CLOSE_ACCOUNTS_BUTTON = "BNK_API_CLOSE_ACCOUNTS_BUTTON";

export const TEST_PAGE = "TEST_PAGE";

/**
 * Blog Api
 */
export const BLG_API_CREATE_BLOG = "BLG_API_CREATE_BLOG";
export const BLG_API_GET_BLOG = "BLG_API_GET_BLOG";
export const BLG_API_GET_ALL_BLOGS = "BLG_API_GET_ALL_BLOGS";

export const BLG_API_CREATE_POST = "BLG_API_CREATE_POST";
export const BLG_API_GET_POSTS = "BLG_API_GET_POSTS";
export const BLG_API_SHOW_POST = "BLG_API_SHOW_POST";
export const BLG_API_PUBLISH_POST = "BLG_API_PUBLISH_POST";
export const BLG_API_DELETE_POST = "BLG_API_DELETE_POST";
export const BLG_API_UPDATE_POST = "BLG_API_UPDATE_POST";

export const BLG_API_ADD_AUTHOR = "BLG_API_ADD_AUTHOR";
export const BLG_API_GET_AUTHORS = "BLG_API_GET_AUTHORS";
export const BLG_API_DELETE_AUTHOR = "BLG_API_DELETE_AUTHOR";
export const BLG_API_UPDATE_AUTHOR = "BLG_API_UPDATE_AUTHOR";
export const BLG_API_SHOW_AUTHOR = "BLG_API_SHOW_AUTHOR";

/**
 * Market Place
 */
export const MP_ADD_CATEGORY_GROUP = "MP_ADD_CATEGORY_GROUP";
export const MP_GET_ALL_PRODUCT_CATEGORY_GROUPS =
  "MP_GET_ALL_PRODUCT_CATEGORY_GROUPS";
export const MARKET_PLACE_DELETE_ORDER = "MARKET_PLACE_DELETE_ORDER";
export const MARKET_PLACE_SHIP_ORDER = "MARKET_PLACE_SHIP_ORDER";
export const MARKET_PLACE_ORDER_DELIVERED = "MARKET_PLACE_ORDER_DELIVERED";
export const MP_ADD_PRODUCT_CATEGORY_GROUP = "MP_ADD_PRODUCT_CATEGORY_GROUP";
export const MARKET_PLACE_DELETE_PRODUCT_CATEGORY_GROUP =
  "MARKET_PLACE_DELETE_PRODUCT_CATEGORY_GROUP";
export const MP_GET_CATEGORY_GROUP = "MP_GET_CATEGORY_GROUP";
export const MP_DELETE_CATEGORY_GROUP = "MP_DELETE_CATEGORY_GROUP";
export const MP_UPDATE_CATEGORY_GROUP = "MP_UPDATE_CATEGORY_GROUP";
export const MP_DELETE_PRODUCT_CATEGORY = "MP_DELETE_PRODUCT_CATEGORY";
export const MP_UPDATE_PRODUCT_CATEGORY = "MP_UPDATE_PRODUCT_CATEGORY";
export const MP_GET_PRODUCT_CATEGORY = "MP_GET_PRODUCT_CATEGORY";
export const MP_GET_PRODUCT = "MP_GET_PRODUCT";
export const MP_UPDATE_PRODUCT = "MP_UPDATE_PRODUCT";

export const MP_ADD_BRAND = "MP_ADD_BRAND";
export const MP_GET_ALL_BRANDS = "MP_GET_ALL_BRANDS";
export const MP_UPDATE_BRAND = "MP_UPDATE_BRAND";
export const MP_DELETE_BRAND = "MP_DELETE_BRAND";
export const MP_GET_BRAND = "MP_GET_BRNAD";

export const MP_GET_LANDING_PAGE_CONTENT = "MP_GET_LANDING_PAGE_CONTENT";
export const MP_UPLOAD_LANDING_PAGE_SLIDE = "MP_UPLOAD_LANDING_PAGE_SLIDE";
export const MP_DELETE_LANDING_PAGE_SLIDE = "MP_DELETE_LANDING_PAGE_SLIDE";
export const MP_UPDATE_LANDING_PAGE_SLIDE = "MP_UPDATE_LADING_PAGE_SLIDE";
export const MP_GET_LANDING_PAGE_SLIDE = "MP_GET_LANDING_PAGE_SLIDE";
export const MP_GET_CATEGORY_GROUP_ITEMS = "MP_GET_CATEGORY_GROUP_ITEMS";
export const MP_ADD_CATEGORY_TO_CATEGORY_GROUP_ITEMS =
  "MP_ADD_CATEGORY_TO_CATEGORY_GROUP_ITEMS";
export const MP_GET_CATEGORIES_NOT_ADDED_TO_CATEGORY_GROUP =
  "MP_GET_CATEGORIES_NOT_ADDED_TO_CATEGORY_GROUP";
export const MP_REMOVE_ITEM_FROM_CATEGORY_GROUP_LIST =
  "MP_REMOVE_ITEM_FROM_CATEGORY_GROUP_LIST";

export const MP_ADD_COUNTRY_TO_SHIPPING_LIST =
  "MP_ADD_COUNTRY_TO_SHIPPING_LIST";
export const MP_REMOVE_COUNTRY_FROM_SHIPPING_LIST =
  "MP_REMOVE_COUNTRY_FROM_SHIPPING_LIST";
export const MP_UPDATE_COUNTRY_IN_SHIPPING_LIST =
  "MP_UPDATE_COUNTRY_IN_SHIPPING_LIST";
export const MP_GET_SHIPPING_LIST_COUNTRIES = "MP_GET_SHIPPING_LIST_COUNTRIES";
export const MP_MP_ADD_PRODUCT = "MP_MP_ADD_PRODUCT";
export const GET_ALL_MARKET_PLACE_PRODUCT = "GET_ALL_MARKET_PLACE_PRODUCT";
export const MARKET_PLACE_DELETE_PRODUCT = "MARKET_PLACE_DELETE_PRODUCT";
export const GET_ALL_MARKET_PLACE_ORDERS = "GET_ALL_MARKET_PLACE_ORDERS";
export const MP_GET_ALL_PRODUCTS = "MP_GET_ALL_PRODUCTS";
export const MP_ADD_PRODUCT = "MP_ADD_PRODUCT";
