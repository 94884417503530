import { Divider, Grid, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import BranchesTable from "./BranchesTable";
import OpenBranchButton from "./OpenBranchButton";

export default function Branches(props) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Helmet>
          <title>Branches</title>
        </Helmet>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <Typography variant="h6">Branches</Typography>
          <OpenBranchButton size="small" variant="outlined" endIcon={<Add />} />
        </div>
        <Divider className="my-2" />
      </Grid>
      <Grid item xs={12}>
        <BranchesTable />
      </Grid>
    </Grid>
  );
}
