import clsx from "clsx";
import React from "react";
import settingsStyles from "./settingsStyles";

export default function AdminProfileImage(props) {
  const classes = settingsStyles();
  return (
    <div
      className={clsx(
        classes.profilePictureWrapper,
        "d-flex justify-content-center align-items-center"
      )}
      style={{ backgroundImage: `url(${props.url})` }}
    >
      <div className={classes.profilePictureBorder}></div>
      <div className={classes.profilePictureIcon}>{props.icon}</div>
    </div>
  );
}
