import { CircularProgress } from "@material-ui/core";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ApiRequest from "../../api/ApiRequest";
import { GET_SETTINGS } from "../../api/apiThreads";
import Logo from "./logo.svg";

const Images = {
  test: "/images/profile.png",
  Logo: (props) => (
    <ApiRequest autoload thread={GET_SETTINGS}>
      {({ res, loading, error }) => {
        if (loading) {
          return (
            <div className="w-100" align="center">
              <CircularProgress size="40px" />
            </div>
          );
        }

        let logo = Logo;
        if (typeof res === "object") {
          if (typeof res.data == "object") {
            if (typeof res.data.settings == "object") {
              logo = res.data.settings.companyLogo;
            }
          }
        }
        return <LazyLoadImage {...props} src={logo} />;
      }}
    </ApiRequest>
  ),
};

export default Images;
