/**Material Ui Components */
import { Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { Refresh } from "@material-ui/icons";
import "animate.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.css";
/**Font faces */
// import "typeface-roboto";
/**Style sheets */
import React from "react";
// import  from "./layout/MainWrapper";
import { Provider as ReduxProvider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import ApiRequest, { SnackbarErrors } from "../api/ApiRequest";
import { IS_LOGGED_IN_THREAD } from "../api/apiThreads";
import MicrosoftLoader from "../global/components/loader/MicrsoftLoader";
import "../scss/app.scss";
import Layout from "./layout";
import AdminLinks from "./routes";
// import InitializingAppLoader from "../global/components/initializer/InitializingAppLoader";
/** import theme */
import theme from "./theme";
import ScrollToTop from "../global/components/ScrollToTop";
import store from "../api/store";
import "rsuite/dist/rsuite.min.css";
import "../less/app.less";
import "../api/ApiMiddleware";

function RedirectToLogin(props) {
  const history = useHistory();
  history.push("/login");
  return <AdminLinks.login.Component />;
}

class AdminRouting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      authenticated: false,
      authChange: 0,
    };
  }

  /**
   * isInitializing has three states,
   * 0 when compoenent has just mounted
   * 1 check  authStatus is loading
   * 2 check last activity is loading
   * 3 all is completed and application is ready for use
   *
   */

  render() {
    if (this.state.loading === true) {
      return (
        <ReduxProvider store={store}>
          <ThemeProvider theme={theme}>
            <SnackbarErrors />
            <ApiRequest
              autoload
              thread={IS_LOGGED_IN_THREAD}
              initialData={{
                data: {
                  admin: {},
                  token: "",
                },
              }}
              callbacks={{
                success: (data) => {
                  if (typeof data.data !== "undefined") {
                    if (typeof data.data.token !== "undefined") {
                      this.setState({
                        ...this.state,
                        authenticated:
                          data.data.token === "valid" ? true : false,
                        loading: false,
                      });
                    }
                  }
                },
              }}
            >
              {({ loading, data, error, RefreshButton }) => {
                if (error) {
                  return (
                    <div className="d-flex flex-column justify-content-center align-items-center vh-100 vh100">
                      <Typography className="mt-4">
                        <b>Error! Please check your network and try again</b>
                      </Typography>
                      <RefreshButton
                        variant="outlined"
                        color="primary"
                        endIcon={<Refresh />}
                      >
                        Reload
                      </RefreshButton>
                    </div>
                  );
                }

                if (loading === true) {
                  return (
                    <div className="d-flex flex-column justify-content-center align-items-center vh-100 vh100">
                      <MicrosoftLoader />
                      <Typography className="mt-4">
                        <b>Loading...</b>
                      </Typography>
                    </div>
                  );
                }

                if (typeof data.data === "undefined") {
                  return <div>Some thing went wrong</div>;
                }

                return (
                  <div className="d-flex flex-column justify-content-center align-items-center vh-100 vh100">
                    <MicrosoftLoader />
                    <Typography className="mt-4">
                      <b>Loading...</b>
                    </Typography>
                  </div>
                );
              }}
            </ApiRequest>
          </ThemeProvider>
        </ReduxProvider>
      );
    }

    if (this.state.authenticated === false) {
      const Login = AdminLinks.login.Component;
      return (
        <ReduxProvider store={store}>
          <ThemeProvider theme={theme}>
            <SnackbarErrors />
            <Router>
              <ScrollToTop>
                <Switch>
                  <Route
                    path="login"
                    render={(props) => <Login {...props} />}
                  />
                  <Route>
                    <RedirectToLogin />
                  </Route>
                </Switch>
              </ScrollToTop>
            </Router>
          </ThemeProvider>
        </ReduxProvider>
      );
    }

    if (this.state.authenticated === true) {
      return (
        <ReduxProvider store={store}>
          <ThemeProvider theme={theme}>
            <SnackbarErrors />
            <Router>
              <ScrollToTop>
                <Layout>
                  <Switch>
                    {AdminLinks.modules.map((module, index) => {
                      const { Component } = module;

                      if (module.path == "/") {
                        return true;
                      }
                      return (
                        <Route
                          key={index}
                          path={module.path}
                          render={(props) => (
                            <Component {...props} {...module} />
                          )}
                        />
                      );
                    })}
                    {AdminLinks.modules.map((module, index) => {
                      const { Component } = module;
                      if (module.path == "/") {
                        return (
                          <Route
                            key={index}
                            path={module.path}
                            render={(props) => (
                              <Component {...props} {...module} />
                            )}
                          />
                        );
                      }
                    })}
                  </Switch>
                </Layout>
              </ScrollToTop>
            </Router>
          </ThemeProvider>
        </ReduxProvider>
      );
    }
  }
}
export default AdminRouting;
