import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  makeStyles,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { teal } from "@material-ui/core/colors";
import { Close, Refresh } from "@material-ui/icons";
import { Alert as MuiAlert, AlertTitle } from "@material-ui/lab";
import Axios from "axios";
import clsx from "clsx";
import propTypes from "prop-types";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { apiEndPoints } from "./apiEndPoints";
import { readUserDataOnDevice } from "./cacheUserOnDevice";
import store, {
  cacheRequest,
  closeErrorSnackbar,
  showErrorSnackBar,
  updateStore,
} from "./store";

const useStyles = makeStyles((theme) => ({
  refreshButton: {
    width: "30px",
    height: "30px",
    borderRadius: "5px",
    backgroundColor: teal[500],
    color: "#fff",
    ["&:hover"]: {
      color: teal[500],
      border: "solid 1px " + teal[500],
    },
  },
}));

export default class ApiRequest extends React.PureComponent {
  constructor(props) {
    super(props);

    /**
     * Iniialize state state will only have the dynamic data of the api request
     */
    this.state = {
      validationErrorMessage: {},
      validationErrors: {},
      errorMessage: "",
      message: "",
      payload: this.props.initialPayload || {},
      data: this.props.initialData,
      loading: false,
      loadingState: 0,
      refreshTime: 500,
      error: false,
      refreshing: false,
      formData: new FormData(),
      fileInputs: {},
      showMessageSnackBar: false,
      page: 1,
      sorting: false,
      loadingPage: false,
      loadedPages: [],
      showDialog: false,
      dialogTitle: "",
      dialogMessage: "",
      errorType: "",
      completed: false, // when the form has finished loading i will be in completed state
    };

    this.headers = props.headers || {};
    this.button = props.button || false;
    this.callbacks = {
      success: () => {},
      error: () => {},
      validationError: () => {},
      resetFormCallback: (data) => {},
      ...this.props.callbacks,
    };

    /**
     * When  ever we make a request, we have response object. This response object 
       {
          data: {
            ['key-tha-holds-the-new-data']: {} || []
          }
       }
     */
    this.uiUpdateKey = props.uiUpdateKey || false;

    // thread of the existing fetched data in the ui if that data is not yet there, it will not be added
    this.uiUpdateThread = props.uiUpdateThread || false;

    // they of the data we are going to update
    this.uiUpdateType = props.uiUpdateType || false;

    // the response key which comes inside the date respose
    this.responseKey = props.responseKey || false;

    // method
    this.method = props.method || "GET";

    // check if the api request is goig to be used in a hook
    this.isHook = props.isHook || false;

    /**
     * [this.props.addOnThread]
     * This is used to enable multiple requests
     * Example we might be fetching data using the same thread but different ids.
     * An addon thread can be that id
     */
    if (typeof this.props.addOnThread === "undefined") {
      this.addOnThread = "";
    } else {
      this.addOnThread = this.props.addOnThread;
    }
    this.thread = this.props.thread + this.addOnThread;

    // ref for the snack bar
    this.ref = React.createRef();
  }

  /**
   * Convert objects to json strings when submitting form data
   */
  parseObjectsToFormDataCompatibleData(property) {
    if (typeof property === "object" && Array.isArray(property) === false) {
      /// check if its a file
      if (property instanceof Blob) {
        // do nothing
      } else {
        // check of the properties of the object ate not files
        property = JSON.stringify(property);
      }
    } else if (Array.isArray(property)) {
      // if the property is any array lets map through the arrays
      for (let propertyKey = 0; propertyKey < property.length; propertyKey++) {
        // check if the property is a file
        if (property[propertyKey] instanceof Blob) {
          // do nothing if its a file
          // Here we should be able to know if the array has any files
          // we shall make an update later
        }
      }
      property = JSON.stringify(property);
    } else {
      // do nothing
    }

    return property;
  }

  /**
   * Reset the state to the initial state
   */
  resetState = () => {};

  /**
   * Load Next page if the request has pagination
   */
  loadNextPage = (options = {}) => {};

  /**
   * Input for input fields
   */
  input = (options) => {
    const { name, defaultValue, type } = options;
    // setPayload({
    //   ...payload,
    //   [name]: options.value || ""
    // })
    const showHelperText = (name) => {
      if (typeof this.state.validationErrors !== "undefined") {
        if (name in this.state.validationErrors) {
          return {
            helperText: this.state.validationErrors[name],
            error: true,
          };
        }
      }
      return {
        helperText: options.helperText || "", //JSON.stringify(validationErrors);
      };
    };
    return {
      ...options,
      onChange: (e) => {
        if (typeof e == "undefined") {
          console.warn("There is an issue");
        } else {
          const { name, value, type, checked } = e.target;

          this.setState({
            ...this.state,
            payload: {
              ...this.state.payload,
              [name]: type === "checkbox" || type === "radio" ? checked : value,
            },
          });
        }
      },
      value:
        typeof this.state.payload[name] === "string"
          ? this.state.payload[name].trimStart()
          : this.state.payload[name] || defaultValue,
      ...showHelperText(name),
    };
  };

  /**
   * Set Payload
   */
  setPayload = (payload) => {
    this.setState({
      ...this.state,
      payload: {
        ...this.props.initialPayload,
        ...this.state.payload,
        ...payload,
      },
    });
  };

  apiConfig = (payload = {}) => {
    var method = this.props.method;
    var url = this.props.endPoint;

    if (typeof this.props.thread !== "undefined") {
      if (this.props.thread in apiEndPoints) {
        var {
          endPoint,
          method,
          uiUpdateKey,
          uiUpdateThread,
          uiUpdateType,
          responseKey,
          headers,
        } = apiEndPoints[this.props.thread];
        if (typeof endPoint === "function") {
          var url = endPoint(this.props.params);
        } else {
          var url = endPoint;
        }

        this.headers = { ...headers, ...this.headers };

        /**
         * Work  on the ui updates
         */
        if (this.uiUpdateKey == false) {
          this.uiUpdateKey = uiUpdateKey;
        }

        if (this.uiUpdateThread === false) {
          this.uiUpdateThread = uiUpdateThread;
        }

        if (this.uiUpdateType == false) {
          this.uiUpdateType = uiUpdateType;
        }

        if (this.responseKey == false) {
          this.responseKey = responseKey;
        }
      }
    } else {
      // alert("apiEndPoint: is undeined");
    }

    // payload passed as a prod to api Request. this is so since the initial payload can be overided at any time.
    let propPayload = {};
    if (typeof this.props.payload == "object") {
      propPayload = this.props.payload;
    }

    // check for query params
    var queryString = "";
    if (typeof this.props.query === "object") {
      queryString = "?";
      for (var key in this.props.query) {
        queryString += `${key}=${this.props.query[key]}&`;
      }

      for (var key in this.state.payload) {
        queryString += `${key}=${this.state.payload[key]}&`;
      }
      queryString = encodeURI(queryString);
      // add the payload to the query string,
      // if (this.isQueryInput === true) {
      //   for (var key in this.state.payload) {
      //     queryString += `&${key}=${this.state.payload[key]}`;
      //   }
      // }
    } else {
      if (typeof method == "string") {
        if (method.toUpperCase() == "get".toUpperCase()) {
          queryString = "?";

          let _qdata = {
            ...this.props.initialPayload,
            ...this.state.payload,
            ...propPayload,
          };
          for (var key in _qdata) {
            let value = _qdata[key];
            if (typeof value == "object") {
              value = JSON.stringify(value);
            }
            queryString += `${key}=${value}&`;
          }
          // remove the & the end
          queryString = queryString.slice(0, queryString.length - 1);
          queryString = encodeURI(queryString);
        }
      }
    }

    var data = {
      ...this.props.initialPayload,
      ...this.state.payload,
      ...propPayload,
      ...payload,
    };

    var contentType = "application/json";

    // handle file upload if the form has file inputs
    if (
      this.props.withFileUpload === true ||
      this.props.withFileUpload === "true"
    ) {
      contentType = `multipart/form-data; boundary=${Math.random()
        .toString()
        .substr(2)}`;
      var formData = new FormData();
      // for (var key3 in this.state.payload) {
      //   let property = this.parseObjectsToFormDataCompatibleData(
      //     this.state.payload[key3]
      //   );
      //   formData.append(key3, property);
      // }

      for (var key1 in data) {
        let property = this.parseObjectsToFormDataCompatibleData(data[key1]);
        formData.append(key1, property);
      }
      data = formData;
    }

    let bearerToken = readUserDataOnDevice().token;

    // if no query string provided url will be fine.
    url = url + queryString;
    this.method = method;
    this.url = url;

    return {
      url,
      method,
      contentType: contentType,
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "*",
        Authorization: "Bearer " + bearerToken,
        ...this.headers,
      },
      widthCredentials: true,
    };
  };

  componentDidUpdate() {
    this.callbacks = {
      success: () => {},
      error: () => {},
      validationError: () => {},
      resetFormCallback: (data) => {},
      ...this.props.callbacks,
    };

    if (typeof this.props.addOnThread === "undefined") {
      this.addOnThread = "";
    } else {
      this.addOnThread = this.props.addOnThread;
    }

    const oldThread = this.thread;
    this.thread = this.props.thread + this.addOnThread;
    const dataStore = store.getState().dataStore;

    /**
     * If there was un update on the thread and its not the same as the old one
     * WE shall need to request that data from the server for
     */
    if (oldThread !== this.thread) {
      if (typeof this.thread !== undefined) {
        if (this.thread in dataStore) {
          this.setState({
            ...this.state,
            data: dataStore[this.thread],
          });
        } else {
          if (this.props.autoload === true || this.props.autoload === "true") {
            this.request();
          }
        }
      }
    }

    // check and see if the snack bar mounted and change the position
    if (typeof this.ref.current !== "undefined") {
    }

    const unsubscribe = store.subscribe(() => {
      try {
        const dataStore = store.getState().dataStore;
        const cacheRequestStore = store.getState().cacheRequestStore;

        if (this.thread in dataStore) {
          if (
            JSON.stringify(dataStore[this.thread]) !==
            JSON.stringify(this.state.data)
          ) {
            this.setState({
              ...this.state,
              ...dataStore[this.thread],
            });
          }
          // unsubscribe();
        }

        if (this.thread in cacheRequestStore) {
          if (cacheRequestStore[this.thread] === true) {
            store.dispatch(
              cacheRequest({
                thread: this.thread,
                refresh: false,
              })
            );
            this.refreshRequest();
          }
        }
        return;
      } catch (error) {
        //console.log("Error encountred:: ", error);
      }
    });
  }

  componentDidMount() {
    const requestChange = store.getState().apiRequestStateChange;
    const dataStore = store.getState().dataStore;

    if (typeof this.thread !== undefined) {
      if (this.thread in dataStore) {
        this.setState({
          ...this.state,
          data: dataStore[this.thread],
        });
      } else {
        if (this.props.autoload === true || this.props.autoload === "true") {
          this.request();
        }
      }
    }

    //Subscribe for future changes.
    const unsubscribe = store.subscribe(() => {
      const dataStore = store.getState().dataStore;
      const cacheRequestStore = store.getState().cacheRequestStore;

      if (this.thread in dataStore) {
        if (
          JSON.stringify(dataStore[this.thread]) !==
          JSON.stringify(this.state.data)
        ) {
          this.setState({
            ...this.state,
            ...dataStore[this.thread],
          });
        }
        // unsubscribe();
        return;
      }

      if (this.thread in cacheRequestStore) {
        if (cacheRequestStore[this.thread] === true) {
          store.dispatch(
            cacheRequest({
              thread: this.thread,
              refresh: false,
            })
          );
          this.refreshRequest();
        }
      }
    });

    /**
     * Implementation is good but functionality needs time.
     */

    // if (this.props.reloadOnMount && this.props.autoload) {
    //   // check if the request is already there
    //   if (typeof this.thread in store.getState().dataStore) {
    //     this.request();
    //     alert("Hello");
    //   }
    // }
  }

  /**
   * @name errorView
   * @type ReactComponent
   * @description Views the error to the user
   */
  errorView = (props) => {
    if (this.state.error === true) {
      return (
        <div align="center">
          <LazyLoadImage
            src="/images/error-encountred.png"
            style={{
              width: "auto",
              maxWidth: "200px",
              height: "auto",
              maxHeight: "200px",
            }}
          />
          <Typography className="mt-3">{this.state.errorMessage}</Typography>
          <Button
            onClick={() => this.refreshRequest()}
            endIcon={
              this.state.refreshing ? (
                <CircularProgress size="20px" />
              ) : (
                <Refresh />
              )
            }
          >
            Try again
          </Button>
        </div>
      );
    }

    return "";
  };

  /**
   * Handle the file input change
   */
  onFileInputChange = (options) => (e) => {
    if (typeof options !== "object") {
      options = {};
    }
    var file = e.target.files[0];
    const { name } = e.target;
    let maxFileSize = e.target.getAttribute("maxFileSize") || 4;
    if (typeof file === "undefined") {
      return false;
    }

    if (typeof file.type === "undefined") {
      return false;
    }
    var size = (file.size * 10 ** -6).toFixed(0);

    if (Number(size) > Number(maxFileSize)) {
      alert(
        `File Size is greater then ${maxFileSize}mb \n Your file is: ${size}`
      );
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    this.setState({
      ...this.state,
      fileInputs: {
        ...this.state.fileInputs,
        [name]: {
          rendering: true,
          rendered: false,
        },
      },
    });
    reader.onloadend = () => {
      /**
       * Compression has  a bug with the image
       * If the type of file is not image it will not work well
       */
      this.setState({
        ...this.state,
        payload: {
          ...this.state.payload,
          [name]: file,
        },
        fileInputs: {
          ...this.state.fileInputs,
          [name]: {
            rendering: false,
            rendered: true,
            // changed it from url to originalUrl because we have compressed version of the file
            originalUrl: reader.result,
            fileName: file.name,
          },
        },
      });
      if (typeof options.onContentLoaded == "function") {
        options.onContentLoaded({ url: reader.result });
      }
    };

    // functionality for Small Size file compression to base64
    var img = new Image();
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var maxW = 200;
    var maxH = 200;

    img.onload = () => {
      var iw = img.width;
      var ih = img.height;
      var scale = Math.min(maxW / iw, maxH / ih);
      var iwScaled = iw * scale;
      var ihScaled = ih * scale;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
      // the small scaled image we could be able to pass in options
      // for compression ratio
      let imageQuality = 0.5;
      let smallImageUrl = canvas.toDataURL("image/jpeg", imageQuality);
      //console.log("Small image quality:: ", smallImageUrl);
      this.setState({
        ...this.state,
        payload: {
          ...this.state.payload,
          [name]: file,
        },
        fileInputs: {
          ...this.state.fileInputs,
          [name]: {
            rendering: false,
            rendered: true,
            // changed it from url to originalUrl because we have compressed version of the file
            url: smallImageUrl,
            fileName: file.name,
          },
        },
      });
      if (typeof options.onContentLoaded == "function") {
        options.onContentLoaded({ url: smallImageUrl });
      }
    };
    img.src = URL.createObjectURL(file); // this line is needed
  };

  /**
   * @name resetForm
   * @param
   * @description Reset the form and make it ready to be reused in the future
   */
  resetForm = () => {
    // the business logic has not been implemented yet
    this.setState({
      ...this.state,
      payload: this.props.initialPayload || {},
      data: this.props.initialData || {},
    });
  };

  /**
   * Reload request threads
   */
  reloadRequestThreads = () => {
    if (Array.isArray(this.props.reloadOnSuccess) == true) {
      if (typeof this.props.reloadOnSuccess.map == "function") {
        try {
          this.props.reloadOnSuccess.map((thread) => {
            store.dispatch(
              cacheRequest({
                thread: thread,
                refresh: true,
              })
            );
          });
        } catch (error) {
          //console.log(error);
        }
      }
    }
  };

  /**
   * File input
   * This is used when there is need to upload files to the server using the same form
   */
  fileInput = (props) => {
    if (typeof props.children === "function") {
    }
    const inputId = uuid();

    const Label = (props) => (
      <label {...props} htmlFor={inputId} className={props.className}>
        {props.children}
      </label>
    );

    // when file render has rendered the file
    let onContentLoaded = props.onContentLoaded;
    if (
      typeof onContentLoaded === "undefined" ||
      typeof onContentLoaded == "null"
    ) {
      onContentLoaded = (options = {}) => {};
    }

    return (
      <React.Fragment>
        <input
          type="file"
          id={inputId}
          accept={props.accept}
          onChange={this.onFileInputChange({ onContentLoaded })}
          name={props.name}
          maxFileSize={props.maxFileSize}
          className={clsx(props.inputClassName)}
        />
        {typeof props.children === "function"
          ? props.children({
              ...this.state.fileInputs[props.name],
              ...{ Label: Label },
            })
          : props.children}
      </React.Fragment>
    );
  };

  /**
   * Response handler for all requests
   */
  responseHandler = (res) => {
    var state = {
      message: "",
      loading: false,
      refreshing: false,
      data: {},
      errorMessage: "",
      error: false,
      validationErrors: {},
    };

    if (typeof res.data.errorCode !== "undefined") {
      this.callbacks.error(res.data);
      state = {
        ...state,
        errorMessage: "Error",
        data: this.props.initialData,
      };
    }

    if ("success" in res.data) {
      // Request was not successful
      if (res.data.success === false) {
        // Check if error exists in the payload
        if ("error" in res.data) {
          // There in an error with the request. this error is known at the server
          if (res.data.error === true) {
            this.callbacks.error(res.data);
            state = {
              ...state,
              errorMessage: res.data.data.message,
              data: res.data,
            };
          }
          // Check if there is a validation error
          if (res.data.validationError == true) {
            this.callbacks.validationError(res.data.data);
            store.dispatch(
              showErrorSnackBar({
                message: res.data.message,
                errors: res.data.data.errors,
                type: "VALIDATION_ERROR",
                severity: "warning",
              })
            );
          } else {
            store.dispatch(
              showErrorSnackBar({
                message: res.data.message,
                errors: res.data.data.errors,
                type: "ERROR",
                severity: "error",
              })
            );
            state = {
              ...state,
              error: true,
            };
          }
        }
      } else {
        /**
         * Let us handle the update on the data that has to be updated
         * When a request has finished loading, it either deletes, adds or gets new data from the server,
         * we would like to check and see if their is some update which needs to be handled.
         */
        let dataStore = JSON.parse(JSON.stringify(store.getState().dataStore));
        if (this.uiUpdateThread in dataStore) {
          let oldData = dataStore[this.uiUpdateThread].data;
          let list, newList, newItem, newData;
          if (typeof this.method == "string") {
            this.method = this.method.toUpperCase();
          }
          switch (this.method) {
            case "POST":
              // Lets know hoe to update the existing ui. Should we update, push , delete, or do something else
              switch (this.uiUpdateType) {
                case "push":
                  list = [];
                  if (Array.isArray(oldData[this.uiUpdateKey])) {
                    list = [...oldData[this.uiUpdateKey]];
                  }
                  newItem = res.data.data[this.responseKey];
                  newList = [];
                  newList.push(newItem, ...list);

                  newData = { ...oldData };
                  newData[this.uiUpdateKey] = newList;

                  store.dispatch(
                    updateStore({
                      thread: this.uiUpdateThread,
                      data: {
                        data: {
                          data: newData,
                        },
                      },
                    })
                  );
                  break;

                case "replace":
                  if (typeof oldData[this.uiUpdateKey] !== "object") {
                    newData = {
                      [this.uiUpdateKey]: {},
                    };
                  } else {
                    newData = { ...oldData };
                  }
                  newItem = res.data.data[this.responseKey];
                  newData[this.uiUpdateKey] = newItem;

                  store.dispatch(
                    updateStore({
                      thread: this.uiUpdateThread,
                      data: {
                        data: {
                          data: newData,
                        },
                      },
                    })
                  );
                  break;
                default:
                  break;
              }
              break;

            case "PATCH":
              break;

            case "DELETE":
              // Lets know hoe to update the existing ui. Should we update, push , delete, or do something else

              break;
            default:
              break;
          }
        }

        /**
         * Reload some requests if the request has been successfull
         */
        if (this.props.reloadOnSuccess != undefined) {
          this.reloadRequestThreads();
        }

        // call the success callback which can be used by the user incase they need to add some data
        this.callbacks.success(res.data, this);
        if (typeof this.props.successDialog == "object") {
          // check the structure
        }

        /**
         * Reset a form when the result are successfull and have some thing new
         */
        this.callbacks.resetFormCallback(res.data, this.resetForm);
        state = {
          ...state,
          ["completed"]: true,
          data: res.data,
        };
        // const
      }
    } else if (this.props.customResponse) {
      state = {
        ...state,
        error: false,
        showMessageSnackBar: false,
        data: res.data,
      };
    } else {
      state = {
        ...state,
        error: true,
      };
      store.dispatch(
        showErrorSnackBar({
          message: res.data.message,
          errors: res.data.data.errors,
          type: "ERROR",
          severity: "error",
        })
      );
      // callbacks(res.data);
    }

    if (typeof this.thread !== "undefined") {
      store.dispatch(
        updateStore({
          thread: this.thread,
          data: res.data,
        })
      );
    }

    this.setState({
      ...this.state,
      ...state,
    });
  };

  /**
   * Handle errors that happen when we are making requests
   */
  errorHandler = (error) => {
    this.setState({
      ...this.state,
      ["error"]: true,
      loading: false,
      completed: false,
      refreshing: false,
    });
    this.callbacks.error(error.data);
    //console.log("Error: ", error, this.props);
  };

  /**
   * Make a request to the server
   */
  request = (payload = {}) => {
    if ("storedRequests" in window) {
    } else {
      window["storedRequests"] = {};
    }

    if (typeof payload === "object") {
      Object.keys(payload).map((key) => {
        if (typeof payload[key] === "function") {
          payload = {};
        }
      });

      if (Object.keys(payload).length > 0) {
        this.setState({
          ...this.state,
          payload: { ...this.state.payload, ...payload },
        });
      } else {
        // console.log("No keys to add.");
      }
    }

    this.forceUpdate(() => {
      console.log("Update completed");
    });
    const config = this.apiConfig(payload);
    // window.storedRequests[this.thread] = config;
    this.setState({ ...this.state, loading: true, completed: false });
    Axios(config).then(this.responseHandler).catch(this.errorHandler);
  };

  /**
   * Refresh the request
   */
  refreshRequest = () => {
    this.setState({ ...this.state, refreshing: true });
    Axios(this.apiConfig()).then(this.responseHandler).catch(this.errorHandler);
  };

  /**
   *
   */

  /**
   * Button used when submitting data to the server
   */
  submitButton = (props) => {
    if (this.state.loading === true) {
      return (
        <Button
          {...props}
          endIcon={<CircularProgress size="16px" />}
          disabled
        />
      );
    }
    return (
      <Button
        {...props}
        onClick={() => {
          if (typeof props.onClick === "function") {
            props.onClick();
          }
          this.request();
        }}
      />
    );
  };

  /**
   * Reset Complete state.
   * Remove the form from the completed state
   */
  resetComplete = () => {
    this.setState({ ...this.state, ["completed"]: false });
  };

  /**
   * Render the application ui
   */
  render() {
    if (this.isHook === true) {
      return {
        some: "text",
      };
    }

    let notificationsEl = document.querySelector("#notifications");
    if (notificationsEl == null) {
      let div = document.createElement("div");
      div.setAttribute("id", "notifications");
      document.body.prepend(div);
    }

    if (this.state.showMessageSnackBar == true) {
    }

    if (typeof this.props.children === "function") {
      return (
        <React.Fragment>
          <Dialog open={this.state.showDialog}>
            <Alert
              onClose={() =>
                this.setState({ ...this.state, showDialog: false })
              }
            >
              <AlertTitle>{this.state.dialogTitle}</AlertTitle>
              {this.state.dialogMessage}
            </Alert>
          </Dialog>
          {this.props.children({
            payload: this.state.payload,
            loading: this.state.loading,
            completed: this.state.completed,
            refreshing: this.state.refreshing,
            resetComplete: this.resetComplete,
            refresh: this.refreshRequest,
            ErrorView: this.errorView,
            RefreshButton: (props) => {
              const classes = useStyles();
              if (props.variant == "icon") {
                return (
                  <IconButton
                    onClick={this.refreshRequest}
                    className={classes.refreshButton}
                  >
                    {this.refreshing ? <CircularProgress /> : <Refresh />}
                  </IconButton>
                );
              }

              return (
                <Button
                  {...props}
                  onClick={() => this.refreshRequest()}
                  endIcon={
                    this.state.refreshing ? (
                      <CircularProgress size="20px" />
                    ) : (
                      props.endIcon
                    )
                  }
                />
              );
            },
            data:
              typeof this.state.data == "undefined"
                ? typeof this.props.initialData == "undefined"
                  ? {}
                  : this.props.initialData
                : this.state.data,
            res:
              typeof this.state.data == "undefined"
                ? typeof this.props.initialData == "undefined"
                  ? {}
                  : this.props.initialData
                : this.state.data,
            error: this.state.error,
            resetForm: this.resetForm,
            setPayload: this.setPayload,
            autoloadStarted: this.state.autoloadStarted,
            errorMessage: this.state.errorMessage,
            sorting: this.state.sorting,
            onSort: () => {
              alert("Sorting Not implemented");
            },
            nextPage: () => {
              alert("Next Page not implemented");
            },
            prevPage: () => {
              alert("Prev Page not implemented");
            },
            page: this.state.page,
            input: this.input,
            Button: this.submitButton,
            SubmitButton: this.submitButton,
            FileInput: this.fileInput,
            loadNextPage: this.loadNextPage,
            submit: this.request, /// send the data to the server
          })}
        </React.Fragment>
      );
    } else {
      return <div></div>;
    }
  }
}

ApiRequest.propTypes = {
  dataIndex: propTypes.any, // the position of data in an array the array default is boolean
  dataPayload: propTypes.any, // This is used when in an array or an object
  withFileUpload: propTypes.bool,
  initialData: propTypes.object,
  thread: propTypes.string, // this thread should be created in api threads
  initialPayload: propTypes.object,
  endPoint: propTypes.string,
  autoload: propTypes.bool,
  query: propTypes.object, // object will be convered into a query string
  isForm: propTypes.bool, // make the request become a form
  isQueryInput: propTypes.bool,
  /**
   * Update the data in datastore with newly loaded data
   *
   */
  uiUpdateKey: propTypes.string,
  uiUpdateThread: propTypes.string,
  uiUpdateType: propTypes.string,
  responseKey: propTypes.string,
  customResponse: propTypes.bool,
  // reload on success
  reloadOnSuccess: propTypes.array,

  // callbacks: propTypes.objectOf({
  //   resetFormCallback: propTypes.func,
  //   success: propTypes.func,
  // }),
};

/**
 * Refresh Request Button
 */
export function RefreshRequestButton(props) {
  return (
    <ApiRequest {...props}>
      {({ refresh, RefreshButton }) => {
        return <RefreshButton variant="icon" {...props} />;
      }}
    </ApiRequest>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStylesz = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

/**
 * Render Error
 */

export function SnackbarErrors(props) {
  let { errors, type, showError, message, severity } = useSelector((store) => {
    // console.log("Store.. ", store.errorSnackbar);
    return store.errorSnackbar;
  });

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeErrorSnackbar());
  };

  if (
    type === "VALIDATION_ERROR" &&
    typeof errors == "object" &&
    showError === true
  ) {
    return (
      <div>
        {Object.keys(errors).map((key, index) => {
          return (
            <Snackbar
              style={{ marginTop: `${index}00px` }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={showError}
              key={index}
            >
              <Alert
                severity={severity}
                open={showError}
                onClose={onClose}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={onClose}
                  >
                    <Close />
                  </IconButton>
                }
                message={errors[key]}
                key={index}
              >
                <AlertTitle style={{ fontSize: "0.9rem" }}>
                  Validation Error
                </AlertTitle>
                <div style={{ fontSize: "0.8rem" }}>{errors[key]}</div>
              </Alert>
            </Snackbar>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <Snackbar
        // className={this.props.errorType}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showError}
        onClose={onClose}
        action={
          <IconButton aria-label="close" color="inherit" onClick={onClose}>
            <Close />
          </IconButton>
        }
        message={message}
        key="error"
      />
      <div
        // className="d-none"
        style={{
          position: "fixed",
          top: "50px",
          right: "20px",
        }}
      >
        {/* {showError == true ? (
          <Alert
            variant="filled"
            severity={severity}
            onClose={onClose}
            closeIcon={<Close />}
          >
            <AlertTitle>{type}</AlertTitle>
            {message}
          </Alert>
        ) : (
          ""
        )} */}
      </div>
    </div>
  );
  //   return (
  //     <ReduxProvider store={store}>
  //       <ThemeProvider theme={theme}>
  //         <div>
  //           <Snackbar
  //             title="Hello"
  //             className={this.props.errorType}
  //             anchorOrigin={{
  //               vertical: "top",
  //               horizontal: "right",
  //             }}
  //             open={this.state.open}
  //             onClose={this.onClose}
  //             action={
  //               <IconButton
  //                 aria-label="close"
  //                 color="inherit"
  //                 onClick={this.onClose}
  //               >
  //                 <Close />
  //               </IconButton>
  //             }
  //             message={this.props.errorMessage}
  //             key="error"
  //           />
  //           <div
  //             // className="d-none"
  //             style={{
  //               position: "fixed",
  //               top: "50px",
  //               right: "20px",
  //             }}
  //           >
  //             {this.state.open == true ? (
  //               <Alert
  //                 variant="filled"
  //                 severity={this.props.errorSeverity}
  //                 onClose={this.onClose}
  //                 closeIcon={<Close />}
  //               >
  //                 <AlertTitle>{this.props.title}</AlertTitle>
  //                 {this.props.errorMessage}
  //               </Alert>
  //             ) : (
  //               ""
  //             )}
  //           </div>
  //         </div>
  //       </ThemeProvider>
  //     </ReduxProvider>
  //     )
  // }
}
