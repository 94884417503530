import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { formatNumber } from "accounting-js";
import clsx from "clsx";
import React, { useState } from "react";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_CHANGE_LOAN_APPLICATION_STATUS,
  BNK_API_GET_LOANS_SUMMARY,
} from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";
import { StatusChip } from "./LoansTable";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "10px",
  },
}));

export default function ChangeLoanStatusButton(props) {
  return (
    <React.Fragment>
      <ApiRequest
        thread={BNK_API_CHANGE_LOAN_APPLICATION_STATUS}
        addOnThread={props.id + props.status}
        payload={{
          loan_id: props.id,
          application_id: props.application_id,
          current_status: props.status,
        }}
        callbacks={{
          success: (res) => {
            if (typeof res == "object") {
              if (typeof res.data == "object") {
                if (res.data.changed === true) {
                  if (typeof props.onChange == "function") {
                    props.onChange();
                  }
                }
              }
            }
          },
        }}
        reloadOnSuccess={[BNK_API_GET_LOANS_SUMMARY]}
      >
        {(reqBody) => {
          let { setPayload } = reqBody;
          if (props.status == "PENDING") {
            return (
              <div className="d-flex">
                <ChangeStatusDialogButton
                  onClick={() => {
                    setPayload({ status: "APPROVED" });
                  }}
                  {...reqBody}
                  {...props}
                  loan={props}
                  color="primary"
                  approve_loan={true}
                >
                  Approve
                </ChangeStatusDialogButton>
                <ChangeStatusDialogButton
                  onClick={() => {
                    setPayload({ status: "DECLINED" });
                  }}
                  {...reqBody}
                  {...props}
                  loan={props}
                  color="primary"
                  decline_loan={true}
                >
                  Decline
                </ChangeStatusDialogButton>
              </div>
            );
          }

          if (props.status === "APPROVED") {
            return (
              <div className="d-flex">
                <ChangeStatusDialogButton
                  onClick={() => {
                    setPayload({ status: "IN_PROGRESS" });
                  }}
                  {...reqBody}
                  {...props}
                  loan={props}
                  issue_loan={true}
                  color="primary"
                >
                  Issue
                </ChangeStatusDialogButton>
                <ChangeStatusDialogButton
                  onClick={() => {
                    setPayload({ status: "DECLINED" });
                  }}
                  {...reqBody}
                  {...props}
                  loan={props}
                  variant="outlined"
                  decline_loan={true}
                  color="secondary"
                >
                  Decline
                </ChangeStatusDialogButton>
              </div>
            );
          }

          return "";
        }}
      </ApiRequest>
    </React.Fragment>
  );
}

function ChangeStatusDialogButton(props) {
  const [dialogState, setDialogState] = useState(false);
  const onDialogStateChange = () => setDialogState(!dialogState);
  let SubmitButton = props.SubmitButton;
  const classes = useStyles();
  let { input } = props;

  return (
    <React.Fragment>
      <Button
        className="me-2"
        variant="contained"
        size="small"
        color="primary"
        {...props}
        onClick={() => {
          onDialogStateChange();
          props.onClick();
        }}
      />
      <Dialog open={dialogState} fullWidth maxWidth="sm">
        <DialogContent>
          <div>
            {props.approve_loan === true ? (
              <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <Typography variant="h6">Approve Loan</Typography>
                  <IconButton onClick={onDialogStateChange}>
                    <Close />
                  </IconButton>
                </div>
                <Divider className="my-2" />
                <Paper
                  variant="outlined"
                  className={clsx("p-1 mb-2", classes.paper)}
                >
                  <List dense>
                    <ListItem>
                      <ListItemText>Account Name</ListItemText>
                      <ListItemSecondaryAction>
                        <Typography>{props.account_name}</Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText>Account Number</ListItemText>
                      <ListItemSecondaryAction>
                        <Typography>{props.account_number}</Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText>Loan Amount</ListItemText>
                      <ListItemSecondaryAction>
                        <Typography>
                          <b>
                            {formatNumber(props.loan_amount)}
                            <small>Ugx</small>
                          </b>
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText>Status</ListItemText>
                      <ListItemSecondaryAction>
                        <StatusChip loan={props.loan} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Paper>
                <Alert>
                  <Typography className="my-2">
                    <b>
                      Approving this loan will mean that you have fully verified
                      and reviewed to loan applicant details. <br />
                    </b>
                  </Typography>
                  <div className="d-flex align-items-center mb-2">
                    <SubmitButton variant="contained" size="small">
                      Approve
                    </SubmitButton>
                    <Button
                      className="ms-2"
                      onClick={onDialogStateChange}
                      variant="outlined"
                      size="small"
                    >
                      Cancel
                    </Button>
                  </div>
                </Alert>
              </div>
            ) : (
              ""
            )}

            {props.issue_loan === true ? (
              <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <Typography variant="h6">Issue Loan</Typography>
                  <IconButton onClick={onDialogStateChange}>
                    <Close />
                  </IconButton>
                </div>
                <Divider className="my-2" />
                <Paper
                  variant="outlined"
                  className={clsx("p-1 mb-2", classes.paper)}
                >
                  <List dense>
                    <ListItem>
                      <ListItem>
                        <ListItemText>Account Name</ListItemText>
                        <ListItemSecondaryAction>
                          <Typography>{props.account_name}</Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItemText>Account Number</ListItemText>
                      <ListItemSecondaryAction>
                        <Typography>{props.account_number}</Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText>Loan Amount</ListItemText>
                      <ListItemSecondaryAction>
                        <Typography>
                          <b>
                            {formatNumber(props.loan_amount)}
                            <small>Ugx</small>
                          </b>
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText>Status</ListItemText>
                      <ListItemSecondaryAction>
                        <StatusChip loan={props.loan} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Paper>
                <Alert>
                  <Typography className="my-2">
                    <b>
                      You will have to provide the first installment date for
                      the loan, if not provided the first installment date will
                      be the issuing date.
                      <br />
                    </b>
                  </Typography>
                </Alert>
                <KijjeInput
                  {...input({
                    name: "first_instalment_date",
                  })}
                  line
                  variant="line"
                  label="First Instalment date"
                  type="date"
                />
                <div className="d-flex align-items-center my-3">
                  <SubmitButton variant="contained" size="small">
                    Issue Loan
                  </SubmitButton>
                  <Button
                    className="ms-2"
                    onClick={onDialogStateChange}
                    variant="outlined"
                    size="small"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}

            {props.decline_loan === true ? (
              <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <Typography variant="h6">Decline Loan</Typography>
                  <IconButton onClick={onDialogStateChange}>
                    <Close />
                  </IconButton>
                </div>
                <Divider className="my-2" />
                <Paper
                  variant="outlined"
                  className={clsx("p-1 mb-2", classes.paper)}
                >
                  <List dense>
                    <ListItem>
                      <ListItemText>Account Name</ListItemText>
                      <ListItemSecondaryAction>
                        <Typography>{props.account_name}</Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText>Account Number</ListItemText>
                      <ListItemSecondaryAction>
                        <Typography>{props.account_number}</Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText>Loan Amount</ListItemText>
                      <ListItemSecondaryAction>
                        <Typography>
                          <b>
                            {formatNumber(props.loan_amount)}
                            <small>Ugx</small>
                          </b>
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText>Status</ListItemText>
                      <ListItemSecondaryAction>
                        <StatusChip loan={props.loan} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Paper>
                <Alert severity="error">
                  <Typography className="my-2">
                    <b>
                      Are you sure you want to decline the loan, once the loan
                      has been declined, it will not be reservable. The customer
                      will get a notification once the loan has been declined.
                      <br />
                    </b>
                  </Typography>
                </Alert>
                <div className="d-flex align-items-center my-3">
                  <SubmitButton variant="contained" size="small">
                    Decline Loan
                  </SubmitButton>
                  <Button
                    className="ms-2"
                    onClick={onDialogStateChange}
                    variant="outlined"
                    size="small"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
