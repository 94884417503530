import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Add, Check, Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { formatNumber } from "accounting-js";
import React, { useState } from "react";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_GET_ALL_INCOMES,
  BNK_API_GET_INCOME_CATEGORIES,
  BNK_API_RECORD_INCOME,
} from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";
import { showTransactionDate } from "../Transactions/TransactionsHistory";
import {
  SelectBranchMenu,
  tableStyles,
} from "../Transactions/TransactionsTable";
const useStyles = makeStyles((theme) => ({
  selectInput: {
    width: "100%",
    height: "35px",
    borderRadius: "5px",
  },
}));

export default function RecordIncome(props) {
  const [dialogState, setDialogState] = useState();
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
  };
  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={onDialogStateChange}
        startIcon={<Add />}
      >
        Record Income
      </Button>
      <Dialog fullWidth maxWidth="lg" open={dialogState}>
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center w-100">
            <Typography>Record Income</Typography>
            <IconButton onClick={onDialogStateChange}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <RecordIncomeTable />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

function SelectIncomeCategory(props) {
  const classes = useStyles();
  return (
    <ApiRequest thread={BNK_API_GET_INCOME_CATEGORIES} autoload>
      {({ loading, error, ErrorView, res }) => {
        if (loading) {
          return <CircularProgress />;
        }
        if (error) {
          return <ErrorView />;
        }

        let types = [];
        if (typeof res == "object") {
          if (typeof res.data == "object") {
            if (Array.isArray(res.data.categories)) {
              types = res.data.categories;
            }
          }
        }

        return (
          <select {...props} className={classes.selectInput}>
            <option>Select income type</option>
            {types.map((type) => {
              return (
                <option value={type.id}>
                  {type.name} - {type.branch}
                </option>
              );
            })}
          </select>
        );
      }}
    </ApiRequest>
  );
}

function RecordIncomeTable(props) {
  const classes = tableStyles();
  const [savedIncomes, setSavedIncomes] = useState([]);
  const [addOnThread, setAddonThread] = useState(Date.now().toString());
  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
      }}
    >
      <table className={classes.table}>
        <thead className={classes.thead}>
          <tr>
            <th>Received From</th>
            <th className="ps-4">Date</th>
            <th className="ps-4">Amount</th>
            <th className="ps-4">Trans Id</th>
            <th className="ps-4">Income Type</th>
            <th className="ps-4">Branch </th>
            <th className="ps-4">Memo</th>
            <th className="ps-4">Action</th>
          </tr>
        </thead>
        <tbody className={classes.tbody}>
          {savedIncomes.map((income) => {
            return (
              <tr>
                <td>{income.received_from}</td>
                <td>{showTransactionDate(income.date)}</td>
                <td>{formatNumber(income.amount)}UGX</td>
                <td>{income.transaction_id}</td>
                <td>{income.income_category}</td>
                <td>{income.branch}</td>
                <td>{income.memo}</td>
                <td>
                  <IconButton disabled>
                    <Check />
                  </IconButton>
                </td>
              </tr>
            );
          })}
          <ApiRequest
            thread={BNK_API_RECORD_INCOME}
            addOnThread={addOnThread}
            initialPayload={{
              transaction_id: "",
              amount: "",
              date: "",
            }}
            callbacks={{
              success: (res, xthis) => {
                if (typeof res == "object") {
                  if (typeof res.data === "object") {
                    if (res.data.incomeRecorded === true) {
                      setSavedIncomes([...savedIncomes, res.data.income]);
                      xthis.resetForm();
                    }
                  }
                }
              },
            }}
            reloadOnSuccess={[BNK_API_GET_ALL_INCOMES]}
          >
            {({ SubmitButton, input, payload }) => {
              return (
                <tr>
                  <td>
                    <KijjeInput
                      {...input({
                        name: "received_from",
                      })}
                      value={payload.received_from}
                      variant="filled"
                      square
                      placeholder="Received from"
                    />
                  </td>
                  <td>
                    <KijjeInput
                      {...input({
                        name: "date",
                      })}
                      value={payload.date}
                      variant="filled"
                      square
                      placeholder="Date"
                      type="date"
                    />
                  </td>
                  <td>
                    <KijjeInput
                      {...input({
                        name: "amount",
                      })}
                      required
                      value={payload.amount}
                      variant="filled"
                      square
                      placeholder="Amount"
                    />
                  </td>
                  <td>
                    <KijjeInput
                      variant="filled"
                      {...input({
                        name: "transaction_id",
                      })}
                      value={payload.transaction_id}
                      square
                      placeholder="Trans Id."
                    />
                  </td>
                  <td>
                    <SelectIncomeCategory
                      {...input({ name: "income_category" })}
                    />
                  </td>
                  <td>
                    <SelectBranchMenu isSelect input={input} />
                  </td>
                  <td>
                    <KijjeInput
                      {...input({
                        name: "memo",
                      })}
                      variant="filled"
                      square
                      placeholder="Memo"
                    />
                  </td>
                  <td>
                    <SubmitButton size="small" variant="contained">
                      Record
                    </SubmitButton>
                  </td>
                </tr>
              );
            }}
          </ApiRequest>
        </tbody>
      </table>
    </div>
  );
}
