import React from "react";
import Accounts from ".";

const accountsModule = {
  name: "Accounts",
  path: "/customer-accounts",
  icon: <i class="bi bi-people"></i>,
  Component: Accounts,
  subModules: [],
};

export default accountsModule;
