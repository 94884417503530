import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { RefreshOutlined } from "@material-ui/icons";
import accounting from "accounting-js";
import clsx from "clsx";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import ApiRequest from "../../../api/ApiRequest";
import { BNK_API_GET_INCOMES_AND_EXPENSES_TOTAL } from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";
import { SelectBranchMenu } from "../Transactions/TransactionsTable";
import ExpenseCategories from "./ExpenseCategories";
import ExpensesTable from "./ExpensesTable";
import IncomeCategories from "./IncomeCategories";
import IncomesTable from "./IncomesTable";
import { RecordExpense } from "./RecordExpense";
import RecordIncome from "./RecordIncome";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: grey[50],
    padding: "10px",
    boxShadow: "none",
    borderRadius: "0px",
    minHeight: "100vh",
  },
  paper: {
    width: "100%",
    height: "200px",
    boxShadow: theme.boxShadows[4],
    borderRadius: "10px",
    padding: "20px",
  },
  divider: {
    background: grey[400],
  },
  btn: {
    borderRadius: "10px",
    height: "30px",
    padding: "10px 10px",
  },
  select: {
    width: "100%",
    borderRadius: "10px",
  },
  activeLink: {
    borderBottom: "solid #000 3px",
    borderRadius: "0px",
  },
}));

export default function IncomesAndExpenses(props) {
  const classes = useStyles();
  const [apiQueryState, setApiQueryState] = useState({
    branch: "ALL",
    page: 1,
    limit: 5,
    start_date: "",
    end_date: "",
  });

  return (
    <div className={classes.root}>
      <div className="w-100">
        <Helmet>
          <title>Incomes & Expenses</title>
        </Helmet>
        <div
          className="d-flex mb-3 justify-content-between align-items-end"
          style={{ position: "relative" }}
        >
          <div className="d-flex align-items-center">
            <div style={{ fontSize: "1.5rem" }}>
              <i className=" bi-grid"></i>
            </div>
            <Typography className="mt-1 ms-1" variant="h6">
              Incomes & Expenses
            </Typography>
          </div>
          <div className="d-flex align-items-center ">
            <div className="pt-3">
              <IconButton
                onClick={() => {
                  setApiQueryState({ ...apiQueryState, refresh: Date.now() });
                }}
                className={classes.refreshBtn}
              >
                <RefreshOutlined />
              </IconButton>
            </div>
            <div className="px-1">
              <KijjeInput
                value={apiQueryState.start_date}
                onChange={(e) => {
                  setApiQueryState({
                    ...apiQueryState,
                    start_date: e.target.value,
                  });
                }}
                type="date"
                label="Start date"
                square
              />
            </div>
            <div className="px-1">
              <KijjeInput
                value={apiQueryState.end_date}
                onChange={(e) => {
                  setApiQueryState({
                    ...apiQueryState,
                    end_date: e.target.value,
                  });
                }}
                type="date"
                label="End date"
                square
              />
            </div>
            <SelectBranchMenu
              hasAll={true}
              isSelect
              input={() => ({
                onChange: (e) => {
                  setApiQueryState({
                    ...apiQueryState,
                    branch: e.target.value,
                  });
                },
                value: apiQueryState.branch,
              })}
              apiQueryState={apiQueryState}
              setApiQueryState={setApiQueryState}
            />
          </div>
        </div>
        <Divider className={clsx(classes.divider, "my-2")} />
        <div className="d-flex justify-content-between align-items-center">
          <ApiRequest
            addOnThread={JSON.stringify(apiQueryState)}
            query={apiQueryState}
            thread={BNK_API_GET_INCOMES_AND_EXPENSES_TOTAL}
            autoload
          >
            {({ loading, error, ErrorView, res }) => {
              if (loading) {
                return <CircularProgress />;
              }

              if (error) {
                return <ErrorView />;
              }

              let total_incomes = 0;
              let total_expenses = 0;

              if (typeof res === "object") {
                if (typeof res.data === "object") {
                  if (typeof res.data.incomes === "undefined") {
                    total_incomes = res.data.total_incomes;
                    total_expenses = res.data.total_expenses;
                  }
                }
              }

              return (
                <div className="d-flex align-items-center py-3">
                  <div className="">
                    <Typography>Total Incomes</Typography>
                    <Typography variant="h4">
                      {accounting.formatNumber(total_incomes)}
                      <small>Ugx</small>
                    </Typography>
                  </div>
                  <div className="ps-3">
                    <Typography color="primary">Total Expenses</Typography>
                    <Typography color="primary" variant="h4">
                      {accounting.formatNumber(total_expenses)}
                      <small>Ugx</small>
                    </Typography>
                  </div>
                </div>
              );
            }}
          </ApiRequest>
          <div>
            <RecordExpense
              apiQueryState={apiQueryState}
              setApiQueryState={setApiQueryState}
            />
            <RecordIncome
              apiQueryState={apiQueryState}
              setApiQueryState={setApiQueryState}
            />
          </div>
        </div>
        <Divider className={clsx(classes.divider, "my-2")} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <div className="d-flex align-items-center">
              <Button
                exact
                activeClassName={classes.activeLink}
                component={NavLink}
                to="/expenses-and-incomes"
              >
                Incomes
              </Button>
              <Button
                exact
                activeClassName={classes.activeLink}
                component={NavLink}
                to="/expenses-and-incomes/expenses"
              >
                Expenses
              </Button>
            </div>
            <Switch>
              <Route path="/expenses-and-incomes/expenses">
                <ExpensesTable
                  apiQueryState={apiQueryState}
                  setApiQueryState={setApiQueryState}
                />
              </Route>
              <Route path="/expenses-and-incomes">
                <IncomesTable
                  apiQueryState={apiQueryState}
                  setApiQueryState={setApiQueryState}
                />
              </Route>
            </Switch>
          </Grid>
          <Grid item xs={12} md={4}>
            <IncomeCategories
              apiQueryState={apiQueryState}
              setApiQueryState={setApiQueryState}
            />
            <ExpenseCategories
              apiQueryState={apiQueryState}
              setApiQueryState={setApiQueryState}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
