import { Button, Menu, MenuItem } from "@material-ui/core";
import { KeyboardArrowDown, Print } from "@material-ui/icons";
import React, { Component } from "react";
import writeXlsxFile from "write-excel-file";

// const schema = [
//   // Column #1
//   {
//     column: "Name",
//     type: String,
//     value: (student) => student.name,
//     borderColor: "#4446677",
//     borderStyle: "thin",
//   },
//   // Column #2
//   {
//     column: "Date of Birth",
//     type: Date,
//     format: "mm/dd/yyyy",
//     value: (student) => student.dateOfBirth,
//   },
//   // Column #3
//   {
//     column: "Cost",
//     type: Number,
//     format: "#,##0.00",
//     value: (student) => student.cost,
//   },
//   // Column #4
//   {
//     column: "Paid",
//     type: Boolean,
//     value: (student) => student.paid,
//   },
// ];

// const objects = [
//   // Object #1
//   {
//     name: "John Smith",
//     dateOfBirth: new Date(),
//     cost: 1800,
//     paid: true,
//   },
//   // Object #2
//   {
//     name: "Alice Brown",
//     dateOfBirth: new Date(),
//     cost: 2600,
//     paid: false,
//   },
// ];

// const data = [
//   // Row #1
//   [
//     // Column #1
//     {
//       value: "Name",
//       fontWeight: "bold",
//     },
//     // Column #2
//     {
//       value: "Date of Birth",
//       fontWeight: "bold",
//     },
//     // Column #3
//     {
//       value: "Cost",
//       fontWeight: "bold",
//     },
//     // Column #4
//     {
//       value: "Paid",
//       fontWeight: "bold",
//     },
//   ],
//   // Row #2
//   [
//     // Column #1
//     {
//       // `type` is optional
//       type: String,
//       value: "John Smith",
//     },
//     // Column #2
//     {
//       // `type` is optional
//       type: Date,
//       value: new Date(),
//       format: "mm/dd/yyyy",
//     },
//     // Column #3
//     {
//       // `type` is optional
//       type: Number,
//       value: 1800,
//     },
//     // Column #4
//     {
//       // `type` is optional
//       type: Boolean,
//       value: true,
//     },
//   ],
//   // Row #3
//   [
//     // Column #1
//     {
//       // `type` is optional
//       type: String,
//       value: "Alice Brown",
//     },
//     // Column #2
//     {
//       // `type` is optional
//       type: Date,
//       value: new Date(),
//       format: "mm/dd/yyyy",
//     },
//     // Column #3
//     {
//       // `type` is optional
//       type: Number,
//       value: 2600,
//     },
//     // Column #4
//     {
//       // `type` is optional
//       type: Boolean,
//       value: false,
//     },
//   ],
// ];

// const columns = [
//   {
//     width: 20,
//   },
//   {
//     width: 14,
//   },
//   {
//     width: 12,
//   },
//   { width: 10 },
// ];

export default class TableExportMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  // open the export meny
  onOpenExportMenu = (e) => {
    this.setState({ ...this.state, anchorEl: e.target });
  };

  // close export menu
  onCloseExportMenu = () => this.setState({ ...this.state, anchorEl: null });

  downloadSheet = (e) => {
    e.preventDefault();
    // When passing `data` for each cell.
    writeXlsxFile(this.props.data, {
      fileName: `${this.props.title || "export"}.xlsx`,
      sheet: this.props.sheet || "Sheet",
      schema: this.props.schema,
    })
      .then((res) => {})
      .catch((error) => {});
  };
  render() {
    return (
      <div>
        <Button
          onClick={this.onOpenExportMenu}
          className="btn me-1"
          endIcon={<KeyboardArrowDown />}
          size="small"
          variant="contained"
        >
          Export
        </Button>
        <Menu
          onClose={this.onCloseExportMenu}
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          className="mt-5"
        >
          <MenuItem
            component={Button}
            size="small"
            fullWidth
            className="w-100"
            endIcon={<Print />}
          >
            Print
          </MenuItem>
          <MenuItem
            fullWidth
            size="small"
            className="w-100"
            onClick={this.downloadSheet}
            component={Button}
            endIcon={<i className="bi bi-filetype-xls"></i>}
          >
            Excel
          </MenuItem>
        </Menu>
      </div>
    );
  }
}
