import {
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from "@material-ui/core";
import { formatNumber } from "accounting-js";
import clsx from "clsx";
import React from "react";
import { Pagination } from "rsuite";
import ApiRequest from "../../../api/ApiRequest";
import { BNK_API_GET_EXPENSES } from "../../../api/apiThreads";
import { showDate } from "../../../global/hooks";
import { ReadTransactionType } from "../Transactions/TransactionsHistory";
import { tableStyles } from "../Transactions/TransactionsTable";

export default function ExpensesTable(props) {
  const classes = tableStyles();
  const { apiQueryState, setApiQueryState } = props;

  return (
    <Paper className={clsx("p-3")} variant="outlined">
      <ApiRequest
        addOnThread={JSON.stringify(apiQueryState)}
        autoload
        thread={BNK_API_GET_EXPENSES}
        payload={apiQueryState}
      >
        {({
          loading,
          ErrorView,
          res,
          RefreshButton,
          refreshing,
          refresh,
          error,
        }) => {
          let expenses = [];

          if (loading) {
            return (
              <div className="w-100 p-5" align="center">
                <CircularProgress size="50px" />
              </div>
            );
          }

          if (error) {
            return (
              <div className="w-100 p-5" align="center">
                <ErrorView />
              </div>
            );
          }

          let total = 0;
          if (typeof res === "object") {
            if (res.success === true) {
              if (typeof res.data == "object") {
                if (Array.isArray(res.data.expenses)) {
                  expenses = res.data.expenses;
                  total = res.data.total;
                }
              }
            }
          }

          return (
            <React.Fragment>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <Typography className={classes.title}>
                    {props.title || "Expenses"}
                  </Typography>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <RefreshButton variant="icon" />
                </div>
              </div>
              <div className={classes.tableWrapper}>
                <table className={classes.table}>
                  <thead className={classes.tableHeading}>
                    <tr>
                      <th className={classes.col2}>No.</th>
                      <th>Trans Id</th>
                      <th>Date</th>
                      <th>Branch</th>
                      <th>Paid to</th>
                      <th>Memo</th>
                      <th>Category</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody className={classes.tbody}>
                    {loading === true ? (
                      <div className="w-100 my-2" align="center">
                        <CircularProgress />
                      </div>
                    ) : (
                      ""
                    )}

                    {expenses.length === 0 ? (
                      <tr className="w-100 my-4" align="center">
                        <td className="w-100" colSpan={7} align="center">
                          <Typography>
                            <b>No expenses recorded yet</b>
                          </Typography>
                        </td>
                        <td></td>
                      </tr>
                    ) : (
                      expenses.map((expense, index) => {
                        return (
                          <tr key={index}>
                            <td>{++index}</td>
                            <td>{expense.transaction_id || "N/A"}</td>
                            <td>{showDate(expense.date)}</td>
                            <td>{expense.branch}</td>
                            <td>{expense.paid_to}</td>
                            <td>
                              {expense.memo.length > 50
                                ? expense.memo.slice(0, 50) + "..."
                                : expense.memo}
                            </td>
                            <td>
                              <ReadTransactionType
                                type={expense.expense_category}
                              />
                            </td>
                            <td>UGX {formatNumber(expense.amount)}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                <Divider />
                <Pagination
                  layout={["total", "-", "limit", "|", "pager", "skip"]}
                  // size="md"
                  prev={true}
                  next={true}
                  first={true}
                  last={true}
                  ellipsis={true}
                  boundaryLinks={true}
                  total={total}
                  limit={apiQueryState.limit}
                  limitOptions={[10, 20, 50, 100, 200, 500]}
                  maxButtons={5}
                  activePage={apiQueryState.page}
                  onChangePage={(page) =>
                    setApiQueryState({ ...apiQueryState, page })
                  }
                  onChangeLimit={(limit) =>
                    setApiQueryState({ ...apiQueryState, limit, page: 1 })
                  }
                />
              </div>
            </React.Fragment>
          );
        }}
      </ApiRequest>
    </Paper>
  );
}
