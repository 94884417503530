import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Add, ArrowForward, Close, CloudUpload } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { Message, toaster, Uploader } from "rsuite";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_CHECKING_ACCOUNT_GET_TRANSACTIONS,
  BNK_API_CHECKING_ACCOUNT_RECORD_BNK_CHARGES,
  BNK_API_CHECKING_ACCOUNT_RECORD_DEPOSIT,
  BNK_API_CHECKING_ACCOUNT_RECORD_WITHDRAW,
} from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";
import { SelectBranchMenu } from "../Transactions/TransactionsTable";
const useStyles = makeStyles((theme) => ({
  selectInput: {
    width: "100%",
    height: "35px",
    borderRadius: "5px",
  },
  btn: {
    borderRadius: "20px",
  },
}));

export default function RecordCATransactionButton(props) {
  const [dialogState, setDialogState] = useState();
  const [fileList, setFileList] = useState([]);
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
  };
  const classes = useStyles();
  let thread;
  if (props.type === "DEPOSIT") {
    thread = BNK_API_CHECKING_ACCOUNT_RECORD_DEPOSIT;
  } else if (props.type === "WITHDRAW") {
    thread = BNK_API_CHECKING_ACCOUNT_RECORD_WITHDRAW;
  } else if (props.type === "BNK_CHARGES") {
    thread = BNK_API_CHECKING_ACCOUNT_RECORD_BNK_CHARGES;
  } else {
  }

  const onFileInputChange = (setPayload) => (files) => {
    setFileList([files[files.length - 1]]);
    setPayload({ receipt_image: files[files.length - 1].blobFile });
  };
  return (
    <React.Fragment>
      <Button
        onClick={onDialogStateChange}
        startIcon={<Add />}
        variant="outlined"
        className={classes.btn}
      >
        {props.title || "Record Transaction"}
      </Button>
      <Dialog fullWidth maxWidth="sm" open={dialogState}>
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center w-100">
            <Typography variant="h5">
              Record {props.title || "Transaction"}
            </Typography>
            <IconButton onClick={onDialogStateChange}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <ApiRequest
            thread={thread}
            isForm
            withFileUpload={true}
            callbacks={{
              success: (res) => {
                if (typeof res == "object") {
                  if (typeof res.data == "object") {
                    if (res.data.recorded === true) {
                      setDialogState(false);
                    }
                  }
                }
              },
            }}
            reloadOnSuccess={[BNK_API_CHECKING_ACCOUNT_GET_TRANSACTIONS]}
          >
            {({
              res,
              loading,
              payload,
              input,
              setPayload,
              SubmitButton,
              FileInput,
            }) => {
              return (
                <div>
                  <SelectBranchMenu
                    isSelect={true}
                    setPayload={setPayload}
                    payload={payload}
                    input={input}
                  />
                  <KijjeInput
                    square
                    variant="filled"
                    placeholder="Enter account number"
                    label="Account Number"
                    {...input({
                      name: "account_number",
                    })}
                  />
                  <KijjeInput
                    square
                    variant="filled"
                    placeholder="Enter account name"
                    label="Account Name"
                    {...input({
                      name: "account_name",
                    })}
                  />
                  <KijjeInput
                    square
                    variant="filled"
                    placeholder="Enter amount"
                    label="Amount"
                    {...input({
                      name: "amount",
                    })}
                  />
                  <KijjeInput
                    square
                    variant="filled"
                    placeholder="Enter date"
                    label="Transaction date"
                    {...input({
                      name: "transaction_date",
                      type: "date",
                    })}
                  />
                  <KijjeInput
                    square
                    variant="filled"
                    placeholder=""
                    label="Transaction By"
                    {...input({
                      name: "transaction_by",
                    })}
                  />
                  <KijjeInput
                    square
                    variant="filled"
                    placeholder="Enter transaction id"
                    label="Transaction id Or Slip No."
                    {...input({
                      name: "transaction_id",
                    })}
                  />
                  <KijjeInput
                    square
                    variant="filled"
                    placeholder=""
                    label="Reason"
                    {...input({
                      name: "reason",
                    })}
                  />
                  <Uploader
                    multiple={false}
                    action="'"
                    listType="picture-text"
                    accept="image/jpeg,image/*"
                    autoUpload={false}
                    fileList={fileList}
                    onChange={onFileInputChange(setPayload)}
                  >
                    <Button
                      variant="outlined"
                      startIcon={<CloudUpload />}
                      fullWidth
                    >
                      Select transaction receipt image
                    </Button>
                  </Uploader>
                  <div align="right">
                    <SubmitButton
                      className="mt-2"
                      size="small"
                      color="primary"
                      variant="contained"
                      endIcon={<ArrowForward />}
                    >
                      Record Transaction
                    </SubmitButton>
                  </div>
                </div>
              );
            }}
          </ApiRequest>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
