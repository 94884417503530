import {
  Button,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Switch,
  TextField,
  Typography,
  Paper,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { blueGrey, green, grey, orange } from "@material-ui/core/colors";
import {
  ArrowForward,
  Check,
  Close,
  Info,
  Money,
  Person,
  Phone,
  Print,
  Receipt,
  Refresh,
} from "@material-ui/icons";
import accounting, { formatNumber } from "accounting-js";
import clsx from "clsx";
import React from "react";
import { Helmet } from "react-helmet";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_GET_TRANSACTION_STATISTICS,
  BNK_API_MAKE_WITHDRAW,
} from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";
import FindAccount from "../Accounts/FindAccount";
import { PrintReceiptDialog } from "../Deposits";
import TransactionsTable, {
  SelectBranchMenu,
} from "../Transactions/TransactionsTable";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    boxShadow: theme.boxShadows[4],
    minHeight: "100%",
    borderRadius: "10px",
  },
  findAccount: {
    borderTop: "solid 5px " + theme.palette.primary.main,
    minHeight: "100%",
  },
  iconButton: {
    width: "30px",
    height: "30px",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: "1rem",
    ["&:hover"]: {
      color: theme.palette.primary.main,
    },
  },
  transactionComplete: {
    width: "100%",
    height: "100%",
    backgroundColor: orange[50],
    display: "flex",
    justifyContent: "center",
    borderRadius: "10px",
    alignItems: "center",
  },
}));

export default function Withdraws(props) {
  const classes = useStyles();
  return (
    <Grid container spacing={2} justify="center" className={clsx(classes.root)}>
      <Helmet>
        <title>Withdraws</title>
      </Helmet>
      <Grid item xs={12}>
        <div>
          <Typography variant="h6">Withdraws</Typography>
        </div>
        <Divider className="my-2" />
      </Grid>
      <GetWithdrawStatistics type="withdraw" />
      <Grid item xs={12} md={8}>
        <FindAccount
          transaction_type="DEPOSIT"
          title="Make Withdraw"
          className={classes.findAccount}
        >
          {({ account, onReset }) => {
            return (
              <ApiRequest
                thread={BNK_API_MAKE_WITHDRAW}
                initialPayload={{
                  amount: "",
                  slip_no: "",
                  early_entry: false,
                  account_name: account.account_name,
                  account_number: account.account_number,
                }}
              >
                {({
                  input,
                  loading,
                  error,
                  ErrorView,
                  SubmitButton,
                  completed,
                  payload,
                  setPayload,
                  res,
                  resetComplete,
                }) => {
                  if (typeof res == "object") {
                    if (typeof res.data == "object") {
                      if (typeof res.data.withdraw && completed) {
                        let withdraw = res.data.withdraw;
                        return (
                          <div
                            className={clsx(
                              classes.transactionComplete,
                              "flex-column pb-3"
                            )}
                          >
                            <Check
                              style={{ fontSize: "50px", color: green[600] }}
                            />
                            <Typography>Transaction Recorded</Typography>
                            <Typography variant="h6">
                              Withdrawn{" "}
                              {accounting.formatNumber(payload.amount, 2)}
                            </Typography>{" "}
                            <div>
                              <Typography variant="h4">
                                Balance <small>Shs: </small>
                                {accounting.formatNumber(withdraw.balance, 2)}
                              </Typography>
                            </div>
                            <div>
                              <Button
                                onClick={onReset}
                                variant="outlined"
                                endIcon={<Close />}
                                size="small"
                              >
                                Close
                              </Button>
                              .
                              <PrintReceiptDialog
                                variant="outlined"
                                endIcon={<Print />}
                                size="small"
                                transaction_id={withdraw.id}
                                branch_id={withdraw.branch_id}
                              >
                                Print Receipt
                              </PrintReceiptDialog>
                            </div>
                          </div>
                        );
                      }
                    }
                  }
                  return (
                    <React.Fragment>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <KijjeInput
                            variant="outlined"
                            square
                            {...input({ name: "name" })}
                            label="Received by"
                            startIcon={<Person />}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <KijjeInput
                            variant="outlined"
                            square
                            startIcon={<Phone />}
                            {...input({ name: "phone_number" })}
                            label="Client's Phone Number"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs={12} sm={6} md={4}>
                          <KijjeInput
                            variant="outlined"
                            square
                            startIcon={<Receipt />}
                            {...input({ name: "slip_no" })}
                            label="Withdraw Slip No. "
                          />
                        </Grid>
                        <Grid item xs={5} md={4}>
                          <KijjeInput
                            variant="outlined"
                            startIcon={<Money />}
                            type="text"
                            square
                            {...input({
                              name: "amount",
                              type: "text",
                            })}
                            label="Amount"
                          />
                        </Grid>
                        <Grid item xs={7} md={4}>
                          <Typography variant="h4" className="mt-4">
                            <small>Shs: </small>
                            {accounting.formatNumber(payload.amount, 2)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider
                        className="my-2"
                        style={{ backgroundColor: "red" }}
                      />
                      <div>
                        <List dense disablePadding className="p-0">
                          <ListItem
                            className="p-0"
                            button
                            onClick={() =>
                              setPayload({ early_entry: !payload.early_entry })
                            }
                          >
                            <ListItemText>
                              Record as entry transaction
                            </ListItemText>
                            <ListItemSecondaryAction className="p-0 me-0">
                              <div className="ms-3" align="right">
                                <Switch
                                  size="small"
                                  onClick={() =>
                                    setPayload({
                                      early_entry: !payload.early_entry,
                                    })
                                  }
                                  checked={payload.early_entry}
                                />
                              </div>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Collapse in={payload.early_entry}>
                            <TextField
                              {...input({ name: "created_at" })}
                              size="small"
                              type="date"
                              fullWidth
                              label="Withdraw date"
                            />
                          </Collapse>
                        </List>
                      </div>
                      <Divider
                        className="my-2"
                        style={{ backgroundColor: "red" }}
                      />
                      <div className="w-100 mt-2" align="right">
                        <Button
                          startIcon={<Close />}
                          onClick={onReset}
                          variant="outlined"
                          className="mx-2"
                        >
                          Close
                        </Button>
                        <SubmitButton
                          variant="contained"
                          endIcon={<ArrowForward />}
                        >
                          Withdraw
                        </SubmitButton>
                      </div>
                    </React.Fragment>
                  );
                }}
              </ApiRequest>
            );
          }}
        </FindAccount>
      </Grid>
      <Grid item xs={12}>
        <TransactionsTable
          title="Withdraw History"
          addOnThread="withdraws"
          show="withdraw|type|amount"
          where="t.withdraw"
          // hide={["balance"]}
        />
      </Grid>
    </Grid>
  );
}

export function GetWithdrawStatistics(props) {
  const classes = useStyles();
  const [apiQueryState, setApiQueryState] = React.useState({
    where: "t." + props.type,
    branch: "ALL",
  });

  return (
    <Grid item xs={12} md={4}>
      <Paper className={clsx(classes.paper, "p-3")} style={{ height: "100%" }}>
        <ApiRequest
          autoload
          thread={BNK_API_GET_TRANSACTION_STATISTICS}
          addOnThread={JSON.stringify(apiQueryState)}
          query={apiQueryState}
        >
          {({
            loading,
            error,
            ErrorView,
            res,
            refresh,
            refreshing,
            RefreshButton,
          }) => {
            if (loading === true) {
              return (
                <div className="w-100 my-5" align="center">
                  <CircularProgress />
                </div>
              );
            }
            if (error === true) {
              return <ErrorView />;
            }

            let statistics = {};
            let total_count,
              today_count,
              today_cumulative_total,
              cumulative_total = "0";
            let total_loan_count = 0;
            let total_amount_in_loans = 0;
            let total_amount_in_loans_count = 0;
            let total_paid_back_amount_in_loans = 0;
            let total_paid_back_amount_in_loans_count = 0;
            let total_paid_back_amount_in_loans_count_today = 0;
            let total_paid_back_amount_in_loans_today = 0;

            if (typeof res == "object") {
              if (typeof res.data === "object") {
                if (typeof res.data.statistics === "object") {
                  statistics = res.data.statistics;
                  try {
                    if (props.type !== "loan payment") {
                      total_count =
                        statistics[`${props.type}_summary`].total_count;
                      cumulative_total =
                        statistics[`${props.type}_summary`].cumulative_total;
                      today_cumulative_total =
                        statistics[`${props.type}_summary_today`]
                          .cumulative_total;
                      today_count =
                        statistics[`${props.type}_summary_today`].total_count;
                    }

                    // check of the loan payment is specified
                    total_amount_in_loans =
                      statistics.total_amount_in_loans.cumulative_total;

                    total_amount_in_loans_count =
                      statistics.total_amount_in_loans.total_count;

                    total_paid_back_amount_in_loans =
                      statistics.loan_payment_summary.cumulative_total;
                    total_paid_back_amount_in_loans_count =
                      statistics.loan_payment_summary.total_count;

                    total_paid_back_amount_in_loans_today =
                      statistics.loan_payment_summary_today.cumulative_total;
                    total_paid_back_amount_in_loans_count_today =
                      statistics.loan_payment_summary_today.total_count;
                  } catch (error) {
                    console.log("Error: ", error);
                  }
                }
              }
            }

            if (props.type === "loan payment") {
              return (
                <React.Fragment>
                  <div className="d-flex justify-content-between align-items-center">
                    <Typography
                      className=""
                      style={{
                        fontWeight: 500,
                        color: blueGrey[500],
                        fontSize: "0.9rem",
                      }}
                    >
                      Total Amt. Issued + Interest
                    </Typography>
                    <div className="d-flex justify-content-center align-items-center">
                      <SelectBranchMenu
                        apiQueryState={apiQueryState}
                        setApiQueryState={setApiQueryState}
                      />
                      <RefreshButton variant="icon" />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="pr-3">
                      <div className="d-flex align-items-start flex-column">
                        <Typography variant="h1">
                          {total_amount_in_loans_count || "0"}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "0.8rem",
                            color: grey[500],
                            fontWeight: 500,
                          }}
                        >
                          Loans Issued
                        </Typography>
                      </div>
                    </div>
                    <div align="right">
                      <Typography variant="h3">
                        {formatNumber(total_amount_in_loans || 0)}
                        <span style={{ color: grey[500], fontSize: "0.8rem" }}>
                          UGX
                        </span>
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "0.8rem",
                          color: grey[500],
                          fontWeight: 500,
                          lineHeight: "0.8rem",
                        }}
                      >
                        Cumulative Total
                      </Typography>
                    </div>
                  </div>
                  <Divider className="my-3" />
                  <Typography
                    className="mb-2"
                    style={{
                      fontWeight: 500,
                      color: blueGrey[500],
                      fontSize: "0.9rem",
                    }}
                  >
                    Total Received
                  </Typography>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="pr-3">
                      <div className="d-flex align-items-start flex-column">
                        <Typography variant="h1">
                          {total_paid_back_amount_in_loans_count || "0"}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "0.8rem",
                            color: grey[500],
                            fontWeight: 500,
                          }}
                        >
                          Transactions
                        </Typography>
                      </div>
                    </div>
                    <div align="right">
                      <Typography variant="h3">
                        {formatNumber(total_paid_back_amount_in_loans || 0)}
                        <span style={{ color: grey[500], fontSize: "0.8rem" }}>
                          UGX
                        </span>
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "0.8rem",
                          color: grey[500],
                          fontWeight: 500,
                          lineHeight: "0.8rem",
                        }}
                      >
                        Cumulative Total
                      </Typography>
                    </div>
                  </div>
                  <Divider className="my-3" />
                  <Typography
                    className="mb-2"
                    style={{
                      fontWeight: 500,
                      color: blueGrey[500],
                      fontSize: "0.9rem",
                    }}
                  >
                    Amount Collected Today
                  </Typography>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="pr-3">
                      <div className="d-flex align-items-start flex-column">
                        <Typography variant="h1">
                          {total_paid_back_amount_in_loans_count_today || "0"}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "0.8rem",
                            color: grey[500],
                            fontWeight: 500,
                          }}
                        >
                          Transactions
                        </Typography>
                      </div>
                    </div>
                    <div align="right">
                      <Typography variant="h3">
                        {formatNumber(
                          total_paid_back_amount_in_loans_today || 0
                        )}
                        <span style={{ color: grey[500], fontSize: "0.8rem" }}>
                          UGX
                        </span>
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "0.8rem",
                          color: grey[500],
                          fontWeight: 500,
                          lineHeight: "0.8rem",
                        }}
                      >
                        Cumulative Total
                      </Typography>
                    </div>
                  </div>
                </React.Fragment>
              );
            }

            return (
              <React.Fragment>
                <div className="d-flex justify-content-between align-items-center">
                  <Typography
                    className=""
                    style={{
                      fontWeight: 500,
                      color: blueGrey[500],
                      fontSize: "0.9rem",
                    }}
                  >
                    All {props.type} Summary
                  </Typography>
                  <div className="d-flex justify-content-center align-items-center">
                    <SelectBranchMenu
                      apiQueryState={apiQueryState}
                      setApiQueryState={setApiQueryState}
                    />
                    <RefreshButton variant="icon" />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="pr-3">
                    <div className="d-flex align-items-start flex-column">
                      <Typography variant="h1">{total_count || "0"}</Typography>
                      <Typography
                        style={{
                          fontSize: "0.8rem",
                          color: grey[500],
                          fontWeight: 500,
                        }}
                      >
                        Total {props.type}s
                      </Typography>
                    </div>
                  </div>
                  <div align="right">
                    <Typography variant="h3">
                      {formatNumber(cumulative_total)}
                      <span style={{ color: grey[500], fontSize: "0.8rem" }}>
                        UGX
                      </span>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "0.8rem",
                        color: grey[500],
                        fontWeight: 500,
                        lineHeight: "0.8rem",
                      }}
                    >
                      Cumulative Total
                    </Typography>
                  </div>
                </div>

                <Divider className="my-3" />
                <Typography
                  className="mb-2"
                  style={{
                    fontWeight: 500,
                    color: blueGrey[500],
                    fontSize: "0.9rem",
                  }}
                >
                  Today's {props.type} summary
                </Typography>

                <div className="d-flex justify-content-between align-items-end">
                  <div className="pr-3">
                    <div className="d-flex align-items-start flex-column">
                      <Typography variant="h2">{today_count || 0}</Typography>
                      <Typography
                        style={{
                          fontSize: "0.8rem",
                          color: grey[500],
                          fontWeight: 500,
                        }}
                      >
                        Total {props.type}s
                      </Typography>
                    </div>
                  </div>
                  <div align="right">
                    <Typography variant="h4">
                      {formatNumber(today_cumulative_total || 0)}
                      <span style={{ color: grey[500], fontSize: "0.7rem" }}>
                        UGX
                      </span>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "0.8rem",
                        color: grey[500],
                        fontWeight: 500,
                      }}
                    >
                      Cumulative Total
                    </Typography>
                  </div>
                </div>
              </React.Fragment>
            );
          }}
        </ApiRequest>
      </Paper>
    </Grid>
  );
}
