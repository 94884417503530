import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close, GpsFixed } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useState } from "react";
import { Divider, Progress } from "rsuite";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_CORRECT_LOAN_ISSUES,
  BNK_API_GET_LOANS_FOR_CORRECTIONS,
} from "../../../api/apiThreads";
import MicrosoftLoader from "../../../global/components/loader/MicrsoftLoader";

export default function CorrectLoanIssues(props) {
  const [dialogState, setDialogState] = useState(false);
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
  };
  const [completed, setCompleted] = useState([]);
  const [loanIndex, setLoanIndex] = useState(0);

  return (
    <React.Fragment>
      <Button
        onClick={onDialogStateChange}
        size="small"
        variant="contained"
        className="rounded-btn"
      >
        Perform Corrections
      </Button>
      <Dialog open={dialogState}>
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <Typography>Perform Loan Corrections</Typography>
            <IconButton onClick={onDialogStateChange}>
              <Close />
            </IconButton>
          </div>
          <Divider className="my-0" />
        </DialogTitle>
        <DialogContent>
          <ApiRequest thread={BNK_API_GET_LOANS_FOR_CORRECTIONS}>
            {({ SubmitButton, res, payload, setPayload }) => {
              if (typeof res === "object") {
                if (typeof res.data === "object") {
                  if (Array.isArray(res.data.loans)) {
                    const loans = res.data.loans;

                    if (loans.length === 0) {
                      return (
                        <Typography>
                          No loans to perform loan corrections.
                        </Typography>
                      );
                    }

                    if (loanIndex - 1 === loans.length) {
                      return (
                        <Alert>
                          <AlertTitle>Corrections Completed</AlertTitle>
                          <Typography>
                            Error correction has been completed. Thank you.
                          </Typography>
                        </Alert>
                      );
                    }

                    return (
                      <div>
                        <Typography>Making loan corrections</Typography>
                        <div>
                          <small>
                            This action is only performed when you need to
                            correct issues with loans, this might have been
                            caused by slow network and other issues.
                          </small>
                        </div>
                        <div className="d-flex my-2 align-items-center">
                          <div>
                            <div style={{ width: "70px" }}>
                              <Progress.Circle
                                percent={Math.round(
                                  (completed.length / loans.length) * 100
                                )}
                                status="active"
                                strokeColor="#ffc107"
                              />
                            </div>
                          </div>
                          <div className="ms-2">
                            {loanIndex < loans.length ? (
                              <ApiRequest
                                autoload
                                thread={BNK_API_CORRECT_LOAN_ISSUES}
                                addOnThread={
                                  loans[loanIndex].application_id + loanIndex
                                }
                                payload={{
                                  application_id:
                                    loans[loanIndex].application_id,
                                }}
                                callbacks={{
                                  success: (res, xthis) => {
                                    if (typeof res == "object") {
                                      if (typeof res.data == "object") {
                                        if (res.data.corrected === true) {
                                          setLoanIndex(loanIndex + 1);
                                          setCompleted([
                                            ...completed,
                                            loans[loanIndex].application_id,
                                          ]);
                                        }
                                      }
                                    }
                                  },
                                }}
                              ></ApiRequest>
                            ) : loans.length === loanIndex - 1 ? (
                              <Alert>
                                <AlertTitle>Corrections Completed</AlertTitle>
                                <Typography>
                                  Error correction has been completed. Thank
                                  you.
                                </Typography>
                              </Alert>
                            ) : (
                              ""
                            )}
                            <Typography>
                              {completed.length}/{loans.length}{" "}
                              <small>Loans cleared</small>
                            </Typography>
                            <MicrosoftLoader />
                          </div>
                        </div>
                      </div>
                    );
                  }
                }
              }
              return (
                <div>
                  <div>
                    This action is only performed when you need to correct
                    issues with loans, this might have been caused by slow
                    network and other issues.
                  </div>
                  <SubmitButton variant="outlined" size="small">
                    Correct Issues
                  </SubmitButton>
                </div>
              );
            }}
          </ApiRequest>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export function CorrectSingleLoanIssues(props) {
  return (
    <ApiRequest
      autoload={false}
      thread={BNK_API_CORRECT_LOAN_ISSUES}
      addOnThread={Date.now().toString()}
      payload={{
        application_id: props.application_id,
      }}
      callbacks={{
        success: (res, xthis) => {
          if (typeof res == "object") {
            if (typeof res.data == "object") {
              if (res.data.corrected === true) {
                console.log("Loan corrected");
              }
            }
          }
        },
      }}
    >
      {({ loading, SubmitButton }) => {
        return <SubmitButton endIcon={<GpsFixed />}></SubmitButton>;
      }}
    </ApiRequest>
  );
}
