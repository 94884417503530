/* eslint-disable eqeqeq */
import {
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Menu,
  Paper,
  Typography,
} from "@material-ui/core";
import { blue, green, orange, purple, red } from "@material-ui/core/colors";
import {
  Check,
  Close,
  EmojiEmotions,
  ErrorOutline,
  Info,
  MoreHoriz,
  MoreVert,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { formatNumber } from "accounting-js";
import clsx from "clsx";
import React, { useState } from "react";
import {
  DatePicker,
  Dropdown,
  Pagination,
  Radio,
  Tooltip,
  Whisper,
} from "rsuite";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_GET_ALL_LOANS,
  BNK_API_GET_LOAN_BALANCE,
} from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";
import TableExportMenu from "../Accounts/TableExportMenu";
import { showTransactionDate } from "../Transactions/TransactionsHistory";
import {
  SelectBranchMenu,
  tableStyles,
} from "../Transactions/TransactionsTable";
import ChangeLoanStatusButton from "./ChangeLoanStatusButton";
import { CorrectSingleLoanIssues } from "./CorrectLoanIssues";
import { DeleteLoanButton } from "./DeleteLoan";
import EditApprovedLoan from "./EditApprovedLoan";
import RescheduleLoan from "./RescheduleLoan";
import UpdateLoanApplication from "./UpdateLoanApplication";
import ViewLoan from "./ViewLoan";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  chip: {
    "& *": {
      color: "#fff",
    },
  },
}));

export default function LoansTable(props) {
  const tableClasses = tableStyles();
  const classes = useStyles();
  const [apiQueryState, setApiQueryState] = useState({
    status: "ALL",
    branch: "ALL",
    limit: 10,
    page: 1,
    start_date: null,
    end_date: null,
    search_by: "ALL",
    // search_term: "",
  });

  return (
    <Paper className={clsx(classes.root, "my-2")} variant="outlined">
      <Grid container>
        <Grid item xs={12}>
          <ApiRequest
            autoload
            addOnThread={JSON.stringify(apiQueryState)}
            thread={BNK_API_GET_ALL_LOANS}
            query={apiQueryState}
          >
            {({
              loading,
              refresh,
              error,
              res,
              ErrorView,
              RefreshButton,
              input,
              submit,
            }) => {
              let loans = [];
              let total = 0;
              if (typeof res == "object") {
                if (typeof res.data == "object") {
                  if (Array.isArray(res.data.loans)) {
                    loans = res.data.loans;
                    total = res.data.total;
                  }
                }
              }

              return (
                <div className="w-100" style={{ overflowY: "auto" }}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <div className="d-flex">
                        <SelectBranchMenu
                          apiQueryState={apiQueryState}
                          setApiQueryState={setApiQueryState}
                        />

                        <Dropdown title="Select Status" eventKey={"ALL"}>
                          {[
                            "PENDING",
                            "IN_PROGRESS",
                            "COMPLETED",
                            "DECLINED",
                            "APPROVED",
                            "ALL",
                          ].map((status) => {
                            return (
                              <Dropdown.Item
                                onClick={() =>
                                  setApiQueryState({
                                    ...apiQueryState,
                                    status: status,
                                  })
                                }
                                icon={
                                  <Radio
                                    checked={apiQueryState.status == status}
                                  />
                                }
                                eventKey={status}
                              >
                                {status}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown>
                      </div>
                    </Grid>
                    <Grid item>
                      <KijjeInput
                        {...input({ name: "search_term" })}
                        variant="search"
                        onSearch={submit}
                      />
                    </Grid>
                    <Grid item>
                      <div className="d-flex justify-content-between align-items-center">
                        <div
                          className="d-flex"
                          style={{ width: "calc(100% - 40px)" }}
                        >
                          <DatePicker
                            onChange={(start_date) =>
                              setApiQueryState({ ...apiQueryState, start_date })
                            }
                            format="dd-MM-yyyy"
                            value={apiQueryState.start_date}
                            placeholder="Start Date"
                            ranges={[
                              {
                                label: "Now",
                                value: new Date(),
                              },
                            ]}
                            style={{ width: "50%" }}
                          />
                          <DatePicker
                            format="dd-MM-yyyy"
                            onChange={(end_date) =>
                              setApiQueryState({ ...apiQueryState, end_date })
                            }
                            value={apiQueryState.end_date}
                            placeholder="End Date"
                            ranges={[
                              {
                                label: "Now",
                                value: new Date(),
                              },
                            ]}
                            style={{ width: "50%" }}
                          />
                        </div>
                        <TableExportMenu
                          data={loans.map((row) => [
                            {
                              ...row,
                              transaction_id: row.transaction_id || 0,
                              slip_number: row.slip_number || 0,
                            },
                          ])}
                          schema={[
                            {
                              column: "FI/Date",
                              type: Date,
                              format: "dd/mm/yyyy",
                              value: (account) =>
                                new Date(account[0].first_installment_date),
                              width: 17,
                            },
                            // {
                            //   column: "Slip No.",
                            //   type: String,
                            //   value: (account) => account[0].slip_number,
                            //   width: 10,
                            // },
                            // {
                            //   column: "Transaction Id",
                            //   type: String,
                            //   value: (account) => account[0].transaction_id,
                            //   width: 20,
                            // },
                            {
                              column: "Account Number",
                              type: String,
                              value: (loan) =>
                                loan[0].account_number.toString(),
                              width: 20,
                            },
                            {
                              column: "Application Id",
                              type: String,
                              value: (loan) =>
                                loan[0].account_number.toString(),
                              width: 20,
                            },
                            {
                              column: "Account Name",
                              type: String,
                              value: (loan) => loan[0].account_name,
                              width: 25,
                            },
                            {
                              column: "Loan Amount",
                              type: Number,
                              value: (loan) => loan[0].loan_amount,
                              width: 20,
                            },
                            // {
                            //   column: "Loan Balance",
                            //   type: Number,
                            //   value: (loan) => formatNumber(loan[0].loan_amount),
                            //   width: 20,
                            // },
                            {
                              column: "Status",
                              type: String,
                              value: (loan) => loan[0].status,
                              width: 20,
                            },
                            {
                              column: "Branch",
                              type: String,
                              value: (account) => account[0].branch,
                              width: 20,
                            },
                          ]}
                        />
                        <RefreshButton variant="icon" />
                      </div>
                    </Grid>
                  </Grid>
                  <table className={tableClasses.table}>
                    <thead className={tableClasses.tableHeading}>
                      <tr>
                        <th style={{ width: "30px" }}>No.</th>
                        <th>Branch</th>
                        <th>Borrower</th>
                        <th>LA/Id</th>
                        <th>Ac/No</th>
                        <th>Amount</th>
                        <th>L/Bal</th>
                        <th>
                          <Whisper
                            trigger="click"
                            placement="topEnd"
                            speaker={<Tooltip>Amount Per installment</Tooltip>}
                          >
                            <span>Amt/I </span>
                          </Whisper>
                        </th>
                        <th>
                          <Whisper
                            trigger="click"
                            placement="topEnd"
                            speaker={<Tooltip>First Installment Date</Tooltip>}
                          >
                            <span>F/I Date</span>
                          </Whisper>
                        </th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    {loading == true ? <CircularProgress size="50px" /> : ""}
                    {error == true ? <ErrorView /> : ""}

                    {loans.length > 0 && loading == false && error == false ? (
                      <tbody className={tableClasses.tbody}>
                        {loans.map((loan, index) => {
                          return (
                            <tr>
                              <td>
                                {++index +
                                  apiQueryState.page * apiQueryState.limit -
                                  apiQueryState.limit}
                              </td>
                              <td>{loan.branch}</td>
                              <td>{loan.account_name}</td>
                              <td>{loan.application_id}</td>
                              <td>{loan.account_number}</td>
                              <td>{formatNumber(loan.loan_amount)} Ugx</td>
                              <td>
                                <ApiRequest
                                  addOnThread={loan.application_id}
                                  thread={BNK_API_GET_LOAN_BALANCE}
                                  payload={{
                                    application_id: loan.application_id,
                                  }}
                                  autoload
                                >
                                  {({
                                    res,
                                    loading,
                                    RefreshButton,
                                    submit,
                                  }) => {
                                    if (loading) {
                                      return <CircularProgress size={20} />;
                                    }

                                    if (typeof res == "object") {
                                      if (typeof res.data == "object") {
                                        if (
                                          typeof res.data.balance !==
                                          "undefined"
                                        ) {
                                          return formatNumber(res.data.balance);
                                        }
                                      }
                                    }

                                    return (
                                      <IconButton onClick={submit()}>
                                        <MoreHoriz />
                                      </IconButton>
                                    );
                                  }}
                                </ApiRequest>{" "}
                                Ugx
                              </td>
                              <td>
                                {formatNumber(loan.amount_per_installment)} Ugx
                              </td>
                              <td>
                                {showTransactionDate(
                                  loan.first_installment_date
                                )}
                              </td>
                              <td>
                                <StatusChip loan={loan} />
                              </td>
                              <td>
                                <div className="d-flex align-items-center justify-content-end w-100">
                                  <ChangeLoanStatusButton
                                    {...loan}
                                    onChange={refresh}
                                  />
                                  <MoreActionsButton
                                    reloadThread={
                                      BNK_API_GET_ALL_LOANS +
                                      JSON.stringify(apiQueryState)
                                    }
                                    {...loan}
                                    onChange={refresh}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      ""
                    )}
                  </table>
                  {loans.length == 0 && loading == false && error == false ? (
                    <div className="d-flex w-100 flex-column align-items-center justify-content-center my-5">
                      <RefreshButton variant="icon" />
                      <Typography>No loans to display</Typography>{" "}
                    </div>
                  ) : (
                    ""
                  )}
                  <Divider />
                  <Pagination
                    layout={["total", "-", "limit", "|", "pager", "skip"]}
                    // size="md"
                    prev={true}
                    next={true}
                    first={true}
                    last={true}
                    ellipsis={true}
                    boundaryLinks={true}
                    total={total}
                    limit={apiQueryState.limit}
                    limitOptions={[10, 20, 50, 100, 200, 500]}
                    maxButtons={5}
                    activePage={apiQueryState.page}
                    onChangePage={(page) =>
                      setApiQueryState({ ...apiQueryState, page })
                    }
                    onChangeLimit={(limit) =>
                      setApiQueryState({ ...apiQueryState, limit, page: 1 })
                    }
                  />
                </div>
              );
            }}
          </ApiRequest>
        </Grid>
      </Grid>
    </Paper>
  );
}

const styles = {
  PENDING: {
    backgroundColor: orange[500],
    color: "#fff",
  },
  APPROVED: {
    backgroundColor: purple[500],
    color: "#fff",
  },
  DECLINED: {
    backgroundColor: red[500],
    color: "#fff",
  },
  IN_PROGRESS: {
    backgroundColor: blue[500],
    color: "#fff",
  },

  COMPLETED: {
    backgroundColor: green[500],
    color: "#fff",
  },
};

const showStatusIcon = (status) => {
  switch (status) {
    case "PENDING":
      return <Info color="inherit" />;

    case "APPROVED":
      return <Check color="inherit" />;

    case "DECLINED":
      return <Close style={{ color: "#fff!important" }} color="inherit" />;

    case "IN_PROGRESS":
      return <MoreHoriz style={{ color: "#fff!important" }} color="inherit" />;

    case "COMPLETED":
      return (
        <EmojiEmotions style={{ color: "#fff!important" }} color="inherit" />
      );

    default:
      return <ErrorOutline />;
  }
};

export const StatusChip = (props) => {
  let { loan } = props;
  const classes = useStyles();
  return (
    <Chip
      size="small"
      className={classes.chip}
      avatar={showStatusIcon(loan.status)}
      style={styles[loan.status]}
      label={loan.status}
    />
  );
};

const MoreActionsButton = (props) => {
  const [state, setState] = useState({
    anchorEl: null,
  });

  const showMenu = (e) => {
    setState({ ...state, anchorEl: e.target });
  };

  const onCloseMenu = (e) => {
    setState({ ...state, anchorEl: null });
  };

  if (props.status == "APPROVED") {
    return (
      <React.Fragment>
        <ViewLoan {...props} />
      </React.Fragment>
    );
  }

  if (props.status == "IN_PROGRESS") {
    return (
      <React.Fragment>
        <IconButton onClick={showMenu}>
          <MoreVert />
        </IconButton>
        <Menu
          open={Boolean(state.anchorEl)}
          anchorEl={state.anchorEl}
          onClose={onCloseMenu}
        >
          <ViewLoan {...props} />
          <EditApprovedLoan {...props} />
          <DeleteLoanButton {...props} />
          <CorrectSingleLoanIssues {...props} />
        </Menu>
      </React.Fragment>
    );
  }

  if (props.status == "COMPLETED") {
    return (
      <React.Fragment>
        <ViewLoan {...props} />
        <CorrectSingleLoanIssues {...props} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <IconButton onClick={showMenu}>
        <MoreVert />
      </IconButton>
      <Menu
        open={Boolean(state.anchorEl)}
        anchorEl={state.anchorEl}
        onClose={onCloseMenu}
      >
        <ViewLoan {...props} />
        <UpdateLoanApplication {...props} />
        <DeleteLoanButton {...props} />
      </Menu>
    </React.Fragment>
  );
};
