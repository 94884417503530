import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import React, { useState } from "react";
import ApiRequest from "../../../api/ApiRequest";
import {
  GET_ADMIN_USERS,
  REGISTER_ADMIN_USER_THREAD,
} from "../../../api/apiThreads";
import FileUploadInput from "../../../global/components/inputs/FileInput";
import { KijjeInput } from "../../../global/components/inputs/Inputs";
import { SelectBranchMenu } from "../Transactions/TransactionsTable";
import AdminProfileImage from "./AdminProfileImage";
import settingsStyles from "./settingsStyles";

export default function RegisterAdminUserButton(props) {
  const classes = settingsStyles();
  const [dialogState, setDialogState] = useState(false);
  const [addOnThread, setAddOnThread] = useState(Date.now());
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
    setAddOnThread(Date.now());
  };
  return (
    <React.Fragment>
      <Paper
        onClick={onDialogStateChange}
        className={clsx(
          classes.addUserCard,
          "d-flex align-items-center justify-content-center"
        )}
      >
        <div align="center">
          <i className=" bi-person-plus"></i>
          <Typography className={classes.addUserText}>Add User</Typography>
        </div>
      </Paper>
      <Dialog open={dialogState} fullScreen fullWidth>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} md={4} lg={3}>
            <Paper style={{ borderRadius: "0px", height: "100vh" }}>
              <Paper
                className="d-flex justify-content-between align-items-center px-3"
                style={{ borderRadius: "0px" }}
              >
                <Typography variant="h6">Add system user</Typography>
                <IconButton onClick={onDialogStateChange} startIcon={<Close />}>
                  <Close />
                </IconButton>
              </Paper>
              <div>
                <Alert severity="info" closeText="Close">
                  User account will be activated once they have confirmed their
                  email with the system.
                </Alert>
                <ApiRequest
                  thread={REGISTER_ADMIN_USER_THREAD}
                  reloadOnSuccess={[GET_ADMIN_USERS]}
                  addOnThread={addOnThread}
                  initialPayload={{
                    readPerm: false,
                    writePerm: false,
                    updatePerm: false,
                    deletePerm: false,
                    password: "",
                    email: "",
                    username: "",
                    phoneNumber: "",
                    country: "",
                    address: "",
                  }}
                  callbacks={{
                    success: (res, xthis) => {
                      if (typeof res == "object") {
                        if (typeof res.data == "object") {
                          if (res.data.registered == true) {
                            xthis.setState({
                              ...xthis.state,
                              showDialog: true,
                              dialogMessage: "Admin user has been registered",
                              dialogTitle: "Admin Registration",
                            });
                          }
                        }
                      }
                    },
                  }}
                >
                  {({
                    error,
                    SubmitButton,
                    input,
                    FileInput,
                    res,
                    setPayload,
                    payload,
                  }) => {
                    if (typeof res == "object") {
                      if (typeof res.data == "object") {
                        console.log("Res:: ", res.data);
                        if (res.data.registered == true) {
                          return (
                            <Paper
                              className={clsx(
                                classes.formCard,
                                "p-3 my-5 pb-5 mt-0"
                              )}
                              align="center"
                            >
                              <small className="p-5" align="center">
                                Admin has been registered and email verification
                                link has been sent out to him for verification.
                              </small>
                              <Button
                                startIcon={<Close />}
                                onClick={onDialogStateChange}
                              >
                                Close
                              </Button>
                            </Paper>
                          );
                        }
                      }
                    }
                    return (
                      <Paper
                        className={clsx(classes.formCard, "p-3 my-5 pb-5 mt-0")}
                      >
                        <div className="w-100" align="center">
                          <FileInput
                            inputClassName="d-none"
                            name="profilePicture"
                            onContentLoaded={({ url }) =>
                              setPayload({ profilePicture: url })
                            }
                          >
                            {({ Label, url }) => {
                              return (
                                <AdminProfileImage
                                  url={url}
                                  icon={
                                    <Label>
                                      <div size="small">
                                        <i className=" bi-camera-fill"></i>
                                      </div>
                                    </Label>
                                  }
                                />
                              );
                            }}
                          </FileInput>
                          <Typography>Add Profile picture</Typography>
                        </div>
                        <KijjeInput
                          variant="filled"
                          placeholder="Full Name"
                          label="Name*"
                          {...input({ name: "name" })}
                        />
                        <KijjeInput
                          variant="filled"
                          placeholder="Valid Email"
                          label="Email*"
                          type="email"
                          {...input({ name: "email" })}
                        />
                        <KijjeInput
                          variant="filled"
                          placeholder="Phone Number"
                          label="Phone Number*"
                          {...input({ name: "phoneNumber" })}
                        />
                        <KijjeInput
                          variant="filled"
                          placeholder="Country"
                          label="Country*"
                          {...input({ name: "country" })}
                        />
                        <KijjeInput
                          variant="filled"
                          placeholder="Physical Address"
                          label="Physical Address*"
                          {...input({ name: "address" })}
                        />
                        <KijjeInput
                          variant="filled"
                          placeholder="Password"
                          label="Password*"
                          type="password"
                          {...input({ name: "password" })}
                        />
                        <SelectBranchMenu
                          isSelect
                          input={(options) => input({ name: "default_branch" })}
                        />
                        <small className="mt-1">Permissions</small>
                        <div
                          className="p-2 my-1"
                          style={{
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                          }}
                        >
                          <FormControlLabel
                            size="small"
                            {...input({ name: "readPerm" })}
                            label={<small>Read</small>}
                            control={<Checkbox size="small" />}
                          />
                          <FormControlLabel
                            {...input({ name: "writePerm" })}
                            label={<small>Write</small>}
                            control={<Checkbox size="small" />}
                          />
                          <FormControlLabel
                            {...input({ name: "deletePerm" })}
                            label={<small>Delete</small>}
                            control={<Checkbox size="small" />}
                          />
                          <FormControlLabel
                            {...input({ name: "updatePerm" })}
                            label={<small>Update</small>}
                            control={<Checkbox size="small" />}
                          />
                        </div>

                        <div className="w-100 mt-2" align="right">
                          <SubmitButton
                            variant="contained"
                            color="secondary"
                            size="small"
                          >
                            ADD USER
                          </SubmitButton>
                        </div>
                      </Paper>
                    );
                  }}
                </ApiRequest>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
