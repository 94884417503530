import { DialogContent, DialogTitle } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import { grey } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Slider from "@material-ui/core/Slider";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Close } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import { getOrientation } from "get-orientation/browser";
import React, { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg, getRotatedImage } from "./canvasUtils";
import clsx from "clsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { blue } from "@mui/material/colors";
import { Message, toaster } from "rsuite";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  cropContainer: {
    position: "relative",
    width: "100%",
    height: "257px",
    background: "#333",
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  sliderContainer: {
    display: "flex",
    flex: "1",
    alignItems: "center",
  },
  sliderLabel: {
    [theme.breakpoints.down("xs")]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: "22px 0px",
    marginLeft: 16,
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      margin: "0 16px",
    },
  },
  inputLabel: {
    width: "100%",
    backgroundColor: grey[100],
    borderRadius: "10px",
    height: "100%",
    padding: "3px",
    border: "dotted 1px " + blue[400],
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
  },
}));

export default function PassportPhotoAndIdCardPhotoCropper(props) {
  const [imageSrc, setImageSrc] = React.useState(null);
  const [dialogState, setDialogState] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const classes = useStyles();
  const ORIENTATION_TO_ANGLE = {
    3: 180,
    6: 90,
    8: -90,
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      if (typeof props.onChange == "function") {
        props.onChange(croppedImage);
      }
      setCroppedImage(croppedImage);
      setDialogState(false);
    } catch (e) {
      console.log(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation]);

  const onFileChange = async (e) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        let imageDataUrl = await readFile(file);

        // apply rotation if needed
        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation];
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }
        setImageSrc(imageDataUrl);
        setDialogState(true);
      }
    } catch (error) {
      const message = (
        <Message showIcon type="warning">
          Failed to add image try again.
        </Message>
      );
      toaster.push(message, "topCenter");
    }
  };

  const closeDialog = () => {
    setDialogState(false);
  };

  return (
    <div className={classes.root}>
      <Dialog open={dialogState} maxWidth="sm" fullWidth>
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <div>{props.title || "Crop Image"}</div>
            <div>
              <IconButton onClick={closeDialog}>
                <Close />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={classes.cropContainer}>
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={props.aspectRatio || 1 / 1}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className={classes.controls}>
            <div className={classes.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Zoom
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                classes={{ root: classes.slider }}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            <div className={classes.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Rotation
              </Typography>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                classes={{ root: classes.slider }}
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </div>
            <Button
              onClick={showCroppedImage}
              variant="contained"
              color="primary"
              classes={{ root: classes.cropButton }}
            >
              Finish
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <div style={{ height: "100%" }}>
        <label
          for={"imageInput" + props.id}
          className={clsx(
            classes.inputLabel,
            "d-flex align-items-center align-items-center justify-content-center my-2"
          )}
        >
          <LazyLoadImage
            src={croppedImage || props.defaultImage}
            className={classes.image}
          />
          <div className={classes.inp}>{props.placeholder}</div>
        </label>
        <input
          className="d-none"
          type="file"
          id={"imageInput" + props.id}
          onChange={onFileChange}
          accept="image/*"
        />
      </div>
    </div>
  );
}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export function dataURItoBlob(dataURI) {
  if (dataURI === "" || dataURI == null || typeof dataURI === "undefined") {
    return false;
  }
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0) {
    byteString = atob(dataURI.split(",")[1]);
  } else {
    byteString = unescape(dataURI.split(",")[1]);
  }

  // separate out the mime component
  let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}
