/* eslint-disable eqeqeq */
import {
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { Close, Search } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import accounting from "accounting-js";
import clsx from "clsx";
import React, { useState } from "react";
import { DateRangePicker, Dropdown, Pagination, Radio } from "rsuite";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_GET_ALL_BRANCHES,
  BNK_API_GET_TRANSACTION_HISTORY,
} from "../../../api/apiThreads";
import TableExportMenu from "../Accounts/TableExportMenu";
import { PrintReceiptDialog } from "../Deposits";
import DeleteTransactionButton from "./DeleteTransactionButton";
import {
  ReadTransactionType,
  ShowTransactionAmount,
  showTransactionDate,
} from "./TransactionsHistory";

export const tableStyles = makeStyles((theme) => ({
  tableRoot: {
    minHeight: "100px",
    minHeight: "100%",
    boxShadow: theme.boxShadows[1],
    borderRadius: "10px",
    marginBottom: "10px",
  },
  table: {
    width: "100%",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  tableHeading: {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    // backgroundColor: grey[100],
    borderBottom: "solid 1px " + blueGrey[100],
    "& thead": {
      width: "100%",
      "& th:last-child": {
        textAlign: "right",
        color: "green",
      },
    },
    "& th": {
      paddingLeft: "20px",
      paddingBottom: "5px",
      width: "auto",
      textAlign: "left",
      paddingTop: "10px",
      fontWeight: 500,
      fontSize: "1rem",
      color: blueGrey[700],
    },
    "& th:last-child": {
      textAlign: "right",
    },
    "& th:first-child": {
      textAlign: "left",
      paddingLeft: "0px",
    },
  },
  col2: {},
  tbody: {
    "& td": {
      paddingLeft: "20px",
      paddingBottom: "10px",
      width: "auto",
      textAlign: "left",
      paddingTop: "10px",
      fontWeight: 500,
      fontSize: "0.8rem",
      color: blueGrey[700],
    },
    "& tr": {
      borderBottom: "solid 1px " + blueGrey[100],
      "& td:last-child": {
        textAlign: "right",
      },
    },

    "& tr:last-child": {
      border: "none",
    },
    "& td:first-child": {
      textAlign: "left",
      paddingLeft: "0px",
    },
  },
  iconButton: {
    width: "30px",
    height: "30px",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: "1rem",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  title: {
    fontWeight: 500,
  },
  tableWrapper: {
    width: "100%",
    overflowY: "auto !important",
  },
  selectInput: {
    width: "100%",
    borderRadius: "5px",
    padding: "5px 10px",
    marginBottom: "10px",
  },
}));

export default function TransactionsTable(props) {
  const classes = tableStyles();
  const date = new Date(Date.now());
  const [apiQueryState, setApiQueryState] = useState({
    where: props.where || "deposits|withdraws",
    show: props.show || "type|deposit|withdraw|date",
    fa: true,
    fd: true,
    branch: "ALL",
    limit: 10,
    page: 1,
    start_date: "",
    end_date: "",
    search_by: "ACCOUNT_NUMBER",
    search_term: "",
    t_type: "ALL",
  });

  let hidden = [];

  if (props.hide) {
    hidden = props.hide;
  }

  return (
    <Paper className={clsx(classes.tableRoot, "p-3")} variant="outlined">
      <ApiRequest
        autoload
        thread={BNK_API_GET_TRANSACTION_HISTORY}
        addOnThread={JSON.stringify(apiQueryState)}
        query={apiQueryState}
      >
        {({
          loading,
          ErrorView,
          res,
          RefreshButton,
          refreshing,
          refresh,
          error,
        }) => {
          let history = [];

          if (loading) {
            return (
              <div className="w-100 p-5" align="center">
                <CircularProgress size="50px" />
              </div>
            );
          }

          if (error) {
            return (
              <div className="w-100 p-5" align="center">
                <ErrorView />
              </div>
            );
          }

          let total = 0;

          if (typeof res === "object") {
            if (res.success == true) {
              if (typeof res.data == "object") {
                if (Array.isArray(res.data.transactions)) {
                  history = res.data.transactions;
                  total = res.data.total;
                }
              }
            }
          }

          return (
            <React.Fragment>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <SelectBranchMenu
                    apiQueryState={apiQueryState}
                    setApiQueryState={setApiQueryState}
                  />
                  <DateRangePicker
                    placeholder="Choose Date"
                    format="dd-MM-yyyy"
                    onChange={(dates) => {
                      if (Array.isArray(dates)) {
                        if (dates.length > 0) {
                          setApiQueryState({
                            ...apiQueryState,
                            start_date: dates[0],
                            end_date: dates[1],
                          });
                        }
                      }
                    }}
                  />
                  <SelectTransactionTypeMenu
                    apiQueryState={apiQueryState}
                    setApiQueryState={setApiQueryState}
                  />
                  <SearchByMenu
                    apiQueryState={apiQueryState}
                    setApiQueryState={setApiQueryState}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <div>
                    <Paper
                      className={clsx(classes.inputBaseRoot, "ps-2 w-100")}
                    >
                      {apiQueryState.search_term.length > 0 ? (
                        <IconButton
                          onClick={() => {
                            setApiQueryState({
                              ...apiQueryState,
                              search_term: "",
                            });
                          }}
                        >
                          <Close />
                        </IconButton>
                      ) : (
                        ""
                      )}
                      <InputBase
                        className={classes.inputBase}
                        onBlur={({ target }) =>
                          setApiQueryState({
                            ...apiQueryState,
                            search_term: target.value,
                          })
                        }
                        placeholder="Search here..."
                        style={
                          apiQueryState.search_term.length > 0
                            ? { width: "calc(100% - 100px)" }
                            : {}
                        }
                      />
                      <IconButton
                        onClick={() => {
                          // onSearch();
                          // submit();
                        }}
                      >
                        <Search />
                      </IconButton>
                    </Paper>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <TableExportMenu
                    data={history.map((row) => [
                      {
                        ...row,
                        transaction_id: row.transaction_id || 0,
                        slip_number: row.slip_no || 0,
                      },
                    ])}
                    schema={[
                      {
                        column: "Transaction Date",
                        type: Date,
                        format: "dd/mm/yyyy",
                        value: (account) => new Date(account[0].created_at),
                        width: 17,
                      },
                      // {
                      //   column: "Slip No.",
                      //   type: String,
                      //   value: (account) => account[0].slip_number,
                      //   width: 10,
                      // },
                      // {
                      //   column: "Transaction Id",
                      //   type: String,
                      //   value: (account) => account[0].transaction_id,
                      //   width: 20,
                      // },
                      {
                        column: "Account Number",
                        type: String,
                        value: (account) =>
                          account[0].account_number.toString(),
                        width: 20,
                      },
                      {
                        column: "Account Name",
                        type: String,
                        value: (account) => account[0].account_name,
                        width: 25,
                      },
                      {
                        column: "Type",
                        type: String,
                        value: (account) => account[0].type,
                        width: 20,
                      },
                      {
                        column: "Amount",
                        type: Number,
                        value: (account) => {
                          switch (account[0].type) {
                            case "DEPOSIT":
                              return account[0].credit;

                            case "DIRECT_LOAN_PAYMENT_DEPOSIT":
                              return account[0].credit;

                            case "WITHDRAW":
                              return account[0].debit;

                            default:
                              return account[0].debit;
                          }
                        },
                        width: 20,
                      },
                      {
                        column: "Balance",
                        type: Number,
                        value: (account) => account[0].balance,
                        width: 20,
                      },
                    ]}
                  />
                  <div>
                    <RefreshButton variant="icon" />
                  </div>
                </div>
              </div>
              <div className={classes.tableWrapper}>
                <table className={classes.table}>
                  <thead className={classes.tableHeading}>
                    <tr>
                      <th className={classes.col2}>No.</th>
                      <th>Slip No</th>
                      <th>Date</th>
                      <th>Branch</th>
                      <th>Ac/No</th>
                      <th>Ac/Name</th>
                      {hidden.indexOf("type") != -1 ? "" : <th>Type</th>}
                      <th>Amount</th>
                      <th>Balance</th>
                      <th>Receipt</th>
                    </tr>
                  </thead>
                  {history.length > 0 ? (
                    <tbody className={classes.tbody}>
                      {loading === true ? (
                        <div className="w-100 my-2" align="center">
                          <CircularProgress />
                        </div>
                      ) : (
                        ""
                      )}

                      {history.map((transaction, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {++index +
                                apiQueryState.page * apiQueryState.limit -
                                apiQueryState.limit}
                            </td>

                            {hidden.indexOf("slip_no") != -1 ? (
                              ""
                            ) : (
                              <td>SL-{transaction.slip_no}</td>
                            )}
                            {hidden.indexOf("date") != -1 ? (
                              ""
                            ) : (
                              <td>
                                {showTransactionDate(transaction.created_at)}
                              </td>
                            )}
                            {hidden.indexOf("branch") != -1 ? (
                              ""
                            ) : (
                              <td>{transaction.branch}</td>
                            )}
                            {hidden.indexOf("account_no") != -1 ? (
                              ""
                            ) : (
                              <td>{transaction.account_number}</td>
                            )}
                            {hidden.indexOf("account_name") != -1 ? (
                              ""
                            ) : (
                              <td>{transaction.account_name}</td>
                            )}
                            {hidden.indexOf("type") != -1 ? (
                              ""
                            ) : (
                              <td>
                                <ReadTransactionType type={transaction.type} />
                              </td>
                            )}
                            {hidden.indexOf("amount") != -1 ? (
                              ""
                            ) : (
                              <td>
                                <ShowTransactionAmount {...transaction} />
                              </td>
                            )}

                            {hidden.indexOf("balance") != -1 ? (
                              ""
                            ) : (
                              <td>
                                UGX{" "}
                                {accounting.formatNumber(transaction.balance)}
                              </td>
                            )}
                            {hidden.indexOf("balance") != -1 ? (
                              ""
                            ) : (
                              <td>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <PrintReceiptDialog
                                      transaction_id={transaction.id}
                                      branch_id={transaction.branch_id}
                                      variant="icon"
                                    />
                                  </div>
                                  <DeleteTransactionButton
                                    transaction_id={transaction.id}
                                  />
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    ""
                  )}
                </table>
                {history.length == 0 ? (
                  <div className="w-100 my-4" align="center">
                    <Typography>
                      <b>No transactions made yet</b>
                    </Typography>
                    <RefreshButton variant="icon" />
                  </div>
                ) : (
                  ""
                )}
                <Divider />
                <Pagination
                  layout={["total", "-", "limit", "|", "pager", "skip"]}
                  // size="md"
                  prev={true}
                  next={true}
                  first={true}
                  last={true}
                  ellipsis={true}
                  boundaryLinks={true}
                  total={total}
                  limit={apiQueryState.limit}
                  limitOptions={[10, 20, 50, 100, 200, 500]}
                  maxButtons={5}
                  activePage={apiQueryState.page}
                  onChangePage={(page) =>
                    setApiQueryState({ ...apiQueryState, page })
                  }
                  onChangeLimit={(limit) =>
                    setApiQueryState({ ...apiQueryState, limit, page: 1 })
                  }
                />
              </div>
            </React.Fragment>
          );
        }}
      </ApiRequest>
    </Paper>
  );
}

export function SelectBranchMenu(props) {
  const classes = tableStyles();
  return (
    <div className="w-100">
      <ApiRequest autoload thread={BNK_API_GET_ALL_BRANCHES}>
        {({ res, loading, error }) => {
          if (loading) {
            return <Skeleton width="100px" height="30px" />;
          }
          let branches = [];
          if (typeof res == "object") {
            if (typeof res.data == "object") {
              if (Array.isArray(res.data.branches)) {
                branches = res.data.branches;
              }
            }
          }

          if (props.isSelect) {
            return (
              <div>
                <label className="mb-2">Select Branch</label>
                <select
                  className={classes.selectInput}
                  {...props.input({ name: "branch" })}
                >
                  <option>Select Branch</option>
                  {props.hasAll == true ? <option value="ALL">All</option> : ""}
                  {branches.map((branch) => {
                    return (
                      <option value={branch.branch_id || branch.id}>
                        {branch.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            );
          }

          return (
            <div className="w-100">
              <Dropdown
                className="me-2 w-100"
                title="Select Branch"
                eventKey="ALL"
              >
                <Dropdown.Item
                  onClick={() =>
                    props.setApiQueryState({
                      ...props.apiQueryState,
                      branch: "ALL",
                    })
                  }
                  icon={
                    <Radio checked={props.apiQueryState.branch === "ALL"} />
                  }
                  eventKey={"ALL"}
                >
                  All branches
                </Dropdown.Item>
                {branches.map((branch, index) => {
                  return (
                    <Dropdown.Item
                      onClick={() =>
                        props.setApiQueryState({
                          ...props.apiQueryState,
                          branch: branch.branch_id || branch.id,
                        })
                      }
                      icon={
                        <Radio
                          checked={
                            props.apiQueryState.branch === branch.branch_id
                          }
                        />
                      }
                      key={index}
                      eventKey={index}
                    >
                      {branch.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown>
            </div>
          );
        }}
      </ApiRequest>
    </div>
  );
}

export function SelectTransactionTypeMenu(props) {
  // transaction types
  const t_types = [
    "DEPOSIT",
    "WITHDRAW",
    "LOAN_PAYMENT",
    "DIRECT_LOAN_PAYMENT_DEPOSIT",
    "DIRECT_LOAN_PAYMENT",
    "TRANSACTION_CHARGES",
    "SMS_CHARGES",
  ];
  return (
    <div className="w-100">
      <Dropdown className="me-2 w-100" title="Transaction Type" eventKey="ALL">
        <Dropdown.Item
          onClick={() =>
            props.setApiQueryState({
              ...props.apiQueryState,
              t_type: "ALL",
            })
          }
          icon={<Radio checked={props.apiQueryState.t_type === "ALL"} />}
          eventKey={"ALL"}
        >
          All Types
        </Dropdown.Item>
        {t_types.map((type) => {
          return (
            <Dropdown.Item
              onClick={() =>
                props.setApiQueryState({
                  ...props.apiQueryState,
                  t_type: type,
                })
              }
              icon={<Radio checked={props.apiQueryState.t_type === type} />}
              key={type}
              eventKey={type}
            >
              {type.replaceAll("_", " ")}
            </Dropdown.Item>
          );
        })}
      </Dropdown>
    </div>
  );
}

export function SearchByMenu(props) {
  // transaction types
  const search_by_terms = [
    "ACCOUNT_NUMBER",
    "ACCOUNT_NAME",
    "TRANSACTION_ID",
    "LOAN_APPLICATION_ID",
  ];
  return (
    <div className="w-100">
      <Dropdown className="me-2 w-100" title="Search By" eventKey="ALL">
        {search_by_terms.map((search_by) => {
          return (
            <Dropdown.Item
              onClick={() =>
                props.setApiQueryState({
                  ...props.apiQueryState,
                  search_by: search_by,
                })
              }
              icon={
                <Radio checked={props.apiQueryState._search_by === search_by} />
              }
              key={search_by}
              eventKey={search_by}
            >
              {search_by.replaceAll("_", " ")}
            </Dropdown.Item>
          );
        })}
      </Dropdown>
    </div>
  );
}
