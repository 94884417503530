import { Grid, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import clsx from "clsx";
import React from "react";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import AppBreadcrumb from "../../../global/components/navigation/AppBreadcrumb";
import LoanPaymentTimeline from "./LoanPaymentTimeline";
import LoansTable from "./LoansTable";
import LoanTypesTable from "./LoanTypesTable";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "0px",
  },
  button: {
    marginLeft: "10px",
  },
  card: {
    boxShadow: theme.boxShadows[1],
    borderRadius: "10px",
    minHeight: "200px",
    padding: "10px",
  },
  link: {
    borderBottom: "solid 3px transparent",
    borderRadius: "0px",
    padding: "10px 10px",
  },
  activeLink: {
    borderColor: theme.palette.primary.main,
    backgroundColor: grey[200],
  },
  tabPaper: {
    boxShadow: theme.boxShadows[4],
  },
}));

export default function Loans(props) {
  const classes = useStyles();

  return (
    <Grid container className={clsx(classes.root)} spacing={1}>
      <Grid item xs={12}>
        <Helmet>
          <title>Loans</title>
        </Helmet>
      </Grid>
      <Grid item xs={12}>
        <AppBreadcrumb />
      </Grid>
      <Grid item xs={12}>
        <Switch>
          {/* <Route path="/loans/payments"></Route> */}
          <Route path="/loans/loan-types">
            <LoanTypesTable />
          </Route>
          <Route path="/loans/applications">
            <LoansTable />
          </Route>
          <Route path="/loans">
            {/* <LoanStatistics /> */}
            <LoanPaymentTimeline />
          </Route>
        </Switch>
      </Grid>
    </Grid>
  );
}
