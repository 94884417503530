import React from "react";
import Settings from ".";
import AccountSettings from "./AccountSettings";
import FrontEndSettings from "./FrontEndSettings";
import ProfileAndBranding from "./ProfileAndBranding";
import SmsMessagesConfig from "./SmsMessagesConfig";
import SystemNotifications from "./SystemNotifications";
import SystemUsers from "./SystemUsers";

const settingsModule = {
  name: "Settings",
  path: "/settings",
  icon: <i className=" bi-gear"></i>,
  Component: Settings,
  subModules: [
    {
      name: "Profile & Branding",
      path: "/settings",
      icon: "",
      Component: ProfileAndBranding,
    },
    {
      name: "Users",
      path: "/settings/users",
      icon: "",
      Component: SystemUsers,
    },
    {
      name: "Notifications",
      path: "/settings/notifications",
      icon: "",
      Component: SystemNotifications,
    },
    {
      name: "SMS Config",
      path: "/settings/sms-messages",
      icon: "",
      Component: SmsMessagesConfig,
    },
    {
      name: "Account",
      path: "/settings/account",
      icon: "",
      Component: AccountSettings,
    },
    // {
    //   name: "Front End",
    //   path: "/settings/font-end",
    //   icon: "",
    //   Component: FrontEndSettings,
    // },
  ],
};

export default settingsModule;
