import {
  CircularProgress,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { ArrowForward, MoreVert, Refresh, Sort } from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import ApiRequest from "../../../api/ApiRequest";
import { BNK_API_GET_ALL_BRANCHES } from "../../../api/apiThreads";

const tableStyles = makeStyles((theme) => ({
  tableRoot: {
    minHeight: "100px",
    minHeight: "100%",
    boxShadow: theme.boxShadows[1],
    borderRadius: "10px",
  },
  table: {
    width: "100%",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  tableHeading: {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    // backgroundColor: grey[100],
    borderBottom: "solid 1px " + blueGrey[100],
    ["& thead"]: {
      width: "100%",
      ["& th:last-child"]: {
        textAlign: "right",
        color: "green",
      },
    },
    ["& th"]: {
      paddingLeft: "20px",
      paddingBottom: "5px",
      width: "auto",
      textAlign: "left",
      paddingTop: "10px",
      fontWeight: 500,
      fontSize: "1rem",
      color: blueGrey[700],
    },
    ["& th:last-child"]: {
      textAlign: "right",
    },
    ["& th:first-child"]: {
      textAlign: "left",
      paddingLeft: "0px",
    },
  },
  col2: {},
  tbody: {
    ["& td"]: {
      paddingLeft: "20px",
      paddingBottom: "10px",
      width: "auto",
      textAlign: "left",
      paddingTop: "10px",
      fontWeight: 500,
      fontSize: "0.8rem",
      color: blueGrey[700],
    },
    ["& tr"]: {
      borderBottom: "solid 1px " + blueGrey[100],
      ["& td:last-child"]: {
        textAlign: "right",
      },
    },

    ["& tr:last-child"]: {
      border: "none",
    },
    ["& td:first-child"]: {
      textAlign: "left",
      paddingLeft: "0px",
    },
  },
  iconButton: {
    width: "30px",
    height: "30px",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: "1rem",
    ["&:hover"]: {
      color: theme.palette.primary.main,
    },
  },
  title: {
    fontWeight: 500,
  },
}));

export default function BranchesTable(props) {
  const classes = tableStyles();
  let [addOnThread, setAddOnThread] = useState();
  return (
    <Paper className={clsx(classes.tableRoot, "p-3")}>
      <ApiRequest
        thread={BNK_API_GET_ALL_BRANCHES}
        autoload
        // addOnThread={addOnThread}
      >
        {({ loading, ErrorView, res, RefreshButton, refreshing, refresh }) => {
          let branches = [];
          let total = 0;
          let limit = 0;
          let page = 1;

          if (typeof res == "object") {
            if (typeof res.data === "object") {
              if (Array.isArray(res.data.branches)) {
                branches = res.data.branches;
                total = parseInt(res.data.total);
                page = parseInt(res.data.current_page);
                limit = parseInt(res.data.limit);
                console.log("Res:: ", res)
              }
            }
          }

          if (branches.length === 0) {
            return (
              <div className="w-100 my-4" align="center">
                <Typography>
                  <b>No Branches</b>
                </Typography>
                <IconButton
                  onClick={refresh}
                  className={clsx(classes.iconButton, "me-2")}
                  {...(refreshing === true ? { disabled: true } : {})}
                >
                  {refreshing === true ? (
                    <div>
                      <CircularProgress size="18px" color="inherit" />
                    </div>
                  ) : (
                    <Refresh />
                  )}
                </IconButton>
              </div>
            );
          }

          return (
            <React.Fragment>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div>
                  <Typography className={classes.title}>
                    {props.title || "All Branches"}
                  </Typography>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <IconButton
                      onClick={refresh}
                      className={clsx(classes.iconButton, "me-2")}
                      {...(refreshing === true ? { disabled: true } : {})}
                    >
                      {refreshing === true ? (
                        <div>
                          <CircularProgress size="18px" color="inherit" />
                        </div>
                      ) : (
                        <Refresh />
                      )}
                    </IconButton>
                  </div>
                  <div>
                    <IconButton
                      onClick={refresh}
                      className={clsx(classes.iconButton, "me-2")}
                      {...(refreshing === true ? { disabled: true } : {})}
                    >
                      {refreshing === true ? (
                        <div>
                          <CircularProgress size="18px" color="inherit" />
                        </div>
                      ) : (
                        <Sort />
                      )}
                    </IconButton>
                    <IconButton className={classes.iconButton}>
                      <ArrowForward />
                    </IconButton>
                  </div>
                </div>
              </div>
              <table className={classes.table}>
                <thead className={classes.tableHeading}>
                  <tr>
                    <th className={classes.col2}>No.</th>
                    <th>Open Date.</th>
                    <th>Branch Name</th>
                    <th>Region</th>
                    <th>City</th>
                    <th>Village</th>
                    <th>Manager</th>
                    <th>Members</th>
                    <th>Employees</th>
                    <th>More</th>
                  </tr>
                </thead>
                <tbody className={classes.tbody}>
                  {loading === true ? (
                    <div className="w-100 my-2" align="center">
                      <CircularProgress />
                    </div>
                  ) : (
                    ""
                  )}

                  {branches.map((branch, index) => {
                    return (
                      <tr key={index}>
                        <td>{page + index}</td>
                        <td>{branch.created_at}</td>
                        <td>{branch.name}</td>
                        <td>{branch.region}</td>
                        <td>{branch.city}</td>
                        <td>{branch.village}</td>
                        <td>{branch.manager}</td>
                        <td>{branch.employees}</td>
                        <td>{branch.members}</td>
                        <td>
                          <IconButton>
                            <MoreVert />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </React.Fragment>
          );
        }}
      </ApiRequest>
    </Paper>
  );
}
