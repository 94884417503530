import Reports from ".";

const reportsModule = {
  icon: <i class="bi bi-bar-chart-line"></i>,
  name: "Reports",
  path: "/reports",
  Component: Reports,
};

export default reportsModule;
