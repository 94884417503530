import {
  Button,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Radio,
  Typography,
} from "@material-ui/core";
import { blueGrey, grey, orange } from "@material-ui/core/colors";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import {
  Add,
  ArrowBack,
  ArrowForward,
  Close,
  CloudUpload,
  PersonAdd,
  PrintOutlined,
  Search,
} from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { formatNumber } from "accounting-js";
import clsx from "clsx";
import React, { useState } from "react";
import { Message, toaster, Uploader } from "rsuite";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_APPLY_FOR_A_LOAN,
  BNK_API_GET_ALL_BRANCHES,
  BNK_API_GET_ALL_CUSTOMERS,
  BNK_API_GET_ALL_LOAN_TYPES,
  BNK_API_GET_CUSTOMER_STATISTICS,
  BNK_API_GET_DASHBOARD_STATISTICS,
  BNK_API_SEARCH_ACCOUNT,
  BNK_API_SHOW_LOAN_TYPE,
  BNK_API_VALIDATE_LOAN_APPLICATION_FORM,
} from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";
import MicrosoftLoader from "../../../global/components/loader/MicrsoftLoader";
import { showDate } from "../../../global/hooks";
import PassportPhotoAndIdCardPhotoCropper, {
  dataURItoBlob,
} from "../Accounts/PassportPhotoAndIdCardPhotoCropper";
import { tableStyles } from "../Transactions/TransactionsTable";

export const loanApplicationStyles = makeStyles((theme) => ({
  root: {
    // height: "100%",
    minHeight: "100vh",
    paddingBottom: "100px",
  },
  passportPhoto: {
    height: "150px",
    backgroundSize: "contain",
    position: "relative",
    backgroundRepeat: "no-repeat",
    borderRadius: "10px",
    backgroundPosition: "center",
  },
  passportPhotoCover: {
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundColor: "rgba(0,0,0,0.3)",
    borderRadius: "10px",
    color: "#fff",
    fontSize: "3rem",
    "& *": {
      fontSize: "3rem",
    },
  },
  select: {
    width: "100%",
    height: "40px",
    padding: "5px 10px",
  },
  listItemLeft: {
    width: "200px",
    color: theme.palette.primary.main,
  },
  listItemTextRight: {
    width: "200px",
    textAlign: "left",
    "&*": {
      textAlign: "left",
    },
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  listCard: {
    borderRadius: "10px",
    border: "solid 1px " + theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  listItemSecondaryText: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  ac_opened_card: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  passportPhotoImage: {
    width: "150px",
    height: "150px",
    background: "#fefefe",
    borderRadius: "5px",
    border: "dashed 1px #000",
  },
  profileId: {
    width: "150px",
    height: "150px",
    borderRadius: "5px",
    border: "solid 2px black",
  },
  idPhoto: {
    width: "200px",
    height: "150px",
    borderRadius: "5px",
    border: "solid 2px black",
  },
  searchInputRoot: {
    // backgroundColor: grey[50],
    boxShadow: theme.boxShadows[4],
    borderLeft: "solid 4px " + orange[600],
  },
  searchInput: {
    width: "calc(100% - 50px)",
    padding: "10px 20px",
  },
  listItem: {
    width: "100%",
    backgroundColor: grey[50],
    marginTop: "5px",
  },
  stepThreeRoot: {
    // backgroundColor: grey[50],
  },
  label: {
    fontSize: "0.9rem",
    color: blueGrey[500],
    fontWeight: 400,
  },
  paperCard: {
    backgroundColor: orange[500],
    color: "#fff",
  },
  btn: {
    borderRadius: "20px",
    boxShadow: "none",
  },
  addCollateralBtn: {
    cursor: "pointer",
  },
}));

const printAccountInfo = () => {
  window.print();
};

/**
 * We shall register members from here
 *
 */
export default function LoanApplicationButton(props) {
  const classes = loanApplicationStyles();
  const [dialogState, setDialogState] = useState(false);
  const [addOnThread, setAddOnThread] = useState("null");
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
    setAddOnThread(Date.now());
  };
  const [documents, setDocuments] = useState({});

  return (
    <React.Fragment>
      <Button
        variant="contained"
        size="small"
        startIcon={<PersonAdd />}
        className={clsx(classes.btn, "m-2")}
        {...props}
        onClick={onDialogStateChange}
      >
        Apply for a loan
      </Button>
      <Dialog open={dialogState} fullScreen fullWidth scroll="body">
        <Paper className={classes.root}>
          <Container>
            <Grid container>
              <Grid item xs={12} className="mt-4">
                <Button onClick={onDialogStateChange} startIcon={<ArrowBack />}>
                  New Loan Application
                </Button>
              </Grid>

              <ApiRequest thread={BNK_API_GET_ALL_BRANCHES} autoload>
                {({ res, loading, error, ErrorView, RefreshButton }) => {
                  if (loading) {
                    return (
                      <div
                        className="w-100 d-flex justify-content-center align-items-center p-5"
                        align="center"
                      >
                        <CircularProgress size="50px" />
                      </div>
                    );
                  }

                  if (error) {
                    return <ErrorView />;
                  }

                  let branches = [];
                  if (typeof res == "object") {
                    if (typeof res.data == "object") {
                      if (Array.isArray(res.data.branches)) {
                        branches = res.data.branches;
                      }
                    }
                  }

                  if (branches.length == null) {
                    return (
                      <div className="p-4 my-3" align="center">
                        <Typography>No Branches opened yet.</Typography>
                        <RefreshButton variant="icon" />
                      </div>
                    );
                  }

                  return (
                    <ApiRequest
                      withFileUpload={true}
                      thread={BNK_API_APPLY_FOR_A_LOAN}
                      addOnThread={addOnThread}
                      reloadOnSuccess={[
                        BNK_API_GET_ALL_CUSTOMERS,
                        BNK_API_GET_CUSTOMER_STATISTICS,
                        BNK_API_GET_DASHBOARD_STATISTICS,
                      ]}
                      isForm
                      initialPayload={{
                        loan_amount: 0,
                        amount_per_installment: 0,
                        installment_type: "WEEKLY",
                        accept_terms_and_conditions: false,
                      }}
                    >
                      {(apiBody) => {
                        let { res } = apiBody;
                        if (typeof res == "object") {
                          if (typeof res.data == "object") {
                            if (res.data.application === "saved") {
                              return (
                                <StepSixApproval
                                  {...apiBody}
                                  onDialogStateChange={onDialogStateChange}
                                />
                              );
                            }
                          }
                        }

                        return (
                          <React.Fragment>
                            <Grid item xs={12} md={12} className="mt-3">
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <HorizontalStepper
                                    {...apiBody}
                                    onDialogStateChange={onDialogStateChange}
                                    branches={branches}
                                    setDocuments={setDocuments}
                                    documents={documents}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        );
                      }}
                    </ApiRequest>
                  );
                }}
              </ApiRequest>
            </Grid>
          </Container>
        </Paper>
      </Dialog>
    </React.Fragment>
  );
}

const useStepperStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return [
    "Select Branch",
    "Account",
    "Guarantor",
    "Loan Type",
    "Loan Details",
    "Collateral/Securities",
    "Terms and Conditions",
    "Approval",
  ];
}

function getStepContent(props) {
  switch (props.step) {
    case 0:
      return <StepOneChooseBranch {...props} />;
    case 1:
      return <StepTwoSelectCustomerAccount {...props} />;
    case 2:
      return <StepThreeSelectGuarantorAccount {...props} />;
    case 3:
      return <StepThreeChooseLoanType {...props} />;
    case 4:
      return <StepFourLoanDetails {...props} />;
    case 5:
      return <LoanApplicationAddCollateralsStep {...props} />;
    case 6:
      return <LoanApplicationLastStepTermsAndConditions {...props} />;
    case 7:
      return <StepSixApproval {...props} />;

    default:
      return "Unknown step";
  }
}

function HorizontalStepper(props) {
  const classes = useStepperStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    if (activeStep === 6) {
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  let OpenAccount = props.SubmitButton;
  const onDialogStateChange = props.onDialogStateChange;

  return (
    <ApiRequest
      thread={BNK_API_VALIDATE_LOAN_APPLICATION_FORM}
      params={{
        step: getSteps()
          [activeStep].replaceAll(" ", "_")
          .toUpperCase()
          .replaceAll("/", "_"),
      }}
      payload={{
        ...props.payload,
        guarantor_identity_card_back: "",
        guarantor_identity_card_front: "",
        guarantor_passport_photo: "",
        securities: "",
        documents: "",
      }}
      callbacks={{
        success: (res, xthis) => {
          if (typeof res == "object") {
            if (typeof res.data == "object") {
              if (res.data.validated === true) {
                if (activeStep === 7) {
                } else {
                  handleNext();
                }
              }
            }
          }
        },
      }}
    >
      {({ SubmitButton }) => {
        return (
          <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="horizontal">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className="px-0 px-md-5">
              {steps.map((label, index) => {
                if (activeStep === index) {
                  return (
                    <div>
                      <Grid container style={{ minHeight: "30vh" }}>
                        <Grid item xs={12}>
                          {getStepContent({
                            ...props,
                            step: index,
                            activeStep,
                            setActiveStep,
                          })}
                        </Grid>
                      </Grid>
                      <div className={classes.actionsContainer}>
                        <div>
                          {activeStep === 8 ? (
                            ""
                          ) : (
                            <Button
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              className={classes.button}
                              size="small"
                              color="secondary"
                              startIcon={<ArrowBack />}
                            >
                              Back
                            </Button>
                          )}

                          {activeStep === 6 ? (
                            <OpenAccount
                              disabled={activeStep === 0}
                              className={classes.button}
                              size="small"
                              variant="contained"
                              color="primary"
                              endIcon={<ArrowForward />}
                            >
                              Submit Application
                            </OpenAccount>
                          ) : activeStep === 7 ? (
                            <Button
                              color="secondary"
                              size="small"
                              variant="contained"
                              endIcon={<Close />}
                              onClick={onDialogStateChange}
                            >
                              Close
                            </Button>
                          ) : (
                            <SubmitButton
                              variant="contained"
                              color="primary"
                              size="small"
                              className={classes.button}
                            >
                              Next
                            </SubmitButton>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        );
      }}
    </ApiRequest>
  );
}

function StepOneChooseBranch(props) {
  const { input } = props;
  return (
    <div>
      <Typography variant="h4">Choose Branch</Typography>
      <Typography className="my-2">
        <small>
          Ensure that you have chosen the correct branch, once you have
          registered a member on a different branch it will cause some issues.
        </small>
      </Typography>
      <div style={{ width: 300 }}>
        <select
          {...input({ name: "branch_id" })}
          style={{ width: "100%", padding: "5px 5px", borderRadius: "5px" }}
        >
          <option value="null">Select Branch</option>
          {props.branches.map((branch, index) => {
            return (
              <option value={branch.branch_id} key={index}>
                {branch.name}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}

function StepTwoSelectCustomerAccount(props) {
  const classes = loanApplicationStyles();
  const { setPayload, payload } = props;
  return (
    <Grid container>
      <Grid item xs={12} md={7} lg={6}>
        <Paper className="p-3">
          <Typography variant="h6" className="my-3">
            Select your loan wallet
          </Typography>
          <Divider />
          <ApiRequest
            initialPayload={{
              search_term: "",
            }}
            payload={{
              branch: payload.branch_id,
              branch_id: payload.branch_id,
            }}
            thread={BNK_API_SEARCH_ACCOUNT}
            addOnThread={payload.branch_id}
          >
            {({ loading, error, ErrorView, res, submit, input }) => {
              let results = [];
              if (typeof res == "object") {
                if (typeof res.data == "object") {
                  if (Array.isArray(res.data.results)) {
                    results = res.data.results;
                  }
                }
              }

              const onSelectAccount = (account) => () => {
                setPayload({
                  account_number: account.account_number,
                });
                props.setActiveStep(props.activeStep + 1);
              };
              return (
                <div>
                  <Paper
                    className={clsx(
                      "d-flex justify-content-between align-items-center",
                      classes.searchInputRoot
                    )}
                  >
                    <InputBase
                      {...input({ name: "search_term" })}
                      onKeyUp={submit}
                      className={classes.searchInput}
                      placeholder="Search customer account"
                    />
                    <div className={classes.searchIcon}>
                      <IconButton onClick={submit} disabled={loading}>
                        {loading === true ? <CircularProgress /> : <Search />}
                      </IconButton>
                    </div>
                  </Paper>
                  <div>
                    {loading == true ? (
                      <MicrosoftLoader />
                    ) : (
                      <List dense>
                        {results.map((account, index) => {
                          return (
                            <ListItem
                              key={index}
                              className={classes.listItem}
                              button
                              onClick={onSelectAccount(account)}
                            >
                              <ListItemText
                                secondary={
                                  account.account_name + " - " + account.branch
                                }
                              >
                                {account.account_number}
                              </ListItemText>
                              <ListItemSecondaryAction>
                                <Radio
                                  onChange={onSelectAccount(account)}
                                  checked={
                                    payload.account_number ===
                                    account.account_number
                                  }
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                      </List>
                    )}
                  </div>
                </div>
              );
            }}
          </ApiRequest>
        </Paper>
      </Grid>
    </Grid>
  );
}

export function StepThreeSelectGuarantorAccount(props) {
  const classes = loanApplicationStyles();
  const { setPayload, payload, input } = props;
  return (
    <Grid container>
      <Grid item xs={12} md={props.fullWidth ? 12 : 7}>
        <Typography>
          Add guarantor details, guarantor can be a us client or any other
          person who qualifies to be a guarantor
        </Typography>
        <List
          style={{ border: "solid 1px #000", borderRadius: "5px" }}
          className="p-2 mt-2"
        >
          <ListItem
            onClick={() => {
              setPayload({
                is_bank_guarantor: !payload.is_bank_guarantor,
              });
            }}
            button
            className={classes.listItemButton}
          >
            <ListItemText
              secondary={
                <small>
                  If the guarantor does not have an account with us please
                  uncheck
                </small>
              }
            >
              <b>Does the guarantor have an account with us</b>
            </ListItemText>
            <ListItemSecondaryAction>
              <Radio
                checked={payload.is_bank_guarantor === true}
                onChange={() =>
                  setPayload({
                    is_bank_guarantor: !payload.is_bank_guarantor,
                  })
                }
              />
            </ListItemSecondaryAction>
          </ListItem>
          <Collapse in={payload.is_bank_guarantor}>
            <Paper className="p-3 mt-2">
              <Typography variant="h6" className="my-3">
                Guarantor loan wallet id
              </Typography>
              <Divider />
              <ApiRequest
                initialPayload={{
                  search_term: "",
                  limit: 5,
                  branch: payload.branch_id,
                  branch_id: payload.branch,
                }}
                thread={BNK_API_SEARCH_ACCOUNT}
                addOnThread="SEARCH_GRANTOR"
              >
                {({ loading, error, ErrorView, res, submit, input }) => {
                  let results = [];
                  if (typeof res == "object") {
                    if (typeof res.data == "object") {
                      if (Array.isArray(res.data.results)) {
                        results = res.data.results;
                      }
                    }
                  }

                  const onSelectGuarantor = (account) => () => {
                    setPayload({
                      guarantor_account_number: account.account_number,
                    });
                    props.setActiveStep(props.activeStep + 1);
                  };
                  return (
                    <div>
                      <Paper
                        className={clsx(
                          "d-flex justify-content-between align-items-center",
                          classes.searchInputRoot
                        )}
                      >
                        <InputBase
                          {...input({ name: "search_term" })}
                          onKeyUp={submit}
                          className={classes.searchInput}
                          placeholder="Search customer account"
                        />
                        <div className={classes.searchIcon}>
                          <IconButton onClick={submit} disabled={loading}>
                            {loading === true ? (
                              <CircularProgress />
                            ) : (
                              <Search />
                            )}
                          </IconButton>
                        </div>
                      </Paper>
                      <div>
                        {loading === true ? (
                          <MicrosoftLoader />
                        ) : (
                          <List dense>
                            {results.map((account, index) => {
                              return (
                                <ListItem
                                  key={index}
                                  className={classes.listItem}
                                  button
                                  onClick={onSelectGuarantor(account)}
                                >
                                  <ListItemText
                                    secondary={
                                      account.account_name +
                                      " " +
                                      account.branch
                                    }
                                  >
                                    {account.account_number}
                                  </ListItemText>
                                  <ListItemSecondaryAction>
                                    <Radio
                                      onChange={() =>
                                        setPayload({
                                          guarantor_account_number:
                                            account.account_number,
                                        })
                                      }
                                      checked={
                                        payload.guarantor_account_number ===
                                        account.account_number
                                      }
                                    />
                                  </ListItemSecondaryAction>
                                </ListItem>
                              );
                            })}
                          </List>
                        )}
                      </div>
                    </div>
                  );
                }}
              </ApiRequest>
              <small>
                Guarantor loan wallet should be an active loan wallet registered
                with us.
              </small>
            </Paper>
          </Collapse>
        </List>
        <List
          style={{ border: "solid 1px #000", borderRadius: "5px" }}
          className={clsx(
            "p-2 mt-2",
            payload.is_bank_guarantor === false ? classes.activeList : ""
          )}
        >
          <ListItem
            onClick={() => {
              setPayload({
                is_bank_guarantor: false,
              });
            }}
            button
            className={classes.listItemButton}
          >
            <ListItemText
              secondary={
                <small>
                  If the guarantor does not have an account with us please
                  uncheck
                </small>
              }
            >
              <b>
                Guarantor does not have a us account and is not a us customer`
              </b>
            </ListItemText>
            <ListItemSecondaryAction>
              <Radio
                checked={payload.is_bank_guarantor === false}
                onChange={() =>
                  setPayload({
                    is_bank_guarantor: false,
                  })
                }
              />
            </ListItemSecondaryAction>
          </ListItem>
          <Collapse in={payload.is_bank_guarantor === false}>
            <Paper className="p-3 mt-2">
              <KijjeInput
                placeholder="Guarantor full name"
                label="Name"
                {...input({ name: "guarantor_name" })}
              />
              <KijjeInput
                label="Email"
                placeholder="Enter guarantor email"
                {...input({ name: "guarantor_email" })}
              />
              <KijjeInput
                type="phoneNumber"
                placeholder="Enter guarantor phone number"
                label="Phone Number"
                {...input({ name: "guarantor_phone_number" })}
              />
              <KijjeInput
                placeholder="Enter National Identification Number"
                label="Enter your nin number"
                {...input({ name: "guarantor_id_card_number" })}
              />
              <KijjeInput
                label="Address"
                placeholder="Enter guarantor address"
                {...input({ name: "guarantor_address" })}
              />
              <UploadLoanGuarantorApplicantDocuments {...props} />
            </Paper>
          </Collapse>
        </List>
      </Grid>
    </Grid>
  );
}

export function StepThreeChooseLoanType(props) {
  const classes = loanApplicationStyles();
  let { input } = props;
  const [addOnThread, setAddOnThread] = useState(Date.now());
  return (
    <Grid container className="mt-1">
      <Grid item xs={12} md={props.fullWidth ? 12 : 7}>
        <Typography variant="h6" className="my-3">
          Choose the loan type
        </Typography>
        <Typography>
          <small>
            The type of loan you are applying for will determine the maximum and
            minimum loan amount, it will also determine the assets and nature of
            business.{" "}
          </small>
        </Typography>

        <ApiRequest
          addOnThread={addOnThread}
          autoload
          thread={BNK_API_GET_ALL_LOAN_TYPES}
        >
          {({ loading, error, ErrorView, res }) => {
            if (loading) {
              return (
                <div>
                  <CircularProgress />
                  Loading loan types
                </div>
              );
            }

            if (error) {
              return <ErrorView />;
            }

            let loan_types = null;
            if (typeof res == "object") {
              if (typeof res.data == "object") {
                if (Array.isArray(res.data.loan_types)) {
                  loan_types = res.data.loan_types;
                }
              }
            }

            if (loan_types == null) {
              return <Typography>No loan types available</Typography>;
            }

            return (
              <select
                required
                className={classes.select}
                {...input({ name: "loan_type" })}
              >
                <option>Choose loan type</option>
                {loan_types.map((type) => {
                  return <option value={type.id}>{type.name}</option>;
                })}
              </select>
            );
          }}
        </ApiRequest>
      </Grid>
    </Grid>
  );
}

export function StepFourLoanDetails(props) {
  let { input, payload, setPayload } = props;
  const classes = loanApplicationStyles();
  const tableClasses = tableStyles();
  const [state, setState] = useState({
    installments: [],
  });
  return (
    <ApiRequest
      thread={BNK_API_SHOW_LOAN_TYPE}
      autoload
      addOnThread={payload.loan_type}
      params={{ loan_type: payload.loan_type }}
    >
      {({ res, loading, error, ErrorView }) => {
        if (loading == true) {
          return <CircularProgress size="50px" />;
        }

        if (error == true) {
          return <ErrorView />;
        }

        let loan_type = null;
        if (typeof res == "object") {
          if (typeof res.data == "object") {
            if (typeof res.data.loan_type == "object") {
              loan_type = res.data.loan_type;
            }
          }
        }

        if (loan_type == null) {
          return (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Loan type does not exist.
            </Alert>
          );
        }

        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={clsx(classes.paperCard, "p-3")}>
                <Typography variant="h6" color="inherit">
                  {loan_type.name} Loan
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Paper className="my-2 p-3">
                    <Typography variant="h5">Customer Request</Typography>
                    <Divider className="my-2" />
                    <KijjeInput
                      square
                      placeholder="Enter the amount your applying for"
                      variant="filled"
                      label="Loan amount applied:"
                      {...input({ name: "loan_amount" })}
                      endIcon={<span className="pe-2">Ush</span>}
                    />
                    <Typography className={classes.label}>
                      Purpose of loan
                    </Typography>
                    <FormGroup size="small" row>
                      <FormControlLabel
                        size="small"
                        onClick={() => setPayload({ loan_purpose: "business" })}
                        control={
                          <Radio
                            checked={payload.loan_purpose == "business"}
                            size="small"
                          />
                        }
                        label="Business"
                      />
                      <FormControlLabel
                        onClick={() => setPayload({ loan_purpose: "medical" })}
                        size="small"
                        control={
                          <Radio
                            size="small"
                            checked={payload.loan_purpose == "medical"}
                          />
                        }
                        label="Medical"
                      />
                      <FormControlLabel
                        size="small"
                        onClick={() =>
                          setPayload({ loan_purpose: "school_fees" })
                        }
                        control={
                          <Radio
                            size="small"
                            checked={payload.loan_purpose == "school_fees"}
                          />
                        }
                        label="School Fees"
                      />
                      <FormControlLabel
                        size="small"
                        onClick={() => setPayload({ loan_purpose: "other" })}
                        control={
                          <Radio
                            size="small"
                            checked={payload.loan_purpose == "other"}
                          />
                        }
                        label="Other"
                      />
                    </FormGroup>
                    <KijjeInput
                      square
                      placeholder="Your first installment date:"
                      variant="filled"
                      label="First installment"
                      type="date"
                      {...input({ name: "first_installment_date" })}
                    />
                    <div>
                      <label>Installment type</label>
                      <select
                        className={classes.select}
                        {...input({ name: "installment_type" })}
                      >
                        <option value="DAILY">Daily</option>
                        <option value="WEEKLY">Weekly</option>
                        <option value="MONTHLY">Monthly</option>
                        <option value="YEARLY">Yearly</option>
                      </select>
                    </div>
                    <KijjeInput
                      square
                      placeholder="Amount in Ugx:"
                      variant="filled"
                      label="Amount per installment"
                      type="text"
                      {...input({ name: "amount_per_installment" })}
                    />
                  </Paper>
                  <Paper className="my-2 p-3">
                    <Typography variant="h5">Credit History</Typography>
                    <Divider className="my-2" />
                    <Typography>
                      <small>
                        If you have any credit history from us or any other bank
                        or credit union, please provide us with at previous last
                        five loans.
                      </small>
                    </Typography>
                    <Typography>1st Loan</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Amount"
                          {...input({ name: "first_loan_amount" })}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Date"
                          type="date"
                          {...input({ name: "first_loan_date" })}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Period"
                          type=""
                          {...input({ name: "first_loan_period" })}
                        />
                      </Grid>
                    </Grid>
                    <Typography>2nd Loan</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Amount"
                          {...input({ name: "second_loan_amount" })}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Date"
                          type="date"
                          {...input({ name: "second_loan_date" })}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Period"
                          type=""
                          {...input({ name: "second_loan_period" })}
                        />
                      </Grid>
                    </Grid>
                    <Typography>3rd Loan</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Amount"
                          {...input({ name: "third_loan_amount" })}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Date"
                          type="date"
                          {...input({ name: "third_loan_date" })}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Period"
                          type=""
                          {...input({ name: "third_loan_period" })}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    className="p-2"
                    style={{ backgroundColor: blueGrey[50] }}
                  >
                    <div
                      className={clsx(
                        classes.cardTitle,
                        "d-flex justify-content-between align-items-center"
                      )}
                    >
                      <Typography variant="h6">
                        Loan Payment Schedule
                      </Typography>
                      <div className="d-flex justify-content-center align-items-center">
                        <Button variant="contained" size="small">
                          Calculate Amount
                        </Button>
                        <IconButton>
                          <PrintOutlined />
                        </IconButton>
                      </div>
                    </div>
                    <Divider className="my-2" />
                    <Grid container>
                      <Grid item xs={12} sm={3}>
                        <div>
                          <small>Loan Amount</small>
                          <Typography>
                            {formatNumber(payload.loan_amount)}
                            <small>
                              <b>Ugx</b>
                            </small>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <div>
                          <small>Interest Rate</small>
                          <Typography>{loan_type.interest_rate}%</Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <div>
                          <small>Total Installments</small>
                          <Typography>{state.installments.length}</Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <div>
                          <small>Installment Method</small>
                          <Typography>{payload.installment_type}</Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Divider className="my-3" />
                    <div>
                      <table className={tableClasses.table}>
                        <thead className={tableClasses.tableHeading}>
                          <tr>
                            <th style={{ width: "30px" }}>No.</th>
                            <th>Date</th>
                            <th>Installment</th>
                            <th>Total</th>
                            <th>Balance</th>
                          </tr>
                        </thead>
                        {/* <tbody className={tableClasses.tbody}>
                          {state.installments.map((installment, index) => {
                            if (index == 0) {
                              return "";
                            }
                            if (index == 49) {
                              return (
                                <tr>
                                  <td>
                                    <Button
                                      startIcon={<ArrowBack />}
                                      fullWidth
                                      size="small"
                                      variant="contained"
                                    >
                                      Prev
                                    </Button>
                                  </td>
                                  <td colSpan={3}></td>
                                  <td>
                                    <div className="w-100">
                                      <Button
                                        endIcon={<ArrowForward />}
                                        fullWidth
                                        size="small"
                                        variant="contained"
                                      >
                                        Next
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }

                            try {
                              return (
                                <tr>
                                  <td>{index}</td>
                                  <td>
                                    {getInstallmentDate(
                                      payload.first_installment_date,
                                      index
                                    )}
                                  </td>
                                  <td>
                                    {formatNumber(
                                      payload.amount_per_installment
                                    )}
                                    <small>Ugx</small>
                                  </td>
                                  <td>
                                    {formatNumber(
                                      Number(payback_amount) -
                                        Number(installment)
                                    )}
                                    <small>Ugx</small>
                                  </td>
                                  <td>
                                    {index + 1 == state.installments.length
                                      ? formatNumber(0)
                                      : `-${formatNumber(installment)}`}
                                    <small>Ugx</small>
                                  </td>
                                </tr>
                              );
                            } catch (error) {
                              return "";
                            }
                          })}
                        </tbody> */}
                      </table>
                    </div>
                    <Divider className="my-3" />
                    <div className="d-flex justify-content-between align-items-center px-2">
                      <Typography variant="h6">Total Amount</Typography>
                      <Typography variant="h6">
                        {formatNumber(state.payback_amount || 0)}{" "}
                        <small>Ugx</small>
                      </Typography>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </ApiRequest>
  );
}

export function LoanApplicationLastStepTermsAndConditions(props) {
  let { setPayload } = props;
  return (
    <div>
      <Paper className="p-3">
        <Typography>
          Please add all the scanned copies of the form and the agreements
        </Typography>
        <Uploader
          action="'"
          listType="picture-text"
          autoUpload={false}
          onChange={(files) => {
            files.map((file, index) => {
              setPayload({ [`documents[${index}]`]: file.blobFile });
            });
          }}
        >
          <div>Upload loan documents</div>
        </Uploader>
      </Paper>
    </div>
  );
}

function StepSixApproval(props) {
  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <div className="my-4">
          <Alert>
            <AlertTitle>
              <Typography variant="h6">Congratulation</Typography>
            </AlertTitle>
            <Typography>
              Loan application form has been submitted and it is waiting for
              approval. <br />
              <b>You will get a notification once it has been approved.</b>
            </Typography>
          </Alert>
        </div>
        <Button
          variant="contained"
          endIcon={<Close />}
          onClick={props.onDialogStateChange}
        >
          Close
        </Button>
      </Grid>
    </Grid>
  );
}

export function LoanApplicationAddCollateralsStep(props) {
  const { payload, setPayload } = props;
  const classes = loanApplicationStyles();
  const securities = props.payload.securities || [];
  const [fileList, setFileList] = useState({});

  const addCollateral = () => {
    setPayload({
      securities: [
        ...securities,
        {
          collateral: "",
          collateral_value: "",
          collateral_receipt: "",
          collateral_type: "", // 'PATENT
          file: "",
        },
      ],
    });
  };
  const onCollateralInputChange = (index) => (e) => {
    const { value, name } = e.target;
    const securities = [...props.payload.securities];
    securities[index][name] = value;
    setPayload({ securities: securities });
  };

  const removeCollateral = (index) => () => {
    const securities = [...payload.securities];
    props.setPayload({
      securities: securities.filter((item, itemIndex) => {
        if (index === itemIndex) {
          return false;
        } else {
          return true;
        }
      }),
    });
  };

  const onSecurityDocumentChange = (index) => (files) => {
    const securities = [...payload.securities];
    const message = (
      <Message showIcon type="error">
        Can't add collateral image without collateral name
      </Message>
    );

    if (securities[index].collateral === "") {
      toaster.push(message, "topEnd");
      console.log(files[0].blobFile instanceof Blob);
      setFileList({ ...fileList, [index]: [] });
      return false;
    } else {
      const securitiesFiles = {};
      files.forEach((file, fIndex) => {
        securitiesFiles[`${securities[index].collateral}[${fIndex}]`] =
          file.blobFile;
      });
      setPayload(securitiesFiles);
    }
    setFileList({ ...fileList, [index]: files });
  };
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography>
          <b>Collateral/Securities</b>
        </Typography>
        <Typography>
          <small>
            Provide details for your loan collaterals or securities. These
            should be equivalent to the value of loan you are taking. In case of
            non-fixed assets whose value depreciates over time you should
            specify.
          </small>
        </Typography>
        {securities.map((collateral, index) => {
          return (
            <Paper variant="outlined" className="p-3 mb-3" key={index}>
              <div className="w-100 d-flex justify-content-end">
                <IconButton onClick={removeCollateral(index)}>
                  <Close />
                </IconButton>
              </div>
              <KijjeInput
                square
                name="collateral"
                value={collateral.collateral}
                onChange={onCollateralInputChange(index)}
                variant="square"
                placeholder="Collateral"
                label="Collateral"
              />
              <KijjeInput
                square
                name="collateral_value"
                value={collateral.collateral_value}
                onChange={onCollateralInputChange(index)}
                variant="square"
                placeholder="Collateral Value"
                label="Collateral Value"
              />
              <KijjeInput
                square
                variant="square"
                name="year_of_purchase"
                value={collateral.year_of_purchase}
                onChange={onCollateralInputChange(index)}
                placeholder="Year of purchase"
                label="Year of purchase"
              />
              <KijjeInput
                square
                name="collateral_description"
                value={collateral.collateral_description}
                onChange={onCollateralInputChange(index)}
                variant="square"
                multiline
                placeholder="Collateral Description"
                label="Collateral Description"
              />
              <Uploader
                action="'"
                listType="picture-text"
                autoUpload={false}
                fileList={fileList[index] || []}
                onChange={onSecurityDocumentChange(index)}
              >
                <Button
                  variant="outlined"
                  startIcon={<CloudUpload />}
                  fullWidth
                >
                  Choose Collateral documents or pictures
                </Button>
              </Uploader>
            </Paper>
          );
        })}
        <Paper
          onClick={addCollateral}
          className={clsx(
            "d-flex justify-content-center align-items-center flex-column p-3 my-2",
            classes.addCollateralBtn
          )}
        >
          <Add />
          <Typography>Add Collateral/Security</Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}

function UploadLoanGuarantorApplicantDocuments(props) {
  return (
    <React.Fragment>
      <Grid container alignItems="center">
        <Grid item>
          <Typography>
            <b>Passport Photo</b>
          </Typography>
          <div style={{ width: "150px", height: "150px" }}>
            <PassportPhotoAndIdCardPhotoCropper
              id="passport-photo"
              aspectRatio={1 / 1}
              onChange={(file) =>
                props.setPayload({
                  guarantor_passport_photo: dataURItoBlob(file),
                })
              }
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item>
          <Typography>
            <b>Id Card Front</b>
          </Typography>
          <div style={{ width: "249px", height: "157px" }}>
            <PassportPhotoAndIdCardPhotoCropper
              id="some1"
              aspectRatio={249 / 157}
              onChange={(file) => {
                props.setPayload({
                  guarantor_identity_card_front: dataURItoBlob(file),
                });
              }}
            />
          </div>
        </Grid>
        <Grid item>
          <Typography>
            <b>Id Card Back</b>
          </Typography>
          <div style={{ width: "249px", height: "157px" }}>
            <PassportPhotoAndIdCardPhotoCropper
              aspectRatio={249 / 157}
              onChange={(file) => {
                props.setPayload({
                  guarantor_identity_card_back: dataURItoBlob(file),
                });
              }}
              id="some2"
            />
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
