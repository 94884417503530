import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { MoreVert, Refresh } from "@material-ui/icons";
import accounting from "accounting-js";
import clsx from "clsx";
import React, { useState } from "react";
import { Pagination } from "rsuite";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_CHECKING_ACCOUNT_GET_TRANSACTIONS,
  BNK_API_GET_transactions,
} from "../../../api/apiThreads";
import { ReadTransactionType } from "../Transactions/TransactionsHistory";
import {
  SelectBranchMenu,
  tableStyles,
} from "../Transactions/TransactionsTable";
import RecordCATransactionButton from "./RecordCATransactionButton";

/**
 * Checking Account Transaction Table
 * @param {*} props
 * @returns
 */
export default function CATTable(props) {
  const classes = tableStyles();
  const [apiQueryState, setApiQueryState] = useState({
    branch: "ALL",
    start_date: "",
    end_date: "",
    limit: 10,
    page: 1,
  });

  return (
    <Paper variant="outlined" className={clsx("p-3")}>
      <ApiRequest
        addOnThread={JSON.stringify(apiQueryState)}
        autoload
        thread={BNK_API_CHECKING_ACCOUNT_GET_TRANSACTIONS}
        payload={apiQueryState}
      >
        {({
          loading,
          ErrorView,
          res,
          RefreshButton,
          refreshing,
          refresh,
          error,
        }) => {
          let transactions = [];

          if (loading) {
            return (
              <div className="w-100 p-5" align="center">
                <CircularProgress size="50px" />
              </div>
            );
          }

          if (error) {
            return (
              <div className="w-100 p-5" align="center">
                <ErrorView />
              </div>
            );
          }

          let total = 0;
          if (typeof res === "object") {
            if (res.success === true) {
              if (typeof res.data == "object") {
                if (Array.isArray(res.data.transactions)) {
                  transactions = res.data.transactions;
                  total = res.data.total;
                }
              }
            }
          }

          function renderAmount(trans) {
            if (trans.type === "DEPOSIT") {
              return trans.credit;
            } else {
              return trans.debit;
            }
          }

          return (
            <React.Fragment>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <Typography
                    variant="h6"
                    style={{
                      width: "230px",
                    }}
                  >
                    CA Transactions
                  </Typography>
                  <SelectBranchMenu
                    apiQueryState={apiQueryState}
                    setApiQueryState={setApiQueryState}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <RecordCATransactionButton type="DEPOSIT" title="Deposit" />
                    <RecordCATransactionButton
                      type="WITHDRAW"
                      title="Withdraw"
                    />
                    <RecordCATransactionButton
                      type="BNK_CHARGES"
                      title="Bnk Charges"
                    />
                  </div>
                  <div>
                    <IconButton
                      onClick={refresh}
                      className={clsx(classes.iconButton, "me-2")}
                      {...(refreshing === true ? { disabled: true } : {})}
                    >
                      {refreshing === true ? (
                        <div>
                          <CircularProgress size="18px" color="inherit" />
                        </div>
                      ) : (
                        <Refresh />
                      )}
                    </IconButton>
                  </div>
                </div>
              </div>
              <div className={classes.tableWrapper}>
                <table className={classes.table}>
                  <thead className={classes.tableHeading}>
                    <tr>
                      <th className={classes.col2}>No.</th>
                      <th>Trans Id</th>
                      <th>Date</th>
                      <th>Branch</th>
                      <th>Ac/No</th>
                      <th>Trans By</th>
                      <th>Reason</th>
                      <th>Type</th>
                      <th>Amount</th>
                      <th>More</th>
                    </tr>
                  </thead>
                  <tbody className={classes.tbody}>
                    {loading === true ? (
                      <div className="w-100 my-2" align="center">
                        <CircularProgress />
                      </div>
                    ) : (
                      ""
                    )}

                    {transactions.length === 0 ? (
                      <tr className="w-100 my-4" align="center">
                        <td className="w-100" colSpan={7} align="center">
                          <Typography>
                            <b>No transactions recorded yet</b>
                          </Typography>
                        </td>
                        <td></td>
                      </tr>
                    ) : (
                      transactions.map((transaction, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {++index +
                                apiQueryState.page * apiQueryState.limit -
                                apiQueryState.limit}
                            </td>
                            <td>{transaction.trans_id || "N/A"}</td>
                            <td>{transaction.trans_date}</td>
                            <td>{transaction.branch}</td>
                            <td>{transaction.account_number}</td>
                            <td>{transaction.trans_by}</td>
                            <td>{transaction.reason}</td>
                            <td>
                              <ReadTransactionType type={transaction.type} />
                            </td>
                            <td>UGX {renderAmount(transaction)}</td>
                            <td>
                              <MoreOptionsButton />
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                <Divider />
                <Pagination
                  layout={["total", "-", "limit", "|", "pager", "skip"]}
                  // size="md"
                  prev={true}
                  next={true}
                  first={true}
                  last={true}
                  ellipsis={true}
                  boundaryLinks={true}
                  total={total}
                  limit={apiQueryState.limit}
                  limitOptions={[10, 20, 50, 100, 200, 500]}
                  maxButtons={5}
                  activePage={apiQueryState.page}
                  onChangePage={(page) =>
                    setApiQueryState({ ...apiQueryState, page })
                  }
                  onChangeLimit={(limit) =>
                    setApiQueryState({ ...apiQueryState, limit, page: 1 })
                  }
                />
              </div>
            </React.Fragment>
          );
        }}
      </ApiRequest>
    </Paper>
  );
}

function MoreOptionsButton(props) {
  return (
    <IconButton>
      <MoreVert />
    </IconButton>
  );
}
