import React, { useState } from "react";
import { Add, ArrowForward, Close, Image } from "@material-ui/icons";
import {
  IconButton,
  Dialog,
  Typography,
  DialogContent,
  DialogTitle,
  makeStyles,
  Divider,
  DialogActions,
  Grid,
  Collapse,
} from "@material-ui/core";
import ApiRequest from "../../../api/ApiRequest";
import KijjeInput from "../../../global/components/inputs/Inputs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import clsx from "clsx";
import {
  BNK_API_ADD_LOAN_TYPE,
  BNK_API_GET_ALL_LOAN_TYPES,
} from "../../../api/apiThreads";
import { Checkbox } from "rsuite";

const useStyles = makeStyles((theme) => ({
  banner: {
    width: "100%",
    height: "200px",
    borderRadius: "10px",
    border: "dotted 1px " + theme.palette.primary.main,
    padding: "10px",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));

export default function NewLoanTypeButton(props) {
  const classes = useStyles();
  const [dialogState, setDialogState] = useState();
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
  };
  return (
    <React.Fragment>
      <IconButton onClick={onDialogStateChange}>
        <Add />
      </IconButton>
      <Dialog open={dialogState} fullWidth maxWidth="sm">
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <Typography variant="h6">Add new loan type</Typography>
            <IconButton onClick={onDialogStateChange}>
              <Close />
            </IconButton>
          </div>
          <Divider />
        </DialogTitle>
        <ApiRequest
          thread={BNK_API_ADD_LOAN_TYPE}
          reloadOnSuccess={[BNK_API_GET_ALL_LOAN_TYPES]}
          initialPayload={{
            has_simple_interest: false,
            application_fees_deduc_on_disbursement: false,
          }}
          callbacks={{
            success: (res, xthis) => {
              if (typeof res == "object") {
                if (typeof res.data == "object") {
                  if (res.data.added == true) {
                    xthis.setState({
                      ...xthis.state,
                      dialogMessage: "Loan type has been added",
                      dialogTitle: "Completed",
                      showDialog: true,
                      payload: {},
                    });
                  }
                }
              }
            },
          }}
        >
          {({ SubmitButton, input, FileInput, payload, setPayload }) => {
            return (
              <React.Fragment>
                <DialogContent>
                  <KijjeInput
                    square
                    variant="filled"
                    placeholder="Enter loan type name"
                    label="Name*"
                    {...input({ name: "name" })}
                  />
                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <KijjeInput
                        square
                        variant="filled"
                        placeholder="0"
                        label="Application Fees*"
                        {...input({ name: "application_fees" })}
                        endIcon={<small className="px-3 me-3">ugx</small>}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Checkbox
                        defaultChecked
                        checked={payload.application_fees_deduc_on_disbursement}
                        value={payload.application_fees_deduc_on_disbursement}
                        onChange={(v, checked, e) => {
                          setPayload({
                            application_fees_deduc_on_disbursement: checked,
                          });
                        }}
                      >
                        Application fees are deductible on disbursement
                      </Checkbox>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <KijjeInput
                        square
                        variant="filled"
                        placeholder="0"
                        label="Days in process*"
                        {...input({ name: "days_in_process" })}
                        endIcon={<small className="px-3 me-3">days</small>}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <KijjeInput
                        square
                        variant="filled"
                        placeholder="0"
                        label="Interest Rate*"
                        endIcon={<small className="px-3">%</small>}
                        {...input({ name: "interest_rate" })}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <KijjeInput
                        square
                        variant="filled"
                        placeholder="0"
                        label="Minimum loan amount"
                        {...input({ name: "min_loan_amount" })}
                        endIcon={<small className="px-3 me-3">ugx</small>}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <KijjeInput
                        square
                        variant="filled"
                        placeholder="0"
                        label="Maximum loan amount"
                        {...input({ name: "max_loan_amount" })}
                        endIcon={<small className="px-3 me-3">ugx</small>}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <KijjeInput
                        square
                        variant="filled"
                        placeholder=""
                        label="Minimum Operating Capital"
                        {...input({ name: "min_operating_capital" })}
                        endIcon={<small className="px-3 me-3">ugx</small>}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <KijjeInput
                        square
                        variant="filled"
                        placeholder=""
                        label="Minimum Account Balance"
                        {...input({ name: "min_account_balance" })}
                        endIcon={<small className="px-3 me-3">%</small>}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <KijjeInput
                        label="Min Loan Period"
                        square
                        variant="filled"
                        {...input({ name: "min_loan_period" })}
                        endIcon={<small className="px-3 me-3">days</small>}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <KijjeInput
                        label="Max Loan Period"
                        square
                        variant="filled"
                        {...input({ name: "max_loan_period" })}
                        endIcon={<small className="px-3 me-3">days</small>}
                      />
                    </Grid>
                  </Grid>

                  <KijjeInput
                    label="Insurance"
                    square
                    variant="filled"
                    {...input({ name: "insurance" })}
                  />
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                      <KijjeInput
                        square
                        variant="filled"
                        type="number"
                        disabled={!payload.has_simple_interest}
                        label="Simple interest rate"
                        endIcon={<small className="px-3 me-3">%</small>}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Checkbox
                        checked={payload.has_simple_interest}
                        value={payload.has_simple_interest}
                        onChange={(value, checked, event) => {
                          setPayload({ has_simple_interest: checked });
                        }}
                      >
                        Enable simple interest on loan type
                      </Checkbox>
                    </Grid>
                  </Grid>

                  <KijjeInput
                    square
                    variant="filled"
                    placeholder=""
                    label="Description"
                    multiline
                    {...input({ name: "description" })}
                  />
                </DialogContent>

                <DialogActions>
                  <Divider className="my-2" />
                  <SubmitButton
                    size="small"
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForward />}
                  >
                    Add Loan Type
                  </SubmitButton>
                </DialogActions>
              </React.Fragment>
            );
          }}
        </ApiRequest>
      </Dialog>
    </React.Fragment>
  );
}
