import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  InputBase,
  makeStyles,
  Menu,
  Typography,
} from "@material-ui/core";
import { green, grey, orange, pink, purple } from "@material-ui/core/colors";
import {
  KeyboardArrowDown,
  Search,
  SortSharp as MenuIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import ApiRequest from "../../api/ApiRequest";
import { IS_LOGGED_IN_THREAD, LOGOUT_ADMIN } from "../../api/apiThreads";
import { cacheUserDataOnDevice } from "../../api/cacheUserOnDevice";
import Images from "../images/images";
import adminLinks from "../routes";
import AdminDrawer from "./AdminDrawer";
import { SyncButton } from "./SyncButton";

const sideBarStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    overflowY: "auto",
    // boxShadow: theme.boxShadows[3],
    backgroundColor: "#fff",
    position: "fixed",
  },
  loginBtn: {
    borderRadius: "5px!important",
    boxShadow: "none",
  },
  link: {
    borderRadius: "5px!important",
    boxShadow: "none",
    // background: "#383",
    // color: "#fff",
  },
  logo: {
    height: "auto",
    width: "70%",
  },
  activeLink: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  appbar: {
    position: "fixed",
    paddingTop: "20px",
    right: "0px",
    zIndex: theme.zIndex.appBar,
    background: "rgba(255,255,255,0.3)",
    backdropFilter: "blur(10px)",
    // borderBottom: "grey 1px solid",
    paddingBottom: "20px",
  },
  menu: {
    borderRadius: "20px",
    boxShadow: theme.boxShadows[3],
  },
}));

let color_count = 0;

const dynamicColor = () => {
  if (color_count == 5) {
    color_count = 0;
  }
  return [orange[500], purple[500], pink[500]][color_count++];
};

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "#E9F4F4",
    // height: "100vh",
    // position: "relative",
  },
  sidebar: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mainContent: {
    marginTop: "120px",
    overflow: "hidden",
  },
  main: {
    position: "relative",
  },
  iconButton: {
    height: "40px",
    width: "40px",
    marginRight: "15px",
    backgroundColor: pink[500],
    color: "#fff",
    fontSize: "1rem",
    transition: "0.3s ease",
    "&:hover": {
      border: "solid 1px " + pink[500],
      transition: "0.3s ease",
      color: pink[500],
    },
  },
  iconButton2: {
    height: "40px",
    width: "40px",
    marginRight: "15px",
    backgroundColor: orange[500],
    color: "#fff",
    fontSize: "1rem",
    transition: "0.3s ease",
    "&:hover": {
      border: "solid 1px " + orange[500],
      transition: "0.3s ease",
      color: orange[500],
    },
  },

  appSearchBarRoot: {
    width: "100%",
    position: "relative",
  },
  appSearchBar: {
    background: grey[200],
    marginTop: "10px",
    width: "100%",
    borderRadius: "10px",
    height: "50px",
  },
  appSearchBarInput: {
    height: "100%",
    width: "100%",
    paddingLeft: "20px",
    color: "#0B1432",
  },
}));

export default function Layout(props) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const dialogState = useSelector((state) => {
    return state.authPostConfirmationPin.showPinDialog;
  });
  let loc = history.location.pathname.split("/").filter((item) => item);
  if (loc[0] === "clients") {
    if (loc[2] === "apps") {
      if (loc[3] === "manage") {
        if (loc.length >= 4) {
          return props.children;
        }
      }
    }
  }

  console.log(dialogState);

  return (
    <React.Fragment>
      <Grid container className={classes.root}>
        <Grid item md={3} lg={2} xl={2} className={classes.sidebar}>
          <Sidebar />
        </Grid>
        <Grid item xs={12} lg={10} className={clsx(classes.main)}>
          <Appbar />
          <div className={clsx(classes.mainContent, "px-4")}>
            {props.children}
          </div>
        </Grid>
      </Grid>
      <Dialog open={dialogState}>
        <DialogContent>
          <Button>Close Dialog</Button>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export function Sidebar(props) {
  const classes = sideBarStyles();

  return (
    <Grid
      item
      xs={3}
      md={3}
      lg={2}
      className={clsx(
        classes.root,
        "d-flex flex-column justify-content-between p-4"
      )}
    >
      <div className="w-100 pt-3">
        <div align="center" className="pb-3">
          <Images.Logo className={classes.logo} />
        </div>
        {adminLinks.modules.map((module, index) => {
          return (
            <Button
              key={index}
              fullWidth
              exact={module.path == "/"}
              component={NavLink}
              to={module.path}
              // variant={index == 0 ? "contained" : ""}
              // color="primary"
              className={clsx("justify-content-start mt-2 ", classes.link)}
              activeClassName={classes.activeLink}
              startIcon={module.icon}
            >
              {module.name}
            </Button>
          );
        })}
      </div>
      <div className="w-100 d-flex justify-content-between" align="right"></div>
    </Grid>
  );
}

function TodayDate(props) {
  const classes = sideBarStyles();
  const date = new Date();
  let day = date.getDate();
  day +=
    day == 1
      ? "st"
      : day == 2
      ? "nd"
      : day == 3
      ? "rd"
      : day == 22
      ? "nd"
      : day == 23
      ? "rd"
      : day == "31"
      ? "st"
      : "th";
  return (
    <Typography>
      <small>
        {
          [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ][date.getDay()]
        }
        &nbsp;{day}{" "}
        {
          [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ][date.getMonth()]
        }
        , {date.getFullYear()}
      </small>
    </Typography>
  );
}

function Appbar(props) {
  const classes = sideBarStyles();

  return (
    <Grid container item xs={12} lg={10} className={clsx(classes.appbar)}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="px-4 py-2"
      >
        <Grid item md={6}>
          <AppSearchBar />
        </Grid>
        <Grid item align="right" className="d-flex align-items-center">
          <Hidden>
            <SyncButton />
            <NotificationsButton />
            <MessagesButton />
          </Hidden>
          <LoggedInUserButton />
        </Grid>
      </Grid>
    </Grid>
  );
}

/**
 * App Notifications
 */
function NotificationsButton(props) {
  const classes = useStyles();
  return (
    <IconButton className={classes.iconButton}>
      <i className="bi bi-bell-fill"></i>
    </IconButton>
  );
}

/**
 * App Notifications
 */
function MessagesButton(props) {
  const classes = useStyles();
  return (
    <IconButton className={classes.iconButton2}>
      <i className="bi bi-chat-square-fill"></i>
    </IconButton>
  );
}

function LoggedInUserButton() {
  const [menu, setMenu] = useState({
    anchorEl: null,
  });
  const classes = sideBarStyles();
  const admin = useSelector(({ dataStore }) => {
    return typeof dataStore[IS_LOGGED_IN_THREAD] == "object"
      ? typeof dataStore[IS_LOGGED_IN_THREAD].data == "object"
        ? dataStore[IS_LOGGED_IN_THREAD].data.admin
        : {}
      : {};
  });

  const openMenu = (e) => {
    setMenu({ ...menu, anchorEl: e.target });
  };

  const closeMenu = (e) => {
    setMenu({ ...menu, anchorEl: null });
  };
  return (
    <React.Fragment>
      <Hidden mdUp>
        <Button
          size="small"
          onClick={openMenu}
          className={clsx("justify-content-between", classes.loginBtn)}
          endIcon={<KeyboardArrowDown />}
          startIcon={
            <Avatar
              style={{
                width: "38px",
                height: "38px",
                backgroundColor: green[200],
              }}
            />
          }
        ></Button>
      </Hidden>
      <Hidden smDown>
        <Button
          size="small"
          onClick={openMenu}
          className={clsx("justify-content-between", classes.loginBtn)}
          endIcon={<KeyboardArrowDown />}
          startIcon={
            <Avatar
              style={{
                width: "38px",
                height: "38px",
                backgroundColor: green[200],
              }}
            />
          }
        >
          <div align="left">
            <div style={{ width: "100%", fontSize: "0.8rem" }}>
              {admin.name}
            </div>
            <div
              style={{
                width: "100%",
                fontSize: "0.7rem",
                color: grey[600],
                lineHeight: "0.7rem",
              }}
            >
              {admin.email}
            </div>
          </div>
        </Button>
      </Hidden>
      <Menu
        onClose={closeMenu}
        anchorEl={menu.anchorEl}
        open={Boolean(menu.anchorEl)}
        style={{ marginTop: "70px" }}
        className={classes.menu}
      >
        <div
          className="d-flex flex-column align-items-center justify-content-center my-2 mt-3"
          style={{ minWidth: "240px" }}
        >
          <Avatar
            style={{
              width: "38px",
              height: "38px",
              backgroundColor: green[200],
            }}
          />
          <Button
            onClick={closeMenu}
            style={{ flexDirection: "column" }}
            className={clsx("justify-content-between my-1", classes.loginBtn)}
          >
            <div align="center">
              <div style={{ width: "100%", fontSize: "0.8rem" }}>
                {admin.name}
              </div>
              <div
                style={{
                  width: "100%",
                  fontSize: "0.9rem",
                  color: grey[600],
                  lineHeight: "0.9rem",
                }}
              >
                {admin.email}
              </div>
            </div>
          </Button>
        </div>
        <Divider className="my-2" />

        <ApiRequest
          thread={LOGOUT_ADMIN}
          callbacks={{
            success: (data) => {
              cacheUserDataOnDevice({
                authenticated: false,
                token: "",
                email: "",
              });
              window.location.href = "/login";
            },
          }}
          initialData={{}}
        >
          {({ SubmitButton }) => {
            return (
              <SubmitButton
                // component={MenuItem}
                fullWidth
                className={clsx("justify-content-center")}
                size="small"
                endIcon={<i className="bi bi-lock"></i>}
              >
                Log Out
              </SubmitButton>
            );
          }}
        </ApiRequest>
      </Menu>
    </React.Fragment>
  );
}

/**
 * Application search bar
 */
function AppSearchBar(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    open: false,
  });
  const openDrawer = () => {
    setState({ ...state, open: !state.open });
  };
  return (
    <React.Fragment>
      <Drawer open={state.open} onClose={openDrawer}>
        <AdminDrawer />
      </Drawer>
      <Hidden mdUp>
        <IconButton
          className={clsx("me-2")}
          color="inherit"
          onClick={openDrawer}
        >
          <MenuIcon color="inherit" />
        </IconButton>

        {/* <IconButton>
          <Search />
        </IconButton> */}
      </Hidden>
      <Hidden smDown>
        <div className="d-flex align-items-center">
          <Hidden lgUp>
            <IconButton
              className={clsx("me-2")}
              color="inherit"
              onClick={openDrawer}
              style={{ width: "50px", height: "50px" }}
            >
              <MenuIcon color="inherit" />
            </IconButton>
          </Hidden>
          <div className={classes.appSearchBarRoot}>
            <div
              className={clsx(
                classes.appSearchBar,
                "d-flex justify-content-space-between align-items-center"
              )}
            >
              <InputBase
                placeholder="Search"
                className={classes.appSearchBarInput}
              />
              <IconButton>
                <Search />
              </IconButton>
            </div>
          </div>
        </div>
      </Hidden>
    </React.Fragment>
  );
}
