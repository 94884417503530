import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Radio,
  Switch,
  Typography,
} from "@material-ui/core";
import { blueGrey, grey, orange } from "@material-ui/core/colors";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import {
  ArrowBack,
  ArrowForward,
  Close,
  Edit,
  PrintOutlined,
  Search,
} from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { formatNumber } from "accounting-js";
import clsx from "clsx";
import React, { useState } from "react";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_GET_ALL_BRANCHES,
  BNK_API_GET_ALL_LOAN_TYPES,
  BNK_API_SEARCH_ACCOUNT,
  BNK_API_SHOW_LOAN,
  BNK_API_SHOW_LOAN_TYPE,
  BNK_API_UPDATE_LOAN_APPLICATION,
  BNK_API_VALIDATE_LOAN_APPLICATION_FORM,
} from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";
import MicrosoftLoader from "../../../global/components/loader/MicrsoftLoader";
import { showDate } from "../../../global/hooks";
import { tableStyles } from "../Transactions/TransactionsTable";

const useStepperStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "100%",
    minHeight: "100vh",
    paddingBottom: "100px",
  },
  passportPhoto: {
    height: "150px",
    backgroundSize: "contain",
    position: "relative",
    backgroundRepeat: "no-repeat",
    borderRadius: "10px",
    backgroundPosition: "center",
  },
  passportPhotoCover: {
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundColor: "rgba(0,0,0,0.3)",
    borderRadius: "10px",
    color: "#fff",
    fontSize: "3rem",
    ["& *"]: {
      fontSize: "3rem",
    },
  },
  select: {
    width: "100%",
    height: "40px",
    padding: "5px 10px",
  },
  listItemLeft: {
    width: "200px",
    color: theme.palette.primary.main,
  },
  listItemTextRight: {
    width: "200px",
    textAlign: "left",
    ["&*"]: {
      textAlign: "left",
    },
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  listCard: {
    borderRadius: "10px",
    border: "solid 1px " + theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  listItemSecondaryText: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  ac_opened_card: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  passportPhotoImage: {
    width: "150px",
    height: "150px",
    background: "#fefefe",
    borderRadius: "5px",
    border: "dashed 1px #000",
  },
  profileId: {
    width: "150px",
    height: "150px",
    borderRadius: "5px",
    border: "solid 2px black",
  },
  idPhoto: {
    width: "200px",
    height: "150px",
    borderRadius: "5px",
    border: "solid 2px black",
  },
  searchInputRoot: {
    // backgroundColor: grey[50],
    boxShadow: theme.boxShadows[4],
    borderLeft: "solid 4px " + orange[600],
  },
  searchInput: {
    width: "calc(100% - 50px)",
    padding: "10px 20px",
  },
  listItem: {
    width: "100%",
    backgroundColor: grey[50],
    marginTop: "5px",
  },
  stepThreeRoot: {
    // backgroundColor: grey[50],
  },
  label: {
    fontSize: "0.9rem",
    color: blueGrey[500],
    fontWeight: 400,
  },
  paperCard: {
    backgroundColor: orange[500],
    color: "#fff",
  },
}));

const printAccountInfo = () => {
  window.print();
};

export default function UpdateLoanApplication(props) {
  const [dialogState, setDialogState] = useState(false);
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
  };

  return (
    <React.Fragment>
      <IconButton onClick={onDialogStateChange}>
        <Edit />
      </IconButton>
      <Dialog fullScreen fullWidth open={dialogState}>
        <Paper>
          <Container>
            <div className="d-flex justify-content-between align-items-center py-3">
              <Typography variant="h6">Update loan details</Typography>
              <IconButton onClick={onDialogStateChange}>
                <Close />
              </IconButton>
            </div>
            <ApiRequest
              thread={BNK_API_SHOW_LOAN}
              addOnThread={props.id}
              autoload
              params={{ loan_id: props.id }}
            >
              {({ res, error, loading, ErrorView, RefreshButton }) => {
                if (loading) {
                  return <CircularProgress size="50px" />;
                }

                if (error) {
                  return <ErrorView />;
                }

                let loan_application = null;
                if (typeof res == "object") {
                  if (typeof res.data == "object") {
                    if (typeof res.data.loan == "object") {
                      loan_application = res.data.loan;
                    }
                  }
                }

                if (loan_application == null) {
                  return (
                    <div className="w-100 d-flex justify-content-center align-items-center flex-column">
                      <Typography>Failed to get loan application.</Typography>
                      <RefreshButton variant="icon" />
                    </div>
                  );
                }

                return (
                  <ApiRequest
                    thread={BNK_API_UPDATE_LOAN_APPLICATION}
                    reloadOnSuccess={[BNK_API_SHOW_LOAN + props.id]}
                    isForm
                    initialPayload={{
                      //   loan_amount: 0,
                      //   amount_per_installment: 0,
                      //   installment_type: "WEEKLY",
                      //   accept_terms_and_conditions: false,
                      ...loan_application,
                      loan_type: loan_application.loan_type_id,
                      accept_terms_and_conditions: true,
                    }}
                  >
                    {(apiBody) => {
                      let account = {};
                      let {
                        input,
                        SubmitButton,
                        FileInput,
                        setPayload,
                        completed,
                        loading,
                        res,
                      } = apiBody;

                      if (typeof res == "object") {
                        if (typeof res.data == "object") {
                          if (typeof res.data.account === "object") {
                            if (completed === true && res.success === true) {
                              account = res.data.account;
                              return "Loan application completed";
                            }
                          }
                        }
                      }

                      return (
                        <React.Fragment>
                          <Grid item xs={12} md={12} className="mt-3">
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <HorizontalStepper
                                  {...apiBody}
                                  onDialogStateChange={onDialogStateChange}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      );
                    }}
                  </ApiRequest>
                );
              }}
            </ApiRequest>
          </Container>
        </Paper>
      </Dialog>
    </React.Fragment>
  );
}

function getSteps() {
  return [
    "Select Branch",
    "Guarantor",
    "Loan Type",
    "Loan Details",
    "Terms and Conditions",
  ];
}

function getStepContent(props) {
  switch (props.step) {
    case 0:
      return <StepOneChooseBranch {...props} />;

    case 1:
      return <StepThreeSelectGuarantorAccount {...props} />;

    case 2:
      return <StepThreeChooseLoanType {...props} />;

    case 3:
      return <StepFourLoanDetails {...props} />;

    default:
      return "Unknown step";
  }
}

function HorizontalStepper(props) {
  const classes = useStepperStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    if (activeStep == 3) {
      console.log("Active step is 6");
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep == 0) {
      console.log("Can't go back");
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  let OpenAccount = props.SubmitButton;

  const onDialogStateChange = props.onDialogStateChange;

  if (typeof props.res == "object") {
    if (typeof props.res.data == "object") {
      if (props.res.data.application == "saved" && activeStep == 3) {
        return (
          <div>
            <Alert>
              <AlertTitle>
                Loan Application has been updated successfully
              </AlertTitle>
            </Alert>
            <Button
              size="small"
              className="my-3"
              endIcon={<Close />}
              onClick={props.onDialogStateChange}
            >
              Close
            </Button>
          </div>
        );
      }
    }
  }

  return (
    <ApiRequest
      thread={BNK_API_VALIDATE_LOAN_APPLICATION_FORM}
      params={{
        step: getSteps()[activeStep].replaceAll(" ", "_").toUpperCase(),
      }}
      payload={props.payload}
      callbacks={{
        success: (res, xthis) => {
          if (typeof res == "object") {
            if (typeof res.data == "object") {
              if (res.data.validated == true) {
                if (activeStep == 3) {
                } else {
                  handleNext();
                }
              }
            }
          }
        },
      }}
    >
      {({ SubmitButton }) => {
        return (
          <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="horizontal">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className="px-0 px-md-5">
              {steps.map((label, index) => {
                if (activeStep == index) {
                  return (
                    <div>
                      <Grid container style={{ minHeight: "30vh" }}>
                        <Grid item xs={12}>
                          {getStepContent({
                            ...props,
                            step: index,
                          })}
                        </Grid>
                      </Grid>
                      <div className={classes.actionsContainer}>
                        <div>
                          {activeStep == 4 ? (
                            ""
                          ) : (
                            <Button
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              className={classes.button}
                              size="small"
                              color="secondary"
                              startIcon={<ArrowBack />}
                            >
                              Back
                            </Button>
                          )}

                          {activeStep == 3 ? (
                            <OpenAccount
                              disabled={activeStep === 0}
                              className={classes.button}
                              size="small"
                              variant="contained"
                              color="primary"
                              endIcon={<ArrowForward />}
                            >
                              Update Application
                            </OpenAccount>
                          ) : activeStep == 5 ? (
                            <Button
                              color="secondary"
                              size="small"
                              variant="contained"
                              endIcon={<Close />}
                              onClick={onDialogStateChange}
                            >
                              Close
                            </Button>
                          ) : (
                            <SubmitButton
                              variant="contained"
                              color="primary"
                              size="small"
                              className={classes.button}
                            >
                              Next
                            </SubmitButton>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        );
      }}
    </ApiRequest>
  );
}

function StepOneChooseBranch(props) {
  const classes = useStyles();
  const { input } = props;

  return (
    <ApiRequest thread={BNK_API_GET_ALL_BRANCHES} autoload>
      {({ res, loading, error, ErrorView, RefreshButton }) => {
        if (loading) {
          return (
            <div
              className="w-100 d-flex justify-content-center align-items-center p-5"
              align="center"
            >
              <CircularProgress size="50px" />
            </div>
          );
        }

        if (error) {
          return <ErrorView />;
        }

        let branches = [];
        if (typeof res == "object") {
          if (typeof res.data == "object") {
            if (Array.isArray(res.data.branches)) {
              branches = res.data.branches;
            }
          }
        }

        if (branches.length == null) {
          return (
            <div className="p-4 my-3" align="center">
              <Typography>No Branches opened yet.</Typography>
              <RefreshButton variant="icon" />
            </div>
          );
        }

        return (
          <div>
            <Typography variant="h4">Choose Branch</Typography>
            <Typography className="my-2">
              <small>
                Ensure that you have chosen the correct branch, once you have
                registered a member on a different branch it will cause some
                issues.
              </small>
            </Typography>
            <div style={{ width: 300 }}>
              <select
                {...input({ name: "branch_id" })}
                style={{
                  width: "100%",
                  padding: "5px 5px",
                  borderRadius: "5px",
                }}
                value={props.payload.branch_id}
              >
                <option value="null">Select Branch</option>
                {branches.map((branch, index) => {
                  return (
                    <option value={branch.branch_id} key={index}>
                      {branch.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        );
      }}
    </ApiRequest>
  );
}

function StepThreeSelectGuarantorAccount(props) {
  const classes = useStyles();
  const { setPayload, payload } = props;
  const [update_loan_account_number, setUpdateLoanAccountNumber] =
    useState(false);
  return (
    <Grid container>
      <Grid item xs={12} md={7} lg={6}>
        <Paper className="p-3">
          <Typography variant="h6" className="my-3">
            Guarantor Account Number
          </Typography>
          <Typography>
            <small>
              Changing guarantor wallet will mean that you are changing the
              customer who is the guarantor to the loan. Choose a person who
              qualifies as a guarantor
            </small>
          </Typography>
          <Divider />
          <div className="d-flex justify-content-between align-items-center">
            <Typography>
              <small style={{ color: grey[700] }}>
                <b>Update Guarantor Loan wallet</b>
              </small>
            </Typography>
            <Switch
              checked={update_loan_account_number}
              onChange={() => {
                setUpdateLoanAccountNumber(!update_loan_account_number);
              }}
            />
          </div>
          <Divider />
          {update_loan_account_number == false ? (
            <div>
              <Typography color="primary">
                {payload.guarantor_account_number}
              </Typography>
            </div>
          ) : (
            <ApiRequest
              initialPayload={{
                search_term: "",
              }}
              thread={BNK_API_SEARCH_ACCOUNT}
            >
              {({ loading, error, ErrorView, res, submit, input }) => {
                let results = [];
                if (typeof res == "object") {
                  if (typeof res.data == "object") {
                    if (Array.isArray(res.data.results)) {
                      results = res.data.results;
                    }
                  }
                }
                return (
                  <div>
                    <Paper
                      className={clsx(
                        "d-flex justify-content-between align-items-center",
                        classes.searchInputRoot
                      )}
                    >
                      <InputBase
                        {...input({ name: "search_term" })}
                        onKeyUp={submit}
                        className={classes.searchInput}
                        placeholder="Search customer account"
                      />
                      <div className={classes.searchIcon}>
                        <IconButton onClick={submit} disabled={loading}>
                          {loading == true ? <CircularProgress /> : <Search />}
                        </IconButton>
                      </div>
                    </Paper>
                    <div>
                      {loading == true ? (
                        <MicrosoftLoader />
                      ) : (
                        <List dense>
                          {results.map((account, index) => {
                            return (
                              <ListItem
                                key={index}
                                className={classes.listItem}
                                button
                                onClick={() =>
                                  setPayload({
                                    guarantor_account_number:
                                      account.account_number,
                                    account_id: account.id,
                                  })
                                }
                              >
                                <ListItemText secondary={account.account_name}>
                                  {account.account_number}
                                </ListItemText>
                                <ListItemSecondaryAction>
                                  <Radio
                                    onChange={() =>
                                      setPayload({
                                        guarantor_account_number:
                                          account.account_number,
                                      })
                                    }
                                    checked={
                                      payload.guarantor_account_number ==
                                      account.account_number
                                    }
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })}
                        </List>
                      )}
                    </div>
                  </div>
                );
              }}
            </ApiRequest>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

function StepThreeChooseLoanType(props) {
  const classes = useStyles();
  let { input } = props;
  return (
    <Grid container className="mt-1">
      <Grid item xs={12} md={6}>
        <Typography variant="h6" className="my-3">
          Choose the loan type
        </Typography>
        <Typography>
          <small>
            The type of loan you are applying for will determine the maximum and
            minimum loan amount, it will also determine the assets and nature of
            business.{" "}
          </small>
        </Typography>

        <ApiRequest autoload thread={BNK_API_GET_ALL_LOAN_TYPES}>
          {({ loading, error, ErrorView, res }) => {
            if (loading) {
              return (
                <div>
                  <CircularProgress />
                  Loading loan types
                </div>
              );
            }

            if (error) {
              return <ErrorView />;
            }

            let loan_types = null;
            if (typeof res == "object") {
              if (typeof res.data == "object") {
                if (Array.isArray(res.data.loan_types)) {
                  loan_types = res.data.loan_types;
                }
              }
            }

            if (loan_types == null) {
              return <Typography>No loan types available</Typography>;
            }

            return (
              <select
                required
                className={classes.select}
                {...input({ name: "loan_type" })}
                selected={props.payload.loan_type}
              >
                <option>Choose loan type</option>
                {loan_types.map((type) => {
                  return (
                    <option
                      selected={props.payload.loan_type == type.id}
                      value={type.id}
                    >
                      {type.name}
                    </option>
                  );
                })}
              </select>
            );
          }}
        </ApiRequest>
      </Grid>
    </Grid>
  );
}

function StepFourLoanDetails(props) {
  let { input, payload, setPayload } = props;
  const classes = useStyles();
  const tableClasses = tableStyles();
  const [state, setState] = useState({
    installments: [],
  });
  return (
    <ApiRequest
      thread={BNK_API_SHOW_LOAN_TYPE}
      autoload
      addOnThread={payload.loan_type}
      params={{ loan_type: payload.loan_type }}
    >
      {({ res, loading, error, ErrorView }) => {
        if (loading == true) {
          return <CircularProgress size="50px" />;
        }

        if (error == true) {
          return <ErrorView />;
        }

        let loan_type = null;
        if (typeof res == "object") {
          if (typeof res.data == "object") {
            if (typeof res.data.loan_type == "object") {
              loan_type = res.data.loan_type;
            }
          }
        }

        if (loan_type == null) {
          return (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Loan type does not exist.
            </Alert>
          );
        }

        let installments = [];
        let payback_amount = 0;
        let installment_amount = 0;
        let installment_balance = 0;
        let loan_amount = 0;

        function calculateInstallments() {
          return new Promise((resolve, reject) => {
            try {
              if (isNaN(payload.loan_amount) == false) {
                loan_amount = Number(payload.loan_amount);
              }

              if (isNaN(payload.amount_per_installment) == false) {
                installment_amount = Number(payload.amount_per_installment);
              }

              payback_amount = Number(
                loan_amount +
                  loan_amount * Number(loan_type.interest_rate / 100)
              );
              installment_balance = payback_amount;

              installments[0] = payback_amount;
              console.log(
                "Installment amount: ",
                installment_amount,
                "Installment Balance: ",
                installment_balance
              );
              let i = 1;
              while (installment_balance > 0 && installment_amount > 0) {
                i++;
                if (installment_balance < installment_amount) {
                  installment_balance =
                    installment_balance - installment_balance;
                  installments[i] = installment_balance;
                  break;
                } else {
                  installment_balance =
                    Number(installment_balance) - Number(installment_amount);
                  installments[i] = installment_balance;
                  continue;
                }
              }
            } catch (error) {
              console.log("Error while calculating loan amount", error);
              installments = [];
            }

            resolve({ installments, payback_amount });
          });
        }

        let reversed = [...installments];
        reversed = reversed.reverse();

        const onCalculate = () => {
          calculateInstallments()
            .then(({ installments, payback_amount }) => {
              if (
                JSON.stringify(installments) !==
                JSON.stringify(state.installments)
              ) {
                setState({
                  ...state,
                  installments: installments.slice(0, 50),
                  payback_amount,
                });
              }
            })
            .catch((error) => {
              console.log("Error was encountered.. ");
            });
        };

        /**
         * @returns installament date
         */
        function getInstallmentDate(first_installment_date, index) {
          if (
            first_installment_date == null ||
            first_installment_date == "" ||
            first_installment_date == "undefined"
          ) {
            first_installment_date = Date.now();
          }

          let date = new Date(first_installment_date);

          if (payload.installment_type == "daily") {
            date = date.setMinutes(1 * (24 * 60) * index);
          }

          if (payload.installment_type == "weekly") {
            date = date.setMinutes(7 * (24 * 60) * index);
          }

          if (payload.installment_type == "monthly") {
            date = date.setMinutes(30 * (24 * 60) * index);
          }

          if (payload.installment_type == "yearly") {
            date = date.setMinutes(30 * (24 * 60) * 12 * index);
          }

          return showDate(date);
        }

        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={clsx(classes.paperCard, "p-3")}>
                <Typography variant="h6" color="inherit">
                  {loan_type.name} Loan
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Paper className="my-2 p-3">
                    <Typography variant="h5">Customer Request</Typography>
                    <Divider className="my-2" />
                    <KijjeInput
                      square
                      placeholder="Enter the amount your applying for"
                      variant="filled"
                      label="Loan amount applied:"
                      {...input({ name: "loan_amount" })}
                      endIcon={<span className="pe-2">Ush</span>}
                    />
                    <Typography className={classes.label}>
                      Purpose of loan
                    </Typography>
                    <FormGroup size="small" row>
                      <FormControlLabel
                        size="small"
                        onClick={() => setPayload({ loan_purpose: "business" })}
                        control={
                          <Radio
                            checked={payload.loan_purpose == "business"}
                            size="small"
                          />
                        }
                        label="Business"
                      />
                      <FormControlLabel
                        onClick={() => setPayload({ loan_purpose: "medical" })}
                        size="small"
                        control={
                          <Radio
                            size="small"
                            checked={payload.loan_purpose == "medical"}
                          />
                        }
                        label="Medical"
                      />
                      <FormControlLabel
                        size="small"
                        onClick={() =>
                          setPayload({ loan_purpose: "school_fees" })
                        }
                        control={
                          <Radio
                            size="small"
                            checked={payload.loan_purpose == "school_fees"}
                          />
                        }
                        label="School Fees"
                      />
                      <FormControlLabel
                        size="small"
                        onClick={() => setPayload({ loan_purpose: "other" })}
                        control={
                          <Radio
                            size="small"
                            checked={payload.loan_purpose == "other"}
                          />
                        }
                        label="Other"
                      />
                    </FormGroup>
                    <KijjeInput
                      square
                      placeholder="Your first installment date:"
                      variant="filled"
                      label="First installment"
                      type="date"
                      {...input({ name: "first_installment_date" })}
                    />
                    <div>
                      <label>Installment type</label>
                      <select
                        className={classes.select}
                        {...input({ name: "installment_type" })}
                      >
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                      </select>
                    </div>
                    <KijjeInput
                      square
                      placeholder="Amount in Ugx:"
                      variant="filled"
                      label="Amount per installment"
                      type="text"
                      {...input({ name: "amount_per_installment" })}
                    />
                  </Paper>
                  <Paper className="my-2 p-3">
                    <Typography variant="h5">Credit History</Typography>
                    <Divider className="my-2" />
                    <Typography>
                      <small>
                        If you have any credit history from nexen or any other
                        bank or credit union, please provide us with at previous
                        last five loans.
                      </small>
                    </Typography>
                    <Typography>1st Loan</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Amount"
                          {...input({ name: "first_loan_amount" })}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Date"
                          type="date"
                          {...input({ name: "first_loan_date" })}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Period"
                          type=""
                          {...input({ name: "first_loan_period" })}
                        />
                      </Grid>
                    </Grid>
                    <Typography>2nd Loan</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Amount"
                          {...input({ name: "second_loan_amount" })}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Date"
                          type="date"
                          {...input({ name: "second_loan_date" })}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Period"
                          type=""
                          {...input({ name: "second_loan_period" })}
                        />
                      </Grid>
                    </Grid>
                    <Typography>3rd Loan</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Amount"
                          {...input({ name: "third_loan_amount" })}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Date"
                          type="date"
                          {...input({ name: "third_loan_date" })}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KijjeInput
                          square
                          placeholder="Period"
                          type=""
                          {...input({ name: "third_loan_period" })}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    className="p-2"
                    style={{ backgroundColor: blueGrey[50] }}
                  >
                    <div
                      className={clsx(
                        classes.cardTitle,
                        "d-flex justify-content-between align-items-center"
                      )}
                    >
                      <Typography variant="h6">
                        Loan Payment Schedule
                      </Typography>
                      <div className="d-flex justify-content-center align-items-center">
                        <Button
                          variant="contained"
                          size="small"
                          onClick={onCalculate}
                        >
                          Calculate Amount
                        </Button>
                        <IconButton>
                          <PrintOutlined />
                        </IconButton>
                      </div>
                    </div>
                    <Divider className="my-2" />
                    <Grid container>
                      <Grid item xs={12} sm={3}>
                        <div>
                          <small>Loan Amount</small>
                          <Typography>
                            {formatNumber(payload.loan_amount)}
                            <small>
                              <b>Ugx</b>
                            </small>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <div>
                          <small>Interest Rate</small>
                          <Typography>{loan_type.interest_rate}%</Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <div>
                          <small>Total Installments</small>
                          <Typography>{state.installments.length}</Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <div>
                          <small>Installment Method</small>
                          <Typography>{payload.installment_type}</Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Divider className="my-3" />
                    <div>
                      <table className={tableClasses.table}>
                        <thead className={tableClasses.tableHeading}>
                          <tr>
                            <th style={{ width: "30px" }}>No.</th>
                            <th>Date</th>
                            <th>Installment</th>
                            <th>Total</th>
                            <th>Balance</th>
                          </tr>
                        </thead>
                        <tbody className={tableClasses.tbody}>
                          {state.installments.map((installment, index) => {
                            if (index == 0) {
                              return "";
                            }
                            if (index == 49) {
                              return (
                                <tr>
                                  <td>
                                    <Button
                                      startIcon={<ArrowBack />}
                                      fullWidth
                                      size="small"
                                      variant="contained"
                                    >
                                      Prev
                                    </Button>
                                  </td>
                                  <td colSpan={3}></td>
                                  <td>
                                    <div className="w-100">
                                      <Button
                                        endIcon={<ArrowForward />}
                                        fullWidth
                                        size="small"
                                        variant="contained"
                                      >
                                        Next
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }

                            try {
                              return (
                                <tr>
                                  <td>{index}</td>
                                  <td>
                                    {getInstallmentDate(
                                      payload.first_installment_date,
                                      index
                                    )}
                                  </td>
                                  <td>
                                    {formatNumber(
                                      payload.amount_per_installment
                                    )}
                                    <small>Ugx</small>
                                  </td>
                                  <td>
                                    {formatNumber(
                                      Number(payback_amount) -
                                        Number(installment)
                                    )}
                                    <small>Ugx</small>
                                  </td>
                                  <td>
                                    {index + 1 == state.installments.length
                                      ? formatNumber(0)
                                      : `-${formatNumber(installment)}`}
                                    <small>Ugx</small>
                                  </td>
                                </tr>
                              );
                            } catch (error) {
                              return "";
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                    <Divider className="my-3" />
                    <div className="d-flex justify-content-between align-items-center px-2">
                      <Typography variant="h6">Total Amount</Typography>
                      <Typography variant="h6">
                        {formatNumber(state.payback_amount || 0)}{" "}
                        <small>Ugx</small>
                      </Typography>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </ApiRequest>
  );
}

function StepFiveTermsAndConditions(props) {
  const classes = useStyles();
  let { input, payload, setPayload } = props;
  return (
    <div>
      <Paper className="my-2 p-3">
        <Typography variant="h5">
          Personal Guarantee, Approval and Confirmation
        </Typography>
        <Divider className="my-2" />
        <Typography>We hereby confirm</Typography>
        <Typography className="mb-2">
          <b>i)</b>
          <small>that the applicant is a known to Nexen Micro Credit.</small>
        </Typography>
        <Typography className="mb-2">
          <b>ii)</b>
          <small>
            That the I/We have personally, physically inspected the assets
            intended as security, listed in the appraisal form and confirmed
            that the asset belong to the borrower.
          </small>
        </Typography>
        <Typography className="mb-2">
          <b>iii)</b>
          <small>
            That in an event default, as the term is defined in the loan
            Agreement, on the part of ANY OF THE UNDERSTAND GUARANTORS, each the
            undesigned GUARANTOR(S) agrees to be personally lianble for the
            payment to Nexen Micro Credit, of any and all principal service
            charge and penalties die and owing to Nexen Micro Creidit
          </small>
        </Typography>
        <Checkbox
          checked={payload.accept_terms_and_conditions == true}
          onClick={() =>
            setPayload({
              accept_terms_and_conditions: !payload.accept_terms_and_conditions,
            })
          }
        />{" "}
        I/We the undersigned authorize Nexen Micro Credit to share our credit
        information with the Ceditt Reference Bureau.
      </Paper>
      <Paper className="my-2 p-3">
        <Typography variant="h5">Loan Agreement</Typography>
        <Divider className="my-2" />
        <Typography>We hereby confirm</Typography>
        <Typography className="mb-2">
          Lorem, ipsum dolor sit amet consectetur adipisicing
          elit......................... Repellendus quidem in reprehenderit quo
          dicta, dolorem blanditiis autem velit numquam laboriosam repellat
          ullam, voluptatibus
        </Typography>
        <Typography className="mb-2">
          <b>ii)</b>
          <small>
            That the I/We have personally, physically inspected the assets
            intended as security, listed in the appraisal form and confirmed
            that the asset belong to the borrower.
          </small>
        </Typography>
      </Paper>
    </div>
  );
}
