import {
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { RefreshOutlined } from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import KijjeInput from "../../../global/components/inputs/Inputs";
import { SelectBranchMenu } from "../Transactions/TransactionsTable";
import CATBalances from "./CATBalances";
import CATTable from "./CATTable";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: grey[50],
    padding: "10px",
    boxShadow: "none",
    borderRadius: "0px",
    minHeight: "100vh",
  },
  paper: {
    width: "100%",
    boxShadow: theme.boxShadows[2],
    borderRadius: "10px",
    padding: "20px",
  },
  divider: {
    background: grey[400],
  },
  btn: {
    borderRadius: "10px",
    height: "30px",
    padding: "10px 10px",
  },
  select: {
    width: "100%",
    borderRadius: "10px",
  },
}));

export default function CheckingAccountTransactions(props) {
  const classes = useStyles();
  const [apiQueryState, setApiQueryState] = useState({
    branch: "ALL",
    start_date: "",
    end_date: "",
    refresh: "",
  });
  const onRefresh = () => {
    setApiQueryState({ ...apiQueryState, refresh: Date.now() });
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={12} md={5}>
          <Typography variant="h4">Checking Account Transactions</Typography>
          <Typography>
            <small>
              Manage the your main bank account transactions. <br />
              These are the transactions from the main account with the bank or
              central bank.
            </small>
          </Typography>
        </Grid>
        <Grid item>
          <div className="d-flex align-items-center ">
            <div className="pt-3">
              <IconButton onClick={onRefresh} className={classes.refreshBtn}>
                <RefreshOutlined />
              </IconButton>
            </div>
            <div className="px-1">
              <KijjeInput
                value={apiQueryState.start_date}
                onChange={(e) => {
                  setApiQueryState({
                    ...apiQueryState,
                    start_date: e.target.value,
                  });
                }}
                type="date"
                label="Start date"
                square
              />
            </div>
            <div className="px-1">
              <KijjeInput
                value={apiQueryState.end_date}
                onChange={(e) => {
                  setApiQueryState({
                    ...apiQueryState,
                    end_date: e.target.value,
                  });
                }}
                type="date"
                label="End date"
                square
              />
            </div>
            <SelectBranchMenu
              hasAll={true}
              isSelect
              input={() => ({
                onChange: (e) => {
                  setApiQueryState({
                    ...apiQueryState,
                    branch: e.target.value,
                  });
                },
                value: apiQueryState.branch,
              })}
              apiQueryState={apiQueryState}
              setApiQueryState={setApiQueryState}
            />
          </div>
        </Grid>
      </Grid>

      <Divider className={clsx(classes.divider, "my-2 mb-3")} />
      <div className="w-100">
        <CATBalances
          apiQueryState={apiQueryState}
          setApiQueryState={setApiQueryState}
        />
        <Grid container spacing={3} className="mt-2">
          <Grid item xs={12} md={12}>
            <CATTable />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
