import { configureStore, createSlice } from "@reduxjs/toolkit";
import { useState } from "react";
import { IS_LOGGED_IN_THREAD } from "./apiThreads";
import { cacheUserDataOnDevice } from "./cacheUserOnDevice";

const userModule = createSlice({
  name: "users",
  initialState: [
    {
      name: "",
    },
  ],
  reducers: {
    updateUsersList: (state, action) => {
      return { ...state };
    },
  },
});

const apiRequestStateChange = createSlice({
  name: "apiRequest",
  initialState: {
    CHANGE_STATE: false,
  },
  reducers: {
    updateApiRequestState: (state, { action, payload }) => {
      const { thread, data } = payload;
      state[thread] = data;
    },
  },
});

const dataStore = createSlice({
  name: "dataStore",
  initialState: {
    CHANGE_STATE: false,
  },
  reducers: {
    updateStore: (state, { action, payload }) => {
      const { thread, data } = payload;

      state[thread] = data;
    },
  },
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    authenticated: false,
    token: "",
    accountCreationOnGoing: false,
  },
  reducers: {
    loginUser: (state, { payload, action }) => {
      cacheUserDataOnDevice({
        ...payload,
      });
      state.authenticated = true;
      return state;
    },

    logoutUser: (state) => {
      state.authenticated = false;
      return state;
    },
    signUpUser: (state) => {
      state.authenticated = true;
      state.accountCreationOnGoing = true;
      return state;
    },

    accountCreationOnGoing: (state) => ({
      ...state,
      accountCreationOnGoing: true,
    }),

    completeSignUp: (state) => ({ ...state, accountCreationOnGoing: false }),
  },
});

const authPostConfirmationPin = createSlice({
  name: "authPostConfirmationPin",
  initialState: {
    pin: "",
    requestAuthorizationToken: "",
    showPinDialog: false,
  },

  reducers: {
    setPostPin: (state, action) => {
      console.log(state, action);
    },
    setAuthorizationToken: (state, action) => {
      console.log(state, action);
    },
    openConfirmationPinDialog: (state, action) => {
      this.showPinDialog = true;
    },
    closeConfirmationPinDialog: (state, action) => {
      this.showPinDialog = false;
    },
  },
});

export const {
  setPostPin,
  openConfirmationPinDialog,
  setAuthorizationToken,
  closeConfirmationPinDialog,
} = authPostConfirmationPin.actions;

export const {
  loginUser,
  logoutUser,
  signUpUser,
  accountCreationOnGoing,
  completeSignUp,
} = authSlice.actions;

export const { updateStore } = dataStore.actions;

/**
 * Api Request state change for monitoring state globerly
 */
export const { updateApiRequestState } = apiRequestStateChange.actions;

/**
 * Store all outgoing requests to the server
 */
const cacheRequestStore = createSlice({
  name: "cacheRequest",
  initialState: {
    CHANGE_STATE: false,
  },
  reducers: {
    cacheRequest: (state, { action, payload }) => {
      const { thread, refresh } = payload;
      state.CHANGE_STATE = new Date().getTime().toString();
      state[thread] = refresh;
    },
  },
});

export const { cacheRequest } = cacheRequestStore.actions;

const errorSnackbarSlice = createSlice({
  name: "errorSnackbar",
  initialState: {
    showError: false,
    type: null,
    errors: {},
    message: "",
    severity: "error",
  },
  reducers: {
    showErrorSnackBar: (state, { payload }) => {
      state.showError = true;
      state.message = payload.message;
      state.type = payload.type;
      state.errors = payload.errors;
      state.severity = payload.severity;
    },
    closeErrorSnackbar: (state, payload) => {
      state.showError = false;
      state.message = "";
      state.errors = {};
    },
  },
});
export const { showErrorSnackBar, closeErrorSnackbar } =
  errorSnackbarSlice.actions;

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    userModule: userModule.reducer,
    dataStore: dataStore.reducer,
    errorSnackbar: errorSnackbarSlice.reducer,
    cacheRequestStore: cacheRequestStore.reducer,
    apiRequestStateChange: dataStore.reducer,
    authPostConfirmationPin: authPostConfirmationPin.reducer,
  },
});

export default store;

export const useDefaultBranch = () => {
  const [branch, setBranch] = useState(false);
  store.subscribe(() => {
    const dataStore = store.getState().dataStore;
    const res = dataStore[IS_LOGGED_IN_THREAD];
    if (typeof res === "object") {
      if (typeof res.data === "object") {
        if (res.data.token === "valid") {
          if (typeof res.data.admin === "object") {
            if (
              res.data.admin.default_branch === null ||
              res.data.admin.default_branch === "null"
            ) {
            } else {
              if (branch !== res.data.admin.default_branch) {
                setBranch(res.data.admin.default_branch);
              }
            }
          }
        }
      }
    }
  });

  return branch;
};
