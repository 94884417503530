import {
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { ArrowForward, Refresh, Sort } from "@material-ui/icons";
import accounting from "accounting-js";
import clsx from "clsx";
import React, { useState } from "react";
import { Divider, Pagination } from "rsuite";
import ApiRequest from "../../../api/ApiRequest";
import { BNK_API_GET_ALL_INCOMES } from "../../../api/apiThreads";
import {
  ReadTransactionType,
  showTransactionDate,
} from "../Transactions/TransactionsHistory";
import {
  SelectBranchMenu,
  tableStyles,
} from "../Transactions/TransactionsTable";

export default function IncomesTable(props) {
  const classes = tableStyles();
  const { apiQueryState, setApiQueryState } = props;

  return (
    <Paper className={clsx(classes.tableRoot, "p-3")}>
      <ApiRequest
        addOnThread={JSON.stringify(apiQueryState)}
        autoload
        thread={BNK_API_GET_ALL_INCOMES}
        payload={apiQueryState}
      >
        {({
          loading,
          ErrorView,
          res,
          RefreshButton,
          refreshing,
          refresh,
          error,
        }) => {
          let incomes = [];

          if (loading) {
            return (
              <div className="w-100 p-5" align="center">
                <CircularProgress size="50px" />
              </div>
            );
          }

          if (error) {
            return (
              <div className="w-100 p-5" align="center">
                <ErrorView />
              </div>
            );
          }

          let total = 0;

          if (typeof res === "object") {
            if (res.success === true) {
              if (typeof res.data == "object") {
                if (Array.isArray(res.data.incomes)) {
                  incomes = res.data.incomes;
                  total = res.data.total;
                }
              }
            }
          }

          return (
            <React.Fragment>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <Typography className={classes.title}>
                    {props.title || "Incomes"}
                  </Typography>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <RefreshButton variant="icon" />
                </div>
              </div>
              <div className={classes.tableWrapper}>
                <table className={classes.table}>
                  <thead className={classes.tableHeading}>
                    <tr>
                      <th className={classes.col2}>No.</th>
                      <th>Trans Id</th>
                      <th>Date</th>
                      <th>Branch</th>
                      <th>Received from</th>
                      <th>Memo</th>
                      <th>Category</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody className={classes.tbody}>
                    {loading === true ? (
                      <div className="w-100 my-2" align="center">
                        <CircularProgress />
                      </div>
                    ) : (
                      ""
                    )}
                    {incomes.length === 0 ? (
                      <tr className="w-100 my-4" align="center">
                        <td colSpan={7} align="center">
                          <Typography>
                            <b>No incomes recorded yet</b>
                          </Typography>
                        </td>
                        <td></td>
                      </tr>
                    ) : (
                      incomes.map((income, index) => {
                        return (
                          <tr key={index}>
                            <td>{++index}</td>
                            <td>{income.transaction_id || "N/A"}</td>
                            <td>
                              {" "}
                              {showTransactionDate(
                                income.created_at || income.date
                              )}{" "}
                            </td>
                            <td>{income.branch}</td>
                            <td>{income.received_from}</td>
                            <td>
                              {income.memo.length > 50
                                ? income.memo.slice(0, 50) + "..."
                                : income.memo}
                            </td>
                            <td>
                              <ReadTransactionType
                                type={income.income_category}
                              />
                            </td>
                            <td>
                              {" "}
                              UGX {accounting.formatNumber(income.amount)}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                <Divider />
                <Pagination
                  layout={["total", "-", "limit", "|", "pager", "skip"]}
                  // size="md"
                  prev={true}
                  next={true}
                  first={true}
                  last={true}
                  ellipsis={true}
                  boundaryLinks={true}
                  total={total}
                  limit={apiQueryState.limit}
                  limitOptions={[10, 20, 50, 100, 200, 500]}
                  maxButtons={5}
                  activePage={apiQueryState.page}
                  onChangePage={(page) =>
                    setApiQueryState({ ...apiQueryState, page })
                  }
                  onChangeLimit={(limit) =>
                    setApiQueryState({ ...apiQueryState, limit, page: 1 })
                  }
                />
              </div>
            </React.Fragment>
          );
        }}
      </ApiRequest>
    </Paper>
  );
}
