import LoanPayment from ".";

const loanPaymentModule = {
  name: "Loan Payment",
  // name: "Disbursement",
  icon: <i class="bi bi-coin"></i>,
  path: "/loan-payment",
  Component: LoanPayment,
  subModules: [],
};

export default loanPaymentModule;
