

import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00A389",
      light: "#E1F2F0",
      dark: "#a72d25",
      text: "#fff",
    },
    secondary: {
      light: "#4890c9",
      dark: "#125183",
      main: "#1B75BC",
      contrastText: "#fff",
    },
    colored: {
      green: "#00FF00",
    },
    type: "light",
  },

  appBarHeight: "60px",
  secondaryAppBarHeight: "60px",
  sideBarWidth: "100px",
  boxShadows: [
    `0 1px 2px 0 rgba(0,0,0,0.05)`,
    `0 2px 5px 0 rgba(0,0,0,0.05)`,
    `0 5px 10px 0 rgba(0,0,0,0.05)`,
    `0 10px 15px 0 rgba(0,0,0,0.05)`,
    "1px 2px 1px -1px rgba(0,0,0,-2.8), 0px -1px 1px 0px rgba(0,0,0,-0.96), 0px 3px 15px 0px rgba(0,0,0,0.12)",
  ],
  bgColor: "#2680EB",
  typography: {
    color: "#253647",
    fontWeightRegular: "300",
    // fontFamily: "Poppins,sans-serif",
    fontFamily: "Jost, sans-serif",
    color: "#253647",
    // fontSize: "16px",
    lineHeight: "26px",
    fontWeight: "400",
    body1: {
      fontFamily: "Poppins,sans-serif",
      fontFamily: "Jost, sans-serif",

      fontSize: "16px",
      lineHeight: "26px",
    },
    h1: {
      fontFamily: "Poppins,sans-serif",
      fontFamily: "Jost, sans-serif",

      color: "#253647",
      fontWeight: 700,
      fontSize: "40px",
    },
    h2: {
      fontFamily: "Poppins,sans-serif",
      fontFamily: "Jost, sans-serif",

      color: "#253647",
      fontSize: "30px",
      lineHeight: "42px",
      fontWeight: 700,
    },
    h3: {
      fontSize: "24px",
      fontFamily: "Poppins,sans-serif",
      fontFamily: "Jost, sans-serif",

      color: "#253647",
      lineHeight: "34px",
      fontWeight: 700,
    },
    h4: {
      fontSize: "18px",
      color: "#253647",
      fontWeight: 600,
      lineHeight: "28px",
      fontFamily: "Poppins, sans-serif",
      fontFamily: "Jost, sans-serif",
    },
    h5: {
      fontSize: "1rem",
      color: "#253647",
      fontWeight: 600,
      lineHeight: "1.2rem",
      fontFamily: "Poppins, sans-serif",
      fontFamily: "Jost, sans-serif",
    },
    h6: {
      fontSize: "1.05rem",
      color: "#253647",
      fontWeight: 600,
      lineHeight: "1.1rem",
      fontFamily: "Poppins, sans-serif",
      fontFamily: "Jost, sans-serif",
    },
  },
});

export default theme;
