import { Divider, Typography, Grid } from "@material-ui/core";
import React, { useState } from "react";

export default function Reports(props) {
  const [apiQueryState, setApiQueryState] = useState({
    start_date: "",
    end_date: "",
    branch: "",
  });
  return (
    <div>
      <Grid container justifyContent="space-between align-items-center">
        <Grid item>
          <Typography>Reports</Typography>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Divider />
    </div>
  );
}
