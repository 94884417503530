import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { green, orange } from "@material-ui/core/colors";
import {
  ArrowForward,
  Check,
  Close,
  Money,
  Person,
  Phone,
  Print,
  Receipt,
} from "@material-ui/icons";
import { blueGrey } from "@mui/material/colors";
import accounting from "accounting-js";
import clsx from "clsx";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_GET_DEPOSIT_HISTORY,
  BNK_API_GET_TRANSACTION_RECEIPT,
  BNK_API_GET_TRANSACTION_STATISTICS,
  BNK_API_MAKE_DEPOSIT,
  IS_LOGGED_IN_THREAD,
} from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";
import { showDate } from "../../../global/hooks";
import FindAccount from "../Accounts/FindAccount";
import TransactionsTable, {
  tableStyles,
} from "../Transactions/TransactionsTable";
import { GetWithdrawStatistics } from "../Withdraws";
const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    boxShadow: theme.boxShadows[3],
    minHeight: "100%",
    borderRadius: "10px",
  },
  findAccountCard: {
    minHeight: "100%",
  },
  transactionComplete: {
    width: "100%",
    height: "100%",
    backgroundColor: orange[50],
    display: "flex",
    justifyContent: "center",
    borderRadius: "10px",
    alignItems: "center",
  },
  label: {
    color: blueGrey[500],
    fontSize: "0.85rem",
    fontWeight: "600 !important",
  },
  printableContentRoot: {
    // width: "100%",
    // height: "100vh",
    backgroundColor: "#fff",
    maxWidth: "300px",
    color: "#000!important",
  },
  table: {
    fontFamily: "mono!important",
  },
  rpBody: {
    fontSize: "2pt !important",
    ["& div"]: {
      fontSize: "2pt !important",
    },
  },
}));

export function PrintReceiptDialog(props) {
  const [apiQueryState, setApiQueryState] = useState();
  const [showPrintDialog, setShowPrintDialogState] = useState(false);
  const classes = tableStyles();
  const printableContentRef = useRef();

  const { companyName } = useSelector(({ dataStore }) => {
    if (typeof dataStore[IS_LOGGED_IN_THREAD] == "object") {
      const res = dataStore[IS_LOGGED_IN_THREAD];
      if (typeof res.data == "object") {
        if (typeof res.data.companyName == "string") {
          return { companyName: res.data.companyName };
        }
      }
    }
    return { companyName: "" };
  });

  const printContent = (props) => {
    // var printableContent = document.getElementById("printable-content");
    // printableContent.innerHTML = printableContentRef.current.innerHTML;
    window.print();
  };

  return (
    <ApiRequest
      thread={BNK_API_GET_TRANSACTION_RECEIPT}
      addOnThread={JSON.stringify(apiQueryState)}
      payload={{
        transaction_id: props.transaction_id,
        branch_id: props.branch_id,
      }}
      callbacks={{
        success: (res) => {
          if (typeof res === "object") {
            if (typeof res.data === "object") {
              if (
                res.data.receiptReady === true &&
                typeof res.data.transaction === "object"
              ) {
                setShowPrintDialogState(true);
              }
            }
          }
        },
      }}
    >
      {({ SubmitButton, res, loading }) => {
        let transaction = {};
        if (typeof res === "object") {
          if (typeof res.data === "object") {
            if (
              res.data.receiptReady === true &&
              typeof res.data.transaction === "object"
            ) {
              transaction = res.data.transaction;
            }
          }
        }

        if (loading === true) {
          return <CircularProgress />;
        }

        return (
          <React.Fragment>
            <SubmitButton
              className="print-display-none"
              variant="outlined"
              endIcon={<Print />}
              size="small"
            >
              {props.variant == "icon" ? "" : "Print Receipt"}
            </SubmitButton>
            <Dialog
              open={showPrintDialog}
              // fullWidth
              // maxWidth="sm"
              scroll="body"
            >
              <DialogTitle className="d-none-on-print">
                <div className="d-flex justify-content-between align-items-center">
                  <Typography variant="h6">Print Receipt</Typography>
                  <IconButton
                    onTouch={() => setShowPrintDialogState(false)}
                    onClick={() => setShowPrintDialogState(false)}
                  >
                    <Close />
                  </IconButton>
                </div>
              </DialogTitle>
              <DialogContent>
                <div ref={printableContentRef} id="printable-content-a">
                  <div className="w-100">
                    <div className="w-100">
                      <div align="center" className="w-100">
                        <Typography
                          className="title"
                          align="center"
                          variant="h6"
                        >
                          {companyName}
                          <br />
                        </Typography>
                        <b>*******************************</b>
                        <Typography variant="h6">
                          <small>{transaction.branch}</small>
                        </Typography>
                      </div>
                    </div>

                    <div
                      className="w-100"
                      style={{
                        borderBottom: "dotted #000 2pt",
                      }}
                    ></div>
                    <div className="rp-body p-2 pe-3 ps-0">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b>A/c Name:</b>
                        </div>
                        <div>{transaction.account_name}</div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <b>A/c No:</b>
                        </div>
                        <div>{transaction.account_number}</div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <b>Amount:</b>
                        </div>
                        <div>
                          <small>UGX:</small>{" "}
                          {accounting.formatNumber(transaction.amount)}
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div>
                          <b>Teller Name:</b>{" "}
                        </div>
                        <div>{transaction.creator_name}</div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <b>Trans by</b>
                        </div>
                        <div>{transaction.transaction_by}</div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <b>Trans Id</b>
                        </div>
                        <div>{transaction.transaction_id}</div>
                      </div>
                      {transaction.loan_application_id !== null ? (
                        <div className="d-flex justify-content-between">
                          <div>
                            <b>Loan Application Id: </b>
                          </div>
                          <div>{transaction.loan_application_id}</div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="d-flex justify-content-between">
                        <div>
                          <b>Date Time</b>
                        </div>
                        <div>{showDate(transaction.transaction_date)}</div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <b>Desc: </b>
                        </div>
                        <div>{transaction.description}</div>
                      </div>
                    </div>
                    <div
                      className="w-100"
                      style={{
                        borderBottom: "dotted #000 2pt",
                      }}
                    ></div>
                    <div className="d-flex justify-content-between mt-2 pe-2">
                      <div>
                        <b>Account Balance: </b>
                      </div>
                      <div>
                        <small>UGX: </small>
                        {accounting.formatNumber(transaction.account_balance)}
                      </div>
                    </div>

                    {transaction.loan_application_id !== null &&
                    transaction.type !== "DIRECT_LOAN_PAYMENT_DEPOSIT" ? (
                      <div className="d-flex justify-content-between pe-2">
                        <div>
                          <b>Loan Balance </b>
                        </div>
                        <div>
                          <small>UGX: </small>
                          {accounting.formatNumber(transaction.loan_balance)}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className="w-100  mt-5"
                      style={{
                        borderBottom: "dotted #000 2pt",
                      }}
                    ></div>
                    {/* <div style={{ fontSize: "7pt!important" }}>
                      Account balance is as of:{" "}
                      <div>{showDate(transaction.account_balance_date)}</div>
                    </div> */}
                    {/* <div className="w-100 my-2" align="center">
                      <div align="center" style={{ width: "10px" }}>
                        <QRCode size={100} value={companyName} />
                      </div>
                    </div> */}
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  className={clsx("d-none-on-print")}
                  fullWidth
                  endIcon={<Print />}
                  variant="contained"
                  size="small"
                  onClick={printContent}
                >
                  Print
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        );
      }}
    </ApiRequest>
  );
}

export default function Deposits(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={2} justify="center" className={clsx(classes.root)}>
      <Helmet>
        <title>Deposits</title>
      </Helmet>
      <Grid item xs={12}>
        <div>
          <Typography variant="h6">Deposits</Typography>
        </div>
        <Divider className="my-2" />
      </Grid>
      <GetWithdrawStatistics type="deposit" />
      <Grid item xs={12} md={8}>
        <FindAccount
          transaction_type="DEPOSIT"
          title="Make Deposit"
          className={classes.findAccountCard}
        >
          {({ account, onReset }) => {
            return (
              <ApiRequest
                thread={BNK_API_MAKE_DEPOSIT}
                reloadOnSuccess={[
                  BNK_API_GET_DEPOSIT_HISTORY,
                  BNK_API_GET_TRANSACTION_STATISTICS,
                ]}
                initialPayload={{
                  name: "",
                  phone_number: "",
                  amount: "",
                  early_entry: false,
                  account_name: account.account_name,
                  account_number: account.account_number,
                }}
                isQueryInput
              >
                {({
                  input,
                  loading,
                  error,
                  ErrorView,
                  SubmitButton,
                  completed,
                  payload,
                  setPayload,
                  res,
                  resetComplete,
                }) => {
                  if (typeof res == "object") {
                    if (typeof res.data == "object") {
                      if (typeof res.data.deposit && completed) {
                        let deposit = res.data.deposit;
                        return (
                          <div
                            className={clsx(
                              classes.transactionComplete,
                              "flex-column pb-3"
                            )}
                          >
                            <Check
                              style={{ fontSize: "50px", color: green[600] }}
                            />
                            <Typography>Transaction Recorded</Typography>
                            <Typography variant="h6">
                              Deposited{" "}
                              {accounting.formatNumber(payload.amount, 2)}
                            </Typography>{" "}
                            <div>
                              <Typography variant="h4">
                                Balance <small>Shs: </small>
                                {accounting.formatNumber(deposit.balance, 2)}
                              </Typography>
                            </div>
                            <div className="d-flex">
                              <Button
                                onClick={onReset}
                                variant="outlined"
                                endIcon={<Close />}
                                size="small"
                              >
                                Close
                              </Button>
                              <PrintReceiptDialog
                                transaction_id={deposit.id}
                                branch_id={deposit.branch_id}
                              />
                            </div>
                          </div>
                        );
                      }
                    }
                  }

                  return (
                    <React.Fragment>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4}>
                          <KijjeInput
                            variant="outlined"
                            square
                            {...input({ name: "name" })}
                            label="Depositor's Name"
                            startIcon={<Person />}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <KijjeInput
                            variant="outlined"
                            square
                            startIcon={<Phone />}
                            {...input({ name: "phone_number" })}
                            label="Depositor's Phone Number"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <KijjeInput
                            variant="outlined"
                            square
                            startIcon={<Receipt />}
                            {...input({ name: "slip_no" })}
                            label="Deposit Slip No. "
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs={5}>
                          <KijjeInput
                            variant="outlined"
                            startIcon={<Money />}
                            type="text"
                            square
                            {...input({
                              name: "amount",
                              type: "text",
                            })}
                            label="Amount"
                          />
                        </Grid>
                        <Grid item xs={7}>
                          <Typography variant="h4" className="mt-4">
                            <small>Shs: </small>
                            {accounting.formatNumber(payload.amount, 2)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider
                        className="my-2"
                        style={{ backgroundColor: "red" }}
                      />
                      <div>
                        <List dense disablePadding className="p-0">
                          <ListItem
                            className="p-0"
                            button
                            onClick={() =>
                              setPayload({ early_entry: !payload.early_entry })
                            }
                          >
                            <ListItemText>Record as entry deposit</ListItemText>
                            <ListItemSecondaryAction className="p-0 me-0">
                              <div className="ms-3" align="right">
                                <Switch
                                  size="small"
                                  onClick={() =>
                                    setPayload({
                                      early_entry: !payload.early_entry,
                                    })
                                  }
                                  checked={payload.early_entry}
                                />
                              </div>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Collapse in={payload.early_entry}>
                            <TextField
                              {...input({ name: "created_at" })}
                              size="small"
                              type="date"
                              fullWidth
                              label="Deposit date"
                            />
                          </Collapse>
                        </List>
                      </div>
                      <Divider
                        className="my-2"
                        style={{ backgroundColor: "red" }}
                      />
                      <div className="w-100 mt-2" align="right">
                        <Button
                          startIcon={<Close />}
                          variant="outlined"
                          className="mx-2"
                          onClick={onReset}
                        >
                          Close
                        </Button>
                        <SubmitButton
                          variant="contained"
                          endIcon={<ArrowForward />}
                        >
                          Deposit
                        </SubmitButton>
                      </div>
                    </React.Fragment>
                  );
                }}
              </ApiRequest>
            );
          }}
        </FindAccount>
      </Grid>
      <Grid item xs={12}>
        <TransactionsTable
          title="Deposit History"
          show="deposit|type"
          where="t.deposit"
          hide={["type"]}
        />
      </Grid>
    </Grid>
  );
}
