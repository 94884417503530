import {
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Switch,
  Typography,
} from "@material-ui/core";
import { blueGrey, grey } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";
import React from "react";
import ApiRequest from "../../../api/ApiRequest";
import {
  SETTINGS_GET_SYSTEM_NOTIFICATIONS_MAIL,
  SETTINGS_NOTIFICATIONS_MAIL_SEND_TEST_MAIL,
  SETTINGS_UPDATE_SYSTEM_NOTIFICATIONS_MAIL,
} from "../../../api/apiThreads";
import { KijjeInput } from "../../../global/components/inputs/Inputs";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.boxShadows[2],
    borderRadius: "10px",
    minHeight: "50vh",
    padding: theme.spacing(3),
  },
}));
export default function SystemNotifications(props) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} md={10}>
        <Paper className={classes.root}>
          <ApiRequest thread={SETTINGS_GET_SYSTEM_NOTIFICATIONS_MAIL} autoload>
            {({ loading, error, ErrorView, res, RefreshButton }) => {
              if (loading) {
                return (
                  <div align="center" className="w-100 p-5">
                    <CircularProgress size="50px" />
                  </div>
                );
              }

              if (error) {
                return <ErrorView />;
              }

              let notificationsMail = null;
              if (typeof res == "object") {
                if (typeof res.data == "object") {
                  if (typeof res.data.notificationsMail == "object") {
                    notificationsMail = res.data.notificationsMail;
                  }
                }
              }

              return (
                <ApiRequest
                  callbacks={{
                    success: (res, xthis) => {
                      if (typeof res == "object") {
                        if (typeof res.data == "object") {
                          if (res.data.updated == true) {
                            xthis.setState({
                              ...xthis.state,
                              dialogMessage: "Settings have been updated",
                              dialogTitle: "Notifications Mail",
                              showDialog: true,
                            });
                          }
                        }
                      }
                    },
                  }}
                  initialPayload={{
                    port: "",
                    host: "",
                    username: "",
                    password: "",
                    secure: true,
                    ...notificationsMail,
                  }}
                  thread={SETTINGS_UPDATE_SYSTEM_NOTIFICATIONS_MAIL}
                  reloadOnSuccess={SETTINGS_GET_SYSTEM_NOTIFICATIONS_MAIL}
                >
                  {({ SubmitButton, input, payload }) => {
                    return (
                      <div>
                        <div className="d-flex justify-content-between align-items-center">
                          <Typography variant="h6">
                            Notifications Mail
                          </Typography>
                          <RefreshButton variant="icon" />
                        </div>
                        <Divider className="my-2" />
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            <Grid container alignItems="center">
                              <Grid item xs={12} md={6}>
                                <Typography variant="body2">
                                  Mail server
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <KijjeInput
                                  square
                                  variant="filled"
                                  placeholder="Host"
                                  {...input({ name: "host" })}
                                />
                              </Grid>
                            </Grid>
                            <Grid container alignItems="center">
                              <Grid item xs={12} md={6}>
                                <Typography variant="body2">Port</Typography>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <KijjeInput
                                  square
                                  variant="filled"
                                  placeholder="Port"
                                  {...input({ name: "port" })}
                                />
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={12} md={6}>
                                <Typography variant="body1">Secure</Typography>
                                <Typography
                                  style={{
                                    color: blueGrey[500],
                                    fontSize: "0.7rem",
                                  }}
                                >
                                  Are you using a secure protocal.
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormControlLabel
                                  {...input({ name: "secure" })}
                                  checked={payload.secure}
                                  control={
                                    <Switch
                                      size="small"
                                      {...input({ name: "secure" })}
                                    />
                                  }
                                  label="Secure"
                                />
                              </Grid>
                            </Grid>

                            <Grid container alignItems="center">
                              <Grid item xs={12} md={6}>
                                <Typography variant="body2">
                                  Username
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <KijjeInput
                                  square
                                  variant="filled"
                                  placeholder="Username"
                                  {...input({ name: "username" })}
                                />
                              </Grid>
                            </Grid>
                            <Grid container alignItems="center">
                              <Grid item xs={12} md={6}>
                                <Typography variant="body2">
                                  Password
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <KijjeInput
                                  square
                                  variant="filled"
                                  placeholder="Password"
                                  type="password"
                                  {...input({ name: "password" })}
                                />
                              </Grid>
                              <Grid container alignItems="center">
                                <Grid item xs={12} md={6}>
                                  <Typography variant="body2">
                                    From Name
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <KijjeInput
                                    square
                                    variant="filled"
                                    placeholder="From name"
                                    {...input({ name: "from_name" })}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container alignItems="center">
                                <Grid item xs={12} md={6}>
                                  <Typography variant="body2">
                                    Cc email
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <KijjeInput
                                    square
                                    variant="filled"
                                    placeholder="Copy Email 1"
                                    {...input({ name: "cc_email_1" })}
                                  />
                                </Grid>
                              </Grid>

                              <Grid container alignItems="center">
                                <Grid item xs={12} md={6}>
                                  <Typography variant="body2">
                                    Cc email two
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <KijjeInput
                                    square
                                    variant="filled"
                                    placeholder="Copy Email 2"
                                    {...input({ name: "cc_email_2" })}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>

                            <div className="w-100 mt-3" align="right">
                              <SubmitButton
                                variant="contained"
                                color="secondary"
                                size="small"
                                className="btn-round"
                              >
                                Update Settings
                              </SubmitButton>
                            </div>
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <Divider className="mb-2 " />
                            <div>
                              <ApiRequest
                                thread={
                                  SETTINGS_NOTIFICATIONS_MAIL_SEND_TEST_MAIL
                                }
                                callbacks={{
                                  success: (res, xthis) => {
                                    if (typeof res == "object") {
                                      if (typeof res.data == "object") {
                                        if (res.data.sent == true) {
                                          xthis.setState({
                                            ...xthis.state,
                                            dialogMessage:
                                              "Test message has been sent. Please check your email",
                                            dialogTitle: "Message sent",
                                            showDialog: true,
                                          });
                                        }
                                      }
                                    }
                                  },
                                }}
                              >
                                {({
                                  SubmitButton,
                                  error,
                                  errorMessage,
                                  input,
                                }) => {
                                  return (
                                    <div>
                                      <Typography variant="h6">
                                        Send Test Mail
                                      </Typography>
                                      {error ? (
                                        <Alert severity="error">
                                          {errorMessage}
                                        </Alert>
                                      ) : (
                                        ""
                                      )}

                                      <Grid
                                        container
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Grid item xs={12} md={6}>
                                          <Typography variant="body2">
                                            Email to send message to
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                          <KijjeInput
                                            variant="filled"
                                            square
                                            type="email"
                                            placeholder="Email"
                                            {...input({
                                              name: "email",
                                            })}
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        className="mt-3"
                                        spacing={2}
                                      >
                                        <Grid item xs={12} md={6}>
                                          <Typography
                                            style={{ fontSize: "0.9rem" }}
                                          >
                                            Message
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            style={{
                                              color: blueGrey[200],
                                              fontSize: "0.7rem",
                                            }}
                                          >
                                            Add a message to send out to your
                                            email that you have provided. If you
                                            don't receive the email within 2min
                                            please check the configurations and
                                            try again
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                          <KijjeInput
                                            variant="filled"
                                            square
                                            multiline
                                            placeholder="Your message here...."
                                            {...input({ name: "message" })}
                                          />
                                        </Grid>
                                        <Grid item xs={12} align="right">
                                          <SubmitButton
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                          >
                                            Send Message
                                          </SubmitButton>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  );
                                }}
                              </ApiRequest>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  }}
                </ApiRequest>
              );
            }}
          </ApiRequest>
        </Paper>
      </Grid>
    </Grid>
  );
}
