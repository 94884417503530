import Withdraws from ".";

const withdrawsModule = {
  name: "Withdraws",
  icon: <i class="bi bi-arrow-bar-up"></i>,
  path: "/withdraws",
  Component: Withdraws,
  subModules: [],
};

export default withdrawsModule;
