import {
  AppBar,
  Box,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { grey, orange } from "@material-ui/core/colors";
import {
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Payment,
  Phone,
  RefreshOutlined,
} from "@material-ui/icons";
import { formatNumber } from "accounting-js";
import React, { useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { Tooltip, Whisper } from "rsuite";
import ApiRequest from "../../../api/ApiRequest";
import { BNK_API_GET_DATE_INSTALLMENTS } from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";
import { showTransactionDate } from "../Transactions/TransactionsHistory";
import {
  SelectBranchMenu,
  tableStyles,
} from "../Transactions/TransactionsTable";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    boxShadow: "border-box",
    margin: "0px",
    padding: "20px",
    borderRadius: "10px",
    background: grey[100],
    "& .calendar-todo-list": {
      listStyle: "none",
      padding: 0,
      margin: 0,
      "& li": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
  },
  paper: {
    boxShadow: theme.boxShadows[3],
    borderRadius: "10px",
  },
}));

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function LoanPaymentTimeline(props) {
  const classes = useStyles();
  const tableClasses = tableStyles();
  const history = useHistory();
  const [tabState, setTabState] = useState(0);
  const [apiQueryState, setApiQueryState] = useState({
    date: new Date(),
    page: 1,
    limit: 100,
  });

  return (
    <div className={classes.root}>
      <div
        className="d-flex mb-3 justify-content-between align-items-end"
        style={{ position: "relative" }}
      >
        <div className="d-flex align-items-center">
          <div style={{ fontSize: "1.5rem" }}>
            <i className=" bi-grid"></i>
          </div>
          <Typography className="mt-1 ms-1" variant="h6">
            Loan payment timeline
          </Typography>
        </div>
        <div className="d-flex align-items-center ">
          <div className="pt-3">
            <IconButton
              onClick={() => {
                setApiQueryState({ ...apiQueryState, refresh: Date.now() });
              }}
              className={classes.refreshBtn}
            >
              <RefreshOutlined />
            </IconButton>
          </div>
          <div className="px-1">
            <KijjeInput
              value={apiQueryState.start_date}
              onChange={(e) => {
                setApiQueryState({
                  ...apiQueryState,
                  start_date: e.target.value,
                });
              }}
              type="date"
              label="Start date"
              square
            />
          </div>
          <div className="px-1">
            <KijjeInput
              value={apiQueryState.end_date}
              onChange={(e) => {
                setApiQueryState({
                  ...apiQueryState,
                  end_date: e.target.value,
                });
              }}
              type="date"
              label="End date"
              square
            />
          </div>
          <SelectBranchMenu
            hasAll={true}
            isSelect
            input={() => ({
              onChange: (e) => {
                setApiQueryState({
                  ...apiQueryState,
                  branch: e.target.value,
                });
              },
              value: apiQueryState.branch,
            })}
            apiQueryState={apiQueryState}
            setApiQueryState={setApiQueryState}
          />
        </div>
      </div>
      <Divider />
      <Grid container>
        <Grid item xs={12}>
          <AppBar position="static" color="default">
            <Tabs
              value={tabState}
              onChange={(e, index) => {
                setTabState(index);
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="MON" {...a11yProps(0)} />
              <Tab label="TUE" {...a11yProps(1)} />
              <Tab label="WED" {...a11yProps(2)} />
              <Tab label="THUR" {...a11yProps(3)} />
              <Tab label="FIR" {...a11yProps(4)} />
              <Tab label="SAT" {...a11yProps(5)} />
              <Tab label="SUN" {...a11yProps(6)} />
            </Tabs>
          </AppBar>
          <ApiRequest
            autoload
            thread={BNK_API_GET_DATE_INSTALLMENTS}
            addOnThread={JSON.stringify(apiQueryState)}
            query={apiQueryState}
          >
            {({ loading, error, res, ErrorView, RefreshButton }) => {
              if (loading === true) {
                return (
                  <div className="d-flex justify-content-center align-items-center p-5">
                    <CircularProgress />
                  </div>
                );
              }

              if (error) {
                return (
                  <div className="d-flex justify-content-center align-items-center p-5">
                    <ErrorView />
                  </div>
                );
              }

              let payments_schedule = {
                mon: [],
                tue: [],
                wed: [],
                thu: [],
                fri: [],
                sat: [],
                sun: [],
              };

              if (typeof res === "object") {
                if (typeof res.data === "object") {
                  if (typeof res.data.payments_schedule == "object") {
                    payments_schedule = {
                      ...payments_schedule,
                      ...res.data.payments_schedule,
                    };
                  }
                }
              }

              const RenderLoanInstallments = (props) => {
                let application_ids = [];
                let installments = [];
                let future_installments = [];
                props.installments.map((loan) => {
                  if (application_ids.indexOf(loan.application_id) === -1) {
                    application_ids.push(loan.application_id);
                    installments.push(loan);
                  } else {
                    future_installments.push(loan);
                  }
                  return loan;
                });

                return (
                  <div className="p-3 w-100" style={{ overflowX: "auto" }}>
                    <table className={tableClasses.table}>
                      <thead className={tableClasses.tableHeading}>
                        <tr>
                          <th style={{ width: "30px" }}></th>
                          <th style={{ width: "30px" }}>No.</th>
                          <th>Borrower</th>
                          <th>Branch</th>
                          <th>Wallet Id</th>
                          <th>
                            <Whisper
                              trigger="click"
                              placement="topEnd"
                              speaker={<Tooltip>Application Id</Tooltip>}
                            >
                              <span>Loan Id</span>
                            </Whisper>
                          </th>
                          <th>
                            <Whisper
                              trigger="click"
                              placement="topEnd"
                              speaker={
                                <Tooltip>Amount Per installment</Tooltip>
                              }
                            >
                              <span>Amt/I </span>
                            </Whisper>
                          </th>
                          <th>
                            <Whisper
                              trigger="click"
                              placement="topEnd"
                              speaker={<Tooltip>Installment Date</Tooltip>}
                            >
                              <span>Date</span>
                            </Whisper>
                          </th>
                          <th>Instal./ Type</th>
                          <th>Status</th>
                          <th>Phone No</th>
                          <th>Pay Loan</th>
                        </tr>
                      </thead>
                      {loading === true ? <CircularProgress size="50px" /> : ""}
                      {error === true ? <ErrorView /> : ""}

                      {installments.length > 0 &&
                      loading === false &&
                      error === false ? (
                        <tbody className={tableClasses.tbody}>
                          {installments.map((loan, index) => {
                            return (
                              <LazyLoadComponent>
                                <TableRow
                                  index={index + 1}
                                  installment={loan}
                                  future_installments={future_installments}
                                />
                              </LazyLoadComponent>
                            );
                          })}
                        </tbody>
                      ) : (
                        ""
                      )}
                    </table>
                    {installments.length === 0 &&
                    loading === false &&
                    error === false ? (
                      <div className="d-flex w-100 flex-column align-items-center justify-content-center my-5">
                        <RefreshButton variant="icon" />
                        <Typography>No installments to display</Typography>{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              };

              if (tabState === 0) {
                return (
                  <RenderLoanInstallments
                    installments={payments_schedule.mon}
                  />
                );
              } else if (tabState === 1) {
                return (
                  <RenderLoanInstallments
                    installments={payments_schedule.tue}
                  />
                );
              } else if (tabState === 2) {
                return (
                  <RenderLoanInstallments
                    installments={payments_schedule.wed}
                  />
                );
              } else if (tabState === 3) {
                return (
                  <RenderLoanInstallments
                    installments={payments_schedule.thu}
                  />
                );
              } else if (tabState === 4) {
                return (
                  <RenderLoanInstallments
                    installments={payments_schedule.fri}
                  />
                );
              } else if (tabState === 5) {
                return (
                  <RenderLoanInstallments
                    installments={payments_schedule.sat}
                  />
                );
              } else if (tabState === 6) {
                return (
                  <RenderLoanInstallments
                    installments={payments_schedule.sun}
                  />
                );
              } else {
                return "";
              }
            }}
          </ApiRequest>
        </Grid>
      </Grid>
    </div>
  );
}

function FutureInstallments(props) {
  const tableClasses = tableStyles();
  let installments = props.installments;
  return (
    <React.Fragment>
      <Collapse in={props.collapseState}>
        <Paper className="p-3">
          <List style={{ backgroundColor: orange[50] }}>
            <ListItem>
              <ListItemText
                secondary={
                  <small>
                    <small>Ugx </small>
                    {formatNumber(props.loan_amount)}
                  </small>
                }
              >
                <small>Loan Amount</small>
              </ListItemText>
              <ListItemText
                secondary={
                  <small>
                    <small>Ugx </small>
                    {formatNumber(props.loan_interest)}
                  </small>
                }
              >
                <small>Loan Interest</small>
              </ListItemText>
              <ListItemText
                secondary={
                  <small>
                    <small>Ugx </small>
                    {formatNumber(props.amount_paid)}
                  </small>
                }
              >
                <small>Amount Paid</small>
              </ListItemText>
              <ListItemText
                secondary={
                  <small>
                    <small>Ugx </small>
                    {formatNumber(props.loan_balance)}
                  </small>
                }
              >
                <small>Loan Balance</small>
              </ListItemText>
            </ListItem>
          </List>
          {Array.isArray(installments) === true ? (
            <table className={tableClasses.table}>
              <thead className={tableClasses.tableHeading}>
                <tr>
                  <th style={{ width: "30px" }}>No.</th>
                  <th>L/Amount</th>
                  <th>L/Interest</th>
                  <th>
                    <Whisper
                      trigger="click"
                      placement="topEnd"
                      speaker={<Tooltip>Amount Per installment</Tooltip>}
                    >
                      <span>Amt/I </span>
                    </Whisper>
                  </th>
                  <th>Amt. Paid</th>
                  <th>L/Balance</th>
                  <th>
                    <Whisper
                      trigger="click"
                      placement="topEnd"
                      speaker={<Tooltip>Installment Date</Tooltip>}
                    >
                      <span>Date</span>
                    </Whisper>
                  </th>
                </tr>
              </thead>
              <tbody className={tableClasses.tbody}>
                {installments.map((loan, index) => {
                  return (
                    <LazyLoadComponent>
                      <tr>
                        <td>{Number(index) + 1}</td>
                        <td>
                          <small>Ugx </small>
                          {formatNumber(loan.loan_amount)}{" "}
                        </td>
                        <td>
                          <small>Ugx </small>
                          {formatNumber(loan.loan_interest)}
                        </td>
                        <td>
                          <small>Ugx </small>
                          {formatNumber(loan.amount_per_installment)}
                        </td>
                        <td>
                          <small>Ugx </small>
                          {formatNumber(loan.amount_paid)}
                        </td>
                        <td>
                          <small>Ugx </small>
                          {formatNumber(loan.loan_balance)}
                        </td>
                        <td>{showTransactionDate(loan.payment_on)}</td>
                      </tr>
                    </LazyLoadComponent>
                  );
                })}
              </tbody>
            </table>
          ) : (
            ""
          )}
        </Paper>
      </Collapse>
    </React.Fragment>
  );
}

function TableRow(props) {
  const [collapseState, setCollapseState] = useState(false);
  const history = useHistory();
  const onCollapseChange = () => {
    console.log(props);
    setCollapseState(!collapseState);
  };

  let loan = props.installment;
  return (
    <React.Fragment>
      <tr>
        <td style={{ width: "30px" }}>
          <IconButton onClick={onCollapseChange}>
            {collapseState === true ? (
              <KeyboardArrowDown />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
        </td>
        <td>{Number(props.index)}</td>
        <td>{loan.account_name}</td>
        <td>{loan.branch_name}</td>
        <td>{loan.account_number}</td>
        <td>{loan.application_id}</td>

        <td>
          <small>Ugx </small>
          {formatNumber(loan.amount_per_installment)}
        </td>

        <td>{showTransactionDate(loan.payment_on)}</td>
        <td>
          <Chip label={loan.installment_type} />
        </td>
        <td>
          <Chip label={loan.status} />
        </td>
        <td>
          <Chip label={loan.phone_number} icon={<Phone />} />
        </td>

        <td>
          <IconButton
            onClick={() => {
              history.push(
                `/loan-payment?pay=true&account_number=${loan.account_number}&loan_application_id=${loan.loan_application_id}&branch=${loan.branch_name}&account_name=${loan.account_name}`
              );
            }}
          >
            <Payment />
          </IconButton>
        </td>
      </tr>
      <tr>
        <td colSpan={16}>
          <FutureInstallments
            {...props.installment}
            collapseState={collapseState}
            installments={props.future_installments}
          />
        </td>
      </tr>
    </React.Fragment>
  );
}
