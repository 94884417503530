import React from "react";
import accountsModule from "../modules/Accounts/accountsModule";
import AppDashboard from "../modules/AppDashboard";
import incomesAndExpensesModule from "../modules/IncomesAndExpenses/incomesAndExpensesModule";
/**
 * Invertory Modules
 */
import Login from "../modules/Auth/Login";
import branchesModule from "../modules/Branches/branchesModule";
import checkingAccountTransactionsModule from "../modules/CheckingAccountTransactions/checkingAccountTransactionsModule";
import depositsModule from "../modules/Deposits/depositsModule";
import loanPaymentModule from "../modules/LoanPayment/loanPaymentModule";
import loansModule from "../modules/Loans/loansModule";
import reportsModule from "../modules/Reports/reportsModule";
import settingsModule from "../modules/Settings/settingsModule";
import transactionsModule from "../modules/Transactions/transactionsModule";
import withdrawsModule from "../modules/Withdraws/withdrawsModule";

const routes = {
  root: {
    name: "Dashboard",
    path: "/",
    icon: <i className=" bi-grid"></i>,
    // Component: AppDashboard,
    Component: () => "",
  },
  modules: [
    {
      name: "Dashboard",
      path: "/",
      icon: <i className=" bi-grid"></i>,
      Component: AppDashboard,
    },
    {
      ...depositsModule,
    },
    {
      ...withdrawsModule,
    },
    {
      ...transactionsModule,
    },
    {
      ...loanPaymentModule,
    },
    {
      ...loansModule,
    },
    {
      ...reportsModule,
    },
    //-----===
    // {
    //   name: "Web Customers",
    //   path: "/web-customer-users",
    //   icon: <People />,
    //   Component: WebCustomerUsers,
    // },
    // {
    //   name: "Text Messages",
    //   path: "/text-messages",
    //   icon: <MessageOutlined />,
    //   Component: TextMessages,
    // },
    // {
    //   ...marketPlaceModule,
    // },
    // {
    //   ...blogApiModule,
    // },

    //-----
    {
      ...branchesModule,
    },
    {
      ...accountsModule,
    },
    { ...checkingAccountTransactionsModule },

    {
      ...incomesAndExpensesModule,
    },
    { ...settingsModule },
  ],
  login: {
    path: "/login",
    Component: Login,
  },
  dashboard: {
    path: "/",
    Component: Login,
  },
};

export default routes;
