import {
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  ArrowForward,
  LockOutlined,
  MailOutline,
  Refresh,
} from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { useDispatch } from "react-redux";
import ApiRequest from "../../../api/ApiRequest";
import {
  LOGIN_ADMIN,
  LOGIN_ADMIN_THREAD,
  REQUEST_LOGIN_VERIFICATION_CODE,
} from "../../../api/apiThreads";
import { KijjeInput } from "../../../global/components/inputs/Inputs";
import Images from "../../images/images";
import { loginUser } from "../../../api/store";
import { cacheUserDataOnDevice } from "../../../api/cacheUserOnDevice";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: "#EDF9F7",
    backgroundImage: 'url("./images/login-bg.png")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  paper: {
    borderRadius: "10px",
    // minHeight: "40vh",
    padding: theme.spacing(4),
    boxShadow: theme.boxShadows[3],
  },
  logo: {
    width: "auto",
    height: "100px",
  },
}));

function Login(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={12} md={4} lg={4} className="p-3 p-sm-0">
        <div align="center">
          <Images.Logo className={clsx(classes.logo, "my-4")} />
        </div>
        <Paper className={clsx("p-4 py-5", classes.paper)}>
          <Typography variant="h6" align="center">
            Login into your account
          </Typography>
          <ApiRequest autoload={false} thread={REQUEST_LOGIN_VERIFICATION_CODE}>
            {({
              loading,
              Button,
              data,
              input,
              error,
              errorMessage,
              SubmitButton,
              validationErrorMessage,
              message,
              res,
              payload,
            }) => {
              function ResendCodeButton(props) {
                return (
                  <SubmitButton endIcon={<Refresh />}>Resend Code</SubmitButton>
                );
              }

              if (typeof res.data === "object") {
                if (res.data.codeSent === true) {
                  return (
                    <VerifyCodeAndLogin
                      {...res.data}
                      password={payload.password}
                      email={payload.email}
                      ResendCodeButton={ResendCodeButton}
                    />
                  );
                }
              }

              return (
                <React.Fragment>
                  <div className="w-100 py-3">
                    <KijjeInput
                      startIcon={<MailOutline />}
                      variant="filled"
                      placeholder="Email"
                      name="email"
                      {...input({
                        name: "email",
                      })}
                    />
                    <KijjeInput
                      startIcon={<LockOutlined />}
                      variant="filled"
                      placeholder="Password"
                      type="password"
                      {...input({
                        name: "password",
                      })}
                    />
                  </div>
                  <div className="w-100" align="right">
                    <Button
                      variant="contained"
                      endIcon={<ArrowForward />}
                      color="primary"
                    >
                      Login
                    </Button>
                  </div>
                </React.Fragment>
              );
            }}
          </ApiRequest>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Login;

export function VerifyCodeAndLogin(props) {
  const dispatch = useDispatch();
  const ResendCodeButton = props.ResendCodeButton;
  return (
    <div>
      <Typography variant="h4">Verify Code</Typography>
      <Typography>
        <small>
          Enter verification code sent to your phone number or email to continue
          with login
        </small>
      </Typography>
      <Divider />
      <ApiRequest
        autoload={false}
        thread={LOGIN_ADMIN}
        initialPayload={{
          email: props.email,
          password: props.password,
          a_token_1: props.a_token_1,
        }}
        initialData={{
          data: {
            authPayload: {},
            authenticated: false,
          },
        }}
        callbacks={{
          success: (data) => {
            if (typeof data.data !== "undefined") {
              if (typeof data.data.authenticated !== "undefined") {
                const { authenticated, authPayload, profile } = data.data;
                if (authenticated === true) {
                  console.log("Authenticated::2::", authenticated);
                  cacheUserDataOnDevice({
                    authenticated: true,
                    token: authPayload.token,
                    email: authPayload.email,
                    profilePicture: profile.profilePicture,
                  });
                  dispatch(
                    loginUser({
                      authenticated: true,
                      token: authPayload.token,
                      refreshToken: authPayload.refreshToken,
                      email: authPayload.email,
                      profilePicture: profile.profilePicture,
                    })
                  );
                  window.location.href = "/dashboard";
                }
              }
            }
          },
        }}
      >
        {({ SubmitButton, res }) => {
          return (
            <div>
              <KijjeInput variant="outlined" label="Verification Code" />
              <div className="d-flex justify-content-between align-items-center">
                <ResendCodeButton />
                <SubmitButton variant="contained">Verify Code</SubmitButton>
              </div>
            </div>
          );
        }}
      </ApiRequest>
    </div>
  );
}
