import Deposits from ".";

const depositsModule = {
  name: "Deposits",
  // name: "Disbursement",
  icon: <i class="bi bi-arrow-bar-down"></i>,
  path: "/deposits",
  Component: Deposits,
  subModules: [],
};

export default depositsModule;
