/* eslint-disable eqeqeq */
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { ArrowForward, Refresh, Sort } from "@material-ui/icons";
import accounting from "accounting-js";
import clsx from "clsx";
import React, { useState } from "react";
import { Divider, Pagination } from "rsuite";
import ApiRequest from "../../../api/ApiRequest";
import { BNK_API_GET_CUSTOMER_TRANSACTIONS } from "../../../api/apiThreads";
import {
  ReadTransactionType,
  ShowTransactionAmount,
  showTransactionDate,
} from "../Transactions/TransactionsHistory";
import TableExportMenu from "./TableExportMenu";

export const tableStyles = makeStyles((theme) => ({
  tableRoot: {
    minHeight: "100px",
    minHeight: "100%",
    boxShadow: theme.boxShadows[1],
    borderRadius: "10px",
    marginBottom: "10px",
  },
  table: {
    width: "100%",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  tableHeading: {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    // backgroundColor: grey[100],
    borderBottom: "solid 1px " + blueGrey[100],
    "& thead": {
      width: "100%",
      "& th:last-child": {
        textAlign: "right",
        color: "green",
      },
    },
    "& th": {
      paddingLeft: "20px",
      paddingBottom: "5px",
      width: "auto",
      textAlign: "left",
      paddingTop: "10px",
      fontWeight: 500,
      fontSize: "1rem",
      color: blueGrey[700],
    },
    "& th:last-child": {
      textAlign: "right",
    },
    "& th:first-child": {
      textAlign: "left",
      paddingLeft: "0px",
    },
  },
  col2: {},
  tbody: {
    "& td": {
      paddingLeft: "20px",
      paddingBottom: "10px",
      width: "auto",
      textAlign: "left",
      paddingTop: "10px",
      fontWeight: 500,
      fontSize: "0.8rem",
      color: blueGrey[700],
    },
    "& tr": {
      borderBottom: "solid 1px " + blueGrey[100],
      "& td:last-child": {
        textAlign: "right",
      },
    },

    "& tr:last-child": {
      border: "none",
    },
    "& td:first-child": {
      textAlign: "left",
      paddingLeft: "0px",
    },
  },
  iconButton: {
    width: "30px",
    height: "30px",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: "1rem",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  title: {
    fontWeight: 500,
  },
  tableWrapper: {
    width: "100%",
    overflowY: "auto !important",
  },
}));

export default function CustomerTransactionHistory(props) {
  const classes = tableStyles();
  const [apiQueryState, setApiQueryState] = useState({
    account_number: props.account.account_number,
    limit: 10,
    where: props.where || "deposits|withdraws",
    show: props.show || "type|deposit|withdraw|date",
    page: 1,
  });

  const onChangePageLimit = (page) => {
    setApiQueryState({ ...apiQueryState, page });
  };

  const onChangePageRecordsLimit = (limit) => {
    setApiQueryState({ ...apiQueryState, limit });
  };

  return (
    <Paper className={clsx(classes.tableRoot, "p-3")}>
      <ApiRequest
        autoload
        thread={BNK_API_GET_CUSTOMER_TRANSACTIONS}
        addOnThread={JSON.stringify(apiQueryState)}
        query={apiQueryState}
      >
        {({
          loading,
          ErrorView,
          res,
          RefreshButton,
          refreshing,
          refresh,
          error,
        }) => {
          let history = [];

          if (loading) {
            return (
              <div className="w-100 p-5" align="center">
                <CircularProgress size="50px" />
              </div>
            );
          }

          if (error) {
            return (
              <div className="w-100 p-5" align="center">
                <ErrorView />
              </div>
            );
          }

          let total = 0;

          if (typeof res === "object") {
            if (res.success == true) {
              if (typeof res.data == "object") {
                if (Array.isArray(res.data.transactions)) {
                  history = res.data.transactions;
                  total = res.data.total;
                }
              }
            }
          }

          if (history.length === 0) {
            return (
              <div className="w-100 my-4" align="center">
                <Typography>
                  <b>No transactions made yet</b>
                </Typography>
                <RefreshButton variant="icon" />
              </div>
            );
          }

          return (
            <React.Fragment>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div>
                  <Typography className={classes.title}>
                    {props.title || "Transactions History"}
                  </Typography>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <TableExportMenu
                    title={props.account.account_name.trim()}
                    data={history.map((row) => [
                      {
                        ...row,
                        transaction_id: row.transaction_id || 0,
                        slip_number: row.slip_number || 0,
                      },
                    ])}
                    schema={[
                      {
                        column: "Transaction Date",
                        type: Date,
                        format: "dd/mm/yyyy",
                        value: (account) => new Date(account[0].created_at),
                        width: 17,
                      },
                      // {
                      //   column: "Slip No.",
                      //   type: String,
                      //   value: (account) => account[0].slip_number,
                      //   width: 10,
                      // },
                      // {
                      //   column: "Transaction Id",
                      //   type: String,
                      //   value: (account) => account[0].transaction_id,
                      //   width: 20,
                      // },
                      {
                        column: "Account Number",
                        type: String,
                        value: (account) =>
                          account[0].account_number.toString(),
                        width: 20,
                      },
                      {
                        column: "Account Name",
                        type: String,
                        value: (account) => props.account.account_name,
                        width: 25,
                      },
                      {
                        column: "Type",
                        type: String,
                        value: (account) => account[0].type,
                        width: 20,
                      },
                      {
                        column: "Amount",
                        type: Number,
                        value: (account) => {
                          switch (account[0].type) {
                            case "DEPOSIT":
                              return account[0].credit;

                            case "DIRECT_LOAN_PAYMENT_DEPOSIT":
                              return account[0].credit;

                            case "WITHDRAW":
                              return account[0].debit;

                            default:
                              return account[0].debit;
                          }
                        },
                        width: 20,
                      },
                      {
                        column: "Balance",
                        type: Number,
                        value: (account) => account[0].balance,
                        width: 20,
                      },
                    ]}
                  />
                  <div>
                    <IconButton
                      onClick={refresh}
                      className={clsx(classes.iconButton, "me-2")}
                      {...(refreshing === true ? { disabled: true } : {})}
                    >
                      {refreshing === true ? (
                        <div>
                          <CircularProgress size="18px" color="inherit" />
                        </div>
                      ) : (
                        <Refresh />
                      )}
                    </IconButton>
                  </div>
                  <div>
                    <IconButton
                      onClick={refresh}
                      className={clsx(classes.iconButton, "me-2")}
                    >
                      <Sort />
                    </IconButton>
                    <IconButton className={classes.iconButton}>
                      <ArrowForward />
                    </IconButton>
                  </div>
                </div>
              </div>
              <div className={classes.tableWrapper}>
                <table className={classes.table}>
                  <thead className={classes.tableHeading}>
                    <tr>
                      <th className={classes.col2}>No.</th>
                      <th>Slip No</th>
                      <th>Date</th>
                      <th>Account No</th>
                      <th>Reason</th>
                      <th>Type</th>
                      <th>Amount</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody className={classes.tbody}>
                    {loading === true ? (
                      <div className="w-100 my-2" align="center">
                        <CircularProgress />
                      </div>
                    ) : (
                      ""
                    )}

                    {history.map((transaction, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {++index +
                              apiQueryState.page * apiQueryState.limit -
                              apiQueryState.limit}
                          </td>
                          <td>SL-{transaction.slip_no}</td>
                          <td>{showTransactionDate(transaction.created_at)}</td>
                          <td>{transaction.account_number}</td>
                          <td>{transaction.name}</td>
                          <td>
                            <ReadTransactionType type={transaction.type} />
                          </td>
                          <td>
                            <ShowTransactionAmount {...transaction} />
                          </td>
                          <td>
                            UGX {accounting.formatNumber(transaction.balance)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Divider />
                <Pagination
                  layout={["total", "-", "limit", "|", "pager", "skip"]}
                  // size="md"
                  prev={true}
                  next={true}
                  first={true}
                  last={true}
                  ellipsis={true}
                  boundaryLinks={true}
                  total={total}
                  limit={apiQueryState.limit}
                  limitOptions={[10, 20, 50, 100, 200, 500]}
                  maxButtons={5}
                  activePage={apiQueryState.page}
                  onChangePage={(page) =>
                    setApiQueryState({ ...apiQueryState, page })
                  }
                  onChangeLimit={(limit) =>
                    setApiQueryState({ ...apiQueryState, limit, page: 1 })
                  }
                />
              </div>
            </React.Fragment>
          );
        }}
      </ApiRequest>
    </Paper>
  );
}
