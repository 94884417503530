import { Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import TransactionsTable from "./TransactionsTable";

export default function Transactions(props) {
  return (
    <Grid container>
        <Helmet>
            <title>
                Transactions
            </title>
        </Helmet>
      <Grid item xs={12}>
        <Typography variant="h6">Transactions</Typography>
        <Divider className="my-2" />
      </Grid>
      <Grid item xs={12}>
          <TransactionsTable />
      </Grid>
    </Grid>
  );
}
