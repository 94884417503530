import CheckingAccountTransactions from ".";

const checkingAccountTransactionsModule = {
  path: "/checking-account-transactions",
  Component: CheckingAccountTransactions,
  name: "Checking Account",
  icon: <i class="bi bi-bank"></i>,
  subModules: [],
};

export default checkingAccountTransactionsModule;
