import {
  Breadcrumbs,
  Typography,
  Grid,
  Divider,
  Button,
} from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useParams,
  NavLink,
} from "react-router-dom";
import CorrectLoanIssues from "../../../admin/modules/Loans/CorrectLoanIssues";
import LoanApplicationButton from "../../../admin/modules/Loans/LoanApplicationButton";

const useStyles = makeStyles((theme) => ({
  root: {
    background: grey[500] + " !important",
  },
  nav: {},
  navBtn: {
    borderRadius: "0px",
    boxShadow: "none",
    borderBottom: "solid 3px transparent",
    color: grey[500],
  },
  activeNavLink: {
    borderBottom: "solid 3px " + green[600],
    color: green[600],
  },
  divider: {
    backgroundColor: green[600],
  },
  link: {
    textDecoration: "none",
    fontWeight: 400,
  },
  subtile: {
    color: grey[600],
    fontWeight: 400,
  },
  navLink: {
    borderRadius: "0px",
  },
  walletCard: {
    width: "100%",
    boxShadow: "none",
    borderRadius: "10px",
  },
}));

export default function AppBreadcrumb(props) {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  return (
    <Switch>
      <Route path="/loans">
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid>
            <Switch>
              <Route exact path="/loans">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link className={classes.link} to="/" color="inherit">
                    Dashboard
                  </Link>
                  <Typography color="textPrimary">Loans</Typography>
                </Breadcrumbs>
              </Route>
              <Route exact path="/loans/applications">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link className={classes.link} to="/" color="inherit">
                    Dashboard
                  </Link>
                  <Link className={classes.link} to="/loans" color="inherit">
                    Loans
                  </Link>
                  <Typography color="textPrimary">Applications</Typography>
                </Breadcrumbs>
              </Route>
              <Route exact path="/loans/loan-types">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link className={classes.link} to="/" color="inherit">
                    Dashboard
                  </Link>
                  <Link className={classes.link} to="/loans" color="inherit">
                    Loans
                  </Link>
                  <Typography color="textPrimary">Loan Types</Typography>
                </Breadcrumbs>
              </Route>
            </Switch>
          </Grid>
          <Grid item>
            <div className="d-flex align-items-center">
              <Button
                activeClassName={classes.activeNavLink}
                className={classes.navLink}
                size="small"
                component={NavLink}
                to="/loans"
                exact
              >
                Loans
              </Button>
              <Button
                activeClassName={classes.activeNavLink}
                className={classes.navLink}
                size="small"
                component={NavLink}
                to="/loans/applications"
                exact
              >
                Applications
              </Button>
              <Button
                activeClassName={classes.activeNavLink}
                className={classes.navLink}
                component={NavLink}
                size="small"
                to="/loans/loan-types"
              >
                Loan Types
              </Button>
              <LoanApplicationButton />
              <CorrectLoanIssues />
            </div>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
      </Route>
    </Switch>
  );
}
