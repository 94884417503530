import { CircularProgress, Typography, Grid, Divider } from "@material-ui/core";
import { Message } from "@material-ui/icons";
import React from "react";
import { toaster, Message as ToasterMessage } from "rsuite";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_GET_SMS_MESSAGES_CONFIG,
  BNK_API_SEND_TEST_MESSAGE,
  BNK_API_UPDATE_SMS_MESSAGES_CONFIG,
} from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";

export default function SmsMessagesConfig(props) {
  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <ApiRequest autoload thread={BNK_API_GET_SMS_MESSAGES_CONFIG}>
          {({ loading, error, res, ErrorView }) => {
            if (loading) {
              return <CircularProgress />;
            }

            if (error) {
              return <ErrorView />;
            }

            let config = {};
            if (typeof res == "object") {
              if (typeof res.data == "object") {
                if (typeof res.data.config == "object") {
                  config = res.data.config;
                }
              }
            }
            return (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" className="mt-3">
                    Africa is talking SMS Configs
                  </Typography>
                  <Divider className="my-2" />
                </Grid>
                <ApiRequest
                  reloadOnSuccess={[BNK_API_GET_SMS_MESSAGES_CONFIG]}
                  thread={BNK_API_UPDATE_SMS_MESSAGES_CONFIG}
                  initialPayload={{ ...config }}
                >
                  {({ SubmitButton, input }) => {
                    return (
                      <React.Fragment>
                        <Grid item xs={12} md={6}>
                          <Typography>
                            <small>Username</small>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <KijjeInput
                            {...input({ name: "username" })}
                            variant="filled"
                            square
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography>
                            <small>Api Key</small>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <KijjeInput
                            {...input({ name: "api_key" })}
                            type="password"
                            variant="filled"
                            square
                          />
                        </Grid>
                        <SubmitButton size="small" variant="contained">
                          Update Settings
                        </SubmitButton>
                      </React.Fragment>
                    );
                  }}
                </ApiRequest>
                <Grid item xs={12}>
                  <Typography variant="h6" className="mt-3">
                    Send Test Message
                  </Typography>
                  <Divider className="my-2" />
                  <ApiRequest
                    thread={BNK_API_SEND_TEST_MESSAGE}
                    callbacks={{
                      success: (res) => {
                        if (typeof res == "object") {
                          if (typeof res.data == "object") {
                            if (res.data.sent === true) {
                              const message = (
                                <ToasterMessage type="success">
                                  Message has been sent
                                </ToasterMessage>
                              );
                              toaster.push(message, "topCenter");
                            }
                          }
                        }
                      },
                    }}
                  >
                    {({ SubmitButton, input }) => {
                      return (
                        <React.Fragment>
                          <KijjeInput
                            {...input({ name: "phone_number" })}
                            type="phone"
                          />
                          <KijjeInput
                            {...input({ name: "message" })}
                            multiline
                            variant="filled"
                            square
                          />
                          <SubmitButton
                            variant="contained"
                            size="small"
                            endIcon={<Message />}
                          >
                            Send Sms
                          </SubmitButton>
                        </React.Fragment>
                      );
                    }}
                  </ApiRequest>
                </Grid>
              </Grid>
            );
          }}
        </ApiRequest>
      </Grid>
    </Grid>
  );
}
