import {
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Radio,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { green, grey, orange } from "@material-ui/core/colors";
import {
  ArrowBack,
  ArrowForward,
  Check,
  Close,
  Money,
  Person,
  Phone,
  Receipt,
} from "@material-ui/icons";
import { blueGrey } from "@mui/material/colors";
import accounting, { formatNumber } from "accounting-js";
import clsx from "clsx";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Notification } from "rsuite";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_GET_CUSTOMER_RUNNING_LOANS,
  BNK_API_GET_DEPOSIT_HISTORY,
  BNK_API_GET_TRANSACTION_STATISTICS,
  BNK_API_MAKE_LOAN_PAYMENT,
} from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";
import FindAccount from "../Accounts/FindAccount";
import { PrintReceiptDialog } from "../Deposits";
import TransactionsTable from "../Transactions/TransactionsTable";
import { GetWithdrawStatistics } from "../Withdraws";
import queryString from "query-string";
const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    boxShadow: theme.boxShadows[3],
    minHeight: "100%",
    borderRadius: "10px",
  },
  findAccountCard: {
    minHeight: "100%",
  },
  transactionComplete: {
    width: "100%",
    height: "100%",
    backgroundColor: orange[50],
    display: "flex",
    justifyContent: "center",
    borderRadius: "10px",
    alignItems: "center",
  },
  label: {
    color: blueGrey[500],
    fontSize: "0.85rem",
    fontWeight: "600 !important",
  },
  printableContentRoot: {
    // width: "100%",
    // height: "100vh",
    backgroundColor: "#fff",
    maxWidth: "300px",
  },
  listItem: {
    border: "dotted 1px " + grey[600],
    borderRadius: "10px",
    "&:hover": {
      borderColor: orange[500],
    },
  },
}));

export default function LoanPayment(props) {
  const classes = useStyles();
  const location = useLocation();
  const { query } = queryString.parseUrl(location.search);
  let canPay = false;
  if (query.pay === true || query.pay === "true") {
    canPay = true;
  }
  return (
    <Grid container spacing={2} justify="start" className={clsx(classes.root)}>
      <Helmet>
        <title>Loan Payment</title>
      </Helmet>
      <Grid item xs={12}>
        <div>
          <Typography variant="h6">Loan Payment</Typography>
        </div>
        <Divider className="my-2" />
      </Grid>
      <GetWithdrawStatistics type="loan payment" />
      <Grid item xs={12} md={8}>
        <FindAccount
          transaction_type="DIRECT_LOAN_PAYMENT"
          title="Pay loan"
          addOnThread={JSON.stringify(query)}
          className={classes.findAccountCard}
          canPay={canPay}
          query={query}
        >
          {({ account, onReset }) => {
            // Check the existsing loans
            return (
              <GetBankCustomerRunningLoans
                canPay={canPay}
                query={query}
                account={account}
                onReset={onReset}
              >
                {({ loan_application_id, loan_id }) => {
                  // make the deposit
                  return (
                    <ApiRequest
                      thread={BNK_API_MAKE_LOAN_PAYMENT}
                      reloadOnSuccess={[
                        BNK_API_GET_DEPOSIT_HISTORY,
                        BNK_API_GET_TRANSACTION_STATISTICS,
                      ]}
                      initialPayload={{
                        name: "",
                        phone_number: "",
                        amount: "",
                        early_entry: false,
                        loan_application_id: loan_application_id,
                        loan_id: loan_id,
                        account_name: account.account_name,
                        account_number: account.account_number,
                      }}
                      isQueryInput
                    >
                      {({
                        input,
                        loading,
                        error,
                        ErrorView,
                        SubmitButton,
                        completed,
                        payload,
                        setPayload,
                        res,
                        resetComplete,
                      }) => {
                        if (typeof res == "object") {
                          if (typeof res.data == "object") {
                            if (typeof res.data.transaction && completed) {
                              let transaction = res.data.transaction;
                              return (
                                <div
                                  className={clsx(
                                    classes.transactionComplete,
                                    "flex-column pb-3"
                                  )}
                                >
                                  <Check
                                    style={{
                                      fontSize: "50px",
                                      color: green[600],
                                    }}
                                  />
                                  <Typography>Payment Complete</Typography>
                                  <Typography variant="h6">
                                    Amount Paid{" "}
                                    {accounting.formatNumber(payload.amount, 2)}
                                  </Typography>{" "}
                                  <div>
                                    {/* <Typography variant="h4">
                                      Balance <small>Shs: </small>
                                      {accounting.formatNumber(
                                        transaction.balance,
                                        2
                                      )}
                                    </Typography> */}
                                  </div>
                                  <div className="d-flex">
                                    <Button
                                      onClick={onReset}
                                      variant="outlined"
                                      endIcon={<Close />}
                                      size="small"
                                    >
                                      Close
                                    </Button>
                                    <PrintReceiptDialog
                                      transaction_id={transaction.id}
                                      branch_id={transaction.branch_id}
                                    />
                                  </div>
                                </div>
                              );
                            }
                          }
                        }

                        return (
                          <React.Fragment>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={6} md={4}>
                                <KijjeInput
                                  variant="outlined"
                                  square
                                  {...input({ name: "name" })}
                                  label="Depositor's Name"
                                  startIcon={<Person />}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <KijjeInput
                                  variant="outlined"
                                  square
                                  startIcon={<Phone />}
                                  {...input({ name: "phone_number" })}
                                  label="Depositor's Phone Number"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <KijjeInput
                                  variant="outlined"
                                  square
                                  startIcon={<Receipt />}
                                  {...input({ name: "slip_no" })}
                                  label="Deposit Slip No. "
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={1} alignItems="flex-end">
                              <Grid item xs={5}>
                                <KijjeInput
                                  variant="outlined"
                                  startIcon={<Money />}
                                  type="text"
                                  square
                                  {...input({
                                    name: "amount",
                                    type: "text",
                                  })}
                                  label="Amount"
                                />
                              </Grid>
                              <Grid item xs={7}>
                                <Typography variant="h4" className="mt-4">
                                  <small>Shs: </small>
                                  {accounting.formatNumber(payload.amount, 2)}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider
                              className="my-2"
                              style={{ backgroundColor: "red" }}
                            />
                            <div>
                              <List dense disablePadding className="p-0">
                                <ListItem
                                  className="p-0"
                                  button
                                  onClick={() =>
                                    setPayload({
                                      early_entry: !payload.early_entry,
                                    })
                                  }
                                >
                                  <ListItemText>
                                    Record as entry deposit
                                  </ListItemText>
                                  <ListItemSecondaryAction className="p-0 me-0">
                                    <div className="ms-3" align="right">
                                      <Switch
                                        size="small"
                                        onClick={() =>
                                          setPayload({
                                            early_entry: !payload.early_entry,
                                          })
                                        }
                                        checked={payload.early_entry}
                                      />
                                    </div>
                                  </ListItemSecondaryAction>
                                </ListItem>
                                <Collapse in={payload.early_entry}>
                                  <TextField
                                    {...input({ name: "created_at" })}
                                    size="small"
                                    type="date"
                                    fullWidth
                                    label="Deposit date"
                                  />
                                </Collapse>
                              </List>
                            </div>
                            <Divider
                              className="my-2"
                              style={{ backgroundColor: "red" }}
                            />
                            <div className="w-100 mt-2" align="right">
                              <Button
                                startIcon={<Close />}
                                variant="outlined"
                                className="mx-2"
                                onClick={onReset}
                              >
                                Close
                              </Button>
                              <SubmitButton
                                variant="contained"
                                endIcon={<ArrowForward />}
                              >
                                Deposit to Loan
                              </SubmitButton>
                            </div>
                          </React.Fragment>
                        );
                      }}
                    </ApiRequest>
                  );
                }}
              </GetBankCustomerRunningLoans>
            );
          }}
        </FindAccount>
      </Grid>
      <Grid item xs={12} md={12}>
        <TransactionsTable
          title="Loan Payment History"
          show="deposit|type"
          where="t.loanPayment"
          hide={[]}
        />
      </Grid>
    </Grid>
  );
}

function GetBankCustomerRunningLoans(props) {
  const { account_number } = props.account;
  const classes = useStyles();
  const [selectedLoan, setSelectedLoan] = useState(null);
  return (
    <Paper elevation={0} className="p-3">
      <ApiRequest
        autoload
        addOnThread={account_number}
        thread={BNK_API_GET_CUSTOMER_RUNNING_LOANS}
        payload={{
          account_number: account_number,
          status: "IN_PROGRESS",
        }}
      >
        {({ loading, error, res, ErrorView, RefreshButton }) => {
          if (loading) {
            return <CircularProgress />;
          }

          if (error) {
            return <ErrorView />;
          }

          let loans = null;
          if (typeof res == "object") {
            if (typeof res.data == "object") {
              if (Array.isArray(res.data.loans)) {
                loans = res.data.loans;
              }
            }
          }

          if (loans == null) {
            return (
              <Notification type="error" header="Failed to loans">
                <RefreshButton variant="icon" />

                <Typography>
                  <small>
                    Customer does not have any running loan, it is not possible
                    to use this type
                  </small>
                </Typography>
              </Notification>
            );
          }

          if (loans.length === 0) {
            return (
              <div>
                <div className="w-100">
                  <Notification type="warning" header="No  Running Loans">
                    <RefreshButton variant="icon" />
                    <Typography>
                      <small>
                        Customer does not have any running loan, it is not
                        possible to use this type
                      </small>
                    </Typography>
                  </Notification>
                </div>
              </div>
            );
          }

          if (selectedLoan !== null) {
            return props.children({
              loan_application_id: selectedLoan.application_id,
              loan_id: selectedLoan.id,
            });
          }

          if (props.canPay === true) {
            loans.map((loan) => {
              if (loan.application_id === props.query.loan_application_id) {
                setSelectedLoan(loan);
              }
            });
          }

          return (
            <List
              subheader={
                <div className="w-100 mb-2">
                  <div className="w-100 d-flex justify-content-between align-content-center">
                    <Typography variant="h6">Running Loans</Typography>
                    <RefreshButton variant="icon" />
                  </div>
                </div>
              }
            >
              {loans.map((loan) => {
                return (
                  <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => setSelectedLoan(loan)}
                  >
                    <ListItemText secondary={loan.application_id}>
                      Application Id
                    </ListItemText>
                    <ListItemText
                      secondary={
                        <div>
                          {formatNumber(loan.loan_amount_with_interest)}{" "}
                          <small>UGX</small>
                        </div>
                      }
                    >
                      Loan Amount
                    </ListItemText>
                    <ListItemText
                      secondary={
                        <div>
                          {formatNumber(loan.amount_paid)} <small>UGX</small>
                        </div>
                      }
                    >
                      Amount Paid
                    </ListItemText>
                    <ListItemText
                      secondary={
                        <div>
                          {formatNumber(loan.loan_balance)} <small>UGX</small>
                        </div>
                      }
                    >
                      Loan Balance
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <Radio onClick={() => setSelectedLoan(loan)} />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
              <Divider />
            </List>
          );
        }}
      </ApiRequest>
      <Divider className="my-2" />
      <Button
        variant="contained"
        onClick={props.onReset}
        startIcon={<ArrowBack />}
        size="small"
      >
        Back
      </Button>
    </Paper>
  );
}
