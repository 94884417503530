import {
  Avatar,
  Button,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { blue, grey } from "@material-ui/core/colors";
import { Add, Close, Remove, RemoveRedEye } from "@material-ui/icons";
import { Alert, AlertTitle, Skeleton } from "@material-ui/lab";
import { formatNumber } from "accounting-js";
import clsx from "clsx";
import React, { useState } from "react";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_GET_CUSTOMER_ACCOUNT_DETAILS,
  BNK_API_GET_CUSTOMER_ACCOUNT_SUMMARY,
} from "../../../api/apiThreads";
import { renderDocumentLoanUrl } from "../../../global/hooks";
import CustomerTransactionHistory from "./CustomerTransactionHistory";
const useStyles = makeStyles((theme) => ({
  root: {},
  paperCard: {
    minHeight: "150px",
    boxShadow: theme.boxShadows[3],
    borderRadius: "0.5em",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  accountSummary: {
    borderRadius: "10px",
    height: "100%",
    boxShadow: "none",
    padding: theme.spacing(2),
    backgroundColor: grey[100],
  },
  tabs: {
    width: "100%",
    boxShadow: theme.boxShadows[4],
  },
  navLink: {
    height: "100%",
    borderBottom: "solid 2px transparent",
    borderRadius: "0px",
  },
  activeLink: {
    color: theme.palette.primary.main,
    borderBottomColor: theme.palette.primary.main,
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
  passportPhoto: {
    width: "auto",
    height: "150px",
    border: "dotted 1px " + blue[500],
    padding: "5px",
    borderRadius: "10px",
  },
  identityCard: {
    minWidth: "150px",
    width: "auto",
    padding: "5px",
    height: "150px",
    borderRadius: "10px",
    border: "dotted 1px " + blue[500],
  },
}));

export default function AccountDetails(props) {
  const [dialogState, setDialogState] = useState(false);
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
  };
  const rootUrl = useRouteMatch().url;
  const rootPath = useRouteMatch().path;
  const classes = useStyles();

  return (
    <React.Fragment>
      <IconButton onClick={onDialogStateChange}>
        <RemoveRedEye />
      </IconButton>
      <Dialog open={dialogState} fullScreen fullWidth scroll="body">
        <Paper square elevation={0}>
          <DialogContent>
            <Container>
              <div className="w-100 d-flex justify-content-between align-items-center">
                <Typography variant="h6">Account details</Typography>
                <IconButton onClick={onDialogStateChange}>
                  <Close />
                </IconButton>
              </div>
              <Divider className={clsx("my-4", classes.divider)} />
              <ApiRequest
                thread={BNK_API_GET_CUSTOMER_ACCOUNT_DETAILS}
                autoload
                addOnThread={props.account_number}
                query={{
                  account_number: props.account_number,
                }}
              >
                {({ res, loading, error, ErrorView, RefreshButton }) => {
                  if (loading) {
                    return (
                      <div className="d-flex justify-content-center align-items-center p-5">
                        <CircularProgress size="70px" />
                      </div>
                    );
                  }

                  if (error) {
                    return (
                      <Container>
                        <div className="p-5">
                          <ErrorView />
                        </div>
                      </Container>
                    );
                  }

                  let account = null;
                  if (typeof res == "object") {
                    if (typeof res.data == "object") {
                      if (typeof res.data.account == "object") {
                        account = res.data.account;
                      }
                    }
                  }

                  if (account == null) {
                    return (
                      <div className="d-flex flex-column align-items-center justify-content-center w-100 p-5">
                        <Typography variant="h5">
                          Failed to get account
                        </Typography>
                        <RefreshButton variant="icon" />
                      </div>
                    );
                  }
                  return (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <AccountStatistics
                          account_number={account.account_number}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <div
                          className={clsx(
                            "w-100 d-flex align-items-end my-3",
                            classes.tabs
                          )}
                        >
                          <Button
                            exact
                            className={classes.navLink}
                            activeClassName={classes.activeLink}
                            component={NavLink}
                            to={rootUrl}
                          >
                            Customer Information
                          </Button>
                          <Button
                            className={classes.navLink}
                            activeClassName={classes.activeLink}
                            component={NavLink}
                            to={rootUrl + "/transactions"}
                          >
                            Transactions
                          </Button>
                          {/* <Button
                            className={classes.navLink}
                            activeClassName={classes.activeLink}
                            component={NavLink}
                            to={rootUrl + "/loans"}
                          >
                            Loans
                          </Button> */}
                          {/* <Button
                            className={classes.navLink}
                            activeClassName={classes.activeLink}
                            component={NavLink}
                            to={rootUrl + "/savings"}
                          >
                            Savings
                          </Button>
                          <Button
                            className={classes.navLink}
                            activeClassName={classes.activeLink}
                            component={NavLink}
                            to={rootUrl + "/preferences"}
                          >
                            Preferences
                          </Button> */}
                        </div>
                        <div>
                          <Switch>
                            <Route
                              path={rootPath + "/transactions"}
                              render={(props) => (
                                <CustomerTransactionHistory
                                  account={account}
                                  {...props}
                                />
                              )}
                            />
                            <Route
                              exact
                              path={rootPath}
                              render={(props) => (
                                <CustomerInformation
                                  {...props}
                                  account={account}
                                />
                              )}
                            />
                          </Switch>
                        </div>
                      </Grid>
                    </Grid>
                  );
                }}
              </ApiRequest>
            </Container>
          </DialogContent>
        </Paper>
      </Dialog>
    </React.Fragment>
  );
}

function AccountStatistics(props) {
  const classes = useStyles();
  return (
    <ApiRequest
      addOnThread={props.account_number}
      initialPayload={{
        account_number: props.account_number,
      }}
      thread={BNK_API_GET_CUSTOMER_ACCOUNT_SUMMARY}
      autoload
    >
      {({ res, loading, error, ErrorView, RefreshButton }) => {
        if (loading) {
          return (
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height="200px"
                  className={classes.paperCard}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height="200px"
                  className={classes.paperCard}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height="200px"
                  className={classes.paperCard}
                />
              </Grid>
            </Grid>
          );
        }

        if (error) {
          return <ErrorView />;
        }

        let account_summary = null;

        if (typeof res == "object") {
          if (typeof res.data == "object") {
            if (typeof res.data.summary == "object") {
              account_summary = res.data.summary;
            }
          }
        }

        if (account_summary == null) {
          return (
            <Paper className={classes.paperCard}>
              <Alert severity="error">
                <AlertTitle>Error while getting account summary.</AlertTitle>
                <RefreshButton variant="icon" />
              </Alert>
            </Paper>
          );
        }

        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Paper className={classes.accountSummary}>
                <div className="d-flex justify-content-between align-items-center">
                  <Typography variant="h6">Account summary</Typography>
                  <RefreshButton variant="icon" />
                </div>
                <List dense>
                  <ListItem>
                    <ListItemText>
                      <Typography variant="h5">
                        <small>Total Credits</small>
                      </Typography>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <Typography variant="h6">
                        {formatNumber(account_summary.total_credit)}{" "}
                        <small>Ugx</small>
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <Typography variant="h5">
                        <small>Total Debits</small>
                      </Typography>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <Typography variant="h6">
                        {formatNumber(account_summary.total_debit)}{" "}
                        <small>Ugx</small>
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
                <Divider className={clsx("my-3", classes.divider)} />
                <List>
                  <ListItem>
                    <ListItemText>
                      <Typography variant="h5">Account balance</Typography>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <Typography variant="h6">
                        {formatNumber(account_summary.account_balance)}{" "}
                        <small>Ugx</small>
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Paper>
            </Grid>

            {/* <Grid item xs={12} md={12}>
              <Paper className={classes.paperCard}>
                <Typography variant="h6">Deposit & Withdraw</Typography>
                <DepositWithdrawChart summary={account_summary} />
              </Paper>
            </Grid> */}
            {/* 
            <Grid item xs={12} md={12}>
              <Paper className={classes.paperCard}>
                <Typography variant="h6">Loan Payment</Typography>
                <Divider className={clsx("my-3", classes.divider)} />
                <LoanPaymentChart />
              </Paper>
            </Grid> */}
          </Grid>
        );
      }}
    </ApiRequest>
  );
}

function CustomerInformation(props) {
  const classes = useStyles();
  let customer = props.account || {};
  const [collapseState, setCollapseState] = useState({
    ACCOUNT_INFO: true,
    PERSONAL_INFO: false,
    ADDRESS_INFO: false,
    CONTACT_DETAILS: false,
    EMPLOYMENT_DETAILS: false,
    NEXT_OF_KIN_DETAILS: false,
  });
  const onCollapseChange = (index) => () => {
    setCollapseState({ ...collapseState, [index]: !collapseState[index] });
  };
  return (
    <Paper className="p-3">
      <Grid container spacing={1}>
        <Grid item>
          <Typography>Passport Photo</Typography>
          <Avatar
            variant="square"
            src={renderDocumentLoanUrl(customer.passport_photo)}
            className={classes.passportPhoto}
          />
        </Grid>
        <Grid item>
          <Typography>Identity Card Front</Typography>
          <Avatar
            variant="square"
            src={renderDocumentLoanUrl(customer.identity_card_front)}
            className={classes.identityCard}
          />
        </Grid>
        <Grid item>
          <Typography>Identity Card Back</Typography>
          <Avatar
            variant="square"
            src={renderDocumentLoanUrl(customer.identity_card_back)}
            className={classes.identityCard}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <div className="d-flex  align-items-center">
            <Typography className="me-3">
              <b>Account Type:</b>
            </Typography>
            <Typography>{customer.account_type}</Typography>
          </div>
          <div className="d-flex  align-items-center">
            <Typography className="me-3">
              <b>Account Number:</b>
            </Typography>
            <Typography>{customer.account_number}</Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="d-flex  align-items-center">
            <Typography className="me-3">
              <b>Account Opened On:</b>
            </Typography>
            <Typography>{customer.account_opened_on}</Typography>
          </div>
          <div className="d-flex  align-items-center">
            <Typography className="me-3">
              <b>Estimated Monthly Saving:</b>
            </Typography>
            <Typography>{customer.estimated_monthly_savings}</Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="d-flex justify-content-between align-items-center">
            <Typography variant="h6" className="my-2">
              Personal Information
            </Typography>
            <IconButton onClick={onCollapseChange("PERSONAL_INFO")}>
              {collapseState.PERSONAL_INFO == false ? <Add /> : <Remove />}
            </IconButton>
          </div>
          <Divider className={clsx(classes.divider, "my-2")} />
          <Collapse in={collapseState["PERSONAL_INFO"]}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Surname:</b>
                  </Typography>
                  <Typography>{customer.surname}</Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Given Name:</b>
                  </Typography>
                  <Typography>{customer.given_name}</Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Middle Name:</b>
                  </Typography>
                  <Typography>{customer.middle_name}</Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Other Name:</b>
                  </Typography>
                  <Typography>{customer.other_name}</Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Date of birth:</b>
                  </Typography>
                  <Typography>{customer.date_of_birth}</Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Nationality:</b>
                  </Typography>
                  <Typography>{customer.nationality}</Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Tribe:</b>
                  </Typography>
                  <Typography>{customer.tribe}</Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Gender:</b>
                  </Typography>
                  <Typography>{customer.gender}</Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Religion:</b>
                  </Typography>
                  <Typography>{customer.religion}</Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Marital Status:</b>
                  </Typography>
                  <Typography>{customer.marital_status}</Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Id Card Type:</b>
                  </Typography>
                  <Typography>{customer.id_card_type}</Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Id Card Number:</b>
                  </Typography>
                  <Typography>{customer.id_card_number}</Typography>
                </div>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid item xs={12}>
          <div className="d-flex justify-content-between align-items-center">
            <Typography variant="h6" className="my-2">
              Address Details
            </Typography>
            <IconButton onClick={onCollapseChange("ADDRESS_INFO")}>
              {collapseState.ADDRESS_INFO == false ? <Add /> : <Remove />}
            </IconButton>
          </div>

          <Divider className={clsx("my-2", classes.divider)} />
          <Collapse in={collapseState.ADDRESS_INFO}>
            <div className="d-flex  align-items-center">
              <Typography className="me-3">
                <b>Country of Residence:</b>
              </Typography>
              <Typography>{customer.country_of_residence}</Typography>
            </div>
            <div className="d-flex  align-items-center">
              <Typography className="me-3">
                <b>City:</b>
              </Typography>
              <Typography>{customer.city}</Typography>
            </div>
            <div className="d-flex  align-items-center">
              <Typography className="me-3">
                <b>Village:</b>
              </Typography>
              <Typography>{customer.village}</Typography>
            </div>
          </Collapse>
        </Grid>

        <Grid item xs={12}>
          <div className="d-flex justify-content-between align-items-center">
            <Typography variant="h6" className="my-2">
              Contact details
            </Typography>
            <IconButton onClick={onCollapseChange("CONTACT_DETAILS")}>
              {collapseState.CONTACT_DETAILS == false ? <Add /> : <Remove />}
            </IconButton>
          </div>
          <Divider className={clsx(classes.divider)} />
          <Collapse in={collapseState.CONTACT_DETAILS}>
            <div className="d-flex  align-items-center">
              <Typography className="me-3">
                <b>Phone Number:</b>
              </Typography>
              <Typography>{customer.primary_phone_number}</Typography>
            </div>
            <div className="d-flex  align-items-center">
              <Typography className="me-3">
                <b>Other Phone Number:</b>
              </Typography>
              <Typography>{customer.secondary_phone_number}</Typography>
            </div>
            <div className="d-flex  align-items-center">
              <Typography className="me-3">
                <b>Email:</b>
              </Typography>
              <Typography>{customer.email}</Typography>
            </div>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <div className="d-flex justify-content-between align-items-center">
            <Typography variant="h6" className="my-2">
              Employment details / Job Information
            </Typography>
            <IconButton onClick={onCollapseChange("EMPLOYMENT_DETAILS")}>
              {collapseState.EMPLOYMENT_DETAILS == false ? <Add /> : <Remove />}
            </IconButton>
          </div>
          <Divider className={clsx(classes.divider)} />
          <Collapse in={collapseState.EMPLOYMENT_DETAILS}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Employment Status:</b>
                  </Typography>
                  <Typography>{customer.employment_status}</Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Business or Employer Company:</b>
                  </Typography>
                  <Typography>{customer.business_name}</Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Job Title:</b>
                  </Typography>
                  <Typography>{customer.job_title}</Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Work Address:</b>
                  </Typography>
                  <Typography>{customer.work_address}</Typography>
                </div>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid item xs={12}>
          <div className="d-flex justify-content-between align-items-center">
            <Typography variant="h6">Next of Kin Details</Typography>
            <IconButton onClick={onCollapseChange("NEXT_OF_KIN_DETAILS")}>
              {collapseState.NEXT_OF_KIN_DETAILS == false ? (
                <Add />
              ) : (
                <Remove />
              )}
            </IconButton>
          </div>
          <Divider className={clsx(classes.divider)} />
          <Collapse in={collapseState.NEXT_OF_KIN_DETAILS}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Next of Kin Surname:</b>
                  </Typography>
                  <Typography>{customer.nxt_of_kin_surname}</Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Other names:</b>
                  </Typography>
                  <Typography>{customer.nxt_of_kin_other_names}</Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Phone Number:</b>
                  </Typography>
                  <Typography>
                    {customer.nxt_of_kin_primary_phone_number}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Other Phone Number</b>
                  </Typography>
                  <Typography>
                    {customer.nxt_of_kin_secondary_phone_number}
                  </Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Relationship</b>
                  </Typography>
                  <Typography>{customer.nxt_of_kin_relationship}</Typography>
                </div>
                <div className="d-flex  align-items-center">
                  <Typography className="me-3">
                    <b>Address</b>
                  </Typography>
                  <Typography>{customer.nxt_of_kin_address}</Typography>
                </div>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  );
}

function DepositWithdrawChart(props) {
  const data = [
    {
      name: "Jan",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Feb",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Mar",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Apr",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "May",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "June",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "July",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  return (
    <div>
      <div style={{ width: "100%", height: 150 }}>
        <ResponsiveContainer>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            {/* <Line
            type="monotone"
            dataKey="uv"
            stroke="#8884d8"
            strokeDasharray="5 5"
          /> */}
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="name" />
            {/* <YAxis /> */}
            <Tooltip />
            <Area
              type="monotone"
              dataKey="uv"
              stroke="#8884d8"
              fill="#8884d8"
            />
            <Area
              type="monotone"
              dataKey="pv"
              stroke="#7984d8"
              fill="#35f4d8"
            />
            <Brush dataKey="name" height={30} stroke="#8884d8" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

function LoanPaymentChart(props) {
  const data = [
    { name: "1", uv: 300, pv: 456 },
    { name: "2", uv: -145, pv: 230 },
    { name: "3", uv: -100, pv: 345 },
    { name: "4", uv: -8, pv: 450 },
    { name: "5", uv: 100, pv: 321 },
    { name: "6", uv: 9, pv: 235 },
    { name: "7", uv: 53, pv: 267 },
    { name: "8", uv: 252, pv: -378 },
    { name: "9", uv: 79, pv: -210 },
    { name: "10", uv: 294, pv: -23 },
    { name: "12", uv: 43, pv: 45 },
    { name: "13", uv: -74, pv: 90 },
    { name: "14", uv: -71, pv: 130 },
    { name: "15", uv: -117, pv: 11 },
    { name: "16", uv: -186, pv: 107 },
    { name: "17", uv: -16, pv: 926 },
    { name: "18", uv: -125, pv: 653 },
    { name: "19", uv: 222, pv: 366 },
    { name: "20", uv: 372, pv: 486 },
    { name: "21", uv: 182, pv: 512 },
    { name: "22", uv: 164, pv: 302 },
    { name: "23", uv: 316, pv: 425 },
    { name: "24", uv: 131, pv: 467 },
    { name: "25", uv: 291, pv: -190 },
    { name: "26", uv: -47, pv: 194 },
    { name: "27", uv: -415, pv: 371 },
    { name: "28", uv: -182, pv: 376 },
    { name: "29", uv: -93, pv: 295 },
    { name: "30", uv: -99, pv: 322 },
    { name: "31", uv: -52, pv: 246 },
    { name: "32", uv: 154, pv: 33 },
    { name: "33", uv: 205, pv: 354 },
    { name: "34", uv: 70, pv: 258 },
    { name: "35", uv: -25, pv: 359 },
    { name: "36", uv: -59, pv: 192 },
    { name: "37", uv: -63, pv: 464 },
    { name: "38", uv: -91, pv: -2 },
    { name: "39", uv: -66, pv: 154 },
    { name: "40", uv: -50, pv: 186 },
  ];
  return (
    <div style={{ width: "100%", height: 150 }}>
      <ResponsiveContainer>
        <BarChart
          width={400}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          {/* <YAxis /> */}
          <Tooltip />
          <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }} />
          <ReferenceLine y={0} stroke="#000" />

          <Brush dataKey="name" height={30} stroke="#8884d8" />
          <Bar dataKey="pv" fill="#8884d8" />
          {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
