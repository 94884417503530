import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputBase,
  makeStyles,
  Menu,
  Paper,
  Typography,
} from "@material-ui/core";
import { blueGrey, green, grey, orange } from "@material-ui/core/colors";
import { Close, Delete, MoreVert, Refresh, Search } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import React, { useState } from "react";
import { Pagination } from "rsuite";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_DELETE_CUSTOMER_ACCOUNT,
  BNK_API_GET_ALL_CUSTOMERS,
} from "../../../api/apiThreads";
import { showTransactionDate } from "../Transactions/TransactionsHistory";
import { SelectBranchMenu } from "../Transactions/TransactionsTable";
import AccountDetails from "./AccountDetails";
import EditAccountDetails from "./EditAccountDetails";
import TableExportMenu from "./TableExportMenu";
import accounting from "accounting-js";

const tableStyles = makeStyles((theme) => ({
  tableRoot: {
    minHeight: "100%",
    boxShadow: theme.boxShadows[1],
    borderRadius: "10px",
  },
  table: {
    width: "100%",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  tableHeading: {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    // backgroundColor: grey[100],
    borderBottom: "solid 1px " + blueGrey[100],
    "& thead": {
      width: "100%",
      "& th:last-child": {
        textAlign: "right",
        color: "green",
      },
    },
    "& th": {
      paddingLeft: "20px",
      paddingBottom: "5px",
      width: "auto",
      textAlign: "left",
      paddingTop: "10px",
      fontWeight: 500,
      fontSize: "1rem",
      color: blueGrey[700],
    },
    "& th:last-child": {
      textAlign: "right",
    },
    "& th:first-child": {
      textAlign: "left",
      paddingLeft: "0px",
    },
  },
  col2: {},
  tbody: {
    "& td": {
      paddingLeft: "20px",
      paddingBottom: "10px",
      width: "auto",
      textAlign: "left",
      paddingTop: "10px",
      fontWeight: 500,
      fontSize: "0.8rem",
      color: blueGrey[700],
    },
    "& tr": {
      borderBottom: "solid 1px " + blueGrey[100],
      "& td:last-child": {
        textAlign: "right",
      },
    },

    "& tr:last-child": {
      border: "none",
    },
    "& td:first-child": {
      textAlign: "left",
      paddingLeft: "0px",
    },
  },
  iconButton: {
    width: "30px",
    height: "30px",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: "1rem",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  title: {
    fontWeight: 500,
  },
  inputBase: {
    padding: "5px 10px",
    width: "calc(100% - 50px)",
  },
  inputBaseRoot: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  selectInput: {
    width: "100px",
    borderRadius: "5px",
    padding: "5px 10px",
    marginBottom: "10px",
  },

  searchInputRoot: {
    // backgroundColor: grey[50],
    boxShadow: theme.boxShadows[4],
    borderLeft: "solid 4px " + orange[600],
  },
  searchInput: {
    width: "calc(100% - 50px)",
    padding: "10px 20px",
  },

  paper: {
    boxShadow: theme.boxShadows[3],
    minHeight: "100%",
    borderRadius: "10px",
    borderTop: "solid 3px " + green[400],
    padding: "10px",
  },
  findAccountCard: {
    minHeight: "100%",
  },
}));

export default function CustomersTable(props) {
  const classes = tableStyles();
  const [apiQueryState, setApiQueryState] = useState({
    limit: 10,
    page: 1,
    branch: "ALL",
    like: "",
    orderBy: "desc",
    orderColumn: "account_number",
    time_stamp: "",
  });

  return (
    <Paper className={clsx(classes.tableRoot, "p-3")}>
      <ApiRequest
        addOnThread={JSON.stringify(apiQueryState)}
        query={apiQueryState}
        autoload
        initialPayload={{
          search_term: "",
        }}
        thread={BNK_API_GET_ALL_CUSTOMERS}
        callbacks={{
          success: (res) => {
            // setSearching(false);
          },
        }}
      >
        {({
          loading,
          ErrorView,
          res,
          RefreshButton,
          refreshing,
          refresh,
          error,
          payload,
          setPayload,
          submit,
        }) => {
          let accounts = [];
          let total = 0;

          if (loading || refreshing) {
            return (
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                align="center"
              >
                <CircularProgress size="50px" />
              </div>
            );
          }

          if (error) {
            return (
              <div className="p-5">
                <ErrorView />
              </div>
            );
          }

          if (typeof res == "object") {
            if (typeof res.data === "object") {
              if (Array.isArray(res.data.accounts)) {
                accounts = res.data.accounts;
                total = parseInt(res.data.total);
              }
            }
          }

          return (
            <React.Fragment>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div style={{ width: "130px" }}>
                    <SelectBranchMenu
                      hasAll={true}
                      isSelect
                      input={() => ({
                        onChange: (e) => {
                          setApiQueryState({
                            ...apiQueryState,
                            branch: e.target.value,
                          });
                        },
                        value: apiQueryState.branch,
                      })}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <label htmlFor="ordercol" className="mb-2">
                      Order Column
                    </label>
                    <select
                      value={apiQueryState.orderColumn}
                      id="ordercol"
                      className={classes.selectInput}
                      onChange={(e) =>
                        setApiQueryState({
                          ...apiQueryState,
                          orderColumn: e.target.value,
                        })
                      }
                    >
                      <option value="account_number">Account No.</option>
                      <option value="account_name">Account Name.</option>
                      <option value="account_balance">Account Balance.</option>
                    </select>
                  </div>
                  <div className="d-flex flex-column">
                    <label htmlFor="orderby" className="mb-2">
                      Order By
                    </label>
                    <select
                      id="orderby"
                      value={apiQueryState.orderBy}
                      className={classes.selectInput}
                      onChange={(e) =>
                        setApiQueryState({
                          ...apiQueryState,
                          orderBy: e.target.value,
                        })
                      }
                    >
                      <option value="desc">Descending</option>
                      <option value="asc">Ascending.</option>
                    </select>
                  </div>
                </div>
                <div>
                  <Paper
                    className={clsx(
                      "d-flex justify-content-between align-items-center",
                      classes.searchInputRoot
                    )}
                  >
                    <InputBase
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          search_term: e.target.value,
                        })
                      }
                      value={payload.search_term}
                      className={classes.searchInput}
                      placeholder="Search customer account"
                    />
                    {payload.search_term.length > 0 ? (
                      <div className={classes.searchIcon}>
                        <IconButton
                          onClick={() => {
                            setPayload({ search_term: "" });
                            setApiQueryState({
                              ...apiQueryState,
                              time_stamp: "",
                            });
                          }}
                          disabled={loading}
                        >
                          <Close />
                        </IconButton>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className={classes.searchIcon}>
                      <IconButton
                        onClick={() => {
                          setApiQueryState({
                            ...apiQueryState,
                            time_stamp: Date.now(),
                          });
                        }}
                        disabled={loading}
                      >
                        {loading == true ? <CircularProgress /> : <Search />}
                      </IconButton>
                    </div>
                  </Paper>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <TableExportMenu
                    data={accounts.map((row) => [row])}
                    schema={[
                      {
                        column: "Opening Date",
                        type: Date,
                        format: "dd/mm/yyyy",
                        value: (account) => new Date(account[0].created_at),
                      },
                      {
                        column: "Branch",
                        type: String,
                        value: (account) => account[0].branch.toString(),
                        width: 20,
                      },
                      {
                        column: "Account Number",
                        type: String,
                        value: (account) =>
                          account[0].account_number.toString(),
                        width: 20,
                      },
                      {
                        column: "Account Name",
                        type: String,
                        value: (account) => account[0].account_name,
                        width: 20,
                      },
                      {
                        column: "Account Type",
                        type: String,
                        value: (account) => account[0].account_type,
                        width: 20,
                      },
                      {
                        column: "Account Balance",
                        type: Number,
                        value: (account) => account[0].account_balance,
                        width: 20,
                      },
                    ]}
                  />
                  <div>
                    <RefreshButton variant="icon" />
                  </div>
                </div>
              </div>
              <table className={classes.table}>
                <thead className={classes.tableHeading}>
                  <tr>
                    <th className={classes.col2}>No.</th>
                    <th>Open Date.</th>
                    <th>Branch.</th>
                    <th>Account No.</th>
                    <th>Account Name</th>
                    <th>Account Type</th>
                    <th>Account Balance</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody className={classes.tbody}>
                  {loading === true ? (
                    <div className="w-100 my-2" align="center">
                      <CircularProgress />
                    </div>
                  ) : (
                    ""
                  )}

                  {accounts.map((account, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {apiQueryState.page === 1
                            ? ++index
                            : apiQueryState.limit * apiQueryState.page +
                              index -
                              9}
                        </td>
                        <td>{showTransactionDate(account.created_at)}</td>
                        <td>{account.branch}</td>
                        <td>{account.account_number}</td>
                        <td>{account.account_name}</td>
                        <td>{account.account_type}</td>
                        <td>
                          UGX {accounting.formatNumber(account.account_balance)}
                        </td>
                        <td>
                          <MoreOptions
                            {...account}
                            reloadThread={
                              BNK_API_GET_ALL_CUSTOMERS +
                              JSON.stringify(apiQueryState)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {accounts.length === 0 ? (
                <div className="w-100 my-4" align="center">
                  <Typography>
                    <b>No Customers</b>
                  </Typography>
                  <IconButton
                    onClick={refresh}
                    className={clsx(classes.iconButton, "me-2")}
                    {...(refreshing === true ? { disabled: true } : {})}
                  >
                    {refreshing === true ? (
                      <div>
                        <CircularProgress size="18px" color="inherit" />
                      </div>
                    ) : (
                      <Refresh />
                    )}
                  </IconButton>
                </div>
              ) : (
                ""
              )}
              <Divider />
              <Pagination
                layout={["total", "-", "limit", "|", "pager", "skip"]}
                // size="md"
                prev={true}
                next={true}
                first={true}
                last={true}
                ellipsis={true}
                boundaryLinks={true}
                total={total}
                limit={apiQueryState.limit}
                limitOptions={[10, 20, 50, 100, 200, 500]}
                maxButtons={5}
                activePage={apiQueryState.page}
                onChangePage={(page) =>
                  setApiQueryState({ ...apiQueryState, page })
                }
                onChangeLimit={(limit) =>
                  setApiQueryState({ ...apiQueryState, limit, page: 1 })
                }
              />
            </React.Fragment>
          );
        }}
      </ApiRequest>
    </Paper>
  );
}

function MoreOptions(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = (e) => {
    setAnchorEl(e.target);
  };
  const closeMenu = (e) => {
    setAnchorEl(null);
  };
  return (
    <div style={{ minWidth: "150px" }}>
      <EditAccountDetails {...props} />
      <AccountDetails {...props} />
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menu onClose={closeMenu} open={Boolean(anchorEl)} anchorEl={anchorEl}>
        <DeleteAccountButton {...props} />
      </Menu>
    </div>
  );
}

export function DeleteAccountButton(props) {
  const [dialogState, setDialogState] = useState(false);
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
  };
  return (
    <React.Fragment>
      {/* <IconButton className={classes.iconButton}>
        <i className="bi bi-trash"></i>
      </IconButton> */}
      <Button
        onClick={onDialogStateChange}
        fullWidth
        className="justify-content-between"
        endIcon={<Delete />}
      >
        Delete Account
      </Button>
      <Dialog open={dialogState} fullWidth maxWidth="xs">
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <Typography>Delete customer account</Typography>
            <IconButton onClick={onDialogStateChange}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="w-100" align="center">
            <Alert severity="warning">
              <Typography>
                <small>
                  Deleting account will mean deleting all the data associated
                  with the user. This process is irreversible. Ensure that you
                  backup the user data before deleting.
                </small>
              </Typography>
            </Alert>
            <ApiRequest
              thread={BNK_API_DELETE_CUSTOMER_ACCOUNT}
              reloadOnSuccess={[props.reloadThread]}
              payload={{
                account_number: props.account_number,
              }}
              callbacks={{
                success: (res, xthis) => {
                  if (typeof res === "object") {
                    if (typeof res.data === "object") {
                      if (res.data.deleted) {
                        onDialogStateChange();
                      }
                    }
                  }
                },
              }}
            >
              {({ loading, SubmitButton }) => {
                return (
                  <SubmitButton
                    className="mt-3"
                    variant="contained"
                    size="small"
                    color="secondary"
                    endIcon={<Delete />}
                  >
                    Delete
                  </SubmitButton>
                );
              }}
            </ApiRequest>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
