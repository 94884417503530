import { Grid, Typography, Paper, Divider, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import KijjeInput from "../../../global/components/inputs/Inputs";
import clsx from "clsx";
import { KeyboardArrowRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.boxShadows[3],
    borderRadius: "10px",
  },
}));
export default function AccountSettings(props) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={10}>
        <Paper className={clsx(classes.root, "p-3")}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography>Username</Typography>
              <small>
                To make a good and complex username for security user both
                lowercase and uppercase with numbers included.
              </small>
            </Grid>
            <Grid item xs={12} md={6}>
              <KijjeInput
                className="mt-3"
                placeholder="Username"
                variant="filled"
                square
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography>Email</Typography>
              <small>
                Email will be used to send you security notification and alerts
                incase someone accessed your account.
              </small>
            </Grid>
            <Grid item xs={12} md={6}>
              <KijjeInput
                className="mt-3"
                placeholder="Your email"
                variant="filled"
                square
              />
            </Grid>
          </Grid>
          <Button
            align="right"
            className="mt-2"
            size="small"
            variant="outlined"
          >
            Update
          </Button>
          <Divider className="my-3" />
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography>Reset Password</Typography>
              <small>
                Once you reset password and password reset code will be sent out
                to your email.
              </small>
              <br />
              <Button
                endIcon={<KeyboardArrowRight />}
                className="mt-2"
                size="small"
                variant="outlined"
              >
                Reset Password
              </Button>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
