import {
  Avatar,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import React from "react";
import ApiRequest from "../../../api/ApiRequest";
import NewLoanTypeButton from "./NewLoanTypeButton";
import clsx from "clsx";
import { BNK_API_GET_ALL_LOAN_TYPES } from "../../../api/apiThreads";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "10px",
  },
}));

export default function LoanTypesTable(props) {
  const classes = useStyles();
  return (
    <Paper className={clsx(classes.paper, "")}>
      <div>
        <ApiRequest thread={BNK_API_GET_ALL_LOAN_TYPES} autoload>
          {({ loading, res, error, ErrorView, RefreshButton }) => {
            if (loading == true) {
              return (
                <React.Fragment>
                  <div className="d-flex justify-content-between align-items-center w-100 ">
                    <Typography variant="h6">Loan Types</Typography>
                    <div className="d-flex justify-content-end align-items-center">
                      <RefreshButton variant="icon" />
                      <NewLoanTypeButton />
                    </div>
                  </div>
                  <Divider />
                  <div className="w-100 d-flex justify-content-center align-items-center p-5">
                    <CircularProgress />
                  </div>
                </React.Fragment>
              );
            }

            if (error == true) {
              return (
                <React.Fragment>
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <Typography variant="h6">Loan Types</Typography>
                    <div className="d-flex justify-content-end align-items-center">
                      <RefreshButton variant="icon" />
                      <NewLoanTypeButton />
                    </div>
                  </div>
                  <Divider />
                  <div className="w-100 d-flex justify-content-center align-items-center p-5">
                    <ErrorView />
                  </div>
                </React.Fragment>
              );
            }

            let loan_types = null;
            if (typeof res == "object") {
              if (typeof res.data == "object") {
                if (Array.isArray(res.data.loan_types)) {
                  loan_types = res.data.loan_types;
                }
              }
            }

            if (loan_types == null) {
              return (
                <React.Fragment>
                  <div className="d-flex justify-content-between align-items-center w-100 px-2">
                    <Typography variant="h6">Loan Types</Typography>
                    <div className="d-flex justify-content-end align-items-center">
                      <RefreshButton variant="icon" />
                      <NewLoanTypeButton />
                    </div>
                  </div>
                  <Divider />
                  <div className="w-100 d-flex justify-content-center align-items-center p-5">
                    <Typography>
                      Some thing went wrong while getting loan types
                    </Typography>
                  </div>
                </React.Fragment>
              );
            }

            if (loan_types.length == 0) {
              return (
                <React.Fragment>
                  <div className="d-flex justify-content-between align-items-center w-100 px-3">
                    <Typography variant="h6">Loan Types</Typography>
                    <div className="d-flex justify-content-end align-items-center">
                      <RefreshButton variant="icon" />
                      <NewLoanTypeButton />
                    </div>
                  </div>
                  <Divider />
                  <div
                    className="w-100 d-flex justify-content-center align-items-center p-5"
                    align="center"
                  >
                    <Typography>
                      There are no loan types at the moment
                    </Typography>
                  </div>
                </React.Fragment>
              );
            }

            return (
              <React.Fragment>
                <div className="d-flex justify-content-between align-items-center w-100 ps-2">
                  <Typography variant="h6">Loan Types</Typography>
                  <div className="d-flex justify-content-end align-items-center">
                    <RefreshButton variant="icon" />
                    <NewLoanTypeButton />
                  </div>
                </div>
                <Divider />
                <div className="w-100">
                  <List dense>
                    {loan_types.map((type, index) => {
                      return (
                        <React.Fragment>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar variant="square" src={type.banner} />
                            </ListItemAvatar>
                            <ListItemText secondary={type.description}>
                              {type.name}
                            </ListItemText>
                            <ListItemSecondaryAction>
                              {/* <IconButton>
                                <Delete />
                              </IconButton>
                              <IconButton>
                                <Edit />
                              </IconButton> */}
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      );
                    })}
                  </List>
                </div>
              </React.Fragment>
            );
          }}
        </ApiRequest>
      </div>
    </Paper>
  );
}
