import {
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
  Add,
  Close,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useStyles } from "@material-ui/pickers/views/Calendar/Day";
import clsx from "clsx";
import React, { useState } from "react";
import { Divider, Message, toaster } from "rsuite";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_GET_BRANCHES_NOT_ACCESSED_BY_USER,
  BNK_API_GRANT_BRANCH_ACCESS_TO_USER,
  BNK_API_UPDATE_BRANCH_USER_ACCESS,
  GET_ADMIN_USERS,
  GET_ADMIN_USER_DETAILS,
  UPDATE_ADMIN_USER_DETAILS,
} from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";
import MicrosoftLoader from "../../../global/components/loader/MicrsoftLoader";
import { SelectBranchMenu } from "../Transactions/TransactionsTable";
import AdminProfileImage from "./AdminProfileImage";
import RegisterAdminUserButton from "./RegisterAdminUserButton";
import settingsStyles from "./settingsStyles";

export default function SystemUsers(props) {
  return (
    <React.Fragment>
      <AllUsers />
    </React.Fragment>
  );
}

function AllUsers(props) {
  return (
    <Grid container spacing={3} className="mt-3">
      <Grid item xs={12} md={3} lg={4}>
        <RegisterAdminUserButton />
      </Grid>
      <ApiRequest thread={GET_ADMIN_USERS} autoload={true}>
        {({ loading, error, res }) => {
          if (loading === true) {
            return <MicrosoftLoader />;
          }
          if (error === true) {
            return <Alert severity="error">Some thing went wrong</Alert>;
          }

          let users = [];
          if (typeof res == "object") {
            if (typeof res.data == "object") {
              if (Array.isArray(res.data.admins)) {
                users = res.data.admins;
              }
            }
          }

          if (users.length == 0) {
            return (
              <div className="w-100" align="center">
                <Typography>There are no records to display</Typography>
              </div>
            );
          }

          return users.map((user) => {
            return (
              <Grid item xs={12} md={3} lg={4}>
                <UserCard {...user} />
              </Grid>
            );
          });
        }}
      </ApiRequest>
    </Grid>
  );
}

function UserCard(props) {
  const classes = settingsStyles();
  const {
    email,
    name,
    readPerm,
    profilePicture,
    writePerm,
    updatePerm,
    id,
    deletePerm,
  } = props;
  return (
    <Paper className={classes.userCardRoot}>
      <div className={classes.cardHeader}>
        <ViewEditSystemUserDetails user_id={id} />
      </div>
      <div className={clsx(classes.cardBody, "py-4")} align="center">
        <AdminProfileImage url={profilePicture} />
        <div className="py-3">
          <Typography className={classes.name}>{name}</Typography>
          <Typography className={classes.email}>{email}</Typography>
        </div>
        <div className={clsx("px-4 mt-2", classes.smallText)} align="center">
          <div className="d-flex align-items-center justify-content-center">
            <div>
              C
              <div className={clsx(classes.accessLevel)}>
                <div
                  className={clsx(
                    writePerm === true ? classes.hasPermissions : ""
                  )}
                />
              </div>
            </div>
            <div>
              R
              <div className={classes.accessLevel}>
                <div
                  className={clsx(
                    readPerm === true ? classes.hasPermissions : ""
                  )}
                />
              </div>
            </div>
            <div>
              U
              <div className={classes.accessLevel}>
                <div
                  className={clsx(
                    updatePerm === 1 ? classes.hasPermissions : ""
                  )}
                />
              </div>
            </div>
            <div>
              D
              <div className={classes.accessLevel}>
                <div
                  className={clsx(
                    deletePerm === 1 ? classes.hasPermissions : ""
                  )}
                />
              </div>
            </div>
          </div>
          <Typography className={classes.smallText}>
            <i className=" bi-layers"></i> Permissions
          </Typography>
        </div>
      </div>
      <div className={clsx(classes.cardFooter, "py-2")}>
        <Typography className={classes.role}>ADMIN</Typography>
      </div>
    </Paper>
  );
}

/**
 * Update and edit user dialog
 */
export function ViewEditSystemUserDetails(props) {
  const classes = useStyles();
  const [dialogState, setDialogState] = useState(false);
  const [apiQueryState, setApiQueryState] = useState({
    date: Date.now(),
  });
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
    setApiQueryState({
      ...apiQueryState,
      date: Date.now(),
    });
  };

  const [collapseState, setCollapseState] = useState(-1);

  return (
    <React.Fragment>
      <IconButton onClick={onDialogStateChange}>
        <i className=" bi-three-dots-vertical"></i>
      </IconButton>
      <Dialog open={dialogState} fullWidth maxWidth="md">
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <Typography variant="h6">User Details</Typography>
            <IconButton onClick={onDialogStateChange}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <ApiRequest
            autoload
            thread={GET_ADMIN_USER_DETAILS}
            addOnThread={JSON.stringify(apiQueryState)}
            query={{
              user_id: props.user_id,
            }}
          >
            {({ res, loading, error, ErrorView, submit, RefreshButton }) => {
              const reloadAdminUserDetails = submit;
              if (loading) {
                return <CircularProgress />;
              }

              if (error) {
                return <ErrorView />;
              }

              let details = {};
              let branches = [];
              if (typeof res == "object") {
                if (typeof res.data == "object") {
                  if (typeof res.data.details === "object") {
                    details = res.data.details;
                  }
                  if (Array.isArray(res.data.branchAccess)) {
                    branches = res.data.branchAccess;
                  }
                }
              }

              return (
                <ApiRequest
                  thread={UPDATE_ADMIN_USER_DETAILS}
                  addOnThread={JSON.stringify(apiQueryState)}
                  reloadOnSuccess={[
                    GET_ADMIN_USERS,
                    GET_ADMIN_USER_DETAILS + JSON.stringify(apiQueryState),
                  ]}
                  initialPayload={{
                    ...details,
                    profilePicture: details.profilePicture || "",
                  }}
                  callbacks={{
                    success: (res, xthis) => {
                      if (typeof res == "object") {
                        if (typeof res.data == "object") {
                          if (res.data.updated === true) {
                            const message = (
                              <Message type="success">
                                User Details have been updated
                              </Message>
                            );
                            toaster.push(message, "topRight");
                          }
                        }
                      }
                    },
                  }}
                >
                  {({
                    error,
                    SubmitButton,
                    input,
                    FileInput,
                    res,
                    setPayload,
                    payload,
                  }) => {
                    return (
                      <div>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <Paper
                              className={clsx(
                                classes.formCard,
                                "p-3 my-5 pb-5 mt-0"
                              )}
                            >
                              <div className="w-100" align="center">
                                <FileInput
                                  inputClassName="d-none"
                                  name="profilePicture"
                                  onContentLoaded={({ url }) =>
                                    setPayload({
                                      profilePicture: url,
                                    })
                                  }
                                >
                                  {({ Label, url }) => {
                                    return (
                                      <AdminProfileImage
                                        url={url}
                                        icon={
                                          <Label>
                                            <div size="small">
                                              <i className=" bi-camera-fill"></i>
                                            </div>
                                          </Label>
                                        }
                                      />
                                    );
                                  }}
                                </FileInput>
                                <Typography>Update Profile picture</Typography>
                              </div>
                              <KijjeInput
                                variant="filled"
                                placeholder="Full Name"
                                label="Name*"
                                {...input({ name: "name" })}
                              />
                              <KijjeInput
                                variant="filled"
                                placeholder="Valid Email"
                                label="Email*"
                                type="email"
                                {...input({ name: "email" })}
                              />
                              <KijjeInput
                                variant="filled"
                                placeholder="Phone Number"
                                label="Phone Number*"
                                {...input({
                                  name: "phoneNumber",
                                })}
                              />
                              <KijjeInput
                                variant="filled"
                                placeholder="Country"
                                label="Country*"
                                {...input({ name: "country" })}
                              />
                              <KijjeInput
                                variant="filled"
                                placeholder="Physical Address"
                                label="Physical Address*"
                                {...input({ name: "address" })}
                              />
                              <KijjeInput
                                variant="filled"
                                placeholder="Password"
                                label="Password*"
                                type="password"
                                {...input({ name: "password" })}
                              />
                              <SelectBranchMenu
                                isSelect
                                input={(options) =>
                                  input({
                                    name: "default_branch",
                                  })
                                }
                              />
                              <small className="mt-1">Permissions</small>
                              <div
                                className="p-2 my-1"
                                style={{
                                  backgroundColor: "#fff",
                                  borderRadius: "5px",
                                }}
                              >
                                <FormControlLabel
                                  size="small"
                                  {...input({ name: "readPerm" })}
                                  label={<small>Read</small>}
                                  checked={payload.readPerm}
                                  control={<Checkbox size="small" />}
                                />
                                <FormControlLabel
                                  {...input({
                                    name: "writePerm",
                                  })}
                                  checked={payload.writePerm}
                                  label={<small>Write</small>}
                                  control={<Checkbox size="small" />}
                                />
                                <FormControlLabel
                                  {...input({
                                    name: "deletePerm",
                                  })}
                                  checked={payload.deletePerm}
                                  label={<small>Delete</small>}
                                  control={<Checkbox size="small" />}
                                />
                                <FormControlLabel
                                  {...input({
                                    name: "updatePerm",
                                  })}
                                  checked={payload.updatePerm}
                                  label={<small>Update</small>}
                                  control={<Checkbox size="small" />}
                                />
                              </div>

                              <div className="w-100 mt-2" align="right">
                                <SubmitButton
                                  variant="contained"
                                  color="secondary"
                                  size="small"
                                >
                                  Update details{" "}
                                </SubmitButton>
                              </div>
                            </Paper>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="d-flex justify-content-between align-items-center">
                              <Typography variant="h6">
                                Branch Access and Permissions
                              </Typography>
                              <div className="d-flex align-items-center">
                                <RefreshButton variant="icon" />
                                <AssignBranchToUser user_id={props.user_id} />
                              </div>
                            </div>
                            <Divider />
                            <List>
                              {branches.map((branch, index) => {
                                return (
                                  <ApiRequest
                                    thread={BNK_API_UPDATE_BRANCH_USER_ACCESS}
                                    addOnThread={
                                      props.user_id + index + branch.branch_id
                                    }
                                    reloadOnSuccess={[]}
                                    initialPayload={{
                                      user_id: props.user_id,
                                      access_deposits: false,
                                      access_withdraws: false,
                                      access_transactions: false,
                                      access_loan_payments: false,
                                      can_deposit: false,
                                      can_open_account: false,
                                      can_pay_loan: false,
                                      can_withdraw: false,
                                      can_approve_loan: false,
                                      can_decline_loan: false,
                                      can_delete_customer: false,
                                      can_edit_customer_details: false,
                                      can_view_customer_details: false,
                                      can_record_expense: false,
                                      can_view_expense: false,
                                      can_create_expense_category: false,
                                      can_view_expense_category: false,
                                      can_delete_transaction: false,
                                      ...branch,
                                    }}
                                    callbacks={{
                                      success: (res, xthis) => {
                                        if (typeof res == "object") {
                                          if (typeof res.data === "object") {
                                            if (res.data.updated === true) {
                                              reloadAdminUserDetails();
                                            }
                                          }
                                        }
                                      },
                                    }}
                                  >
                                    {({ SubmitButton, input, payload }) => {
                                      return (
                                        <React.Fragment key={index}>
                                          <ListItem key={index}>
                                            <ListItemText>
                                              {branch.name}
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                              <IconButton
                                                onClick={() => {
                                                  if (collapseState === index) {
                                                    setCollapseState(-1);
                                                  } else {
                                                    setCollapseState(index);
                                                  }
                                                }}
                                              >
                                                {collapseState === index ? (
                                                  <KeyboardArrowDown />
                                                ) : (
                                                  <KeyboardArrowRight />
                                                )}
                                              </IconButton>
                                            </ListItemSecondaryAction>
                                          </ListItem>
                                          <Collapse
                                            in={collapseState === index}
                                          >
                                            <div className="px-2">
                                              <Paper
                                                style={{
                                                  backgroundColor: grey[50],
                                                  borderRadius: "10px",
                                                }}
                                              >
                                                <div className="d-flex justify-content-center align-content-center">
                                                  {loading === true ? (
                                                    <CircularProgress />
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                                <List dense>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Access Deposits
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.access_deposits
                                                        }
                                                        {...input({
                                                          name: "access_deposits",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Access Loan Payments
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.access_loan_payments
                                                        }
                                                        {...input({
                                                          name: "access_loan_payments",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Access Withdraws
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.access_withdraws
                                                        }
                                                        {...input({
                                                          name: "access_withdraws",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Access Transactions
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.access_transactions
                                                        }
                                                        {...input({
                                                          name: "access_transactions",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Access Loans
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.access_loans
                                                        }
                                                        {...input({
                                                          name: "access_loans",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can Pay loan
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_pay_loan
                                                        }
                                                        {...input({
                                                          name: "can_pay_loan",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can Deposit
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_deposit
                                                        }
                                                        {...input({
                                                          name: "can_deposit",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can open account
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_open_account
                                                        }
                                                        {...input({
                                                          name: "can_open_account",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can Withdraw
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_withdraw
                                                        }
                                                        {...input({
                                                          name: "can_withdraw",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can Approve Loan
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_approve_loan
                                                        }
                                                        {...input({
                                                          name: "can_approve_loan",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can Decline Loan
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_decline_loan
                                                        }
                                                        {...input({
                                                          name: "can_decline_loan",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can Issue Loan
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_issue_loan
                                                        }
                                                        {...input({
                                                          name: "can_issue_loan",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can Reschedule Loan
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_reschedule_loan
                                                        }
                                                        {...input({
                                                          name: "can_reschedule_loan",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can Edit Approved Loan
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_edit_approved_loan
                                                        }
                                                        {...input({
                                                          name: "can_edit_approved_loan",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can Delete Approved Loan
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_delete_approved_loan
                                                        }
                                                        {...input({
                                                          name: "can_delete_approved_loan",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Delete Customer Account
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_delete_customer
                                                        }
                                                        {...input({
                                                          name: "can_delete_customer",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can Edit Customer Details
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_edit_customer_details
                                                        }
                                                        {...input({
                                                          name: "can_edit_customer_details",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can View Customer Details
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_view_customer_details
                                                        }
                                                        {...input({
                                                          name: "can_view_customer_details",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can Record Expense
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_record_expense
                                                        }
                                                        {...input({
                                                          name: "can_record_expense",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can View Expenses
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_view_expense
                                                        }
                                                        {...input({
                                                          name: "can_view_expense",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can Create Expenses
                                                      Category
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_create_expense_category
                                                        }
                                                        {...input({
                                                          name: "can_create_expense_category",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can View Expenses Category
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_view_expense_category
                                                        }
                                                        {...input({
                                                          name: "can_view_expense_category",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can Delete Transaction
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_delete_transaction
                                                        }
                                                        {...input({
                                                          name: "can_delete_transaction",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can Record Income
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_record_income
                                                        }
                                                        {...input({
                                                          name: "can_record_income",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can Delete Income
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_delete_income
                                                        }
                                                        {...input({
                                                          name: "can_delete_income",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can Edit Income
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_edit_income
                                                        }
                                                        {...input({
                                                          name: "can_edit_income",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText>
                                                      Can View Incomes
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                      <Checkbox
                                                        checked={
                                                          payload.can_view_incomes
                                                        }
                                                        {...input({
                                                          name: "can_view_incomes",
                                                          type: "checkbox",
                                                        })}
                                                      />
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                </List>
                                                <Divider className="my-0" />
                                                <div className="d-flex justify-content-end align-items-center p-2">
                                                  <SubmitButton
                                                    size="small"
                                                    variant="contained"
                                                  >
                                                    Update Details
                                                  </SubmitButton>
                                                </div>
                                              </Paper>
                                            </div>
                                          </Collapse>
                                        </React.Fragment>
                                      );
                                    }}
                                  </ApiRequest>
                                );
                              })}
                            </List>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  }}
                </ApiRequest>
              );
            }}
          </ApiRequest>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

function AssignBranchToUser(props) {
  const [dialogState, setDialogState] = useState(false);
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
  };
  const [collapseState, setCollapseState] = useState(null);
  return (
    <React.Fragment>
      <IconButton onClick={onDialogStateChange}>
        <Add />
      </IconButton>
      <Dialog open={dialogState} fullWidth maxWidth="sm">
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <Typography>Assign Branch to user</Typography>
            <IconButton onClick={onDialogStateChange}>
              <Close />
            </IconButton>
          </div>
          <Divider className="my-0" />
        </DialogTitle>
        <List>
          <ApiRequest
            autoload
            thread={BNK_API_GET_BRANCHES_NOT_ACCESSED_BY_USER}
            addOnThread={props.user_id}
            payload={{
              user_id: props.user_id,
            }}
          >
            {({ res, loading, error, ErrorView, RefreshButton, submit }) => {
              const reloadBranches = submit;
              if (loading) {
                return <CircularProgress />;
              }

              if (error) {
                return <ErrorView />;
              }

              let branches = [];
              if (typeof res == "object") {
                if (typeof res.data == "object") {
                  if (Array.isArray(res.data.branches)) {
                    branches = res.data.branches;
                  }
                }
              }

              if (branches.length === 0) {
                return (
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <div>No branches to show</div>
                    <RefreshButton variant="icon" />
                  </div>
                );
              }

              return branches.map((branch, index) => {
                return (
                  <React.Fragment key={index}>
                    <ListItem key={index}>
                      <ListItemText>{branch.name} - branch</ListItemText>
                      <ListItemSecondaryAction>
                        <ApiRequest
                          thread={BNK_API_GRANT_BRANCH_ACCESS_TO_USER}
                          reloadOnSuccess={[
                            BNK_API_GET_BRANCHES_NOT_ACCESSED_BY_USER +
                              props.user_id,
                          ]}
                          payload={{
                            user_id: props.user_id,
                            branch_id: branch.id,
                            access_loans: false,
                            access_deposits: false,
                            access_withdraws: false,
                            access_transactions: false,
                            access_loan_payments: false,
                            can_deposit: false,
                            can_open_account: false,
                            can_pay_loan: false,
                            can_withdraw: false,
                            can_approve_loan: false,
                            can_decline_loan: false,
                            can_delete_customer: false,
                            can_edit_customer_details: false,
                            can_view_customer_details: false,
                            can_record_expense: false,
                            can_view_expense: false,
                            can_create_expense_category: false,
                            can_view_expense_category: false,
                            can_reschedule_loan: false,
                            can_edit_approved_loan: false,
                            can_view_incomes: false,
                            can_edit_income: false,
                            can_record_income: false,
                            can_delete_income: false,
                          }}
                          onSuccess={{
                            success: (res, xthis) => {
                              if (typeof res == "object") {
                                if (typeof res.data === "object") {
                                  if (res.data.accessGranted === true) {
                                    reloadBranches();
                                  }
                                }
                              }
                            },
                          }}
                        >
                          {({ submit, loading }) => {
                            return (
                              <IconButton onClick={submit}>
                                {loading === true ? (
                                  <CircularProgress />
                                ) : (
                                  <Add />
                                )}
                              </IconButton>
                            );
                          }}
                        </ApiRequest>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </React.Fragment>
                );
              });
            }}
          </ApiRequest>
        </List>
      </Dialog>
    </React.Fragment>
  );
}
