import {
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Select,
} from "@material-ui/core";
import { blueGrey, grey } from "@material-ui/core/colors";
import { Search, Visibility, VisibilityOff } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import * as uuid from "uuid";
import { countries } from "./countries";
import "react-phone-number-input/style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    "& .MuiSvgIcon-root": {
      color: grey[600],
    },
    "&:focus-within .startIcon, &:focus-within label": {
      color: theme.palette.secondary.main,
      transition: "0.3s ease",
      "& .MuiSvgIcon-root": {
        color: theme.palette.secondary.light,
      },
    },
    "& *": {
      ...theme.typography.body1,
      lineHeight: "inherit",
    },
  },

  input: {
    ...theme.typography.fontFamily,
    fontSize: "14px",
    width: "100%",
    height: "35px",
    // border: "solid 1px " + grey[500],
    border: "solid 1px " + theme.palette.secondary.light,
    // borderRadius: "10px",
    borderRadius: "20px",
    padding: "10px 20px",
    paddingLeft: "37px",
    transition: "0.3s ease",
    "& .no-start-icon": {
      paddingLeft: "10px",
    },
    "&:focus, &:active, &:hover": {
      outline: "none",
      paddingLeft: "37px",
      border: "solid 1px " + theme.palette.secondary.main,
      transition: "0.3s ease",
      // boxShadow: theme.shadows[2],
    },
    "&.line": {
      border: "none",
      borderRadius: "0px!important",
      background: "transparent!important",
      borderBottom: "solid 1px" + grey[500],
      transition: "0.3s ease",
      "&:focus, &:active, &:hover": {
        paddingLeft: "37px",
        border: "none",
        transition: "0.3s ease",
        borderBottom: "solid 1px" + theme.palette.secondary.main,
      },
    },
    "&.filled": {
      background: blueGrey[50],
      borderRadius: "2px",
      border: "0px",
      "&:focus, &:active, &:hover": {
        boxShadow: "none!important",
        background: "transparent",
        // border: "solid 1px " + theme.palette.secondary.light,
        border: "solid 1px " + theme.palette.secondary.main,
        borderRadius: "3px!important",
      },
    },
  },

  noStartIcon: {
    paddingLeft: "20px",
  },

  startIcon: {
    position: "absolute",
    height: "100%",
    top: "0px",
    left: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "34px",
    paddingLeft: "5px",
    color: theme.palette.secondary.main,
    "& *": {
      fontWeight: 500,
    },
  },
  endIcon: {
    position: "absolute",
    height: "100%",
    top: "0px",
    right: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "34px",
  },
  inputWrapper: {
    position: "relative",
    marginTop: "5px",
  },
  label: {
    paddingLeft: "5px",
    fontSize: "14px",
    marginBottom: "0px",
    width: "100%",
    fontWeight: 500,
    color: blueGrey[500],
    "&.small": {
      color: "#253647",
      fontSize: "10px",
      lineHeight: "18px",
      fontWeight: "700",
      textTransform: "uppercase",
      fontFamily: "Poppins, sans-serif",
    },
  },

  square: {
    borderRadius: "0px",
  },
  multiline: {
    height: "auto!important",
    resize: "none",
  },
  helperText: {
    // paddingTop: '10px',
    color: theme.palette.warning.dark,
    fontSize: "12px !important",
    "& *": {
      fontSize: "12px !important",
    },
    lineHeight: "0px!important",
  },

  errorText: {
    color: theme.palette.error.dark,
  },
  errorInput: {
    border: "solid 1px",
    borderColor: theme.palette.error.dark,
    "& *": {
      border: "0px transparent solid!important",
    },
  },
  phoneInput: {
    borderRadius: "20px",
    border: "solid 1px " + theme.palette.secondary.main,
    padding: "5px 10px",

    "& *": {
      border: "none",
    },
    "& * ::focus": {
      border: "none",
    },
    "& .PhoneInputInput": {
      border: "none !important",
      outline: "none !important",
    },
  },
  phoneInputLabel: {
    fontSize: "0.85rem",
    fontWeight: 500,
    color: blueGrey[500],
  },
  searchPaper: {
    display: "flex",
  },
  searchInputButton: {
    width: "40px",
    height: "40px",
    borderRadius: "5px",
    backgroundColor: grey[200] + "!important",
  },
  searchInputBase: {
    width: "calc(100%  - 40px)",
    height: "40px",
    borderRadius: "5px",
  },
}));

export function KijjeInput(props) {
  const uid = uuid.v4();
  const classes = useStyles();
  const variant = props.variant || "";
  const [inputPadding, setInputPadding] = useState(null);
  const [inputType, setInputType] = useState(props.type || "text");
  const startIconRef = useRef();
  const multiline = props.multiline || false;

  useEffect(() => {
    if (inputPadding === null) {
      setInputPadding(
        Number(
          typeof startIconRef.current != "undefined"
            ? Number(startIconRef.current.clientWidth)
            : 10
        ) + Number(5)
      );
    }
  }, [inputPadding, setInputPadding]);

  // remove the padding for the start
  const inputClass =
    typeof props.startIcon == "null" ||
    typeof props.startIcon == "undefined" ||
    typeof props.startIcon == false
      ? classes.noStartIcon +
          " " +
          classes.input +
          " no-start-icon " +
          props.className || " "
      : classes.input + " " + props.className || " ";

  const onShowPasswordChange = () => {
    setInputType(inputType == "text" ? "password" : "text");
  };

  var errorClassName = "";
  if (typeof props.error !== "undefined") {
    errorClassName = classes.errorInput;
  }

  if (props.variant === "search") {
    let onSearch = () => {};
    if (typeof props.onSearch === "function") {
      onSearch = () => {
        props.onSearch();
      };
    }
    return (
      <Paper className={clsx("d-flex align-items-center", classes.searchPaper)}>
        <InputBase
          {...props}
          className={clsx(classes.searchInputBase, "px-2")}
        />
        <IconButton onClick={onSearch} className={classes.searchInputButton}>
          <Search />
        </IconButton>
      </Paper>
    );
  }

  if (props.countrySelect) {
    return (
      <div {...props} className={classes.root}>
        {props.label ? (
          <label
            className={clsx(classes.label, props.labelVariant)}
            htmlFor={uid}
          >
            {props.label}
          </label>
        ) : (
          ""
        )}
        <select
          {...props}
          className={clsx(
            props.square
              ? inputClass +
                  " " +
                  variant +
                  " " +
                  classes.multiline +
                  " " +
                  classes.square
              : inputClass + " " + variant + " " + classes.multiline,
            props.error === true ? classes.errorInput : ""
          )}
          style={{ padding: "6px 10px", marginTop: "4px" }}
        >
          {countries.map((country) => {
            return (
              <option value={Object.keys(country)[0]}>
                {country[Object.keys(country)[0]]}
              </option>
            );
          })}
        </select>
        <div
          className={clsx(
            classes.helperText,
            props.error ? classes.errorText : "",
            "pt-1"
          )}
        >
          {typeof props.helperText === "string" ? (
            props.helperText.length > 0 ? (
              <p className="mt-1">{props.helperText}</p>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  if (
    props.type === "phoneNumber" ||
    props.type === "phone_number" ||
    props.type === "phone" ||
    props.type === "tell"
  ) {
    return (
      <div>
        {props.label ? (
          <label className={classes.label}>{props.label}</label>
        ) : (
          ""
        )}
        <div className={classes.phoneInput}>
          <PhoneInput
            defaultCountry={props.defaultCountry || "UG"}
            placeholder="Enter phone number"
            {...props}
            onChange={(phone_number) => {
              props.onChange({
                target: {
                  value: phone_number,
                  name: props.name,
                  type: props.type || "text",
                },
              });
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div {...props} className={classes.root}>
      {props.label ? (
        <label
          className={clsx(classes.label, props.labelVariant)}
          htmlFor={uid}
        >
          {props.label}
        </label>
      ) : (
        ""
      )}
      <div className={classes.inputWrapper + " " + errorClassName}>
        {props.startIcon ? (
          <div className={classes.startIcon + " startIcon"} ref={startIconRef}>
            {props.startIcon}
          </div>
        ) : (
          ""
        )}

        {multiline !== false ? (
          <textarea
            id={uid}
            placeholder={props.placeholder}
            {...props}
            className={clsx(
              props.square
                ? inputClass +
                    " " +
                    variant +
                    " " +
                    classes.multiline +
                    " " +
                    classes.square
                : inputClass + " " + variant + " " + classes.multiline,
              props.error === true ? classes.errorInput : ""
            )}
          ></textarea>
        ) : (
          <input
            id={uid}
            placeholder={props.placeholder}
            {...props}
            className={
              props.square
                ? inputClass + " " + variant + " " + classes.square
                : inputClass + " " + variant + "input"
            }
            type={inputType}
            style={{ paddingLeft: inputPadding + "px" }}
          />
        )}
        {props.type == "password" ? (
          <IconButton
            onClick={onShowPasswordChange}
            className={classes.endIcon}
          >
            {inputType == "text" ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        ) : props.endIcon ? (
          <div className={classes.endIcon}> {props.endIcon}</div>
        ) : (
          ""
        )}
        {/* {props.type == "number" ? <PhoneInput /> : ""} */}
      </div>
      <div
        className={clsx(
          classes.helperText,
          props.error ? classes.errorText : "",
          "pt-1"
        )}
      >
        {typeof props.helperText === "string" ? (
          props.helperText.length > 0 ? (
            <p className="mt-1">{props.helperText}</p>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

KijjeInput.propTypes = {
  startIcon: PropTypes.string,
  square: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
};
export default KijjeInput;

const codeInputStyles = makeStyles((theme) => ({
  root: {},
  inputCodesWrapper: {
    width: "100%",
    display: "flex",
  },
  codeInput: {
    width: "20px",
    outline: "none",
    border: "none",
    borderBottom: `solid 1px ${grey[700]}`,
    marginLeft: "5px",
    marginRight: "5px",
  },
}));

export class KijjeCodeInput extends React.Component {
  constructor(props) {
    super(props);
    this.refs = [];
    this.inputs = Array.from(Array(this.props.codes).keys()).map((index) => {
      this.refs[index] = React.createRef();
    });

    this.state = {
      initialize: false,
    };
  }

  componentDidMount = () => {};

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ ...this.state, [name]: value });
  };

  render() {
    const classes = {};
    return (
      <div className={classes.root}>
        <div className={classes.inputCodesWrapper}>
          {Array.from(Array(this.props.codes).keys()).map((index) => {
            return (
              <input
                style={{ width: `${10 * this.props.codeLength}px` }}
                className={classes.codeInput}
                key={index}
                name={index}
                value={this.state["A" + index]}
                onChange={this.onChange}
                // ref={ref}
                maxLength={this.props.codeLength}
                max={this.props.codeLength}
                type={this.props.codeType}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export function KijjeCodeInputP(props) {
  const classes = codeInputStyles(props);
  var refs = [];
  const [state, setState] = useState({
    initialize: false,
  });

  Array.from(Array(props.codes).keys()).map((index) => {
    refs[index] = React.createRef();
  });

  const onChange = (e) => {
    const { value, name } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <div className={classes.root}>
      <div className={classes.inputCodesWrapper}>
        {refs.map((ref, index) => {
          return (
            <input
              style={{ width: `${10 * props.codeLength}px` }}
              className={classes.codeInput}
              key={index}
              name={index}
              value={state["A" + index]}
              onChange={onChange}
              // ref={ref}
              maxLength={props.codeLength}
              max={props.codeLength}
              type={props.codeType}
            />
          );
        })}
      </div>
    </div>
  );
}

KijjeCodeInput.propTypes = {
  codes: PropTypes.number.isRequired,
};
