import React from "react";
import IncomesAndExpensesModule from ".";

const incomesAndExpensesModule = {
  name: "Expenses & Incomes",
  path: "/expenses-and-incomes",
  icon: <i class="bi bi-layers-half"></i>,
  Component: IncomesAndExpensesModule,
};

export default incomesAndExpensesModule;
