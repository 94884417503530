import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  Add,
  ArrowForward,
  Check,
  Close,
  Refresh,
  Sort,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import accounting, { formatNumber } from "accounting-js";
import clsx from "clsx";
import { format } from "date-fns";
import React, { useState } from "react";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_GET_EXPENSES,
  BNK_API_GET_EXPENSE_CATEGORIES,
  BNK_API_RECORD_EXPENSE,
} from "../../../api/apiThreads";
import KijjeInput from "../../../global/components/inputs/Inputs";
import {
  ReadTransactionType,
  ShowTransactionAmount,
  showTransactionDate,
} from "../Transactions/TransactionsHistory";
import {
  SelectBranchMenu,
  tableStyles,
} from "../Transactions/TransactionsTable";

const useStyles = makeStyles((theme) => ({
  selectInput: {
    width: "100%",
    height: "35px",
    borderRadius: "5px",
  },
}));
export default function ExpensesTable(props) {
  const classes = tableStyles();
  let where = "deposits|withdraws";
  let show = "type|deposit|withdraw|date";
  let hidden = [];

  return (
    <Paper className={clsx(classes.tableRoot, "p-3")}>
      <ApiRequest autoload thread={BNK_API_GET_EXPENSES} query={{}}>
        {({
          loading,
          ErrorView,
          res,
          RefreshButton,
          refreshing,
          refresh,
          error,
        }) => {
          let history = [];

          if (loading) {
            return (
              <div className="w-100 p-5" align="center">
                <CircularProgress size="50px" />
              </div>
            );
          }

          if (error) {
            return (
              <div className="w-100 p-5" align="center">
                <ErrorView />
              </div>
            );
          }

          if (typeof res === "object") {
            if (res.success == true) {
              if (typeof res.data == "object") {
                if (Array.isArray(res.data.transactions)) {
                  history = res.data.transactions;
                }
              }
            }
          }

          if (history.length === 0) {
            return (
              <div className="w-100 my-4" align="center">
                <RecordExpense />
                <Typography>
                  <b>No transactions made yet</b>
                </Typography>
                <RefreshButton variant="icon" />
              </div>
            );
          }

          return (
            <React.Fragment>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div>
                  <Typography className={classes.title}>
                    {props.title || "Expenses"}
                  </Typography>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <IconButton
                      onClick={refresh}
                      className={clsx(classes.iconButton, "me-2")}
                      {...(refreshing === true ? { disabled: true } : {})}
                    >
                      {refreshing === true ? (
                        <div>
                          <CircularProgress size="18px" color="inherit" />
                        </div>
                      ) : (
                        <Refresh />
                      )}
                    </IconButton>
                  </div>
                  <div>
                    <IconButton
                      onClick={refresh}
                      className={clsx(classes.iconButton, "me-2")}
                      {...(refreshing === true ? { disabled: true } : {})}
                    >
                      {refreshing === true ? (
                        <div>
                          <CircularProgress size="18px" color="inherit" />
                        </div>
                      ) : (
                        <Sort />
                      )}
                    </IconButton>
                    <IconButton className={classes.iconButton}>
                      <ArrowForward />
                    </IconButton>
                  </div>
                </div>
              </div>
              <div className={classes.tableWrapper}>
                <table className={classes.table}>
                  <thead className={classes.tableHeading}>
                    <tr>
                      <th className={classes.col2}>No.</th>
                      {/* <th>Slip No</th> */}
                      <th>Date</th>
                      <th>Reason</th>
                      <th>Type</th>
                      <th>Amount</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody className={classes.tbody}>
                    {loading === true ? (
                      <div className="w-100 my-2" align="center">
                        <CircularProgress />
                      </div>
                    ) : (
                      ""
                    )}

                    {history.map((transaction, index) => {
                      return (
                        <tr key={index}>
                          <td>{++index}</td>
                          {/* <td>SL-{++index}</td> */}
                          <td>
                            {" "}
                            {showTransactionDate(transaction.created_at)}{" "}
                          </td>
                          <td>
                            {transaction.reason.length > 50
                              ? transaction.reason.slice(0, 50) + "..."
                              : transaction.reason}
                          </td>
                          <td>
                            <ReadTransactionType type={transaction.type} />
                          </td>
                          <td>
                            {" "}
                            <ShowTransactionAmount {...transaction} />{" "}
                          </td>
                          <td>
                            {" "}
                            UGX {accounting.formatNumber(transaction.balance)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          );
        }}
      </ApiRequest>
    </Paper>
  );
}

export function RecordExpense(props) {
  const [dialogState, setDialogState] = useState();
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
  };
  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={onDialogStateChange}
        startIcon={<Add />}
      >
        Record Expense
      </Button>
      <Dialog fullWidth maxWidth="lg" open={dialogState}>
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center w-100">
            <Typography>Record Expense</Typography>
            <IconButton onClick={onDialogStateChange}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <RecordExpenseTable {...props} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

function SelectExpenseCategory(props) {
  const classes = useStyles();
  return (
    <ApiRequest thread={BNK_API_GET_EXPENSE_CATEGORIES} autoload>
      {({ loading, error, ErrorView, res }) => {
        if (loading) {
          return <CircularProgress />;
        }
        if (error) {
          return <ErrorView />;
        }

        let types = [];
        if (typeof res == "object") {
          if (typeof res.data == "object") {
            if (Array.isArray(res.data.categories)) {
              types = res.data.categories;
            }
          }
        }

        return (
          <select {...props} className={classes.selectInput}>
            <option>Select expense type</option>
            {types.map((type) => {
              return (
                <option value={type.id}>
                  {type.name} - {type.branch}
                </option>
              );
            })}
          </select>
        );
      }}
    </ApiRequest>
  );
}

function RecordExpenseTable(props) {
  const classes = tableStyles();
  const [savedExpenses, setSavedExpenses] = useState([]);
  const [addOnThread, setAddonThread] = useState(Date.now().toString());
  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
      }}
    >
      <table className={classes.table}>
        <thead className={classes.thead}>
          <tr>
            <th>Paid to</th>
            <th className="ps-4">Date</th>
            <th className="ps-4">Amount</th>
            <th className="ps-4">Trans Id</th>
            <th className="ps-4">Expense Type</th>
            <th className="ps-4">Branch </th>
            <th className="ps-4">Memo</th>
            <th className="ps-4">Action</th>
          </tr>
        </thead>
        <tbody className={classes.tbody}>
          {savedExpenses.map((expense) => {
            return (
              <tr>
                <td>{expense.paid_to}</td>
                <td>{showTransactionDate(expense.date)}</td>
                <td>{formatNumber(expense.amount)}UGX</td>
                <td>{expense.transaction_id}</td>
                <td>{expense.expense_category}</td>
                <td>{expense.branch}</td>
                <td>{expense.memo}</td>
                <td>
                  <IconButton disabled>
                    <Check />
                  </IconButton>
                </td>
              </tr>
            );
          })}
          <ApiRequest
            thread={BNK_API_RECORD_EXPENSE}
            addOnThread={addOnThread}
            initialPayload={{
              transaction_id: "",
              amount: "",
              date: "",
            }}
            callbacks={{
              success: (res, xthis) => {
                if (typeof res == "object") {
                  if (typeof res.data === "object") {
                    if (res.data.expenseRecorded === true) {
                      setSavedExpenses([...savedExpenses, res.data.expense]);
                      xthis.resetForm();
                    }
                  }
                }
              },
            }}
            reloadOnSuccess={[BNK_API_GET_EXPENSES]}
          >
            {({ SubmitButton, input, payload }) => {
              return (
                <tr>
                  <td>
                    <KijjeInput
                      {...input({
                        name: "paid_to",
                      })}
                      value={payload.paid_to}
                      variant="filled"
                      square
                      placeholder="Paid to"
                    />
                  </td>
                  <td>
                    <KijjeInput
                      {...input({
                        name: "date",
                      })}
                      value={payload.date}
                      variant="filled"
                      square
                      placeholder="Date"
                      type="date"
                    />
                  </td>
                  <td>
                    <KijjeInput
                      {...input({
                        name: "amount",
                      })}
                      required
                      value={payload.amount}
                      variant="filled"
                      square
                      placeholder="Amount"
                    />
                  </td>
                  <td>
                    <KijjeInput
                      variant="filled"
                      {...input({
                        name: "transaction_id",
                      })}
                      value={payload.transaction_id}
                      square
                      placeholder="Trans Id."
                    />
                  </td>
                  <td>
                    <SelectExpenseCategory
                      {...input({ name: "expense_category" })}
                    />
                  </td>
                  <td>
                    <SelectBranchMenu isSelect input={input} />
                  </td>
                  <td>
                    <KijjeInput
                      {...input({
                        name: "memo",
                      })}
                      variant="filled"
                      square
                      placeholder="Memo"
                    />
                  </td>
                  <td>
                    <SubmitButton size="small" variant="contained">
                      Record
                    </SubmitButton>
                  </td>
                </tr>
              );
            }}
          </ApiRequest>
        </tbody>
      </table>
    </div>
  );
}
