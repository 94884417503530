import {
  CircularProgress,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Radio,
  Typography,
} from "@material-ui/core";
import { green, grey, orange } from "@material-ui/core/colors";
import { Search } from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";
import ApiRequest from "../../../api/ApiRequest";
import {
  BNK_API_CREATE_TRANSACTION,
  BNK_API_SEARCH_ACCOUNT,
} from "../../../api/apiThreads";
import MicrosoftLoader from "../../../global/components/loader/MicrsoftLoader";
import { renderDocumentLoanUrl } from "../../../global/hooks";
import { SelectBranchMenu } from "../Transactions/TransactionsTable";

const useStyles = makeStyles((theme) => ({
  searchInputRoot: {
    // backgroundColor: grey[50],
    boxShadow: theme.boxShadows[4],
    borderLeft: "solid 4px " + orange[600],
  },
  searchInput: {
    width: "calc(100% - 50px)",
    padding: "10px 20px",
  },
  listItem: {
    width: "100%",
    backgroundColor: grey[50],
    marginTop: "5px",
  },
  depositCard: {
    padding: theme.spacing(3),
    borderRadius: "10px",
    boxShadow: theme.boxShadows[1],
  },
  leftListItem: {
    textAlign: "left",
    minWidth: "150px",
    flex: "1",
  },
  rightListItem: {
    textAlign: "left",
    flex: "1",
  },
  profilePictureWrapper: {
    display: "flex",
    // justifyContent: "center",
    paddingLeft: "20px",
    width: "100%",
  },
  profilePicture: {
    height: "120px",
    width: "100px",
    objectFit: "cover",
    objectPosition: "top",
    border: "solid 7px #fff",
    boxShadow: theme.boxShadows[3],
  },
  paper: {
    boxShadow: theme.boxShadows[3],
    minHeight: "100%",
    borderRadius: "10px",
    borderTop: "solid 3px " + green[400],
    padding: "10px",
  },
  findAccountCard: {
    minHeight: "100%",
  },
}));

export default function FindAccount(props) {
  const classes = useStyles();
  const history = useHistory();
  const [apiQueryState, setApiQueryState] = useState({
    branch: "ALL",
    search_term: "",
  });
  const [_account, setAccount] = useState(
    props.canPay === true ? props.query : {}
  );
  const [state, setState] = useState({});
  const onReset = () => {
    setAccount({});
    setApiQueryState({
      branch: "ALL",
      search_term: "",
    });
  };

  if (typeof _account == "object") {
    if (Object.keys(_account).length >= 2) {
      return (
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h4" className="mb-3">
                {props.title}
              </Typography>
            </Grid>
            <Grid item>
              <div className={classes.profilePictureWrapper}>
                <LazyLoadImage
                  src={renderDocumentLoanUrl(_account.passport_photo)}
                  className={classes.profilePicture}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <List dense disablePadding className={classes.list}>
                <ListItem>
                  <ListItemText className={classes.leftListItem}>
                    Account Name
                  </ListItemText>
                  <ListItemText className={classes.rightListItem} align="left">
                    {_account.account_name}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.leftListItem}>
                    Account Number
                  </ListItemText>
                  <ListItemText className={classes.rightListItem} align="left">
                    {_account.account_number}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.leftListItem}>
                    Account Branch
                  </ListItemText>
                  <ListItemText className={classes.rightListItem} align="left">
                    {_account.branch}
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="p-2">
                {props.children({ account: _account, onReset })}
              </div>
            </Grid>
          </Grid>
        </Paper>
      );
    }
  }

  return (
    <Paper className={clsx(classes.paper, props.className)}>
      <Typography variant="h4" className="mb-3">
        {props.title}
      </Typography>
      <ApiRequest
        thread={BNK_API_SEARCH_ACCOUNT}
        addOnThread={JSON.stringify(apiQueryState)}
        query={apiQueryState}
      >
        {({
          loading,
          error,
          ErrorView,
          res,
          submit,
          input,
          payload,
          setPayload,
        }) => {
          let results = [];
          if (typeof res == "object") {
            if (typeof res.data == "object") {
              if (Array.isArray(res.data.results)) {
                results = res.data.results;
              }
            }
          }
          return (
            <div className="p-4">
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={7} md={9}>
                  <Paper
                    className={clsx(
                      "d-flex justify-content-between align-items-center",
                      classes.searchInputRoot
                    )}
                  >
                    <InputBase
                      onChange={(e) =>
                        setApiQueryState({
                          ...apiQueryState,
                          search_term: e.target.value,
                        })
                      }
                      value={apiQueryState.search_term}
                      className={classes.searchInput}
                      placeholder="Search customer account"
                    />
                    <div className={classes.searchIcon}>
                      <IconButton onClick={submit} disabled={loading}>
                        {loading == true ? <CircularProgress /> : <Search />}
                      </IconButton>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={5} md={3}>
                  <SelectBranchMenu
                    apiQueryState={apiQueryState}
                    setApiQueryState={setApiQueryState}
                  />
                </Grid>
              </Grid>
              <div>
                {loading == true ? (
                  <MicrosoftLoader />
                ) : (
                  <List dense>
                    <ApiRequest
                      thread={BNK_API_CREATE_TRANSACTION}
                      initialPayload={{
                        transaction_type: props.transaction_type,
                      }}
                      callbacks={{
                        success: (res) => {
                          if (typeof res == "object") {
                            if (typeof res.data === "object") {
                              if (res.data.created === true) {
                                setAccount({
                                  ...state,
                                  transaction_token: res.data.transaction_token,
                                });
                              }
                            }
                          }
                        },
                      }}
                    >
                      {({ loading, res, submit }) => {
                        if (loading) {
                          return <CircularProgress />;
                        }
                        return results.map((account, index) => {
                          return (
                            <ListItem
                              key={index}
                              className={classes.listItem}
                              button
                              onClick={() => {
                                setState({ ...account });
                                submit({
                                  ...account,
                                  transaction_type: props.transaction_type,
                                });
                              }}
                            >
                              <ListItemText
                                secondary={
                                  account.account_name +
                                  " | " +
                                  account.branch +
                                  " branch"
                                }
                              >
                                {account.account_number}
                              </ListItemText>
                              <ListItemSecondaryAction>
                                <Radio
                                  onClick={() => {
                                    setState({ ...account });

                                    submit({
                                      ...account,
                                      transaction_type: props.transaction_type,
                                    });
                                  }}
                                  checked={
                                    _account.account_number ===
                                    account.account_number
                                  }
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        });
                      }}
                    </ApiRequest>
                  </List>
                )}
              </div>
            </div>
          );
        }}
      </ApiRequest>
    </Paper>
  );
}

// {
//   ({
//     ErrorView,
//     res,
//     completed,
//     submit,
//     loading,
//     SubmitButton,
//     input,
//     payload,
//     setPayload,
//     resetComplete,
//     resetState,
//   }) => {
//     let CloseAccountButton = (props) => {
//       return (
//         <Button
//           {...props}
//           onClick={() => {
//             history.push(history.location.pathname);
//           }}
//         />
//       );
//     };
//     if (typeof res === "object") {
//       if (
//         completed === true &&
//         res.success == true &&
//         res.data.exists == true &&
//         queryString.parse(history.location.search).search != "undefined" &&
//         queryString.parse(history.location.search).search != undefined
//       ) {
//         let account = res.data.account;

//         return (
// );
//       }
//     }

//     const onKeydown = (e) => {
//       setPayload({ search: true });
//       history.push(
//         history.location.pathname +
//           `?account_name=${payload.account_name}&account_number=${payload.account_number}&search=true`
//       );
//       submit();
//     };
//     let results = [];
//     if (typeof res == "object") {
//       if (typeof res.data == "object") {
//         if (Array.isArray(res.data.results)) {
//           results = res.data.results;
//         }
//       }
//     }

//     return (
//       <Grid container spacing={2} alignItems="flex-start">
//         <Grid item xs={12} md={5}>
//           <KijjeInput
//             {...input({
//               name: "account_name",
//               defaultValue: queryString.parse(history.location.search)
//                 .account_name,
//             })}
//             square
//             label="Account Name"
//             onKeyUp={onKeydown}
//             startIcon={<Person />}
//             style={{ marginBottom: "0px" }}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <KijjeInput
//             {...input({
//               name: "account_number",
//               defaultValue: queryString.parse(history.location.search)
//                 .account_number,
//             })}
//             onKeyUp={onKeydown}
//             type="text"
//             square
//             style={{ marginBottom: "0px" }}
//             label="Account Number"
//           />
//         </Grid>
//         <Grid item xs={12} md={4} align="center">
//           <SubmitButton
//             fullWidth
//             startIcon={<Search />}
//             variant="contained"
//             endIcon={<ArrowForward />}
//             style={{ marginTop: "30px" }}
//             onClick={() => {
//               history.push(
//                 history.location.pathname +
//                   `?account_name=${payload.account_name}&account_number=${payload.account_number}&search=false`
//               );
//             }}
//           >
//             Find Account
//           </SubmitButton>
//         </Grid>
//         <Grid item xs={12}>
//           <List dense disablePadding>
//             {loading == true ? (
//               <div className="w-100 p-4" align="center">
//                 <CircularProgress />
//               </div>
//             ) : (
//               results.map((account) => {
//                 return (
//                   <ListItem
//                     button
//                     onClick={() => {
//                       setPayload({
//                         account_name: account.account_name,
//                         account_number: account.account_number,
//                         search: false,
//                       });

//                       history.push(
//                         history.location.pathname +
//                           `?account_name=${account.account_name}&account_number=${account.account_number}&search=false`
//                       );
//                     }}
//                   >
//                     <ListItemText secondary={account.account_number}>
//                       {account.account_name}
//                     </ListItemText>
//                     <ListItemSecondaryAction>
//                       <IconButton size="small">
//                         <ArrowForward />
//                       </IconButton>
//                     </ListItemSecondaryAction>
//                   </ListItem>
//                 );
//               })
//             )}
//           </List>
//           {res.warn === true ? (
//             typeof res.data === "object" ? (
//               <Alert severity="warning" variant="filled" square>
//                 {res.data.message}
//               </Alert>
//             ) : (
//               ""
//             )
//           ) : (
//             ""
//           )}
//         </Grid>
//       </Grid>
//     );
//   };
// }
