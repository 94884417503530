import {
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { blueGrey, grey } from "@material-ui/core/colors";
import {
  ArrowBack,
  Attachment,
  Close,
  KeyboardArrowDown,
  KeyboardArrowRight,
  RemoveRedEye,
  RemoveRedEyeOutlined,
} from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { blue } from "@mui/material/colors";
import { formatNumber } from "accounting-js";
import clsx from "clsx";
import React, { useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Avatar } from "rsuite";
import ApiRequest from "../../../api/ApiRequest";
import { BNK_API_SHOW_LOAN } from "../../../api/apiThreads";
import { renderDocumentLoanUrl, showDate } from "../../../global/hooks";
import LoanInstallmentRender from "./LoanInstallmentsRender";

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: blueGrey[500],
  },
  paperRoot: {
    background: grey[100],
  },
  paper: {
    borderRadius: "5px",
    padding: "10px",
    margin: "2px 0px",
    boxShadow: "none",
  },
  listItem: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    margin: "5px 0px",
    boxShadow: theme.boxShadows[3],
  },
  idCardPhoto: {
    width: "300px",
    height: "190px",
    borderRadius: "10px",
    border: "dotted 1px " + blue[200],
    outline: "none",
    objectFit: "contain",
  },
  listItemContainer: {
    border: "solid 1px " + blue[300],
    borderRadius: "5px",
  },
  collateralCollapse: {
    backgroundColor: grey[100],
    margin: "-10px",
  },
  avatar: {
    "& .rs-avatar-image": {
      width: "100%",
      height: "100%",
      borderRadius: "5px",
    },
  },
}));

export default function ViewLoan(props) {
  const classes = useStyles();
  const [dialogState, setDialogState] = useState(false);
  const [addOnThread, setAddOnThread] = useState(props.id + Date.now());
  const [collateralCollapse, setCollateralCollapseState] = useState({});
  const [collateralDialogState, setCollateralDialogState] = useState({});
  const [collapse, setCollapse] = useState({
    borrowerDetails: true,
    guarantorDetails: true,
    loanSchedule: true,
  });
  const iFrame = useRef(null);
  const onCollapseChange = (property) => () => {
    setCollapse({ ...collapse, [property]: !collapse[property] });
  };
  const onDialogStateChange = () => {
    setDialogState(!dialogState);
    setAddOnThread(props.id + Date.now());
  };
  const onCollateralCollapseChange = (index) => () => {
    setCollateralCollapseState({
      ...collateralCollapse,
      [index]: !collateralCollapse[index],
    });
  };
  const renderCollapseIcon = (property, type = null) => {
    if (type == null) {
      if (collapse[property] === false) {
        return <KeyboardArrowRight />;
      }
      return <KeyboardArrowDown />;
    } else if (property === true) {
      return <KeyboardArrowDown />;
    } else {
      return <KeyboardArrowRight />;
    }
  };
  const viewAttachment = (attachment) => () => {
    setCollateralDialogState({
      ...collateralDialogState,
      [attachment]: !collateralDialogState[attachment],
    });
    const stylesheet = document.createElement("style");
    stylesheet.innerHTML = "img{width: 100%; height: auto}";
    window.frames.document.head.appendChild(stylesheet);
    window.frames.document.querySelector("img").style =
      "width: 100%; height: auto;";
  };
  return (
    <React.Fragment>
      <IconButton onClick={onDialogStateChange}>
        <RemoveRedEye />
      </IconButton>
      <Dialog open={dialogState} fullScreen fullWidth>
        <Paper className={classes.paperRoot}>
          <Container>
            <div className="d-flex justify-content-between align-items-center py-3">
              <div>
                <Button onClick={onDialogStateChange} startIcon={<ArrowBack />}>
                  Loan Details
                </Button>
              </div>
              <IconButton onClick={onDialogStateChange}>
                <Close />
              </IconButton>
            </div>
            <Divider className={clsx(classes.divider, "my-1")} />
            <ApiRequest
              thread={BNK_API_SHOW_LOAN}
              addOnThread={addOnThread}
              autoload
              params={{
                loan_id: props.id,
              }}
            >
              {({ loading, ErrorView, error, res, RefreshButton }) => {
                if (loading) {
                  return (
                    <div className="w-100 d-flex justify-content-center align-items-center my-5 py-5">
                      <CircularProgress size="50px" />
                    </div>
                  );
                }

                if (error) {
                  return (
                    <div>
                      <ErrorView />
                    </div>
                  );
                }

                let loan = null;
                let installments = null;
                let borrower = null;
                let guarantor = null;
                let securities = [];
                let documents = [];
                let guarantorDocuments = {};

                if (typeof res === "object") {
                  if (typeof res.data === "object") {
                    if (typeof res.data.loan == "object") {
                      loan = res.data.loan;
                      installments = res.data.installments;
                      borrower = res.data.borrower;
                      guarantor = res.data.guarantor;
                      securities = res.data.loanCollaterals;
                      documents = res.data.loanDocuments;
                      guarantorDocuments = res.data.guarantorDocuments;
                    }
                  }
                }

                if (loan == null) {
                  return (
                    <div>
                      <Alert severity="error">
                        <AlertTitle>Failed to get loan details</AlertTitle>
                        <Typography>
                          This might be a network or wrong url try again.
                        </Typography>
                        <RefreshButton variant="icon" />
                      </Alert>
                    </div>
                  );
                }

                return (
                  <Grid container className="mb-5" spacing={3}>
                    <Grid item xs={12} md={4}>
                      <Paper className={clsx(classes.paper, "p-4 mt-2")}>
                        <div>
                          <RefreshButton />
                          <Typography variant="h6">Loan Summary</Typography>
                          <Divider className="my-1" />
                        </div>
                        <div className={clsx("d-flex justify-content-between")}>
                          <Typography>Application Id</Typography>
                          <Typography>
                            <small>{loan.application_id}</small>
                          </Typography>
                        </div>
                        <div className={clsx("d-flex justify-content-between")}>
                          <Typography>Loan Interest Rate</Typography>
                          <Typography>
                            <small>{loan.interest_rate}%</small>
                          </Typography>
                        </div>
                        <div className={clsx("d-flex justify-content-between")}>
                          <Typography>Loan Amount</Typography>
                          <small>{formatNumber(loan.loan_amount)} ugx</small>
                        </div>
                        <div className={clsx("d-flex justify-content-between")}>
                          <Typography>Loan Interest</Typography>
                          <small>
                            {formatNumber(
                              loan.payback_amount - loan.loan_amount
                            )}
                            ugx
                          </small>
                        </div>
                        <div className={clsx("d-flex justify-content-between")}>
                          <Typography>Amount Paid</Typography>
                          <Typography>
                            <small>
                              {formatNumber(loan.amount_paid)}
                              ugx
                            </small>
                          </Typography>
                        </div>
                        <Divider className={clsx("my-2")} />
                        <div className={clsx("d-flex justify-content-between")}>
                          <Typography>Loan Balance</Typography>
                          <Typography>
                            <b>
                              <small>
                                {formatNumber(loan.loan_balance)}
                                ugx
                              </small>
                            </b>
                          </Typography>
                        </div>
                      </Paper>
                      <Paper className={clsx(classes.paper, "p-4 mt-3")}>
                        <div>
                          <Typography variant="h6">Request Summary</Typography>
                          <Divider className="my-1" />
                        </div>
                        <div className={clsx("d-flex justify-content-between")}>
                          <Typography>Requested On: </Typography>
                          <Typography>
                            <small>{showDate(loan.created_at)}</small>
                          </Typography>
                        </div>
                        <div className={clsx("d-flex justify-content-between")}>
                          <Typography>Approved On:</Typography>
                          <Typography>
                            <small>{showDate(loan.approved_on)}</small>
                          </Typography>
                        </div>
                        <div className={clsx("d-flex justify-content-between")}>
                          <Typography>Issued On:</Typography>
                          <Typography>
                            <small>
                              <small>{showDate(loan.issued_on)}</small>
                            </small>
                          </Typography>
                        </div>
                        <div className={clsx("d-flex justify-content-between")}>
                          <Typography>Declined On:</Typography>
                          <Typography>
                            <small>
                              <small>{showDate(loan.declined_on)}</small>
                            </small>
                          </Typography>
                        </div>
                        <div className={clsx("d-flex justify-content-between")}>
                          <Typography>Fist Installment on:</Typography>
                          <Typography>
                            <small>
                              {showDate(loan.first_installment_date)}
                            </small>
                          </Typography>
                        </div>
                        <div className={clsx("d-flex justify-content-between")}>
                          <Typography>Installment Type</Typography>
                          <Typography>
                            <small>{loan.installment_type}</small>
                          </Typography>
                        </div>
                        <div className={clsx("d-flex justify-content-between")}>
                          <Typography>Installments</Typography>
                          <Typography>
                            <small>{formatNumber(loan.installments)}</small>
                          </Typography>
                        </div>
                      </Paper>

                      <Paper className={clsx(classes.paper, "p-4 mt-3")}>
                        <div>
                          <Typography variant="h6">Loan Securities</Typography>
                          <Divider className="my-1" />
                          <List>
                            {securities.map((sec, index) => {
                              return (
                                <div className={classes.listItemContainer}>
                                  <ListItem
                                    button
                                    key={index}
                                    onClick={onCollateralCollapseChange(index)}
                                  >
                                    <ListItemText>
                                      {sec.collateral}
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                      <IconButton
                                        onClick={onCollateralCollapseChange(
                                          index
                                        )}
                                      >
                                        {renderCollapseIcon(
                                          collateralCollapse[index],
                                          "bool"
                                        )}
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                  <Collapse in={collateralCollapse[index]}>
                                    <Paper
                                      className={clsx(
                                        classes.paper,
                                        "p-2 m-0",
                                        classes.collateralCollapse
                                      )}
                                    >
                                      <List dense>
                                        {/* <ListItem>
                                          <ListItemText>
                                            Collateral Type
                                          </ListItemText>
                                          <ListItemSecondaryAction>
                                            <small>{sec.collateral_type}</small>
                                          </ListItemSecondaryAction>
                                        </ListItem> */}
                                        <ListItem>
                                          <ListItemText>
                                            Year Of Purchase
                                          </ListItemText>
                                          <ListItemSecondaryAction>
                                            <small>
                                              {sec.year_of_purchase}
                                            </small>
                                          </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                          <ListItemText>
                                            Collateral Value
                                          </ListItemText>
                                          <ListItemSecondaryAction>
                                            <small>
                                              {sec.collateral_value}
                                            </small>
                                          </ListItemSecondaryAction>
                                        </ListItem>
                                        <div className="d-flex flex-wrap">
                                          {sec.attachments.map(
                                            (attachment, index) => {
                                              return (
                                                <React.Fragment>
                                                  <Chip
                                                    className="m-1"
                                                    icon={<Attachment />}
                                                    deleteIcon={
                                                      <RemoveRedEyeOutlined />
                                                    }
                                                    onDelete={viewAttachment(
                                                      attachment
                                                    )}
                                                    label={
                                                      "Attachment " +
                                                      (index + 1)
                                                    }
                                                  />
                                                  <Dialog
                                                    scroll="body"
                                                    fullWidth={true}
                                                    maxWidth="lg"
                                                    open={
                                                      collateralDialogState[
                                                        attachment
                                                      ]
                                                    }
                                                  >
                                                    <DialogTitle>
                                                      <div className="d-flex justify-content-between align-items-center">
                                                        <Typography variant="h5">
                                                          View Document
                                                        </Typography>
                                                        <IconButton
                                                          onClick={viewAttachment(
                                                            attachment
                                                          )}
                                                        >
                                                          <Close />
                                                        </IconButton>
                                                      </div>
                                                    </DialogTitle>
                                                    <DialogContent>
                                                      <iframe
                                                        ref={iFrame}
                                                        style={{
                                                          width: "100%",
                                                          height: "1000px",
                                                        }}
                                                        title={sec.title}
                                                        src={renderDocumentLoanUrl(
                                                          attachment
                                                        )}
                                                      ></iframe>
                                                    </DialogContent>
                                                  </Dialog>
                                                </React.Fragment>
                                              );
                                            }
                                          )}
                                        </div>
                                      </List>
                                    </Paper>
                                  </Collapse>
                                </div>
                              );
                            })}
                          </List>
                        </div>
                      </Paper>
                      <Paper className={clsx(classes.paper, "p-4 mt-3")}>
                        <div>
                          <Typography variant="h6">Loan Documents</Typography>
                          <Divider className="my-1" />
                        </div>
                        <List>
                          {documents.map((doc, index) => {
                            return (
                              <ListItem key={index}>
                                <ListItemText>{doc}</ListItemText>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <List>
                        <ListItem
                          className={classes.listItem}
                          button
                          onClick={onCollapseChange("borrowerDetails")}
                        >
                          <ListItemText>
                            <b>Borrower Details</b>
                          </ListItemText>
                          <ListItemSecondaryAction>
                            <IconButton
                              onClick={onCollapseChange("borrowerDetails")}
                            >
                              {renderCollapseIcon("borrowerDetails")}
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Collapse in={collapse.borrowerDetails}>
                          <Paper className={classes.paper}>
                            <Grid container>
                              <Grid item xs={12} md={4}>
                                <Typography>
                                  Name:
                                  <small>{borrower.name}</small>
                                </Typography>
                                <Typography>
                                  Account Number:
                                  <small>{borrower.account_number}</small>
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Typography>
                                  Phone
                                  <small>{borrower.primary_phone_number}</small>
                                </Typography>
                                <Typography>
                                  Email: <small>{borrower.email}</small>
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Typography>
                                  Physical Address:{" "}
                                  <small>{borrower.physical_address}</small>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Collapse>

                        <ListItem
                          button
                          className={classes.listItem}
                          onClick={onCollapseChange("guarantorDetails")}
                        >
                          <ListItemText>
                            <b>Guarantor details</b>
                          </ListItemText>
                          <ListItemSecondaryAction>
                            <IconButton
                              onClick={onCollapseChange("guarantorDetails")}
                            >
                              {renderCollapseIcon("guarantorDetails")}
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Collapse in={collapse.guarantorDetails}>
                          <Paper className={classes.paper}>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item>
                                <Avatar
                                  className={classes.avatar}
                                  sizes="100%"
                                  style={{ width: "100px", height: "100px" }}
                                  src={renderDocumentLoanUrl(
                                    guarantorDocuments.passport_photo
                                  )}
                                />
                              </Grid>
                              <Grid item md={4}>
                                <Typography>
                                  Name:
                                  <small>{guarantor.name}</small>
                                </Typography>
                                <Typography>
                                  Account Number:
                                  <small>{guarantor.account_number}</small>
                                </Typography>
                                <Typography>
                                  Physical Address:{" "}
                                  <small>
                                    {" "}
                                    &nbsp; &nbsp;
                                    {guarantor.physical_address ||
                                      guarantor.address}
                                  </small>
                                </Typography>
                              </Grid>
                              <Grid item md={4}>
                                <Typography>
                                  Phone: &nbsp;
                                  <small className="ml-2">
                                    {guarantor.primary_phone_number ||
                                      guarantor.phone_number}
                                  </small>
                                </Typography>
                                <Typography>
                                  Email: <small>{guarantor.email}</small>
                                </Typography>
                                <Typography>
                                  Nin: <small>{guarantor.id_number}</small>
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item>
                                <LazyLoadImage
                                  src={renderDocumentLoanUrl(
                                    guarantorDocuments.id_card_front
                                  )}
                                  className={classes.idCardPhoto}
                                />
                              </Grid>
                              <Grid item>
                                <LazyLoadImage
                                  src={renderDocumentLoanUrl(
                                    guarantorDocuments.id_card_back
                                  )}
                                  className={classes.idCardPhoto}
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Collapse>

                        <ListItem
                          className={classes.listItem}
                          button
                          onClick={onCollapseChange("loanSchedule")}
                        >
                          <ListItemText>
                            <b>Loan Schedule</b>
                          </ListItemText>
                          <ListItemSecondaryAction>
                            <IconButton
                              onClick={onCollapseChange("loanSchedule")}
                            >
                              {renderCollapseIcon("loanSchedule")}
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Collapse in={collapse.loanSchedule}>
                          <Paper className={clsx(classes.paper)}>
                            <LoanInstallmentRender
                              loan={loan}
                              installments={installments}
                            />
                          </Paper>
                        </Collapse>
                      </List>
                    </Grid>
                  </Grid>
                );
              }}
            </ApiRequest>
          </Container>
        </Paper>
      </Dialog>
    </React.Fragment>
  );
}
